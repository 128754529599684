<div class="card mb-1">
    <div class="card-body card-body-cwi">
        <div class="system">
            <div class="systemName">
                <button class="button-text btn btn-sm btn-outline-primary" (click)="$event.stopPropagation(); systemClick()">
                    {{ systemStatus.name }}
                </button>
                <!-- <span class="fas ml-2" [ngClass]="systemStatus.status"
                    [class.fa-check-circle]="systemStatus.status==='Working'"
                    [class.fa-exclamation]="systemStatus.status==='Warning'"
                    [class.fa-exclamation-triangle]="systemStatus.status==='Error'"
                    title="{{returnTitle()}}">
                </span> -->
            </div>
            <div class="wrapper mt-2 text-secondary">
                <h6 class="ml-1" i18n>Setup:</h6>
                <label class="ml-2"> {{ systemStatus.setup }}</label>
            </div>
            <div class="wrapper text-secondary">
                <h6 class="ml-1" i18n>Mode:</h6>
                <label class="ml-2"> {{ systemStatus.mode }}</label>
            </div>
            <!--  <h6 class="text-secondary">{{ systemStatus.setup }} - {{ systemStatus.mode }} </h6> -->
            <cwi-remote-probe-status *ngFor="let probe of systemStatus?.probes"
                [probeStatus]=probe>
            </cwi-remote-probe-status>
        </div>
    </div>
</div>
