import { Component } from '@angular/core';
import { AuthenticationService, UserClaims } from '@cwi/components';

@Component({
    selector: 'cwi-pg-usersview',
    templateUrl: './user-management-usersview.component.html',
    styleUrls: ['./user-management-usersview.component.scss'],
    providers: [],
  })
  export class UserManagementUsersViewComponent {

    userInfo: UserClaims;

    constructor(
        private readonly authenticationService: AuthenticationService,
    ) {
      this.userInfo = this.authenticationService.userInfo;
    }
}
