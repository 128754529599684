export function isNullOrUndefined(value: any): value is null|undefined {
  return value == null;
}


export function *filterIterable<TIn>(input: Iterable<TIn>, selector: (element: TIn) => boolean): Iterable<TIn> {
  for (const entry of input) {
    if (selector(entry)) {
      yield entry;
    }
  }
}

export function *mapIterable<TIn, TOut>(input: Iterable<TIn>, transform: (element: TIn) => TOut): Iterable<TOut> {
  for (const entry of input) {
    yield transform(entry);
  }
}

export function *flatIterable<TIn>(input: Iterable<Iterable<TIn>>): Iterable<TIn> {
  for (const iterable of input) {
    for (const item of iterable) {
      yield item;
    }
  }
}

export function firstIterable<TIn>(input: Iterable<TIn>): TIn|undefined {
  for (const item of input) {
    return item;
  }
}

export function isBoolean(bool): bool is boolean {
  return typeof bool === 'boolean';
}

export function isString(str): str is string {
  return typeof str === 'string' || str instanceof String;
}

export function isNumber(num): num is number {
  if (typeof num === 'number') {
    return isFinite(num);
  } else if (isString(num) && num.trim() !== '') {
    return (Number.isFinite || isFinite)(+num);
  } else {
    return false;
  }
}

export function any<T>(iterable: Iterable<T>) {
  for (const _ of iterable) {
    return true;
  }

  return false;
}

export function join<T>(iterable: Iterable<T>, separator: string) {
  const iterator = iterable[Symbol.iterator]();
  let result = '';
  let value = iterator.next();
  if (!value.done) {
    result = value.value.toString();
    value = iterator.next();
    while (!value.done) {
      result += separator + value.value;
      value = iterator.next();
    }
  }

  return result;
}
