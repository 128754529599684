import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUploadTrackDatabaseService, ITopologyConfiguration, TOPOLOGY_CONFIGURATION } from './service-configuration';

@Injectable()
export class UploadtTrackDatabaseService implements IUploadTrackDatabaseService {

  baseUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(TOPOLOGY_CONFIGURATION)
    configuration: ITopologyConfiguration) {
      this.baseUrl = configuration.baseUrl;
  }

  upload(formData: FormData): Observable<HttpEvent<any>> {
    const req = new HttpRequest('POST', `${this.baseUrl}/database`, formData, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request(req);
  }

  confirm(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/confirm/database`, {});
  }

  getConfirmable(): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/confirmable/database`);
  }
}
