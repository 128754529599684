import { InjectionToken } from '@angular/core';

export interface Section {
  title: string;
  description: string;
  link: string[];
  linkCaption: string;
  roles: ReadonlySet<string>;
}

export interface Logo {
  alt?: string;
  width?: number;
  height?: number;
  path: string;
}

export interface HomeConfiguration {
  greetings: string;
  logos: Logo[];
}

export interface LoginPageConfiguration {
  logos: Logo[]
}

export const Version = require('../../../../../package.json').version;

export const PAGES_SECTION = new InjectionToken<Section>('PAGES_SECTION');
export const HOME_CONFIGURATION = new InjectionToken<HomeConfiguration>('HOME_CONFIGURATION');
export const LOGIN_PAGE_CONFIGURATION = new InjectionToken<LoginPageConfiguration>('LOGIN_PAGE_CONFIGURATION');
