<form [formGroup]="deleteConfirmationForm" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <h2 class="text-danger">{{ deleteModalSettings.title }}</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close();">
            <span aria-hidden="false">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3>{{ deleteModalSettings.text }}</h3>
        <p i18n>To confirm insert the following characters:</p>
        <p class="text-center font-weight-bold">{{ confirmationString }}</p>
        <div class="form-container">
            <input type="text" class="form-control input-text text-center"
                placeholder="Confirm..."
                formControlName="confirmText"
                [class.is-valid]="deleteConfirmationForm.controls.confirmText.valid && deleteConfirmationForm.controls.confirmText.touched"
                [class.is-invalid]="deleteConfirmationForm.controls.confirmText.invalid && deleteConfirmationForm.controls.confirmText.touched" />
            <div class="invalid-feedback">
                <ng-container *ngTemplateOutlet="defaultConfirmationTemplate; context: { $implicit: deleteConfirmationForm.controls.confirmText.errors }"></ng-container>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" [disabled]="!deleteConfirmationForm.valid" class="btn btn-primary">Confirm</button>
    </div>
</form>

<ng-template #defaultConfirmationTemplate let-errors>
    <ul *ngIf="errors">
        <li i18n *ngIf="errors.required">This field is required.</li>
        <li i18n *ngIf="errors.charsDontMatch">Inserted characters do not match.</li>
    </ul>
</ng-template>
