import { Component, Inject } from '@angular/core';
import { ISoftwareUpdatesService, SOFTWAREUPDATES_SERVICE } from '../services/software-updates';
import { Observable } from 'rxjs';
import { SoftwareUpdatesClient, SoftwareUpdatesPackage } from '../services/software-updates-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssignPackageModalComponent } from '../components/assign-package-modal/assign-package-modal.component';
import { UploadPackageModalComponent } from '../components/upload-package-modal/upload-package-modal.component';
import { DeletePackageModalComponent } from '../components/delete-package-modal/delete-package-modal.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cwi-pg-software-updates-view',
  templateUrl: './software-updates-view.component.html',
  styleUrls: ['./software-updates-view.component.scss'],
  providers: [],
})
export class SoftwareUpdatesViewComponent {

  error: string
  clients$: Observable<Iterable<SoftwareUpdatesClient>>
  packages$: Observable<Iterable<SoftwareUpdatesPackage>>

  clientColumnsToDisplay = ['trainSystemWagon', 'id', 'name', 'tag', 'installedVersion', 'assignedVersion', 'apps', 'actions'];
  packageColumnsToDisplay = ['tagVersion', 'notes', 'actions'];

  constructor(
    @Inject(SOFTWAREUPDATES_SERVICE)
    private readonly softwareUpdatesService: ISoftwareUpdatesService,
    private readonly modalService: NgbModal
  ) {
    this.clients$ = softwareUpdatesService.clients$.pipe(
      map((clients, idx) => {
        for (let c of clients) {
          c.apps = c.apps.filter(v => v.runningVersion != '')
        }
        return clients
      }))
    this.packages$ = softwareUpdatesService.packages$
  }

  refreshClients() {
    this.softwareUpdatesService.reload()
  }

  refreshPackages() {
    this.softwareUpdatesService.reload()
  }

  deletePackage(updatePackage: SoftwareUpdatesPackage) {
    const modalRef = this.modalService.open(DeletePackageModalComponent, {
      centered: true
    });

    modalRef.result.then((userResponse) => {
      if (userResponse) {
        this.softwareUpdatesService.deletePackage(updatePackage)
          .subscribe(
            (response) => {
              this.refreshPackages()
            },
            (error) => {
              this.error = $localize `Error deleting package`
            }
          )
      }
    })
  }

  openUploadPackageModal() {
    this.modalService.open(UploadPackageModalComponent, {
      centered: true
    });
  }

  closeAlert() {
    this.error = null
  }

  openAssignPackageModal(client: SoftwareUpdatesClient) {
    const modalRef = this.modalService.open(AssignPackageModalComponent, {
      centered: true
    });
    const componentInstance = modalRef.componentInstance;

    modalRef.result.then((userResponse) => {
      if (userResponse) {
        const value = componentInstance.assignedPackageVersion ?? '0'
        this.softwareUpdatesService.setClientVersion(client, value)
          .subscribe(
            (response) => {
              this.refreshClients()
            },
            (error) => {
              this.error = $localize `Error in assigning package version.`;
            })
      }
    })
  }
}
