import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ISoftwareUpdatesService, SOFTWAREUPDATES_SERVICE } from "../../services/software-updates";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'cwi-upload-package-modal-component',
  templateUrl: './upload-package-modal.component.html',
  styleUrls: [],
  providers: [],
})
export class UploadPackageModalComponent {
  file: File | null = null
  notes: string = ""
  // It stores the upload status
  status: "initial" | "uploading" | "success" | "fail" = "initial"
  error: string = null

  constructor(
    @Inject(SOFTWAREUPDATES_SERVICE)
    private readonly softwareUpdatesService : ISoftwareUpdatesService,
    public activeModal: NgbActiveModal) {}

  onChange(event: any) {
    const file: File = event.target.files[0]

    if (file) {
      this.status = 'initial'
      this.file = file
    }
  }

  onUpload() {
    this.status = 'uploading';
    this.softwareUpdatesService.uploadPackage(this.file, this.notes).subscribe({
      next: () => {
        this.status = 'success'
      },
      error: (error: HttpErrorResponse) => {
        this.status = 'fail'
        this.error = error.message
      },
    })
  }
}
