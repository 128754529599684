import { Component, OnDestroy, ElementRef, Input } from '@angular/core';
import { LeafletDirective } from '@asymmetrik/ngx-leaflet';
import { Subscription } from 'rxjs';
import * as L from 'leaflet';
import { startWith, filter, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'cwi-control-group',
  templateUrl: './control-group.component.html',
  styleUrls: ['./control-group.component.scss']
})
export class ControlGroupComponent extends L.Control implements OnDestroy {

  @Input()
  set position(value: L.ControlPosition) {
    if (value) {
      this.setPosition(value);
    }
  }

  private readonly subscription: Subscription;

  constructor(private readonly element: ElementRef<HTMLElement>, leaflet: LeafletDirective) {
    super({
      position: 'topleft'
    });
    this.subscription = leaflet.mapReady.pipe(
      startWith(leaflet.map),
      filter(m => !!m),
      distinctUntilChanged()
    ).subscribe(map => this.onMapReady(map));
  }

  onMapReady(map: L.Map) {
    this.addTo(map);
  }

  onAdd() {
    return this.element.nativeElement;
  }

  ngOnDestroy(): void {
    this.remove();
    this.subscription.unsubscribe();
  }
}
