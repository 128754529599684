// Ho tanta voglia di aggiungere tstl come libreria
// che mima i container del c++ che sono IMHO i migliori
// (se poi aggiungi le estension boost, non ne parliamo)
// Ma per ora copio invece alcune implementazioni dalle
// nostre CWI.Libraries

export interface ForwardLinkedListNode<T> {
  next: ForwardLinkedListNode<T>;
  value: T;
}

// Implementazione di circular single-linked list (forward).
export class ForwardLinkedList<T> implements Iterable<T> {

  private last: ForwardLinkedListNode<T>;
  private count = 0;

  public get size() {
    return this.count;
  }

  // public ForwardLinkedListNode<T> First => last.next;

  public constructor()
  public constructor(collection: Iterable<T>)
  public constructor(collection?: Iterable<T>) {
    const last = { next: null, value: null };
    last.next = last;
    this.last = last;

    if (collection) {
      for (const item of collection) {
        this.addLast(item);
      }
    }
  }


  public add(item: T) {
    this.addLast(item);
  }

  public addAfter(node: ForwardLinkedListNode<T>, value: T) {
    const result = {
      value,
      next: null
    };
    this.addNodeAfter(node, result);
    return result;
  }

  public addNodeAfter(node: ForwardLinkedListNode<T>, newNode: ForwardLinkedListNode<T>) {
    newNode.next = node.next;
    node.next = newNode;
    this.count++;
  }

  public addFirst(value: T) {
    const result = {
      value,
      next: null
    };
    this.addNodeFirst(result);
    return result;
  }

  public addNodeFirst(node: ForwardLinkedListNode<T>) {
    node.next = this.last.next;
    this.last.next = node;
    this.count++;
  }

  public addLast(value: T) {
    const result = this.last;
    const node = {
      value: null,
      next: null
    };

    node.next = this.last.next;
    this.last.next = node;
    this.last.value = value;
    this.last = node;
    this.count++;
    return result;
  }

  public clear() {
    this.last = {
      value: null,
      next: null
    };
  }

  *[Symbol.iterator]() {
    const last = this.last;
    const current = last;
    while (current.next !== last) {
      yield current.value;
    }
  }
}

