import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentsModule } from '@cwi/components';
import { USER_SERVICE } from '../settings/services/service-configuration';
import { UserService } from '../settings/services/user-service';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { UserSettingsRoutingModule } from './user-settings-routing.module';
import { UserSettingsViewComponent } from './user-settings-view/user-settings-view.component';
import { UserSettingsComponent } from './user-settings.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    UserSettingsComponent,
    UserSettingsViewComponent,
    ChangePasswordComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    UserSettingsRoutingModule,
    NgbAlertModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
  ],
  exports: [
    ChangePasswordComponent
  ],
  providers: [
    {
      provide: USER_SERVICE,
      useClass: UserService
    },
  ],
})
export class UserSettingsModule { }
