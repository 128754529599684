import { Component, Inject, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PlanDefinition } from '../plan-definition';
import { SpeedModeDefinition } from '../speed-mode-definition';
import { IRemoteControllerSectionConfiguration, REMOTECONTROLLER_SECTION_CONFIGURATION } from '../../../pages/lib/remote-controller/section.configuration';
import { UnitOfMeasurement } from '../unit-of-measurement';

@Component({
  selector: 'cwi-remote-speed-mode-creator',
  templateUrl: './speed-mode-creator.component.html',
  styleUrls: ['./speed-mode-creator.component.scss']
})
export class SpeedModeComponent implements OnDestroy {
  
  private subscription = new Subscription();
  private speedModeInfoInternal: SpeedModeDefinition;
  edit: boolean;
  speedModeForm: FormGroup;

  @Input()
  setups: string[];
  
  @Input()
  isActive: boolean;

  @Input()
  selectedPlan: PlanDefinition;

  @Input()
  public set speedModeInfo(value: SpeedModeDefinition) {
    this.speedModeInfoInternal = value;
    if (value) {
      this.setPreviusValues();
      this.edit = true;
    } else {
      this.edit = false;
    }
  }

  @Output()
  public readonly result = new EventEmitter<SpeedModeDefinition>();

  @Output()
  public readonly canceled = new EventEmitter<void>();

  constructor(
    @Inject(REMOTECONTROLLER_SECTION_CONFIGURATION)
  readonly sectionConfiguration: IRemoteControllerSectionConfiguration
  ) {
    this.speedModeForm = new FormGroup({
      setup: new FormControl(null, Validators.required),
      recordingSpeed: new FormControl(null, Validators.required),
      stopSpeed: new FormControl(null, Validators.required),
      recordingDebounce: new FormControl(null, Validators.required),
      stopDebounce: new FormControl(null, Validators.required),
      speedSource: new FormControl(null, Validators.required)
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setPreviusValues() {
    if (this.sectionConfiguration.unitOfMeasurement === UnitOfMeasurement.mph) {
      this.speedModeForm.get('recordingSpeed').setValue(Math.trunc(this.speedModeInfoInternal.recordingSpeed / 1.60934));
      this.speedModeForm.get('stopSpeed').setValue(Math.trunc(this.speedModeInfoInternal.stopSpeed / 1.60934));
    }
    else {
      this.speedModeForm.get('recordingSpeed').setValue(this.speedModeInfoInternal.recordingSpeed);
      this.speedModeForm.get('stopSpeed').setValue(this.speedModeInfoInternal.stopSpeed);
    }
    
    this.speedModeForm.get('setup').setValue(this.speedModeInfoInternal.setup);
  
    this.speedModeForm.get('recordingDebounce').setValue(this.speedModeInfoInternal.recordingDebounce);
    this.speedModeForm.get('stopDebounce').setValue(this.speedModeInfoInternal.stopDebounce);
    this.speedModeForm.get('speedSource').setValue(this.speedModeInfoInternal.speedSource);
  }

  close() {
    this.canceled.next();
  }

  cancel() {
    this.speedModeForm.reset();
    this.setPreviusValues();
  }

  onSubmit() {
    if (this.speedModeForm.valid) {
      if (this.sectionConfiguration.unitOfMeasurement === UnitOfMeasurement.mph) {
        this.speedModeForm.value.recordingSpeed = this.speedModeForm.value.recordingSpeed * 1.60934;
        this.speedModeForm.value.stopSpeed = this.speedModeForm.value.stopSpeed * 1.60934;
      }
      
      this.speedModeForm.value.recordingDebounce = Math.trunc(this.speedModeForm.value.recordingDebounce);
      this.speedModeForm.value.stopDebounce = Math.trunc(this.speedModeForm.value.stopDebounce);
      
      const id = this.speedModeInfoInternal ? this.speedModeInfoInternal.id : null;
      this.result.emit({
        id,
        ...this.speedModeForm.value
      });
    }
  }

  disableSubmit() {
    if (this.speedModeForm) {
      if (this.isActive) {
        return true;
      } else {
        return !this.speedModeForm.valid;
      }
    } else {
      return !this.speedModeForm.valid;
    }
  }

  getTitle() {
    return this.edit ? $localize`Edit speed mode` : $localize`Add speed mode`;
  }
}
