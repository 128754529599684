import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TrainDefinition } from '../train-definition';

@Component({
  selector: 'cwi-remote-trains-list',
  templateUrl: './trains-list.component.html',
  styleUrls: ['./trains-list.component.scss']
})
export class TrainsListComponent {
  @Input()
  isVisible: boolean;

  @Input()
  public trains: Iterable<TrainDefinition>;

  @Output()
  public readonly trainSelected = new EventEmitter<TrainDefinition>();

  @Output()
  public readonly followClicked = new EventEmitter<TrainDefinition>();

  trainClick(train: TrainDefinition) {
    this.trainSelected.next(train);
  }

  trainTrackBy(index: number, train: TrainDefinition) {
    return train.name;
  }

  followClick(train: TrainDefinition) {
    this.followClicked.next(train);
  }

}
