import { Component, OnDestroy, Input, ElementRef, HostBinding, NgZone, Output, EventEmitter, ɵConsole } from '@angular/core';
import { LeafletDirective } from '@asymmetrik/ngx-leaflet';
import { Subscription } from 'rxjs';
import * as L from 'leaflet';
import { TrainDefinition } from '../train-definition';
import { SystemDefinition } from '../system-definition';
import moment, { isMoment, Moment } from 'moment';
import { startWith, filter, distinctUntilChanged } from 'rxjs/operators';

const DELAY_POSITION = 15; // minutes

export function getAveragePosition(train: TrainDefinition): L.LatLngTuple {
  let xSum = 0;
  let ySum = 0;
  let count = 0;
  for (const system of train.systems) {
    for (const probe of system.probes) {
      if (probe.status !== 'Unreachable'
          && probe.position) {
        xSum += probe.position.lat;
        ySum += probe.position.lng;
        count++;
      }
    }
  }

  if (count) {
    const xMedium = xSum / count;
    const yMedium = ySum / count;
    return xMedium && yMedium ? [xMedium, yMedium] : null;
  } else {
    return null;
  }
}

function isProbeUpdated(lastUpd: any): boolean {
  let lastUpdate: Moment;
  if (isMoment(lastUpdate)) {
    lastUpdate = lastUpd;
  } else {
    lastUpdate = moment(lastUpd);
  }

  const now = moment().utc(true);
  const lastUpdateToSeconds = getTotalSeconds(lastUpdate) + DELAY_POSITION * 60;
  const nowToSeconds = getTotalSeconds(now);
  const result = lastUpdate.dayOfYear() >= now.dayOfYear()
                && lastUpdateToSeconds >= nowToSeconds;
  return result;
}

function getTotalSeconds(mom: Moment): number {
  return mom.seconds() +
          mom.minutes() * 60 +
          mom.hours() * 3600;
}

@Component({
  selector: 'cwi-remote-train-map-marker',
  templateUrl: './train-map-marker.component.html',
  styleUrls: ['./train-map-marker.component.scss']
})
export class TrainMapMarkerComponent implements OnDestroy {

  @HostBinding('class.d-none')
  hidden = true;

  private trainStatusInternal: TrainDefinition;
  followTrainInternal: boolean;

  @Input()
  set trainStatus(value: TrainDefinition) {
    this.trainStatusInternal = value;
    if (value) {
      const averagePosition = getAveragePosition(value);
      if (averagePosition) {
        this.upsertMarker(averagePosition);
      }
    } else {
      this.removeMarker();
    }
  }
  get trainStatus() {
    return this.trainStatusInternal;
  }

  @Input()
  isClickDisabled: boolean;

  @Input()
  set followTrain(value: boolean) {
    this.followTrainInternal = value;
    if (value &&
      this.trainStatusInternal) {
        const averagePosition = getAveragePosition(this.trainStatusInternal);
        if (averagePosition) {
          this.map.setView(averagePosition, 15);
        }
    }
  }
  get followTrain() {
    return this.followTrainInternal;
  }

  @Output()
  markerClicked = new EventEmitter<void>();

  private readonly subscription: Subscription;
  private marker: L.Marker;
  private map: L.Map;

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly zone: NgZone,
    leaflet: LeafletDirective
  ) {
    this.map = leaflet.map;
    this.subscription = leaflet.mapReady.pipe(
      startWith(leaflet.map),
      filter(m => !!m),
      distinctUntilChanged()
    ).subscribe(leafletMap => this.onMapReady(leafletMap));
  }

  onMapReady(leafletMap: L.Map) {
    this.map = leafletMap;
    if (this.marker) {
      this.marker.remove();
      this.marker.addTo(this.map);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.removeMarker();
  }

  upsertMarker(averagePosition: L.LatLngTuple) {
    if (!this.marker) {

      const icon = L.divIcon({
        html: '<i class="fa fa-train fa-2x" style="text-shadow:2px 2px 4px #585858;"></i>',
        popupAnchor: [-95, -10]
      });

      this.marker = L.marker(
        averagePosition,
        {
          icon,
        }
      );

      this.marker.bindTooltip(this.trainStatus.name);
      this.marker.bindPopup(this.elementRef.nativeElement);

      this.marker.on('popupopen', () => this.zone.run(() => {
        this.hidden = false;
        this.marker.closeTooltip();
      }));

      this.marker.on('popupclose', () => this.zone.run(() => {
        this.hidden = true;
      }));

      /* this.marker.on('click', () => {
        if (!this.isClickDisabled) {
          this.markerClicked.next();
        }
      }); */

      if (this.map) {
        this.marker.addTo(this.map);
      }
    }
    this.marker.setLatLng(averagePosition);

    if (this.followTrainInternal &&
      this.trainStatusInternal) {
      this.map.setView(averagePosition, 15);
    }
  }

  removeMarker() {
    if (this.marker) {
      this.marker.remove();
      this.marker.clearAllEventListeners();
      this.marker = null;
    }
  }

  systemTrackBy(index: number, system: SystemDefinition) {
    return system.name;
  }
}
