import { Directive, ElementRef, OnDestroy, Output, NgZone } from '@angular/core';
import { Subject, combineLatest, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Directive({
  selector: '[cwiSizeChanged]'
})
export class SizeChangedDirective implements OnDestroy {
  private readonly heigthSubject = new Subject<number>();
  private readonly widthSubject = new Subject<number>();
  private subscription: Subscription;

  @Output()
  public readonly heightChanged;

  @Output()
  public readonly widthChanged;

  @Output()
  public readonly cwiSizeChanged;

  constructor(element: ElementRef<HTMLElement>, zone: NgZone) {

    this.heightChanged = this.heigthSubject.pipe(
      startWith(element.nativeElement.offsetHeight),
    );
    this.widthChanged = this.widthSubject.pipe(
      startWith(element.nativeElement.offsetWidth),
    );

    this.cwiSizeChanged = combineLatest([this.widthChanged, this.heightChanged]).pipe(
      map(([width, height]) => ({ width, height }))
    );

    let id;
    zone.runOutsideAngular(() => {
      let width;
      let height;
      const update = () => {
        const { offsetHeight, offsetWidth } = element.nativeElement;
        if (height !== offsetHeight) {
          height = offsetHeight;
          zone.run(() => this.heigthSubject.next(height));
        }

        if (width !== offsetWidth) {
          width = offsetWidth;
          zone.run(() => this.widthSubject.next(width));
        }

        id = window.requestAnimationFrame(update);
      };

      update();
    });

    this.subscription = new Subscription(() => { window.cancelAnimationFrame(id); });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
