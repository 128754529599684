import { HomeConfiguration, LoginPageConfiguration } from '@cwi/pages';
import { IRemoteControllerSectionConfiguration } from 'shared/cwi/pages/lib/remote-controller/section.configuration';
import { UnitOfMeasurement } from 'shared/cwi/remote-controller/lib/unit-of-measurement';

export const EvoiaDtHomeConfiguration: HomeConfiguration = {
  greetings: ``,
  logos: [
    {
      path: 'assets/network-rail-logo.svg',
      alt: 'Network Rail logo',
      height: 50
    }
  ]
};

export const EvoiaDtLoginPageConfiguration: LoginPageConfiguration = {
  logos: [
    {
      path: 'assets/viavi-solutions-vector-logo.svg',
      alt: 'VIAVI Solutions logo',
      height: 100
    }
  ]
}

export const EvoiaDtRemoteControllerSectionConfiguration: IRemoteControllerSectionConfiguration = {
  title: 'Remote Controller',
  description: $localize`Remote controller`,
  linkCaption: $localize`Go to remote controller`,
  navigationHeadText: 'Remote Controller',
  unitOfMeasurement: UnitOfMeasurement.mph
}
