import {
  BaseChartComponent,
  calculateViewDimensions,
  ViewDimensions,
  ColorHelper,
  getUniqueXDomainValues,
  getScaleType
} from '@swimlane/ngx-charts';

import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  HostListener,
  ChangeDetectionStrategy,
  ContentChild,
  TemplateRef,
  ElementRef,
  NgZone,
  ChangeDetectorRef,
  ContentChildren,
  QueryList,
  ViewChildren,
  AfterViewInit,
  OnDestroy,
  OnChanges
} from '@angular/core';

import { trigger, style, animate, transition } from '@angular/animations';
import { scaleLinear, scaleTime, scalePoint } from 'd3-scale';
import { curveLinear, CurveFactory } from 'd3-shape';
import shortid from 'shortid';
import { isNumber } from '@cwi/lib';
import { ComboChartSeries } from './combo-chart-model';
import { ComboChartSeriesDirective, ComboChartSeriesDirectiveContext } from './combo-chart-series.directive';
import { Subscription, fromEvent } from 'rxjs';
import { VisibilityObserver } from '@swimlane/ngx-charts';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'cwi-combo-chart',
  templateUrl: './combo-chart.component.html',
  styleUrls: ['./combo-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition(':leave', [
        style({
          opacity: 1
        }),
        animate(
          500,
          style({
            opacity: 0
          })
        )
      ])
    ])
  ]
})
export class ComboChartComponent implements AfterViewInit, OnDestroy, OnChanges {

  private subscription: Subscription;
  private readonly availableSeries = new Map<string, TemplateRef<ComboChartSeriesDirectiveContext<string, any>>>();
  readonly clipPath: string;
  readonly clipPathId: string;

  @Input() public results: any;
  @Input() public view: [number, number];
  @Input() public scheme: any = 'cool';
  @Input() public schemeType = 'ordinal';
  @Input() public customColors: any;
  @Input() public animations = true;
  @Input() public legend = true;
  @Input() public legendTitle = 'Legend';
  @Input() public legendPosition: 'right'|'below' = 'right';
  @Input() public xAxis = true;
  @Input() public yAxis = true;
  @Input() public showXAxisLabel = false;
  @Input() public showYAxisLabel = false;
  @Input() public autoScale = true;
  @Input() public timeline = true;
  @Input() public gradient = true;
  @Input() public xAxisLabel: string;
  @Input() public yAxisLabel: string;
  @Input() public showGridLines = true;
  @Input() public curve: CurveFactory = curveLinear;
  @Input() public activeEntries: any[] = [];
  @Input() public rangeFillOpacity: number;
  @Input() public trimXAxisTicks = true;
  @Input() public trimYAxisTicks = true;
  @Input() public rotateXAxisTicks = true;
  @Input() public maxXAxisTickLength = 16;
  @Input() public maxYAxisTickLength = 16;
  @Input() public xAxisTickFormatting: any;
  @Input() public yAxisTickFormatting: any;
  @Input() public xAxisTicks: any[];
  @Input() public yAxisTicks: any[];
  @Input() public roundDomains = false;
  @Input() public tooltipDisabled = false;
  @Input() public showRefLines = false;
  @Input() public referenceLines: any;
  @Input() public showRefLabels = true;
  @Input() public xScaleMin: any;
  @Input() public xScaleMax: any;
  @Input() public yScaleMin: number;
  @Input() public yScaleMax: number;

  @Output() public select = new EventEmitter();
  @Output() public activate: EventEmitter<any> = new EventEmitter();
  @Output() public deactivate: EventEmitter<any> = new EventEmitter();

  @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;
  @ContentChild('seriesTooltipTemplate') seriesTooltipTemplate: TemplateRef<any>;

  @ViewChildren(ComboChartSeriesDirective) defaultSeries!: QueryList<ComboChartSeriesDirective<string, any>>;
  @ContentChildren(ComboChartSeriesDirective) customSeries!: QueryList<ComboChartSeriesDirective<string, any>>;

  dims: ViewDimensions;
  xSet: any;
  xDomain: any;
  yDomain: any;
  seriesDomain: any;
  yScale: any;
  xScale: any;
  colors: ColorHelper;
  scaleType: string;
  transform: string;
  series: any;
  areaPath: any;
  margin = [10, 20, 10, 20];
  hoveredVertical: any; // the value of the x axis that is hovered over
  xAxisHeight = 0;
  yAxisWidth = 0;
  filteredDomain: any;
  legendOptions: any;
  hasRange: boolean; // whether the line has a min-max range around it
  timelineWidth: any;
  timelineHeight = 50;
  timelineXScale: any;
  timelineYScale: any;
  timelineXDomain: any;
  timelineTransform: any;
  timelinePadding = 10;
  width: number;
  height: number;
  resizeSubscription: any;
  visibilityObserver: VisibilityObserver;

  constructor(protected chartElement: ElementRef, protected zone: NgZone, protected cd: ChangeDetectorRef) {
    this.clipPathId = `clip${shortid()}`;
    this.clipPath = `url(#${this.clipPathId})`;
  }

  log = console.log;

  ngOnChanges(): void {
    this.update();
  }

  getContainerDims(): any {
    let width;
    let height;
    const hostElem = this.chartElement.nativeElement;

    if (hostElem) {
      // Get the container dimensions
      const dims = hostElem.getBoundingClientRect();
      width = dims.width;
      height = dims.height;
    }

    if (width && height) {
      return { width, height };
    }

    return null;
  }

  needCircles(series: ComboChartSeries<string, any>): boolean {
    console.log(series.circles);
    return series.circles;
  }

  formatDates(): void {
    for (const g of this.results) {
      g.label = g.name;
      if (g.label instanceof Date) {
        g.label = g.label.toLocaleDateString();
      }

      if (g.series) {
        for (const d of g.series.length) {
          d.label = d.name;
          if (d.label instanceof Date) {
            d.label = d.label.toLocaleDateString();
          }
        }
      }
    }
  }

  protected unbindEvents(): void {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  private bindWindowResizeEvent(): void {
    const source = fromEvent(window, 'resize');
    const subscription = source.pipe(debounceTime(200)).subscribe(e => {
      this.update();
      if (this.cd) {
        this.cd.markForCheck();
      }
    });
    this.resizeSubscription = subscription;
  }

  computeSeries() {
    this.availableSeries.clear();
    this.defaultSeries.forEach((series) => {
      this.availableSeries.set(series.cwiComboChartSeries, series.template);
    });

    this.customSeries.forEach((series) => {
      this.availableSeries.set(series.cwiComboChartSeries, series.template);
    });
  }

  getSeriesTemplate(series: ComboChartSeries<string, any>) {
    const template = this.availableSeries.get(series.type);
    return template;
  }

  getSeriesContext<TTag extends string, T>(series: ComboChartSeries<TTag, T>): ComboChartSeriesDirectiveContext<TTag, T> {
    return {
      $implicit: series,
      chart: this,
      mainColor: this.colors.getColor(series.name)
    };
  }

  ngAfterViewInit() {
    this.bindWindowResizeEvent();

    // listen for visibility of the element for hidden by default scenario
    this.visibilityObserver = new VisibilityObserver(this.chartElement, this.zone);
    this.visibilityObserver.visible.subscribe(this.update.bind(this));

    this.subscription = this.customSeries.changes.subscribe(() => {
      this.computeSeries();
      this.update();
    });
    this.computeSeries();
  }

  ngOnDestroy() {
    this.unbindEvents();
    if (this.visibilityObserver) {
      this.visibilityObserver.visible.unsubscribe();
      this.visibilityObserver.destroy();
    }
    this.subscription.unsubscribe();
  }

  update(): void {
    if (this.view) {
      this.width = this.view[0];
      this.height = this.view[1];
    } else {
      const dims = this.getContainerDims();
      if (dims) {
        this.width = dims.width;
        this.height = dims.height;
      }
    }

    // default values if width or height are 0 or undefined
    if (!this.width) {
      this.width = 600;
    }

    if (!this.height) {
      this.height = 400;
    }

    this.width = Math.floor(this.width);
    this.height = Math.floor(this.height);

    if (this.cd) {
      this.cd.markForCheck();
    }

    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.showYAxisLabel,
      showLegend: this.legend,
      legendType: this.schemeType,
      legendPosition: this.legendPosition
    });

    if (this.timeline) {
      this.dims.height -= this.timelineHeight + this.margin[2] + this.timelinePadding;
    }

    this.xDomain = this.getXDomain();
    if (this.filteredDomain) {
      this.xDomain = this.filteredDomain;
    }

    this.yDomain = this.getYDomain();
    this.seriesDomain = this.getSeriesDomain();

    this.xScale = this.getXScale(this.xDomain, this.dims.width);
    this.yScale = this.getYScale(this.yDomain, this.dims.height);

    this.updateTimeline();

    this.setColors();
    this.legendOptions = this.getLegendOptions();

    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0]})`;
  }

  updateTimeline(): void {
    if (this.timeline) {
      this.timelineWidth = this.dims.width;
      this.timelineXDomain = this.getXDomain();
      this.timelineXScale = this.getXScale(this.timelineXDomain, this.timelineWidth);
      this.timelineYScale = this.getYScale(this.yDomain, this.timelineHeight);
      this.timelineTransform = `translate(${this.dims.xOffset}, ${-this.margin[2]})`;
    }
  }

  getXDomain(): any[] {
    let values = getUniqueXDomainValues(this.results);

    this.scaleType = getScaleType(values);
    let domain = [];

    if (this.scaleType === 'linear') {
      values = values.map(v => Number(v));
    }

    let min;
    let max;
    if (this.scaleType === 'time' || this.scaleType === 'linear') {
      min = this.xScaleMin ? this.xScaleMin : Math.min(...values);
      max = this.xScaleMax ? this.xScaleMax : Math.max(...values);
    }

    if (this.scaleType === 'time') {
      domain = [new Date(min), new Date(max)];
      this.xSet = [...values].sort((a, b) => {
        const aDate = a.getTime();
        const bDate = b.getTime();
        if (aDate > bDate) { return 1; }
        if (bDate > aDate) { return -1; }
        return 0;
      });
    } else if (this.scaleType === 'linear') {
      domain = [min, max];
      // Use compare function to sort numbers numerically
      this.xSet = [...values].sort((a, b) => a - b);
    } else {
      domain = values;
      this.xSet = values;
    }

    return domain;
  }

  getYDomain(): any[] {
    const domain = [];
    for (const results of this.results) {
      for (const d of results.series) {
        if (isNumber(d.value)) {
          if (domain.indexOf(d.value) < 0) {
            domain.push(d.value);
          }
        }
      }
    }

    const values = [...domain];
    if (!this.autoScale) {
      values.push(0);
    }

    const min = this.yScaleMin ? this.yScaleMin : Math.min(...values);
    const max = this.yScaleMax ? this.yScaleMax : Math.max(...values);

    return [min, max];
  }

  getSeriesDomain(): any[] {
    return this.results.map(d => d.name);
  }

  getXScale(domain, width): any {
    let scale;

    if (this.scaleType === 'time') {
      scale = scaleTime()
        .range([0, width])
        .domain(domain);
    } else if (this.scaleType === 'linear') {
      scale = scaleLinear()
        .range([0, width])
        .domain(domain);

      if (this.roundDomains) {
        scale = scale.nice();
      }
    } else if (this.scaleType === 'ordinal') {
      scale = scalePoint()
        .range([0, width])
        .padding(0.1)
        .domain(domain);
    }

    return scale;
  }

  getYScale(domain, height): any {
    const scale = scaleLinear()
      .range([height, 0])
      .domain(domain);

    return this.roundDomains ? scale.nice() : scale;
  }

  updateDomain(domain): void {
    this.filteredDomain = domain;
    this.xDomain = this.filteredDomain;
    this.xScale = this.getXScale(this.xDomain, this.dims.width);
  }

  updateHoveredVertical(item): void {
    this.hoveredVertical = item.value;
    this.deactivateAll();
  }

  @HostListener('mouseleave')
  hideCircles(): void {
    this.hoveredVertical = null;
    this.deactivateAll();
  }

  // Click on Legend element
  onClick(data): void {
    this.select.emit(data);
  }

  setColors(): void {
    let domain;
    if (this.schemeType === 'ordinal') {
      domain = this.seriesDomain;
    } else {
      domain = this.yDomain;
    }

    this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
  }

  getLegendOptions() {
    const opts = {
      scaleType: this.schemeType,
      colors: undefined,
      domain: [],
      title: undefined,
      position: this.legendPosition
    };
    if (opts.scaleType === 'ordinal') {
      opts.domain = this.seriesDomain;
      opts.colors = this.colors;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.yDomain;
      opts.colors = this.colors.scale;
    }
    return opts;
  }

  updateYAxisWidth({ width }): void {
    this.yAxisWidth = width;
    this.update();
  }

  updateXAxisHeight({ height }): void {
    this.xAxisHeight = height;
    this.update();
  }

  onActivate(item) {
    this.deactivateAll();

    const idx = this.activeEntries.findIndex(d => {
      return d.name === item.name && d.value === item.value;
    });
    if (idx > -1) {
      return;
    }

    this.activeEntries = [item];
    this.activate.emit({ value: item, entries: this.activeEntries });
  }

  onDeactivate(item) {
    const idx = this.activeEntries.findIndex(d => {
      return d.name === item.name && d.value === item.value;
    });

    this.activeEntries.splice(idx, 1);
    this.activeEntries = [...this.activeEntries];

    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }

  deactivateAll() {
    this.activeEntries = [...this.activeEntries];
    for (const entry of this.activeEntries) {
      this.deactivate.emit({ value: entry, entries: [] });
    }
    this.activeEntries = [];
  }
}
