import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import moment from "moment";
import { Moment } from "moment";
import { SubSystemStatus } from "../diagnostic";

export interface SubSystemBaseInfo {
  isMaster: boolean;
  name: string;
  fixGps: boolean;
  warning?: string;
  devicesDisconnected: number;
  status: SubSystemStatus;
  trackdbUpdateTime: Moment;
}

@Component({
  selector: 'cwi-remote-subsystem-status-list',
  templateUrl: './subsystem-status-list.component.html',
  styleUrls: ['./subsystem-status-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubSystemStatusListComponent {

  displayedColumns: string[] = ['name', 'fixGps', 'warning', 'devices', 'status', 'tdbUpdate'];

  @Input()
  public subSystems: SubSystemBaseInfo[];

  @Input()
  public tag: string;

  get anchor() {
    return `${window.location.pathname}#${this.tag}`;
  }

  getIsSubSystemUnreachable(subSystem: SubSystemBaseInfo) {
    return subSystem.status === SubSystemStatus.Unreachable;
  }

  getSystemRole(isMaster: boolean) {
    return isMaster ? $localize`Main` : $localize`Mirror`
  }

  // fa schifo
  checkValidityDate(date: Moment){
    const minDate = moment('0001-01-01');
    if (moment(date).isAfter(minDate)) {
      return true;
    } else {
      return false;
    }
  }
}
