import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserCreationInfo, UserInfo } from '../../models/user-definition';
import { IUserCreationConfiguration, USER_CREATION_CONFIGURATION } from '../../../user-creation.configuration';

@Component({
    selector: 'cwi-user-creation',
    templateUrl: './user-creation.component.html',
    styleUrls: ['./user-creation.component.scss'],
    providers: [],
  })
  export class UserCreationComponent {

    @Input()
    public error: string;

    @Output()
    public readonly created = new EventEmitter<UserCreationInfo>();

    @Output()
    public readonly closed = new EventEmitter<void>();

    userInfoInternal: UserInfo;
    userCreationForm: FormGroup;

    constructor(
      @Inject(USER_CREATION_CONFIGURATION)
      configuration: IUserCreationConfiguration,
    ) {
      this.userCreationForm = new FormGroup({
        userName: new FormControl(null, configuration.usernameValidators),
        displayName: new FormControl(null, configuration.displaynameValidators),
        email: new FormControl(null, configuration.emailValidators),
        password: new FormControl(null, configuration.passwordValidators),
        confirmPassword: new FormControl(null, configuration.passwordValidators),
      }, { validators: [ this.checkPasswords ] });
    }

    checkPasswords(formGroup: FormGroup) {
      const password = formGroup.get('password').value;
      const confirmPassword = formGroup.get('confirmPassword').value;
      return password === confirmPassword ? null : { notSamePassword: true };
    }

    onSubmit() {
      if (this.userCreationForm.valid) {
        this.created.next(this.userCreationForm.value);
      }
    }

    cancel() {
      this.userCreationForm.reset();
    }

    close() {
      this.closed.next();
    }
}
