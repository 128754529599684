import { Component, Input, Inject } from '@angular/core';
import { MANUAL_COMMANDS_SERVICE, IManualCommandsService } from '../service-configuration';
import { ProbeCommandsDefinition } from '../probe-commands-definition';
import { ProbeDefinition } from '../probe-definition';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'cwi-remote-manual-commands',
  templateUrl: './manual-commands.component.html',
  styleUrls: ['./manual-commands.component.scss']
})
export class ManualCommandsComponent {

  showCommands: boolean;
  error: string;

  @Input()
  public setups: string[];

  @Input()
  public lastCommand: string;

  @Input()
  public probes: ProbeDefinition[];

  @Input()
  public selectedSetup: string;

  @Input()
  public immediateStartRecording: boolean;

  newCommand: string;
  status: 'Idle'|'Measuring'|'Recording'|'Error'|'Unknown';
  awaitingCommand: boolean;

  constructor(
    @Inject(MANUAL_COMMANDS_SERVICE)
    private readonly manualCommandsService: IManualCommandsService
  ) {
  }

  async startMeasuring() {
    return await this.pushCommand('StartMeasuring');
  }
  async stopMeasuring() {
    return await this.pushCommand('StopMeasuring');
  }
  async startRecording() {
    return await this.pushCommand('StartRecording');
  }
  async stopRecording() {
    return await this.pushCommand('StopRecording');
  }

  async pushCommand(command: string) {
    this.awaitingCommand = true;
    const result: ProbeCommandsDefinition = {
      setup: this.selectedSetup,
      command: command as any
    };
    await this.manualCommandsService.pushCommand(result).toPromise()
      .then(() => {
        this.error = null;
      })
      .catch((error: HttpErrorResponse) => {
        if (error.status === 404
          || error.status === 412) {
            this.error = $localize `Impossible to send command.`;
        }
      });
    this.awaitingCommand = false;
  }

  checkCommonStateProbes(status: string) {
    return this.probes.some(probe => probe.status === status);
  }

  startMeasuringEnabled(): boolean {
    return this.selectedSetup != null
      && (this.lastCommand == null
          || this.lastCommand === 'StopMeasuring')
      && this.checkCommonStateProbes('Idle');
  }

  stopMeasuringEnabled(): boolean {
    if (this.lastCommand) {
      if (this.lastCommand === 'StartMeasuring'
        || this.lastCommand === 'StopRecording') {
        return this.checkCommonStateProbes('Measuring');
      }
    }
    return false;
  }

  startRecordingEnabled(): boolean {
    if (this.immediateStartRecording) {
      if (this.selectedSetup != null
        && (this.lastCommand == null || this.lastCommand === 'StopRecording')) {
          return this.checkCommonStateProbes('Idle');
        }
      return false;
    }
    else {
      if (this.lastCommand
        && (this.lastCommand === 'StartMeasuring'
            || this.lastCommand === 'StopRecording')) {
        return this.checkCommonStateProbes('Measuring');
      }
      return false;
    }
  }

  stopRecordingEnabled(): boolean {
    if (this.lastCommand
      && (this.lastCommand === 'StartRecording')) {
      return this.checkCommonStateProbes('Recording');
    }
    return false;
  }

  enableSetups(): boolean {
    if (this.lastCommand) {
      return this.immediateStartRecording 
        ? this.lastCommand === 'StopRecording' 
        : this.lastCommand === 'StopMeasuring';
    } else {
      return true;
    }
  }

  bothSystemsUnreachable() {
    return this.probes.every(probe => probe.status === 'Unreachable');
  }

  spinnerInvisible() {
    if (this.bothSystemsUnreachable() || this.lastCommand == null) {
      return true;
    }
    else {
      switch (this.lastCommand) {
        case 'StartMeasuring':
          return this.checkCommonStateProbes('Measuring');
        case 'StopMeasuring':
          return this.checkCommonStateProbes('Idle');
        case 'StartRecording':
          return this.checkCommonStateProbes('Recording');
        case 'StopRecording':
          return this.immediateStartRecording 
              ? this.checkCommonStateProbes('Idle') 
              : this.checkCommonStateProbes('Measuring');
      }
    }
  }
}
