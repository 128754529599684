import * as msgpack from 'msgpack-lite';
import moment from 'moment';
import { Gi, K, M } from './units';
import { BufferWriter } from './buffer-writer';
import { isString, isNumber, isBoolean } from './utils';

export function addTimeExt(codec: msgpack.Codec) {
  codec.addExtUnpacker(0xFF, (buffer: Uint8Array) => {
    const dataView = new DataView(buffer.buffer);
    switch (buffer.byteLength) {
      case 4:
        return moment.unix(dataView.getUint32(0));
      case 8: {
        const ns = dataView.getUint32(0);
        let sec = dataView.getUint32(4);
        const remainder = (ns % 4);
        sec += remainder * 4 * Gi;

        return moment((sec * K) + (ns - remainder) / (4 * M));
      }
      case 12: {
        const ns = dataView.getUint32(0);
        // Quì sto ignorando i primi 16 bit del numero a 64
        // bit perché si usano solo double (schifo!) e la
        // mantissa è fissata a 48 bit. Comunque mi da un discreto
        // numero di anni da poter memorizzare in questo numero.
        const sec = dataView.getInt16(6) * 4 * Gi + dataView.getUint32(8);
        return moment((sec * K) + (ns / M));
      }
      default:
        // E' meglio tirare un'eccezione?
        return { type: 0xFF, buffer };
    }
  });

  return codec;
}

export const timeCodec = addTimeExt(msgpack.createCodec());

const encoder = new TextEncoder();

// This is to overcome a msgpack-lite limitation. Hopefully we will remove it.
// See https://github.com/kawanet/msgpack-lite/pull/109
function writeMsgpackArray(writer: BufferWriter, value: any[]) {
  // First the array header
  const length = value.length;

  if (length <= 0xF) {
    writer.writeInt8(0x90 + length);
  } else if (length <= 0xFFFF) {
    writer.writeInt8(0xDC);
    writer.writeInt16(length);
  } else {
    writer.writeInt8(0xDD);
    writer.writeInt32(length);
  }
  // Then the items
  for (const item of value) {
    writeMsgpack(writer, item);
  }
}


function writeMsgpackString(writer: BufferWriter, value: string) {
  // String header
  const buffer = encoder.encode(value);
  const length = buffer.byteLength;

  if (length <= 0x1F) {
    writer.writeInt8(0xA0 + length);
  } else if (length <= 0xFF) {
    writer.writeInt8(0xD9);
    writer.writeInt8(length);
  } else if (length <= 0xFFFF) {
    writer.writeInt8(0xDA);
    writer.writeInt16(length);
  } else {
    writer.writeInt8(0xDB);
    writer.writeInt32(length);
  }

  writer.writeBuffer(buffer);
}

function writeMsgpackDouble(writer: BufferWriter, value: number) {
  writer.writeInt8(0xCB);
  writer.writeDouble(value);
}

function writeMsgpackInteger(writer: BufferWriter, value: number) {
  if (value < 0) {
    const ivalue = -value; // Just take the upper part
    if (ivalue <= 0x20) {
      writer.writeInt8(value);
    } else if (ivalue <= 0x80) {
      writer.writeInt8(0xD0);
      writer.writeInt8(value);
    } else if (ivalue <= 0x8000) {
      writer.writeInt8(0xD1);
      writer.writeInt16(value);
    } else if (ivalue <= 0x80000000) {
      writer.writeInt8(0xD2);
      writer.writeInt32(value);
    } else {
      writer.writeInt8(0xD3);
      // Writing a negative value without bit manipulation is annoying.
      // I will use a trick:
      // - in two's complement negation(x) = not(x) + 1 => not(x) = negation(x) - 1
      const complement = ivalue - 1;
      // - bitwise or with 0xFFF...FFFFF is the same as % 0x1000...000000
      const low = -(complement % 0x100000000) - 1;
      const high = -(complement / 0x100000000) - 1;
      // then I can write
      writer.writeInt32(high);
      writer.writeInt32(low);
    }
  } else {
    if (value <= 0x7F) {
      writer.writeInt8(value);
    } else if (value <= 0xFF) {
      writer.writeInt8(0xCC);
      writer.writeInt8(value);
    } else if (value <= 0xFFFF) {
      writer.writeInt8(0xCD);
      writer.writeInt16(value);
    } else if (value <= 0xFFFFFFFF) {
      writer.writeInt8(0xCE);
      writer.writeInt32(value);
    } else {
      writer.writeInt8(0xCF);
      // To write an int64, I need 2 int32. In big endian
      writer.writeInt32(Math.floor(value / 0x100000000));
      writer.writeInt32(value % 0x100000000);
    }
  }
}

export function writeMsgpack(writer: BufferWriter, value: any) {
  // I support just:
  // - arrays
  // - integers
  // - doubles
  // - strings
  // - booleans
  // See full spec at https://github.com/msgpack/msgpack/blob/master/spec.md
  if (Array.isArray(value)) {
    writeMsgpackArray(writer, value);
  } else if (isString(value)) {
    writeMsgpackString(writer, value);
  } else if (isNumber(value)) {
    const intValue = Math.floor(value);
    if (value === intValue) {
      // integer
      writeMsgpackInteger(writer, intValue);
    } else {
      writeMsgpackDouble(writer, value);
    }
  } else if (isBoolean(value)) {
    writer.writeInt8(value ? 0xc3 : 0xc2);
  } else if (value) {
    throw new Error('Not supported');
  } else {
    // nil
    writer.writeInt8(0xc0);
  }
}

export function encodeMsgpack(value: any) {
  const writer = new BufferWriter();
  writeMsgpack(writer, value);
  return writer.build();
}
