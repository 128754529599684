<div class="trainsContainer" *ngIf="isVisible">
  <h6 class="font-weight-bold mt-2 text-muted text-center" i18n>Trains</h6>
  <ul class="list-group">
    <cwi-remote-train-status *ngFor="let train of trains; trackBy: trainTrackBy;"
      [trainStatus]=train
      (trainSelected)=trainClick(train)
      (followClicked)="followClick(train)">
    </cwi-remote-train-status>
   </ul>
</div>
