import { Injectable } from '@angular/core';
import { AuthenticationService, InRoleGuard } from '@cwi/components';
import { ALLOWED_ROLES } from './settings.roles';

@Injectable()
export class SettingsRoleGuard extends InRoleGuard
{
  constructor(authenticationService: AuthenticationService) {
    super(authenticationService, ALLOWED_ROLES);
  }
}