<div class="text-right carousel-container my-auto">
  <span class="pages mr-4 my-auto ">
    <span class="ml-1 current-page">{{ currentPage + 1 }}</span>
    <span class="ml-1">/</span>
    <span class="ml-1">{{ totalPages }}</span>
  </span>
  <button (click)="previousPage();" class="arrow" [disabled]="previousDisabled">
    <mat-icon class="icon my-auto text-center">arrow_back</mat-icon>
  </button>
  <button (click)="nextPage();" class="arrow ml-1" [disabled]="nextDisabled">
    <mat-icon class="my-auto text-center icon">arrow_forward</mat-icon>
  </button>
</div>