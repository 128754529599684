import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { TimeSchedulationDefinition } from '../time-schedulation-definition';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'cwi-remote-priority-schedulation',
  templateUrl: './priority-schedulation.component.html',
  styleUrls: ['./priority-schedulation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrioritySchedulationComponent {

  schedulationsInternal: TimeSchedulationDefinition[];

  @Input()
  public set schedulations(value: TimeSchedulationDefinition[]) {
    this.schedulationsInternal = value && [ ...value ];
  }

  @Output()
  public readonly update = new EventEmitter<TimeSchedulationDefinition[]>();

  @Output()
  public readonly canceled = new EventEmitter<void>();

  edited = false;

  cancel() {
    this.canceled.next();
  }

  save() {
    this.update.emit(this.schedulationsInternal);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.schedulationsInternal, event.previousIndex, event.currentIndex);
    this.edited = true;
  }
}
