import { Directive, ViewContainerRef, TemplateRef, Input, EmbeddedViewRef, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

export enum AsyncDirectiveStatus {
  empty,
  loading,
  error,
  loaded
}

export class AsyncDirectiveContext<T> {
  $implicit: T = null;
  error: any;
  reload: () => any;
  status = AsyncDirectiveStatus.empty;
}

@Directive({
  selector: '[cwiAsync]'
})
export class AsyncDirective<T> implements OnDestroy {

  private readonly context = new AsyncDirectiveContext<T>();
  private value$: Observable<T>;

  private emptyTemplateRef: TemplateRef<AsyncDirectiveContext<T>>|null;
  private errorTemplateRef: TemplateRef<AsyncDirectiveContext<T>>|null;
  private loadingTemplateRef: TemplateRef<AsyncDirectiveContext<T>>|null;

  private emptyViewRef: EmbeddedViewRef<AsyncDirectiveContext<T>>|null = null;
  private errorViewRef: EmbeddedViewRef<AsyncDirectiveContext<T>>|null = null;
  private loadingViewRef: EmbeddedViewRef<AsyncDirectiveContext<T>>|null = null;
  private loadedViewRef: EmbeddedViewRef<AsyncDirectiveContext<T>>|null = null;

  private subscription: Subscription;

  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly loadedTemplateRef: TemplateRef<AsyncDirectiveContext<T>>
  ) {
    this.context.reload = () => { this.cwiAsync = this.value$; };
  }

  @Input()
  set cwiAsync(value: Observable<T>) {
    this.value$ = value;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (value) {
      this.context.status = AsyncDirectiveStatus.loading;
      this.subscription = value.subscribe(
        item => {
          this.context.$implicit = item;
          this.context.status = AsyncDirectiveStatus.loaded;
          this.updateView();
        },
        error => {
          this.subscription = null;
          this.context.$implicit = null;
          this.context.error = error;
          this.context.status = AsyncDirectiveStatus.error;
          this.updateView();
        });
    } else {
      this.subscription = null;
      this.context.$implicit = null;
      this.context.error = null;
      this.context.status = AsyncDirectiveStatus.empty;
    }

    this.updateView();
  }

  @Input()
  set cwiAsyncEmpty(value: TemplateRef<AsyncDirectiveContext<T>>) {
    this.emptyTemplateRef = value;
    this.updateView();
  }

  @Input()
  set cwiAsyncLoading(value: TemplateRef<AsyncDirectiveContext<T>>) {
    this.loadingTemplateRef = value;
    this.updateView();
  }

  @Input()
  set cwiAsyncError(value: TemplateRef<AsyncDirectiveContext<T>>) {
    this.errorTemplateRef = value;
    this.updateView();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
      this.viewContainer.clear();
    }
  }

  private updateView() {
    switch (this.context.status) {
      case AsyncDirectiveStatus.empty:
        if (!this.emptyViewRef) {
          this.viewContainer.clear();
          this.errorViewRef = this.loadedViewRef = this.loadingViewRef = null;
          if (this.emptyTemplateRef) {
            this.emptyViewRef = this.viewContainer.createEmbeddedView(this.emptyTemplateRef, this.context);
          }
        }
        break;
      case AsyncDirectiveStatus.error:
        if (!this.errorViewRef) {
          this.viewContainer.clear();
          this.emptyTemplateRef = this.loadedViewRef = this.loadingViewRef = null;
          if (this.errorTemplateRef) {
            this.errorViewRef = this.viewContainer.createEmbeddedView(this.errorTemplateRef, this.context);
          }
        }
        break;
      case AsyncDirectiveStatus.loading:
        if (!this.loadingViewRef) {
          this.viewContainer.clear();
          this.errorViewRef = this.loadedViewRef = this.emptyViewRef = null;
          if (this.loadingTemplateRef) {
            this.loadingViewRef = this.viewContainer.createEmbeddedView(this.loadingTemplateRef, this.context);
          }
        }
        break;
      case AsyncDirectiveStatus.loaded:
        if (!this.loadedViewRef) {
          this.viewContainer.clear();
          this.errorViewRef = this.emptyViewRef = this.loadingViewRef = null;
          if (this.loadedTemplateRef) {
            this.loadedViewRef = this.viewContainer.createEmbeddedView(this.loadedTemplateRef, this.context);
          }
        }
        break;
    }
  }
}
