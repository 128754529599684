import { InjectionToken } from '@angular/core';
import { Section } from '../home/configuration';
import { ALLOWED_ROLES } from './remote-controller.roles';
import { UnitOfMeasurement } from 'shared/cwi/remote-controller/lib/unit-of-measurement';

export interface IRemoteControllerSectionConfiguration {
  title: string
  description: string
  linkCaption: string
  navigationHeadText: string
  unitOfMeasurement: UnitOfMeasurement
}

export const DEFAULT_RC_SECTION_CONFIGURATION: IRemoteControllerSectionConfiguration = {
  title: 'SDTLC+',
  description: $localize`Remote controller for SDTLC+ system`,
  linkCaption: $localize`Go to remote controller`,
  navigationHeadText: 'SDTLC+ - Remote Controller',
  unitOfMeasurement: UnitOfMeasurement.kmh
}

export const REMOTECONTROLLER_SECTION_CONFIGURATION = new InjectionToken<IRemoteControllerSectionConfiguration>('IRemoteControllerSectionConfiguration');

export function buildRcSectionConfiguration(config: IRemoteControllerSectionConfiguration): Section {
  return {
    title: config.title,
    description: config.description,
    link: [ '/remote-controller' ],
    linkCaption: config.linkCaption,
    roles: ALLOWED_ROLES
  }
}
