import { CanActivate } from '@angular/router';
import { AuthenticationService } from './authentication.service';

export abstract class InRoleGuard implements CanActivate {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly allowedRoles: ReadonlySet<string>) { }

  public async canActivate() {
    const userInfo = await this.authenticationService.refreshUserInfo();
    try {
      for (const privilege of userInfo.roles) {
        if (this.allowedRoles.has(privilege)) {
          return true;
        }
      }
    } catch {
      return false;
    }
  }
}
