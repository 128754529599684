import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.configuration';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
    private config: AppConfig;
    loaded = false;
    constructor(private readonly $http: HttpClient) {}
    loadConfig(): Promise<void> {
        return this.$http
            .get<AppConfig>('assets/app.config.json')
            .toPromise()
            .then(data => {
                this.config = data;
                this.loaded = true;
            });
    }

    getConfig(): AppConfig {
        return this.config;
    }
}
