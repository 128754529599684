import { Component, Input } from '@angular/core';
import { ProbeDefinition } from '../probe-definition';

@Component({
  selector: 'cwi-remote-probe-map-marker',
  templateUrl: './probe-map-marker.component.html',
  styleUrls: ['./probe-map-marker.component.scss']
})
export class ProbeMapMarkerComponent {

  private probeInternal: ProbeDefinition;

  @Input()
  set probeStatus(value: ProbeDefinition) {
    this.probeInternal = value;
  }

  get probeStatus() {
    return this.probeInternal;
  }
}
