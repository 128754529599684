<div class="plans-container" *ngIf="plans$ | async; let plans;">
  <div class=plan-panel>
    <h6 class="font-weight-bold mt-2 text-muted text-center" i18n>Plans</h6>
    <div class=list>
      <ng-container *ngFor="let plan of plans; trackBy: planTrackBy; ">
        <cwi-remote-plan-item class="mx-auto" *ngIf="!selectedPlanName || selectedPlanName === plan.name"
          [planItem]="plan"
          [isActive]="activatedPlan === plan.name"
          [toggleDisabled]="getToggleDisabled(plan.name)"
          [isClickable]="!selectedPlanName"
          [planItemError]="planError"
          (editPlan)="openEditPlanModal(plan);"
          (deletePlan)="deletePlan(plan);" 
          (planSelected)="planClick(plan);"
          (activate)="activatePlan(plan)"
          (deactivate)="deactivatePlan(plan)">
        </cwi-remote-plan-item>
      </ng-container>
    </div>
    <button *ngIf="!selectedPlanName" class="btn btn-sm btn-primary mx-auto mt-2 mb-2" (click)="openAddPlanModal()" i18n>
      <span class="fas fa-plus"></span> Add new
    </button>
  </div>
</div>