<div class="schedulation-container">
    <div class="scroll-svg">
        <svg class="hours-container" width="100%" [attr.viewBox]="'0 0 200 6'">
            <text class="hours" *ngFor="let h of hours; let j = index"
                [attr.x]="j * 200 / (hours.length - 1)"
                [attr.y]="3.5"
                dominant-baseline="middle"
                text-anchor="middle">
                {{ h }}
            </text>
        </svg>
        <svg style="z-index: -1;" [attr.viewBox]="'0 0 200 ' + (2 + 6 * filteredSchedulations.length)" *ngIf="filteredSchedulations.length > 0">
            <line *ngFor="let h of hours; let j = index" text-anchor="middle"
                [attr.x1]="j * 200 / (hours.length - 1)"
                [attr.y1]="0"
                [attr.x2]="j * 200 / (hours.length - 1)"
                [attr.y2]="100" 
                style="position: relative; z-index: 1; stroke: #d3dee1; stroke-width: 0.2; opacity: 0.8;">
            </line>
            <g *ngFor="let sched of filteredSchedulations; let i = index" 
                (click)="schedulationClick(sched)">
                <rect class="rect"
                    [attr.x]="(sched.day === 'all' || (sched.endTime === 0 && sched.startTime === 0)) ? 0 : sched.startTime / 432"
                    [attr.width]="(sched.day === 'all' || (sched.endTime === 0 && sched.startTime === 0)) ? 200 : (sched.endTime - sched.startTime) / 432"
                    rx="0.5px"
                    height= 4
                    [attr.y]="2 + 6 * i">
                </rect>
                <text style="font-size: 2px; font-weight: bold;"
                    [attr.x]="(sched.day === 'all' || (sched.endTime === 0 && sched.startTime === 0)) ? 100 : 
                            sched.startTime / 432 + ((sched.endTime - sched.startTime) / 864)"
                    [attr.y]="4 + 6 * i"
                    dominant-baseline="middle"
                    text-anchor="middle">
                    {{ sched.setupName}} 
                </text>
            </g>
            <line *ngIf="dateTimeNowSeconds" [attr.y1]="0"
                [attr.y2]="200"
                [attr.x1]="200 * (dateTimeNowSeconds / 86400)"                
                [attr.x2]="200 * (dateTimeNowSeconds / 86400)"
                style="stroke: #0000ff; stroke-width: 0.2; opacity: 0.7;">
            </line>
        </svg>
    </div>    
</div>

