import { Directive, ElementRef, HostListener, Renderer2, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[cwiInputOnlyNumber]'
})
export class InputNumberDirective {

  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];
  inputElement: HTMLElement;
  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Allow: Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Allow: Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Allow: Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) // Allow: Cmd+X (Mac)
      ) {
        // let it happen, don't do anything
        return;
    }
    // Ensure that it is a number and stop the keypress
    const key = Number(e.key);
    if (isNaN(key) || e.key === null || e.key === '' || e.shiftKey) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData
      .getData('text/plain')
      .replace(/\D/g, ''); // get a digit-only string
    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    const textData = event.dataTransfer.getData('text').replace(/\D/g, '');
    this.inputElement.focus();
    document.execCommand('insertText', false, textData);
  }
}

@Directive({
  selector: '[cwiMinMaxInputNumber]'
})
export class MinMaxInputNumberDirective implements OnInit {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @Input()
  maxValue: number;
  @Input()
  minValue: number;

  ngOnInit(): void {
    this.renderer.setProperty(this.el.nativeElement, 'min', this.minValue);
    this.renderer.setProperty(this.el.nativeElement, 'max', this.maxValue);
  }

  @HostListener('change') onChange() {
    const value = this.el.nativeElement.value;
    if (value > this.maxValue) {
      this.el.nativeElement.value = this.maxValue;
    } else if (value < this.minValue) {
      this.el.nativeElement.value = this.minValue;
    }
  }
}
