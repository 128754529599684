import { ChangeDetectionStrategy, Component, Input } from '@angular/core';


function computePartialCircle (cx: number, cy: number, r: number, start: number, end: number)  {
    
  const length = end - start;
  if (length === 0) {
    return "";
  }

  const fromX = r * Math.cos(start) + cx;
  const fromY = r * Math.sin(start) + cy;
  const toX = r * Math.cos(end) + cx;
  const toY = r * Math.sin(end) + cy;
  const large = Math.abs(length) <= Math.PI ? '0' : '1';
  const sweep = length < 0 ? '0' : '1';

  return `M ${fromX} ${fromY} A ${r} ${r} 0 ${large} ${sweep} ${toX} ${toY}`;
}


function computeFromValue(value: number): string {
  return computePartialCircle(50, 50, 44, Math.PI * 3/4, Math.PI * (3 / 4 + Math.log10(value) * 3 / 20));
}

const units = [
  'Bps',
  'KBps',
  'MBps',
  'GBps'
];

@Component({
  selector: 'cwi-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GaugeComponent {

  @Input()
  speed: number;

  get path(): string
  {
    return computeFromValue(this.speed);
  }

  get unit(): string
  {
    let bytes = this.speed ?? 0;
    let unit = 0;

    while ( bytes >= 1000 ) {
      bytes /= 1000;
      unit ++;
    }

    return units[unit];
  }

  get speedFormatted()
  {
    let bytes = this.speed ?? 0;

    while ( bytes >= 1000 ) {
      bytes /= 1000;
    }

    return bytes.toFixed(0);
  }
}
