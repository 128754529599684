import { SETTINGS_CONFIGURATION, ISettingsConfiguration, IGroupService } from './service-configuration';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { GroupInfo } from '../models/group-definition';
import { reload } from '@cwi/rx';

const jsonContentOptions = { headers: new HttpHeaders({'Content-Type': 'application/json' }) };

@Injectable()
export class GroupService implements IGroupService {

  private readonly baseUrl: string;
  private reloadSubject: Subject<any> = new Subject<any>();
  public groups$: Observable<GroupInfo[]>;

  constructor(
    private readonly $http: HttpClient,
    @Inject(SETTINGS_CONFIGURATION)
    configuration: ISettingsConfiguration
  ) {
    this.baseUrl = configuration.baseUrl;
    this.groups$ = this.$http.get<GroupInfo[]>(`${this.baseUrl}/groups`).pipe(
      reload(this.reloadSubject),
      shareReplay(1)
    );
  }

  reload() {
    this.reloadSubject.next();
  }

  addGroup(name: string): Observable<void>  {
    return this.$http.post<void>(`${this.baseUrl}/groups`, JSON.stringify(name), jsonContentOptions);
  }

  addRoleToGroup(name: string, role: string): Observable<void>  {
    return this.$http.post<void>(`${this.baseUrl}/group/${encodeURI(name)}/roles`, JSON.stringify(role), jsonContentOptions);
  }

  addUserToGroup(userName: string, groupName: string): Observable<void>  {
    return this.$http.post<void>(`${this.baseUrl}/group/${encodeURI(groupName)}/users`, JSON.stringify(userName), jsonContentOptions);
  }

  deleteGroup(name: string): Observable<void> {
    return this.$http.delete<any>(`${this.baseUrl}/group/${encodeURI(name)}`);
  }

  deleteRoleFromGroup(name: string, role: string): Observable<void> {
    return this.$http.delete<any>(`${this.baseUrl}/group/${encodeURI(name)}/role/${encodeURI(role)}`);
  }

  removeGroupFromUser(userName: string, groupName: string) {
    return this.$http.delete<any>(`${this.baseUrl}/group/${encodeURI(groupName)}/user/${encodeURI(userName)}`);
  }
}
