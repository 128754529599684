<form [formGroup]="uploadForm" (ngSubmit)="submit()" [ngClass]="{'processing': processing }">
    <ng-container *ngTemplateOutlet="spinnerTemp"></ng-container>
    <div class="modal-header">
        <h5 class="modal-title" i18n>Upload Track Database files</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close();">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body upload-body">
        <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="error">
            {{ error }}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeAlert()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        <div class="form-group">
            <label for="line" i18n>Select line.tch file:</label>
            <input 
                type="file"
                name="line"
                accept=".tch"
                formControlName="line"
                class="form-control"
                (change)="onFileChange('line', $event)"
                [class.is-valid]="uploadForm.controls.line.valid && uploadForm.controls.line.touched"
                [class.is-invalid]="uploadForm.controls.line.invalid && uploadForm.controls.line.touched" />
            <div class="invalid-feedback">
                <ng-container *ngTemplateOutlet="messageTemplates; context: { $implicit: uploadForm.controls.line.errors }"></ng-container>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="form-group">
            <label for="track" i18n>Select track.tch file:</label>
            <input 
                type="file"
                name="track"
                accept=".tch"
                formControlName="track"
                class="form-control"
                (change)="onFileChange('track', $event)"
                [class.is-valid]="uploadForm.controls.track.valid && uploadForm.controls.track.touched"
                [class.is-invalid]="uploadForm.controls.track.invalid && uploadForm.controls.track.touched" />
            <div class="invalid-feedback">
                <ng-container *ngTemplateOutlet="messageTemplates; context: { $implicit: uploadForm.controls.track.errors }"></ng-container>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="form-group">
            <label for="trackDescription" i18n>Select trackdescription.tcb file:</label>
            <input 
                type="file"
                name="trackDescription"
                accept=".tcb"
                formControlName="trackDescription"
                class="form-control"
                (change)="onFileChange('trackDescription', $event)"
                [class.is-valid]="uploadForm.controls.trackDescription.valid && uploadForm.controls.trackDescription.touched"
                [class.is-invalid]="uploadForm.controls.trackDescription.invalid && uploadForm.controls.trackDescription.touched" />
            <div class="invalid-feedback">
                <ng-container *ngTemplateOutlet="messageTemplates; context: { $implicit: uploadForm.controls.trackDescription.errors }"></ng-container>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="form-group">
            <label for="connection" i18n>Select connection.tch file:</label>
            <input 
                type="file"
                name="connection"
                accept=".tch"
                formControlName="connection"
                class="form-control"
                (change)="onFileChange('connection', $event)"
                [class.is-valid]="uploadForm.controls.connection.valid && uploadForm.controls.connection.touched"
                [class.is-invalid]="uploadForm.controls.connection.invalid && uploadForm.controls.connection.touched" />
            <div class="invalid-feedback">
                <ng-container *ngTemplateOutlet="messageTemplates; context: { $implicit: uploadForm.controls.connection.errors }"></ng-container>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="form-group">
            <label for="tunnel" i18n>Select tunnel.tch file:</label>
            <input 
                type="file"
                name="tunnel"
                accept=".tch"
                formControlName="tunnel"
                class="form-control"
                (change)="onFileChange('tunnel', $event)"
                [class.is-valid]="uploadForm.controls.tunnel.valid && uploadForm.controls.tunnel.touched"
                [class.is-invalid]="uploadForm.controls.tunnel.invalid && uploadForm.controls.tunnel.touched" />
            <div class="invalid-feedback">
                <ng-container *ngTemplateOutlet="messageTemplates; context: { $implicit: uploadForm.controls.tunnel.errors }"></ng-container>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="form-group">
            <label for="location" i18n>Select location.tch file:</label>
            <input 
                type="file"
                name="location"
                accept=".tch"
                formControlName="location"
                class="form-control"
                (change)="onFileChange('location', $event)"
                [class.is-valid]="uploadForm.controls.location.valid && uploadForm.controls.location.touched"
                [class.is-invalid]="uploadForm.controls.location.invalid && uploadForm.controls.location.touched" />
            <div class="invalid-feedback">
                <ng-container *ngTemplateOutlet="messageTemplates; context: { $implicit: uploadForm.controls.location.errors }"></ng-container>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="form-group">
            <label for="locationIndex" i18n>Select location.tcb file:</label>
            <input 
                type="file"
                name="locationIndex"
                accept=".tcb"
                formControlName="locationIndex"
                class="form-control"
                (change)="onFileChange('locationIndex', $event)"
                [class.is-valid]="uploadForm.controls.locationIndex.valid && uploadForm.controls.locationIndex.touched"
                [class.is-invalid]="uploadForm.controls.locationIndex.invalid && uploadForm.controls.locationIndex.touched" />
            <div class="invalid-feedback">
                <ng-container *ngTemplateOutlet="messageTemplates; context: { $implicit: uploadForm.controls.locationIndex.errors }"></ng-container>
            </div>
        </div>

        <cwi-upload-download-bar *ngIf="progress !== 0"
            [isDownload]="false"
            [fileSize]="totalSize"
            [progress]="progress">
        </cwi-upload-download-bar>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit" [disabled]="uploadForm.invalid" i18n>Submit</button>
    </div>
</form>

<ng-template #messageTemplates let-errors>
    <div *ngIf="errors?.required" i18n>File is required.</div>
</ng-template>

<ng-template #spinnerTemp>
    <mat-spinner *ngIf="processing"
            class="mat-spinner-color spinner-style"
            [diameter]=45>
    </mat-spinner>
</ng-template>