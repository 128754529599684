<mat-card class="d-flex flex-column">
  <mat-card-title>
    <div class="row">
      <div class="col">
        <span class="title" i18n>Acquisition Storages</span>
      </div>
    </div>
  </mat-card-title>
  <mat-divider [inset]="true"></mat-divider>
  <mat-card-content class="flex">
    <div *ngFor="let storage of storages; trackBy: storagesTrackBy" class="mt-3">
      <cwi-remote-storage [storage]="storage"></cwi-remote-storage>
    </div>
  </mat-card-content>
</mat-card>