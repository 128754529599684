<ng-container *ngIf="train$ | async; let trainName">
  <div class="modal-container">
    <div class="modal-title">
      <div class="row pt-3 pl-3 pr-3 pb-0">
        <div class="col">
          <button [ngStyle]="{ 'visibility': restarting ? 'collapse' : 'visible' }" type="button" class="close close-button" data-dismiss="modal" aria-label="Close" (click)="close();">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>
    <div class="modal-body flex my-auto">
      <div class="row" *ngIf="powerSystems.length > 1">
        <div class="col text-center my-auto title-container" i18n>
          Are you sure you want to restart all systems
        </div>
      </div>
      <div class="row" *ngIf="powerSystems.length === 1">
        <div class="col text-center my-auto title-container" i18n>
          Are you sure you want to restart system
        </div>
      </div>
      <div class="row mt-3">
        <div class="col system-container text-center my-auto">
          <span class="name">{{ name }}</span>
          <span *ngIf="powerSystems.length === 1; let system;" class="subname">
            {{ getSystemRole(system.isMaster) }}
          </span>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col text-center my-auto">
          <button *ngIf="restarting === false" class="btn p-0" (click)="restart(trainName)">
            <div class="restart"></div>
          </button>
          <div [@fade] *ngIf="restarting"  class="restarting my-auto"></div>
        </div>
      </div>
      <div class="row mt-2" *ngIf="restarting">
        <div class="col title-container text-center my-auto">
          <span i18n>System is restarting</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>