import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { PsChannelInfo, ResponderStatus, ResponderPsTestType } from "../diagnostic";

@Component({
  selector: 'cwi-remote-responder-ps-status-card',
  templateUrl: './responder-ps-status-card.component.html',
  styleUrls: ['./responder-ps-status-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponderPsStatusCardComponent {
  channelVisible: boolean;
  displayedColumns: string[] = ['test', 'sourceIp', 'protocol'];
  
  @Input()
  public title: string;
  @Input()
  public status: ResponderStatus;
  @Input()
  public version: string;
  @Input()
  public channels: PsChannelInfo[];

  isChannelListEmpty() {
    if(this.channels.length) {
      return false;
    }
    else {
      return true;
    }
  }

  //TODO update with subset V4
  getResponderPsTestType(testType) {
    switch (testType) {
      case ResponderPsTestType.None:
        return `None`;
      case ResponderPsTestType.PDPLossRate:
        return `PDP Loss Rate`;
      case ResponderPsTestType.TransferDelay:
        return `Transfer Delay`;
      case ResponderPsTestType.Saturate:
        return `Saturate`;
      case ResponderPsTestType.checkConnectivity:
        return `Check Connectivity`;
      case ResponderPsTestType.TransactionTransferDelay:
        return `Transaction Transfer Delay`;
      case ResponderPsTestType.TransactionTransferDelay2:
        return `Transaction Transfer Delay 2`;
      case ResponderPsTestType.Undefined:
      default:
        return '-';
    }
  }
}
