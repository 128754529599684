import { Component, Inject, Input, LOCALE_ID, TemplateRef } from '@angular/core';
import { LanguageSection, LANGUAGE_SECTION } from './language-selector.configuration';

@Component({
  selector: 'cwi-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent {

  @Input()
  public languageSelectorTemplate: TemplateRef<HTMLElement>;
  activeLanguage: LanguageSection;

  constructor(
    @Inject(LOCALE_ID)
    readonly locale: string,
    @Inject(LANGUAGE_SECTION)
    readonly languages: LanguageSection[],
  ) {
      if (languages.length == 1) {
        this.activeLanguage = languages[0];
      } else {
        this.activeLanguage = languages.find(language => language.locale === locale);
      }
  }
}
