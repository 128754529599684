import { Directive, EventEmitter, Inject, InjectionToken, Input, NgZone, OnDestroy, Output } from "@angular/core";
import { LeafletDirective } from "@asymmetrik/ngx-leaflet";
import { TrackInfo } from "@cwi/pages";
import L from "leaflet";
import { Observable, Subscription } from "rxjs";
import { startWith, filter, distinctUntilChanged } from 'rxjs/operators';

export const TRACK_DRAWER_FACTORY = new InjectionToken<ITrackDrawerFactory>('TrackDrawer');

export interface ITrackDrawerFactory {
    create(onclick, definition: TrackInfo): ITrackDrawer;
}

export interface ITrackDrawer {
    addTo(leafletMap: L.Map): this;
    remove();
}

export type TrackDrawerConstructor = 
    new (onclick: () => void, definition: TrackInfo) => TrackDrawer;

export function createTrackFactory(implementation: TrackDrawerConstructor) {
    return class Factory implements ITrackDrawerFactory {
        
      create(onclick: () => void, definition: TrackInfo) {
        return new implementation(onclick, definition);
      }
    };
}

export class TrackDrawer implements ITrackDrawer {
    public readonly control: L.Polyline;
    public readonly start: L.Circle;

    constructor(
        private readonly onclick: () => void,
        track: TrackInfo
    ) {

        this.control = L.polyline(track.points, { smoothFactor: 5, noClip: true });
        (this.control as any).clicked = track.hasUser;

        const resetStyle = { color: '#3388ff', weight: 6, opacity: 0.8};
        const clickedStyle = { color: 'green', weight: 10, opacity: 1};

        if (track.hasUser) {
            this.control.setStyle(clickedStyle);
        } else {
            this.control.setStyle(resetStyle);
        }

        this.control.bindTooltip(`Line: ${track.lineCode}, Track Code: ${track.code}, Track Name: ${track.name }`)
        
        this.control.on('click', (e) => 
            {   
                var layer = e.target;
                layer.clicked = !layer.clicked;
                if (layer.clicked) {
                    layer.setStyle(clickedStyle);
                } else {
                    layer.setStyle(resetStyle);
                }
                layer.bringToFront();
                this.onclick();
            }
        );
        

        this.control.on('mouseover', (e) => {
            var layer = e.target;
            if (!layer.clicked) {
                layer.setStyle({
                    color: 'blue',
                    opacity: 1,
                    weight: 10
                });
            }
        });

        this.control.on('mouseout', (e) => {
            var layer = e.target;
            if (!layer.clicked) {
                layer.setStyle(resetStyle);

            }
        });
        
        const startPoint = track.points[0];
        this.start = L.circle(L.latLng(startPoint[0], startPoint[1]), 30, { fill: true });
        this.start.setStyle({ color : '#ffffff', opacity: 0.8, fillColor: '#000000', weight: 2})
    }

    addTo(leafletMap: L.Map) {
        this.control.addTo(leafletMap);
        this.start.addTo(leafletMap);
        this.control.bringToBack();
        return this;
    }

    remove() {
        this.control.remove();
    }
}
  
export const TrackDrawerFactory = createTrackFactory(TrackDrawer);

export class TrackDrawingContext {
    private drawers: ITrackDrawer[];

    constructor(
        private readonly leafletMap: L.Map,
        private readonly drawerFactories: ITrackDrawerFactory[],
        private readonly onclick: (definition: TrackInfo) => void,
        private definition: TrackInfo
    ) {
        this.reset(definition);
    }

    private removeAll() {
        if (this.drawers) {
          for (const drawer of this.drawers) {
            drawer.remove();
          }
        }
    }

    private reset(value: TrackInfo) {
        this.definition = value;
        this.removeAll();
        if (this.leafletMap) {
            for (const factory of this.drawerFactories) {
                factory.create(() => this.onclick(this.definition), value).addTo(this.leafletMap)
            }
        }
    }
    
    destroy() {
        this.removeAll();
    }
}

@Directive({
    selector: '[cwiTrackDatabaseViewer]'
})
export class TrackViewerDirective implements OnDestroy {

    @Input('cwiTrackDatabaseViewer')
    public set trackdb(value: Observable<TrackInfo[]>) {
        this.updatesSubscription.unsubscribe();
        if (value) {
            this.updatesSubscription = this.subscription.add(value.subscribe(tracks => {
                this.addTracks(tracks);
            }, () => { }));
        } 
    }
    
    @Output()
    public readonly trackSelected = new EventEmitter<TrackInfo>();

    private readonly subscription: Subscription;
    private updatesSubscription = new Subscription();
    private drawingContexts: TrackDrawingContext[] = [];
    leafletMap: L.Map;

    constructor(
        private readonly zone: NgZone,
        leaflet: LeafletDirective,
        @Inject(TRACK_DRAWER_FACTORY)
        private readonly drawers: ITrackDrawerFactory[]
    ) {
        this.subscription = leaflet.mapReady.pipe(
            startWith(leaflet.map),
            filter(m => !!m),
            distinctUntilChanged()
        ).subscribe(map => this.onMapReady(map));
    }

    private clearAll() {
        this.zone.runOutsideAngular(() => {
            if (this.drawingContexts) {
                for (const context of this.drawingContexts) {
                    context.destroy();
                }
            }
            this.drawingContexts = [];
        });
    }
    
    private addTrack(track: TrackInfo) {
        this.drawingContexts.push(new TrackDrawingContext(
            this.leafletMap,
            this.drawers,
            (definition) => this.trackSelected.next(definition),
            track
        ));
    }

    private addTracks(tracks: TrackInfo[]) {
        this.zone.runOutsideAngular(() => {
            if (this.leafletMap) {
                this.leafletMap.eachLayer((layer:any) => {
                    if (!!layer.toGeoJSON) {
                      this.leafletMap.removeLayer(layer);
                    }
                  });
            }
            for (const track of tracks) {
                this.addTrack(track);
            }
        })
    }

    private onMapReady(leafletMap: L.Map) {
        this.leafletMap = leafletMap;
        this.clearAll();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.updatesSubscription.unsubscribe();
        this.clearAll();
    }
    
}