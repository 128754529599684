<div class="col time">
    <span i18n>Remaining Time</span>
    <span *ngIf="time; else nodata;">
        {{ days | number: '2.0-0' }}<span class="format ml-2 mr-2">d</span>
        {{ hours | number: '2.0-0' }}<span class="format ml-2 mr-2">h</span>
        {{ minutes | number: '2.0-0' }}<span class="format ml-2">m</span>
    </span>
</div>

<ng-template i18n #nodata>
    <div class="text-center">-</div>
</ng-template>