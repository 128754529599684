import { CircleDefinition, PolygonDefinition, GeometryDefinition, RectangleDefinition } from './geometry-definition';
import * as turf from '@turf/turf';
import { Feature, pointToLineDistance } from '@turf/turf';
import * as L from 'leaflet';

function getPointsRectangleBounds(rectDefinition: RectangleDefinition): L.LatLng[] {
    return [
        L.latLng(rectDefinition.boundaries[0][0], rectDefinition.boundaries[0][1]),
        L.latLng(rectDefinition.boundaries[0][0], rectDefinition.boundaries[1][1]),
        L.latLng(rectDefinition.boundaries[1][0], rectDefinition.boundaries[1][1]),
        L.latLng(rectDefinition.boundaries[1][0], rectDefinition.boundaries[0][1])
    ];
}

function toLineString(polyDefinition: PolygonDefinition) {
    return turf.lineString(polyDefinition.points.map(vertex => [vertex.lng, vertex.lat]));
}

function pointToPointDistance(point1: L.LatLng, point2: L.LatLng) {
    const turfPoint1 = turf.point([ point1.lng, point1.lat ]);
    const turfPoint2 = turf.point([ point2.lng, point2.lat ]);
    const res = turf.distance(turfPoint1, turfPoint2, { units: 'meters'});
    return res;
}

function circleCircleDistance(firstCircle: CircleDefinition, secondCircle: CircleDefinition): number {
    return pointToPointDistance(
        firstCircle.center,
        secondCircle.center
    ) - (firstCircle.ray + secondCircle.ray);
}

function circlePolygonDistance(circle: CircleDefinition, polyDefinition: PolygonDefinition): number {
    const lineString = toLineString(polyDefinition);
    const dist = turf.pointToLineDistance(
        turf.point([ circle.center.lng, circle.center.lat]),
        lineString, { units: 'meters' }
    ) - circle.ray;
    return dist;
}

function circleRectangleDistance(geometry1: CircleDefinition, geometry2: RectangleDefinition): number {
    const rectPoints = getPointsRectangleBounds(geometry2);
    let minDistance = Infinity;
    const circleCenterCoord = turf.point([ geometry1.center.lng, geometry1.center.lat ]);
    for (let i = 0; i < rectPoints.length - 1; i++) {
        const polyline = turf.lineString([
            [ rectPoints[i].lng, rectPoints[i].lat ],
            [ rectPoints[i + 1].lng, rectPoints[i + 1].lat ]
        ]);
        const a = pointToLineDistance(circleCenterCoord, polyline, {units: 'meters', mercator: true}, true);
        minDistance = Math.min(
            minDistance,
            a
        );
    }
    // Calcolo l'ultimo punto con il primo
    const lastPolyline = turf.lineString([
        [ rectPoints[rectPoints.length - 1].lng, rectPoints[rectPoints.length - 1].lat ],
        [ rectPoints[0].lng, rectPoints[0].lat ]
    ]);
    const b = pointToLineDistance(circleCenterCoord, lastPolyline, {units: 'meters', mercator: true}, true);
    minDistance = Math.min(
        minDistance,
        b
    );
    return minDistance - geometry1.ray;
}

function genericPolygonPolygonDistance(pointsPoly1: L.LatLng[], pointsPoly2: L.LatLng[]) {
    let minDistance = Infinity;
    for (let j = pointsPoly1.length; j !== 1; j--) {
        const lastPoint = pointsPoly1[j - 1];
        const secondLastPoint = pointsPoly1[j - 2];
        const firstPolyLine = turf.lineString([
            [ secondLastPoint.lng, secondLastPoint.lat ],
            [ lastPoint.lng, lastPoint.lat ]
        ]);
        const secondPointCoord = turf.point([ lastPoint.lng, lastPoint.lat ]);

        for (let i = 0; i < pointsPoly2.length - 1; i++) {
            const pt = turf.point([ pointsPoly2[i].lng, pointsPoly2[i].lat ]);
            const polyLine = turf.lineString([
                [ pointsPoly2[i + 1].lng, pointsPoly2[i + 1].lat ],
                [ pointsPoly2[i].lng, pointsPoly2[i].lat ]
            ]);
            if (!turf.booleanDisjoint(firstPolyLine, polyLine)) {
                return 0;
               } else {
                const a = pointToLineDistance(secondPointCoord, polyLine, {units: 'meters', mercator: true}, true);
                const b = pointToLineDistance(pt, firstPolyLine, {units: 'meters', mercator: true}, true);
                minDistance = Math.min(
                    minDistance,
                    a,
                    b,
                );
            }
            // Calcolo l'ultimo punto con il primo
            const lastPolyline = turf.lineString([
                [ pointsPoly2[pointsPoly2.length - 1].lng, pointsPoly2[pointsPoly2.length - 1].lat ],
                [ pointsPoly2[0].lng, pointsPoly2[0].lat ]
            ]);
            const c = pointToLineDistance(secondPointCoord, lastPolyline, {units: 'meters', mercator: true}, true);
            minDistance = Math.min(
                minDistance,
                c
            );
        }
    }
    return minDistance;
}

function polyRectDistance(poly: PolygonDefinition, rect: RectangleDefinition): number {
    return genericPolygonPolygonDistance(poly.points, getPointsRectangleBounds(rect));
}

function polyPolyDistance(poly1: PolygonDefinition, poly2: PolygonDefinition) {
    return genericPolygonPolygonDistance(poly1.points, poly2.points);
}

function genericRectPolyDistance(firstPoints: L.LatLng[], secondPoints: L.LatLng[]): number {
    let minDistance = Infinity;
    for (let j = 0; j < firstPoints.length - 1; j++) {
        const p1 = firstPoints[j];
        const p2 = firstPoints[j + 1];
        const rectPolyline = turf.lineString([
            [ p2.lng, p2.lat ],
            [ p1.lng, p1.lat ]
        ]);
        const point = turf.point([ p1.lng, p1.lat ]);
        for (let i = 0; i < secondPoints.length - 1; i++) {
            const polyPoint = turf.point([secondPoints[i].lng, secondPoints[i].lat]);
            const polyLine = turf.lineString([
                [ secondPoints[i + 1].lng, secondPoints[i + 1].lat ],
                [ secondPoints[i].lng, secondPoints[i].lat ]
            ]);
            const a = pointToLineDistance(point, polyLine, {units: 'meters', mercator: true}, true);
            const b = pointToLineDistance(polyPoint, rectPolyline, {units: 'meters', mercator: true}, true);
            minDistance = Math.min(
                minDistance,
                a,
                b
            );
        }

        // Calcolo l'ultimo punto con il primo
        const lastPolyline = turf.lineString([
            [ secondPoints[secondPoints.length - 1].lng, secondPoints[secondPoints.length - 1].lat ],
            [ secondPoints[0].lng, secondPoints[0].lat ]
        ]);
        const c = pointToLineDistance(point, lastPolyline, {units: 'meters', mercator: true}, true);
        minDistance = Math.min(
            minDistance,
            c
        );
    }
    return minDistance;
}

function rectPolyDistance(rect: RectangleDefinition, poly: PolygonDefinition): number {
    return genericRectPolyDistance(getPointsRectangleBounds(rect), poly.points);
}

function rectRectDistance(firstRect: RectangleDefinition, secondRect: RectangleDefinition): number {
    const firstRectPoints = getPointsRectangleBounds(firstRect);
    const secondRectPoints = getPointsRectangleBounds(secondRect);

    let minDistance = genericRectPolyDistance(firstRectPoints, secondRectPoints);

    for (const a of secondRectPoints) {
        const point = turf.point([ a.lng, a.lat ]);
        for (let i = 0; i < firstRectPoints.length - 1; i++) {
            const polyLine = turf.lineString([
                [ firstRectPoints[i + 1].lng, firstRectPoints[i + 1].lat ],
                [ firstRectPoints[i].lng, firstRectPoints[i].lat ]
            ]);
            const b = pointToLineDistance(point, polyLine, {units: 'meters', mercator: true}, true);
            minDistance = Math.min(
                minDistance,
                b,
            );
        }

        // Calcolo l'ultimo punto con il primo
        const lastPolyline = turf.lineString([
            [ firstRectPoints[firstRectPoints.length - 1].lng, firstRectPoints[firstRectPoints.length - 1].lat ],
            [ firstRectPoints[0].lng, firstRectPoints[0].lat ]
        ]);
        const c = pointToLineDistance(point, lastPolyline, {units: 'meters', mercator: true}, true);
        minDistance = Math.min(
            minDistance,
            c
        );
    }
    return minDistance;
}

export function distance(geometry1: GeometryDefinition, geometry2: GeometryDefinition): number {
    if (geometry1.shape === 'circle' && geometry2.shape === 'circle') {
        return circleCircleDistance(geometry1, geometry2);
    } else if (geometry1.shape === 'circle' && geometry2.shape === 'polygon') {
        return circlePolygonDistance(geometry1, geometry2);
    } else if (geometry1.shape === 'circle' && geometry2.shape === 'rectangle') {
        return circleRectangleDistance(geometry1, geometry2);
    } else if (geometry1.shape === 'rectangle' && geometry2.shape === 'circle') {
        return circleRectangleDistance(geometry2, geometry1);
    }  else if (geometry1.shape === 'rectangle' && geometry2.shape === 'rectangle') {
        return rectRectDistance(geometry1, geometry2);
    }  else if (geometry1.shape === 'rectangle' && geometry2.shape === 'polygon') {
        return rectPolyDistance(geometry1, geometry2);
    } else if (geometry1.shape === 'polygon' && geometry2.shape === 'circle') {
        return circlePolygonDistance(geometry2, geometry1);
    } else if (geometry1.shape === 'polygon' && geometry2.shape === 'polygon') {
        return polyPolyDistance(geometry1, geometry2);
    }  else if (geometry1.shape === 'polygon' && geometry2.shape === 'rectangle') {
        return polyRectDistance(geometry1, geometry2);
    }
}

export function geometryContainsAnother(newGeom: GeometryDefinition, previousGeo: GeometryDefinition): boolean {
    let turfPoly1: Feature;
    let turfPoly2: Feature;

    if (newGeom.shape === 'polygon') {
        turfPoly1 = L.polygon(newGeom.points).toGeoJSON();
    } else if (newGeom.shape === 'circle') {
        const center = turf.point([newGeom.center.lng, newGeom.center.lat]);
        const radius = newGeom.ray;
        if (center && radius) {
            turfPoly1 = turf.circle(center, radius, {units: 'meters'});
        }
    } else if (newGeom.shape === 'rectangle') {
        turfPoly1 = L.rectangle(newGeom.boundaries).toGeoJSON();
    }

    if (previousGeo.shape === 'polygon') {
        turfPoly2 = L.polygon(previousGeo.points).toGeoJSON();
    } else if (previousGeo.shape === 'circle') {
        const center = turf.point([previousGeo.center.lng, previousGeo.center.lat]);
        const radius = previousGeo.ray;
        if (center && radius) {
            turfPoly1 = turf.circle(center, radius, {units: 'meters'});
        }
    } else if (previousGeo.shape === 'rectangle') {
        turfPoly2 = L.rectangle(previousGeo.boundaries).toGeoJSON();
    }

    if (turfPoly1 && turfPoly2) {
        const contains = turf.booleanContains(turfPoly1, turfPoly2 as any);
        const overlap = turf.booleanOverlap(turfPoly1, turfPoly2);
        return contains || overlap;
    }
    return false;
}
