import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SystemDefinition } from '../system-definition';

@Component({
  selector: 'cwi-remote-systems-list',
  templateUrl: './systems-list.component.html',
  styleUrls: ['./systems-list.component.scss']
})
export class SystemsListComponent {

  @Input()
  isVisible: boolean;

  @Input()
  public systems: Iterable<SystemDefinition>;

  @Output()
  public readonly systemSelected = new EventEmitter<SystemDefinition>();

  systemClick(system: SystemDefinition) {
    this.systemSelected.next(system);
  }

  systemTrackBy(index: number, system: SystemDefinition) {
    return system.name;
  }

}
