import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GroupInfo as GroupInfo } from '../../models/group-definition';
import { GROUPCREATION_CONFIGURATION, IGroupCreationConfiguration } from './group-creation.configuration';

export interface GroupCreationInfo {
  groupName: string;
}

@Component({
    selector: 'cwi-group-creation',
    templateUrl: './group-creation.component.html',
    styleUrls: ['./group-creation.component.scss'],
    providers: [],
  })
  export class GroupCreationComponent {

    @Input()
    public error: string;

    @Output()
    public readonly created = new EventEmitter<GroupCreationInfo>();

    @Output()
    public readonly closed = new EventEmitter<void>();

    edit: boolean;
    groupInfoInternal: GroupInfo;
    groupCreationForm: FormGroup;

    constructor(
      @Inject(GROUPCREATION_CONFIGURATION)
      configuration: IGroupCreationConfiguration,
    ) {
      this.groupCreationForm = new FormGroup({
        name: new FormControl(null, configuration.nameValidators),
      });
    }

    setPreviusValues() {
      this.groupCreationForm.get('name').setValue(this.groupInfoInternal.groupName);
    }

    onSubmit() {
      if (this.groupCreationForm.valid) {
        const value = this.groupCreationForm.get('name').value as string;
        this.created.next( { groupName: value });
      }
    }

    cancel() {
      this.groupCreationForm.reset();
    }

    close() {
      this.closed.next();
    }

    getTitle() {
      return this.edit ? $localize`Edit group` : $localize`Create group`;
    }
}
