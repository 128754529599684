import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PlanDefinition } from '../plan-definition';
import { StandardModeDefinition } from '../standard-mode-definition';

@Component({
  selector: 'cwi-remote-standard-mode-creator',
  templateUrl: './standard-mode-creator.component.html',
  styleUrls: ['./standard-mode-creator.component.scss']
})
export class StandardModeComponent implements OnDestroy {

  private subscription = new Subscription();
  private standardModeInfoInternal: StandardModeDefinition;
  edit: boolean;
  standardModeForm: FormGroup;

  @Input()
  setups: string[];

  @Input()
  isActive: boolean;

  @Input()
  selectedPlan: PlanDefinition;

  @Input()
  public set standardModeInfo(value: StandardModeDefinition) {
    this.standardModeInfoInternal = value;
    if (value) {
      this.setPreviusValues();
      this.edit = true;
    } else {
      this.edit = false;
    }
  }

  @Output()
  public readonly result = new EventEmitter<StandardModeDefinition>();

  @Output()
  public readonly canceled = new EventEmitter<void>();

  constructor() {
    this.standardModeForm = new FormGroup({
      setup: new FormControl(null, Validators.required)
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setPreviusValues() {
    this.standardModeForm.get('setup').setValue(this.standardModeInfoInternal.setup);
  }

  close() {
    this.canceled.next();
  }

  cancel() {
    this.standardModeForm.reset();
    this.setPreviusValues();
  }

  onSubmit() {
    if (this.standardModeForm.valid) {
      const id = this.standardModeInfoInternal ? this.standardModeInfoInternal.id : null;
      this.result.emit({
        id,
        ...this.standardModeForm.value
      });
    }
  }

  disableSubmit() {
    if (this.standardModeForm) {
      if (this.isActive) {
        return true;
      } else {
        return !this.standardModeForm.valid;
      }
    } else {
      return !this.standardModeForm.valid;
    }
  }

  getTitle() {
    return this.edit ? $localize`Edit standard mode` : $localize`Add standard mode`;
  }
}
