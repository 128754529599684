import { InjectionToken } from '@angular/core';
import L from 'leaflet';
import { latLng, MapOptions, tileLayer } from 'leaflet';

export const SOUTH_WEST = L.latLng(-90, -180);
export const NORTH_EAST = L.latLng(90, 180);

export const MAP_OPTIONS = new InjectionToken<MapOptions>('MAP_OPTIONS');

export const DEFAULT_MAP_OPTIONS_FACTORY: () => MapOptions = () => ({
    preferCanvas: true,
    // only 1 layer here. Others will be added as <div>s directly in the view.
    // FIXME remove hardcoded openstreetmap tile server link from here
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        detectRetina: true,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      })
    ],
    minZoom: 6,
    maxZoom: 17,
    zoom: 7,
    center: latLng(41.902782, 12.496366)
  });
