import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {

  constructor(private readonly authenticationService: AuthenticationService) { }

  public async canActivate() {
    try {
      return !!await this.authenticationService.refreshUserInfo();
    } catch {
      return false;
    }
  }
}
