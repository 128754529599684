import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SubSystemStatus } from "../diagnostic";

@Component({
  selector: 'cwi-remote-subsystem-status-indicator',
  templateUrl: './subsystem-status-indicator.component.html',
  styleUrls: ['./subsystem-status-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubSystemStatusIndicatorComponent {

  @Input()
  public status: SubSystemStatus;

  enumValue = SubSystemStatus;

  get statusValue(): string {
    switch (this.status)
    {
      case SubSystemStatus.Idle:
        return $localize`Idle`;
      case SubSystemStatus.Measuring:
        return $localize`Measuring`;
      case SubSystemStatus.Recording:
        return $localize`Recording`;
      case SubSystemStatus.Error:
        return $localize`Error`;
      case SubSystemStatus.Unreachable:
        default:
        return $localize`Unreachable`;
    }
  }
}
