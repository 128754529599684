<mat-card>
  <mat-card-title>
    <div class="row">
      <div class="col">{{ title }}</div>
      <div class="col-auto my-auto small">
        <cwi-remote-responder-status-indicator [status]="status"></cwi-remote-responder-status-indicator>  
      </div>
      <div class="col-auto my-auto small">
        <button mat-icon-button (click)="channelVisible = !channelVisible" class="icon" [disabled]="isChannelListEmpty()"><mat-icon ckass="material-icons">{{ channelVisible ? 'more_horiz' : 'more_vert' }}</mat-icon></button>
      </div>
    </div>
  </mat-card-title>
  <mat-card-content class="w-100" *ngIf="channelVisible">
    <mat-divider></mat-divider>
    <table mat-table [dataSource]="channels" class="mat-elevation-z0">    
      <!-- Test Column -->
      <ng-container matColumnDef="test">
        <th mat-header-cell *matHeaderCellDef class="my-auto header" i18n>Test</th>
        <td mat-cell *matCellDef="let channel">
          <span>{{ getResponderCsTestType(channel.testType) }}</span>
        </td>
      </ng-container>

      <!-- Calling Number Column -->
      <ng-container matColumnDef="callingNumber">
        <th mat-header-cell *matHeaderCellDef class="my-auto header" i18n>Calling Number</th>
        <td mat-cell *matCellDef="let channel">
          <span>{{ channel.callingNumber ?? '-' }}</span>
        </td>
      </ng-container>  
      
      <!-- Called Number Column -->
      <ng-container matColumnDef="calledNumber">
        <th mat-header-cell *matHeaderCellDef class="my-auto header" i18n>Called Number</th>
        <td mat-cell *matCellDef="let channel">
          <span>{{ channel.calledNumber ?? '-' }}</span>
        </td>
      </ng-container> 

      <!-- Channel Column -->
      <ng-container matColumnDef="channel">
        <th mat-header-cell *matHeaderCellDef class="my-auto header" i18n>Channel</th>
        <td mat-cell *matCellDef="let channel">
          <span>{{ channel.channel ?? '-' }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
  <mat-card-footer>
    <div class="col-auto my-auto small">
      <p>v.{{ version ?? '-' }}</p>
    </div>
  </mat-card-footer>
</mat-card>
