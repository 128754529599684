import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SystemDiagnostic } from "../diagnostic";

const KM_FACTOR = 50000 / 2199023255552;

const units = [
  'B',
  'KB',
  'MB',
  'GB',
  'TB'
];

@Component({
  selector: 'cwi-remote-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StorageComponent {

  @Input()
  public storage: SystemDiagnostic;

  get unit() {
    let bytes = this.storage.availableSpace;
    let unit = 0;

    while ( bytes >= 1000 ) {
      bytes /= 1000;
      unit ++;
    }
    return units[unit];
  }

  get spaceFormatted() {
    let bytes = this.storage.availableSpace;

    while ( bytes >= 1000 ) {
      bytes /= 1000;
    }

    return bytes.toFixed(1);
  }

  get percentage() {
    return this.storage.totalSpace > 0
      ? 100 - ((this.storage.availableSpace / this.storage.totalSpace) * 100)
      : 0;
  }

  get percentageValue() {
    return this.percentage.toFixed(1);
  }

  // Calcolo fuffa basato su quanto dichiarato in DR3
  // Ossia che 4TB di spazio corrispondono a 50k Km
  get availableKm() {
    return KM_FACTOR * this.storage.availableSpace;
  }

  get availableKmValue() {
    return this.availableKm.toFixed(0);
  }

  getbarColor() {
    if (this.percentage > 60 && this.percentage < 90 ) {
      return '#EF8354';
    } else if (this.percentage > 90) {
      return '#DA5050';
    }
    return '#5FDCB3';
  }

  getStorageRole(isMaster: boolean) {
    return isMaster ? $localize`Main` : $localize`Mirror`
  }

}

