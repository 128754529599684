import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@cwi/components';
import { UserSettingsComponent } from './user-settings.component';

const routes: Routes = [
  {
    path: 'user-settings/:username', pathMatch: 'full', component: UserSettingsComponent, canActivate: [ AuthenticationGuard ]
  },
];

@NgModule({
  declarations: [],
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class UserSettingsRoutingModule { }
