<div class="card card-cwi mb-1" [ngClass]="{ 'disabled': probeStatus.status === 'Unreachable' }">
    <div class="card-body-cwi">
        <div class="wrapper mt-1">
            <h6 i18n>Wagon:</h6>
            <label class="ml-2"> {{ probeStatus.wagon }}</label>
            <i *ngIf="probeStatus.isMaster" class=" ml-2 fas fa-crown text-secondary" title="Master"></i>
        </div>
        <div class="wrapper">
            <h6 i18n>Status:</h6>
            <label class="ml-2"> {{ probeStatus.status }}</label>
            <i [ngClass]="probeStatus.status" style="text-shadow:2px 2px 4px #585858;" class="fas fa-circle ml-2" [title]="probeStatus.status"></i>
        </div>
        <label i18n> at {{ probeStatus.lastUpdate | cwiDate: true: 'HH:mm:ss DD-MM-YYYY' }} </label>
    </div>
</div>