<button mat-icon-button [matMenuTriggerFor]="user" title="{{'Welcome ' + userInfo.userName + '!'}}">
    <mat-icon class="user-icon material-icons color_white">account_circle</mat-icon>
</button>
<mat-menu #user="matMenu">
    <ng-container *ngTemplateOutlet="userProfileTemplate || defaultUserProfileTemplate"></ng-container>
</mat-menu>


<ng-template #defaultUserProfileTemplate>
    <div class="main-container">
        <span class="info" i18n>You are logged as<span style="font-weight:bold" class="ml-1">{{ userInfo.userName }}</span></span>
    </div>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="GoToSettings()">
        <mat-icon>settings</mat-icon>
        <span i18n>Manage your account</span>
    </button>
    <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span i18n>Logout</span>
    </button>
</ng-template>
