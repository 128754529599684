
// Chunked request serializer
const CHUNK_SIZE = 1024;
const encoder = new TextEncoder();

export class BufferWriter {
  private readonly chunks: Uint8Array[];
  private chunkLength: number;
  private current: ArrayBuffer;
  private currentTyped: Uint8Array;
  private currentDataView: DataView;
  private currentPosition: number;

  constructor() {
    this.chunks = [];
    this.chunkLength = 0;
    this.current = new ArrayBuffer(CHUNK_SIZE);
    this.currentTyped = new Uint8Array(this.current);
    this.currentDataView = new DataView(this.current);
    this.currentPosition = 0;
  }

  private ensure(size: number) {
    if (this.current.byteLength - this.currentPosition < size) {
      if (this.currentPosition > 0) {
        this.chunks.push(this.currentTyped.slice(0, this.currentPosition));
        this.chunkLength += this.currentPosition;
      }
      this.current = new ArrayBuffer(Math.max(CHUNK_SIZE, size));
      this.currentTyped = new Uint8Array(this.current);
      this.currentDataView = new DataView(this.current);
      this.currentPosition = 0;
    }
  }

  writeInt8(value: number) {
    this.ensure(1);
    this.currentDataView.setUint8(this.currentPosition, value);
    this.currentPosition += 1;
  }

  writeInt16(value: number, littleEndian?: boolean) {
    this.ensure(2);
    this.currentDataView.setUint16(this.currentPosition, value, littleEndian);
    this.currentPosition += 2;
  }

  writeInt32(value: number, littleEndian?: boolean) {
    this.ensure(4);
    this.currentDataView.setUint32(this.currentPosition, value, littleEndian);
    this.currentPosition += 4;
  }

  writeDouble(value: number, littleEndian?: boolean) {
    this.ensure(8);
    this.currentDataView.setFloat64(this.currentPosition, value, littleEndian);
    this.currentPosition += 8;
  }

  writeString(value: string, prefixLength?: 'le'|'be') {
    const buffer = encoder.encode(value);
    if (prefixLength) {
      this.writeInt32(buffer.length, prefixLength === 'le');
    }
    this.writeBuffer(buffer, false);
  }

  private flush() {
    if (this.currentPosition > 0) {
      this.chunks.push(this.currentTyped.slice(0, this.currentPosition));
      this.chunkLength += this.currentPosition;

      this.current = this.current.slice(this.currentPosition);
      this.currentTyped = new Uint8Array(this.current);
      this.currentDataView = new DataView(this.current);
      this.currentPosition = 0;
    }
  }

  writeBuffer(buffer: Uint8Array, copy = true) {
    if (copy) {
      this.ensure(buffer.byteLength);
      this.currentTyped.set(buffer, this.currentPosition);
      this.currentPosition += buffer.byteLength;
    } else {
      // Performance trick: if I can transfer to this class the ownership
      // of the buffer, I can just add it to the "chunks", of course
      // payng a bit of attention.
      this.flush();
      // then I can just add to the list of chunks
      this.chunks.push(buffer);
      this.chunkLength += buffer.byteLength;
    }
  }


  build() {
    this.flush();
    const result = new ArrayBuffer(this.chunkLength);
    const resultTyped = new Uint8Array(result);
    let position = 0;
    for (const chunk of this.chunks) {
      resultTyped.set(chunk, position);
      position += chunk.byteLength;
    }

    return resultTyped;
  }
}
