<div class="schedulations text-center mb-2 mt-2" *ngIf="timeSchedulations">
  <h5 class="font-weight-bold text-primary" i18n> Time Schedulation Mode</h5>
  <div class="mt-3">
    <button class="btn btn-sm btn-light text-primary icon-left"
    [disabled]="selectedDayIndex === 0"
    (click)="moveLeft();">
    <i class="fas fa-caret-left fa-2x"></i>
  </button>
  <label class="font-weight-bold text dayLabel"> {{ days[selectedDayIndex] }}</label>
  <button class="btn btn-sm btn-light text-primary icon-right"
    [disabled]="selectedDayIndex === days.length - 1"
    (click)="moveRight();">
    <i class="fas fa-caret-right fa-2x"></i>
  </button>
  <button [disabled]="selectedPlan.name === activatedPlan" class="btn btn-sm btn-primary ml-2" (click)="openAddTimeScheduleModal()" i18n-title title="Add new time schedulation" i18n>
    <span class="fas fa-clock"></span> Add new
  </button>
  <button [disabled]="selectedPlan.name === activatedPlan"
    class="btn btn-sm btn-primary ml-2" (click)="openSort(timeSchedulations)" i18n-title title="Sort schedulations" i18n>
    <span class="fas fa-sort"></span> Sort Priority
  </button>
  <cwi-remote-schedulation-timeline [timeSchedulations]="timeSchedulations"
    (schedulationClicked)="openEditTimeScheduleModal($event)">
  </cwi-remote-schedulation-timeline>
  </div>
</div>
