import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LOGIN_CONFIGURATION, ILoginConfiguration } from './login/login.configuration';

export interface UserClaims {
  userName: string;
  email: string;
  displayName: string;
  roles: string[];
}

@Injectable()
export class AuthenticationService implements HttpInterceptor {
  private userInfoPromise: Promise<UserClaims>;
  userInfo: UserClaims;

  constructor(
    private readonly $http: HttpClient,
    private readonly router: Router,
    @Inject(LOGIN_CONFIGURATION)
    private readonly configuration: ILoginConfiguration
  ) { }

  private async refreshUserInfoImpl() {
    try {
      return this.userInfo = await this.$http.get<UserClaims>(this.configuration.userInfo).toPromise();
    } finally {
      this.userInfoPromise = null;
    }
  }

  private async logoutImpl() {
    try {
      return this.userInfo = await this.$http.get<UserClaims>(this.configuration.logout).toPromise();
    } finally {
      this.userInfoPromise = null;
    }
  }

  private async loginImpl(username: string, password: string) {
    try {
      return this.userInfo = await this.$http.post<UserClaims>(this.configuration.login, {username, password}).toPromise();
    } finally {
      this.userInfoPromise = null;
    }
  }

  public async login(username: string, password: string): Promise<any> {
    if (!this.userInfo) {
      if (!this.userInfoPromise) {
        this.userInfoPromise = this.loginImpl(username, password);
      }

      return await this.userInfoPromise;
    } else {
      return this.userInfo;
    }
  }

  public async logout() {
    this.userInfo = null;

    if (!this.userInfoPromise) {
      this.userInfoPromise = this.logoutImpl();
    }

    return await this.userInfoPromise;
  }

  async refreshUserInfo(): Promise<UserClaims> {
    if (!this.userInfo) {
      if (!this.userInfoPromise) {
        this.userInfoPromise = this.refreshUserInfoImpl();
      }

      return await this.userInfoPromise;
    } else {
      return this.userInfo;
    }
  }

  private handleError(event: HttpEvent<any>) {
    if ('status' in event && (event.status === 403 || event.status === 401)) {
      this.userInfo = null;
      this.router.navigateByUrl('/login');
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        next: event => this.handleError(event),
        error: event => this.handleError(event)
      })
    );
  }
}
