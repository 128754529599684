import { Component, OnInit, Input, Inject } from '@angular/core';
import { INavbarConfiguration, NAVBAR_CONFIGURATION } from './navbar.configuration';

@Component({
  selector: 'cwi-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

  collapsed = true;

  constructor(
    @Inject(NAVBAR_CONFIGURATION)
    readonly configuration: INavbarConfiguration
  ) { }
}
