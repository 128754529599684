import { Injectable, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SOFTWAREUPDATES_CONFIGURATION, ISoftwareUpdatesConfiguration, ISoftwareUpdatesService } from './software-updates';
import { SoftwareUpdatesClient, SoftwareUpdatesPackage } from './software-updates-model';
import { reload } from '@cwi/rx';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class SoftwareUpdatesService implements ISoftwareUpdatesService {
  private readonly baseUrl: string;
  private reloadSubject: Subject<any> = new Subject<any>();

  public clients$: Observable<Iterable<SoftwareUpdatesClient>>
  public packages$: Observable<Iterable<SoftwareUpdatesPackage>>

  constructor(
    private readonly $http: HttpClient,
    @Inject(SOFTWAREUPDATES_CONFIGURATION)
    configuration: ISoftwareUpdatesConfiguration
  ) {
    this.baseUrl = configuration.baseUrl
    this.clients$ = this.$http.get<SoftwareUpdatesClient[]>(`${this.baseUrl}/clients`).pipe(
      reload(this.reloadSubject),
      shareReplay(1)
    );
    this.packages$ = this.$http.get<SoftwareUpdatesPackage[]>(`${this.baseUrl}/updates`).pipe(
      reload(this.reloadSubject),
      shareReplay(1)
    );
  }

  reload() {
    this.reloadSubject.next();
  }

  deletePackage(updatePackage: SoftwareUpdatesPackage): Observable<void> {
    return this.$http.delete<void>(`${this.baseUrl}/update/${updatePackage.tag}/${updatePackage.version}`);
  }

  setClientVersion(client: SoftwareUpdatesClient, version: string) : Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/update-client/${client.trainName}/${client.wagonName}/${client.systemName}/${client.id}/${version}`, {})
  }

  uploadPackage(file: File, notes: string) : Observable<void> {
    const formData = new FormData()
    formData.append('update', file, file.name)
    formData.append('notes', notes)
    return this.$http.put<void>(`${this.baseUrl}/update`, formData)
  }

}
