import { Observable } from "rxjs";
import { SoftwareUpdatesClient, SoftwareUpdatesPackage } from "./software-updates-model";
import { InjectionToken } from "@angular/core";

export const SOFTWAREUPDATES_SERVICE = new InjectionToken<ISoftwareUpdatesService>('ISoftwareUpdatesService');
export const SOFTWAREUPDATES_CONFIGURATION = new InjectionToken<ISoftwareUpdatesConfiguration>('ISoftwareUpdatesConfiguration');
export const SOFTWAREUPDATES_DEFAULT: ISoftwareUpdatesConfiguration = {
  baseUrl: '/api/software-update'
};

export interface ISoftwareUpdatesConfiguration {
  baseUrl: string;
}

export interface ISoftwareUpdatesService {
  clients$: Observable<Iterable<SoftwareUpdatesClient>>;
  packages$: Observable<Iterable<SoftwareUpdatesPackage>>;
  reload() : void;
  deletePackage(updatePackage: SoftwareUpdatesPackage) : Observable<void>;
  setClientVersion(client: SoftwareUpdatesClient, version: string) : Observable<void>;
  uploadPackage(file: File, notes: string) : Observable<void>;
}
