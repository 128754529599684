import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import 'moment/locale/it';

@Pipe({
  name: 'cwiDate'
})
export class DatePipe implements PipeTransform {
  
  transform(value: moment.Moment|number, isLocal: boolean, format: string): any {
    let result: moment.Moment;

    if (isLocal) {
      result = moment.utc(value).local();
    } else {
      result = moment.utc(value);
    }
    return result.format(format);
  }
}
