import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboChartComponent } from './combo-chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ComboChartSeriesDirective } from './combo-chart-series.directive';
import { CwiCommonModule } from '../common/common.module';

@NgModule({
  declarations: [ ComboChartComponent, ComboChartSeriesDirective ],
  imports: [
    CommonModule,
    CwiCommonModule,
    NgxChartsModule
  ],
  exports: [ ComboChartComponent, ComboChartSeriesDirective ]
})
export class ComboChartsModule { }
