import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ResponderMobileDiagnostic, ResponderIsdnDiagnostic, ResponderPsDiagnostic } from "../diagnostic";
import { SubSystemFullStatus } from "../subsystem-status/subsystem-status.component";

@Component({
  selector: 'cwi-remote-system-tab-content',
  templateUrl: './system-tab-content.component.html',
  styleUrls: ['./system-tab-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemTabContentComponent {

  shownSubSystems: SubSystemFullStatus[];

  @Input()
  public isMaster: boolean;

  @Input()
  public subSystems: SubSystemFullStatus[];

  @Input()
  public respondersMobile: ResponderMobileDiagnostic[];

  @Input()
  public respondersIsdn: ResponderIsdnDiagnostic[];

  @Input()
  public respondersPs: ResponderPsDiagnostic[];

  getMainSubSystems(subSystems: SubSystemFullStatus[]) {
    this.shownSubSystems = subSystems;
  }

  responderMobileTrackBy(index: number, responderMobile: ResponderMobileDiagnostic) {
    return responderMobile.name;
  }

  responderIsdnTrackBy(index: number, responderIsdn: ResponderIsdnDiagnostic) {
    return responderIsdn.name;
  }

  responderPsTrackBy(index: number, responderPs: ResponderPsDiagnostic) {
    return responderPs.name;
  }

  subSystemsTrackBy(index: number, subSystem: SubSystemFullStatus) {
    return subSystem.name; 
  }

  getSystemRole() {
    return this.isMaster ? $localize`Main system` : $localize`Mirror system`
  }
}
