<div class="text-center mt-2">
  <button *ngIf="!standardMode" [disabled]="selectedPlan.name === activatedPlan" class="btn btn-sm btn-primary ml-2" (click)="openAddStandardMode()" i18n-title title="Add new standard mode" i18n>
    <span class="fas fa-hourglass mr-1"></span> Select setup
  </button>
  <cwi-remote-standard-mode-info class="standardModeInfo mt-2" *ngIf="standardMode"
    [standardModeInfo]="standardMode"
    [editDisabled]="selectedPlan.name === activatedPlan"
    (editClicked)="editStandardMode()"
    (deleteClicked)="deleteStandardMode(standardMode)">
  </cwi-remote-standard-mode-info>
</div>
