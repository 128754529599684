
<div class="logos">
  <img *ngFor="let logo of pageConfiguration.logos"
    class="img-logo"
    [attr.width]=logo.width
    [attr.height]=logo.height
    [attr.alt]=logo.alt
    [src]=logo.path />
</div>
<h3 i18n>Login</h3>

<cwi-loading class="container" [loading]="loading">
  <form [formGroup]="loginForm" (ngSubmit)="login()">
    <div class="alert alert-danger" role="alert" *ngIf="loginError">
      {{ errorMessage }}
    </div>

    <div class="form-group">
      <label i18n>Username:
        <input type="text" class="form-control input-text"
          placeholder="[Domain\]Username"
          formControlName="username"
          [class.is-valid]="loginForm.controls.username.valid && loginForm.controls.username.touched"
          [class.is-invalid]="loginForm.controls.username.invalid && loginForm.controls.username.touched" />
        <div class="invalid-feedback">
          <ng-container *ngTemplateOutlet="usernameTemplate || defaultUsernameTemplate; context: { $implicit: loginForm.controls.username.errors }"></ng-container>
        </div>
      </label>
    </div>

    <div class="form-group">
      <label i18n>Password:
        <div class="input-group">
          <input [type]="showPassword ? 'text' : 'password'" class="form-control input-text"
            placeholder="Password"
            formControlName="password"
            [class.is-valid]="loginForm.controls.password.valid && loginForm.controls.password.touched"
            [class.is-invalid]="loginForm.controls.password.invalid && loginForm.controls.password.touched" />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="togglePassword()">
              <span class="fas" [class.fa-eye-slash]="showPassword" [class.fa-eye]="!showPassword"></span>
            </button>
          </div>
          <div class="invalid-feedback">
            <ng-container *ngTemplateOutlet="passwordTemplate || defaultPasswordTemplate; context: { $implicit: loginForm.controls.password.errors }"></ng-container>
          </div>
        </div>
      </label>
    </div>

    <button type="submit" class="btn btn-primary float-right" i18n><span class="fas fa-sign-in-alt"></span> Login</button>
  </form>
</cwi-loading>


<!-- Default templates -->
<ng-template #defaultPasswordTemplate let-errors>
  <ul *ngIf=errors>
    <li *ngIf="errors.required" i18n>This field is required</li>
    <li *ngIf="errors.minlength" i18n>Must be at least of {{errors.minlength.requiredLength}} characters </li>
    <li *ngIf="errors.maxlength" i18n>Must be less than {{errors.maxlength.requiredLength}} characters </li>
    <li *ngIf="errors.uppercase" i18n>Must contain an uppercase letter</li>
    <li *ngIf="errors.lowercase" i18n>Must contain a lowercase letter</li>
    <li *ngIf="errors.number" i18n>Must contain a number</li>
  </ul>
</ng-template>

<ng-template #defaultUsernameTemplate let-errors>
  <ul *ngIf=errors>
    <li *ngIf="errors.required" i18n>This field is required</li>
    <li *ngIf="errors.minlength" i18n>Must be at least of {{errors.minlength.requiredLength}} characters </li>
    <li *ngIf="errors.maxlength" i18n>Must be less than {{errors.maxlength.requiredLength}} characters </li>
    <li *ngIf="errors.specialChars" i18n>Only characters, numbers and some special characters (<em> \ - . _ </em>) are allowed </li>
  </ul>
</ng-template>

