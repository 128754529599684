import { AppConfigService } from './services/app-config.service';
import { latLng, tileLayer, MapOptions } from 'leaflet';

export function mapOptionsFactoryGenerator(appConfig: AppConfigService): MapOptions {
    const config = appConfig.getConfig().mapOptions;
    return {
      preferCanvas: true,
      layers: [ tileLayer(config.url, config.layerOptions) ],
      minZoom: config.minZoom,
      maxZoom: config.maxZoom,
      zoom: config.zoom,
      center: latLng(config.center.latitude, config.center.longitude)
    }
}
