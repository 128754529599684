<mat-card>
    <mat-card-content>
        <div class="row">
            <div class="col-2 my-auto">
                <div class="image" [ngClass]="{'gps-on': device.fixGps === true, 'gps-off': device.fixGps === false}">
                    <img *ngIf="device.type === 'R&D TSMx Scanner'" class="my-auto" src="assets/icons/diagnostic/scanner.svg">
                    <img *ngIf="device.type === 'Track Chaser EE' && device.fixGps" class="my-auto" src="assets/icons/diagnostic/trackchaser-on.svg">
                    <img *ngIf="device.type === 'Track Chaser EE' && !device.fixGps" class="my-auto" src="assets/icons/diagnostic/trackchaser-off.svg">
                    <img *ngIf="device.type === 'Visual Scripting'" class="my-auto" src="assets/icons/diagnostic/visualscripting.svg">
                </div>
            </div>
            <div class="col-6 my-auto">
                <div class="name-container">
                    <span class="name">{{ device.name }}</span>
                    <span class="subname">{{ getDeviceType(device) }}
                        <span *ngIf="device.type === 'Track Chaser EE' "
                            class="ml-3 text-small font-weight-bold"> Position {{ device.fixGps ? 'on' : 'off' }}
                        </span>
                    </span>
                </div>
            </div>
            <div class="col-4 my-auto">
                <cwi-remote-device-status-indicator [status]="device.status"></cwi-remote-device-status-indicator>
            </div>
        </div>
    </mat-card-content>
</mat-card>