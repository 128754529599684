import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TrainMapMarkerComponent } from './train-map-marker/train-map-marker.component';
import { TrainStatusComponent } from './train-status/train-status.component';
import { PlanItemComponent } from './plan-item/plan-item.component';
import { PlanCreatorComponent } from './plan-creator/plan-creator.component';
import { TimeSchedulationCreatorComponent } from './time-schedulation-creator/time-schedulation-creator.component';
import { SchedulationTimelineComponent } from './schedulation-timeline/schedulation-timeline.component';
import { PrioritySchedulationComponent } from './priority-schedulation/priority-schedulation.component';
import { ComponentsModule, DEFAULT_MAP_OPTIONS_FACTORY, MAP_OPTIONS } from '@cwi/components';
import { NgbPopoverModule, NgbDatepickerModule, NgbTimepickerModule,
  NgbPaginationModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { GeometryInfoComponent } from './geometry-info/geometry-info.component';
import { ProbeStatusComponent } from './probe-status/probe-status.component';
import { SystemStatusComponent } from './system-status/system-status.component';
import { SystemsListComponent } from './systems-list/systems-list.component';
import { PlansListComponent } from './plans-list/plans-list.component';
import { TrainsListComponent } from './trains-list/trains-list.component';
import { TimeSchedulationsComponent } from './time-schedulations/time-schedulations.component';
import { TrainControlButtonsComponent } from './train-control-buttons/train-control-buttons.component';
import { SystemMapMarkerComponent } from './system-map-marker/system-map-marker.component';
import { REMOTECONTROLLER_CONFIGURATION, REMOTECONTROLLER_DEFAULT, TRAIN_SERVICE, SRCU_COMMANDS_SERVICE, TOPOLOGY_CONFIGURATION, TOPOLOGY_CONFIGURATION_DEFAULT } from './service-configuration';
import { PlanServiceFactory } from './plan-service';
import { SystemServiceFactory } from './system-service';
import { TimeSchedulationServiceFactory } from './time-schedulation-service';
import { GeometryTriggerServiceFactory } from './geometry-trigger-service';
import { TrainService } from './train-service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { StandardModeComponent } from './standard-mode-creator/standard-mode-creator.component';
import { StandardModePanelComponent } from './standard-mode-panel/standard-mode-panel.component';
import { StandardModeServiceFactory } from './standard-mode-service';
import { StandardModeInfoComponent } from './standard-mode-info/standard-mode-info.component';
import { SpeedModeComponent } from './speed-mode-creator/speed-mode-creator.component';
import { SpeedModePanelComponent } from './speed-mode-panel/speed-mode-panel.component';
import { SpeedModeServiceFactory } from './speed-mode-service';
import { SpeedModeInfoComponent } from './speed-mode-info/speed-mode-info.component';
import { CustomBreadcrumbsComponent } from './custom-breadcrumbs/custom-breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { ManualCommandsComponent } from './manual-commands/manual-commands.component';
import { ProbesInfoComponent } from './probes-info/probes-info.component';
import { PlanModeTitleComponent } from './planmode-title/planmode-title.component';
import { ProbeMapMarkerComponent } from './probe-map-marker/probe-map-marker.component';
import { SRCUCommandsServiceFactory, SRCUCommandsServiceWrapper } from './srcu-commands-service';
import { TrackDatabaseUploadComponent } from './trackdatabase-upload/trackdatabase-upload.component';
import { UploadtTrackDatabaseService } from './upload-service';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { OptionsFileCfgServiceFactory } from './options-fileCfg-service';
import { ManageConfigurationsComponent } from './manage-configurations/manage-configurations.component';
import { GeneralSettingsServiceFactory } from './general-settings-service';
import { ManualCommandsServiceFactory } from './manual-commands-service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DiagnosticModule } from './diagnostic-module/diagnostic.module';

@NgModule({
  declarations: [
    PlanCreatorComponent,
    PlanItemComponent,
    PrioritySchedulationComponent,
    SchedulationTimelineComponent,
    TimeSchedulationCreatorComponent,
    TrainMapMarkerComponent,
    TrainStatusComponent,
    GeometryInfoComponent,
    ProbeStatusComponent,
    SystemStatusComponent,
    TrainsListComponent,
    SystemsListComponent,
    PlansListComponent,
    TimeSchedulationsComponent,
    TrainControlButtonsComponent,
    ManualCommandsComponent,
    SystemMapMarkerComponent,
    StandardModeComponent,
    StandardModePanelComponent,
    StandardModeInfoComponent,
    SpeedModeComponent,
    SpeedModePanelComponent,
    SpeedModeInfoComponent,
    CustomBreadcrumbsComponent,
    ProbesInfoComponent,
    PlanModeTitleComponent,
    ProbeMapMarkerComponent,
    TrackDatabaseUploadComponent,
    GeneralSettingsComponent,
    ManageConfigurationsComponent,
    DeleteConfirmationComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbPopoverModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbCollapseModule,
    DragDropModule,
    RouterModule,
    NgbPaginationModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    DiagnosticModule
  ],
  exports: [
    PlanCreatorComponent,
    PlanItemComponent,
    PrioritySchedulationComponent,
    SchedulationTimelineComponent,
    TimeSchedulationCreatorComponent,
    TrainMapMarkerComponent,
    TrainStatusComponent,
    GeometryInfoComponent,
    ProbeStatusComponent,
    SystemStatusComponent,
    TrainsListComponent,
    SystemsListComponent,
    PlansListComponent,
    TimeSchedulationsComponent,
    TrainControlButtonsComponent,
    ManualCommandsComponent,
    SystemMapMarkerComponent,
    StandardModeComponent,
    StandardModePanelComponent,
    StandardModeInfoComponent,
    SpeedModeComponent,
    SpeedModePanelComponent,
    SpeedModeInfoComponent,
    CustomBreadcrumbsComponent,
    RouterModule,
    ProbesInfoComponent,
    PlanModeTitleComponent,
    ProbeMapMarkerComponent,
    TrackDatabaseUploadComponent,
    GeneralSettingsComponent,
    ManageConfigurationsComponent,
    DeleteConfirmationComponent,
    DiagnosticModule,
  ],
  providers: [
    {
      provide: REMOTECONTROLLER_CONFIGURATION,
      useValue: REMOTECONTROLLER_DEFAULT
    },
    {
      provide: TOPOLOGY_CONFIGURATION,
      useValue: TOPOLOGY_CONFIGURATION_DEFAULT
    },
    {
      provide: SRCU_COMMANDS_SERVICE,
      useClass: SRCUCommandsServiceWrapper
    },
    {
      provide: MAP_OPTIONS,
      useFactory: DEFAULT_MAP_OPTIONS_FACTORY
    },
    PlanServiceFactory,
    SystemServiceFactory,
    OptionsFileCfgServiceFactory,
    TimeSchedulationServiceFactory,
    GeometryTriggerServiceFactory,
    {
      provide: TRAIN_SERVICE,
      useClass: TrainService
    },
    StandardModeServiceFactory,
    SpeedModeServiceFactory,
    ManualCommandsServiceFactory,
    UploadtTrackDatabaseService,
    SRCUCommandsServiceFactory
  ],
  entryComponents: [ PlanCreatorComponent,
    TimeSchedulationCreatorComponent, GeometryInfoComponent, PrioritySchedulationComponent,
    StandardModeComponent, SpeedModeComponent, TrackDatabaseUploadComponent, DeleteConfirmationComponent ]
})
export class RemoteControllerModule { }
