import { ValidatorFn, Validators } from '@angular/forms';
import { InjectionToken, Inject, Injectable } from '@angular/core';
import { LoginPageConfiguration } from '@cwi/pages';
export interface ILoginConfiguration {
  passwordValidators: ValidatorFn|ValidatorFn[];
  usernameValidators: ValidatorFn|ValidatorFn[];
  login: string;
  logout: string;
  userInfo: string;
}

export const LOGIN_PASSWORD_VALIDATORS = new InjectionToken<ValidatorFn|ValidatorFn[]>('LOGIN_PASSWORD_VALIDATORS');
export const LOGIN_USERNAME_VALIDATORS = new InjectionToken<ValidatorFn|ValidatorFn[]>('USERNAME_VALIDATORS');
export const LOGIN_URL = new InjectionToken<string>('LOGIN_URL');
export const LOGOUT_URL = new InjectionToken<string>('LOGOUT_URL');
export const USER_INFO_URL = new InjectionToken<string>('USER_INFO_URL');
export const LOGIN_CONFIGURATION = new InjectionToken<ILoginConfiguration>('LOGIN_CONFIGURATION');


export const DEFAULT_LOGIN_PASSWORD_VALIDATORS: ValidatorFn[] = [
  Validators.required
];

export const DEFAULT_LOGIN_USERNAME_VALIDATORS: ValidatorFn[] = [
  Validators.required
];

export const DEFAULT_LOGIN_ACTION = '/api/login?return=/api/user';
export const DEFAULT_LOGOUT_ACTION = '/api/logout';
export const DEFAULT_USER_INFO = '/api/user';

export const DEFAULT_LOGIN_PAGE_CONFIGURATION: LoginPageConfiguration = {
  logos: [
    {
      path: "assets/cwi_logo_small.png",
      alt: "Comtest Wireless International a VIAVI Solutions Company",
      height: 60
    }
  ]
}

@Injectable()
export class DefaultLoginConfiguration implements ILoginConfiguration {
  constructor(
    @Inject(LOGIN_PASSWORD_VALIDATORS)
    public readonly passwordValidators: ValidatorFn|ValidatorFn[],
    @Inject(LOGIN_USERNAME_VALIDATORS)
    public readonly usernameValidators: ValidatorFn|ValidatorFn[],
    @Inject(LOGIN_URL)
    public readonly login: string,
    @Inject(LOGOUT_URL)
    public readonly logout: string,
    @Inject(USER_INFO_URL)
    public readonly userInfo: string
  ) { }
}
