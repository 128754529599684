import { InjectionToken } from "@angular/core"

export interface NavLogo {
    path: string,
    alt: string
}

export interface INavbarConfiguration {
    logo: NavLogo
}

export const DEFAULT_NAVBAR_CONFIGURATION: INavbarConfiguration = {
    logo: {
        path: 'assets/cwi_logo_small.png',
        alt: 'Comtest Wireless International a VIAVI Solutions Company'
    }
}

export const NAVBAR_CONFIGURATION = new InjectionToken<INavbarConfiguration>("INavbarConfiguration")
