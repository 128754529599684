import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IUploadTrackDatabaseService, UPLOAD_TRACKDATABASE_SERVICE } from '../service-configuration';

@Component({
  selector: 'cwi-remote-trackdatabase-upload',
  templateUrl: './trackdatabase-upload.component.html',
  styleUrls: ['./trackdatabase-upload.component.scss']
})
export class TrackDatabaseUploadComponent {

  processing: boolean;
  totalSize: number;
  progress: number;
  error: string;
  
  @Output()
  public readonly completed = new EventEmitter<void>();

  @Output()
  public readonly closed = new EventEmitter<void>();
  
  public uploadForm = new FormGroup({
    /* Used only to wrap validation error*/
     line: new FormControl('', [Validators.required]),
     track: new FormControl('', [Validators.required]),
     trackDescription: new FormControl('', [Validators.required]),
     connection: new FormControl('', [Validators.required]),
     tunnel: new FormControl('', [Validators.required]),
     location: new FormControl('', [Validators.required]),
     locationIndex: new FormControl('', [Validators.required]),

     /* File used to create form-data*/
     lineFile: new FormControl('', [Validators.required]),
     trackFile: new FormControl('', [Validators.required]),
     trackDescriptionFile: new FormControl('', [Validators.required]),
     connectionFile: new FormControl('', [Validators.required]),
     tunnelFile: new FormControl('', [Validators.required]),
     locationFile: new FormControl('', [Validators.required]),
     locationIndexFile: new FormControl('', [Validators.required]),
   });
 
  constructor(private http: HttpClient,
    @Inject(UPLOAD_TRACKDATABASE_SERVICE)
    private readonly trackDbService: IUploadTrackDatabaseService) { }
 
  get uploadControls(){
    return this.uploadForm.controls;
  }
 
  onFileChange(name: string, event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.patchFile(name, file);
    }
  }

  patchFile(name: string, file: any) {
    if (name === 'line') {
      this.uploadForm.patchValue({
        lineFile: file
      })
    } else if (name === 'track') {
      this.uploadForm.patchValue({
        trackFile: file
      })
    } else if (name === 'trackDescription') {
      this.uploadForm.patchValue({
        trackDescriptionFile: file
      })
    } else if (name === 'connection') {
      this.uploadForm.patchValue({
        connectionFile: file
      })
    } else if (name === 'tunnel') {
      this.uploadForm.patchValue({
        tunnelFile: file
      })
    } else if (name === 'location') {
      this.uploadForm.patchValue({
        locationFile: file
      })
    } else if (name === 'locationIndex') {
      this.uploadForm.patchValue({
        locationIndexFile: file
      })
    }
  }

  submit() {

    const formData = new FormData();

    formData.append('line', this.uploadForm.get('lineFile').value);
    formData.append('track', this.uploadForm.get('trackFile').value);
    formData.append('trackDescription', this.uploadForm.get('trackDescriptionFile').value);
    formData.append('connection', this.uploadForm.get('connectionFile').value);
    formData.append('tunnel', this.uploadForm.get('tunnelFile').value);
    formData.append('location', this.uploadForm.get('locationFile').value);
    formData.append('locationIndex', this.uploadForm.get('locationIndexFile').value);

    this.processing = true;
    this.error = null;
    this.trackDbService.upload(formData).subscribe(
      (res: any) => {
        this.totalSize = res.total;
        this.progress = (res.loaded / res.total) * 100;
        if (res && res.status === 200) {
          this.completed.next();
          this.processing = false;
        }
      },
      (err: any) => {
        this.error = $localize `Error during upload of files.`;
        this.processing = false;
      }
    );
   }

  closeAlert() {
    this.error = null;
  }

  close() {
    this.closed.next();
  }
}