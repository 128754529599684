import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import moment, { Moment } from "moment";

@Component({
  selector: 'cwi-remote-refresh-error-alert',
  templateUrl: './refresh-error-alert.component.html',
  styleUrls: ['./refresh-error-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefreshErrorAlertComponent {

  @Input()
  public lastUpdateTime: Moment;
}
