import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Duration } from "moment";
import { Observable, Subject } from "rxjs";

@Component({
  selector: 'cwi-remote-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadComponent {

  test: Observable<number[]>;

  speedSubject$ = new Subject();
  
  @Input()
  public uploadTime: Duration;

  @Input()
  public uploadSpeed: number;

  @Input()
  public uploadSpeeds: number[];

  @Input()
  public tag: string;

  get anchor() {
    return `${window.location.pathname}#${this.tag}`;
  }
}
