import { Component, Inject } from '@angular/core';
import { OPTIONS_FILECFG_SERVICE, IRemoteControllerConfiguration,
  REMOTECONTROLLER_CONFIGURATION, OptionsFileCfgServiceWrapper,
  TRAIN_SERVICE, ITrainService, GENERAL_SETTINGS_SERVICE, GeneralSettingsServiceWrapper } from '@cwi/remote-controller';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';

@Component({
  selector: 'cwi-pg-trainoptionsview',
  templateUrl: './remote-controller-trainoptionsview.component.html',
  styleUrls: ['./remote-controller-trainoptionsview.component.scss'],
  providers: [
    {
      provide: GENERAL_SETTINGS_SERVICE,
      useClass: GeneralSettingsServiceWrapper
    },
    {
      provide: OPTIONS_FILECFG_SERVICE,
      useClass: OptionsFileCfgServiceWrapper
    },
  ]
})
export class RemoteControllerTrainOptionsComponent {

  private readonly subscription = new Subscription();
  train: string;

  constructor(
    @Inject(REMOTECONTROLLER_CONFIGURATION)
    configuration: IRemoteControllerConfiguration,
    @Inject(TRAIN_SERVICE)
    private readonly trainService: ITrainService,
    activatedRoute: ActivatedRoute,
    private router: Router) {

      this.subscription.add(activatedRoute.params.pipe(
        switchMap(params => {
          this.train = params.trainName;
          return 'trainName' in params
            ? this.trainService.getTrain(params.trainName)
            : of(null);
          }
        )
      ).subscribe());
    }

    goBack() {
      this.router.navigate(['/remote-controller']);
    }
}
