import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { Component } from '@angular/core';
import { DeleteConfirmationComponent } from '@cwi/components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { GroupInfo } from '../../models/group-definition';;
import { GROUP_SERVICE, IGroupService, IRoleService, ROLE_SERVICE } from '../../services/service-configuration';
import { GroupCreationComponent, GroupCreationInfo } from '../group-creation/group-creation.component';

@Component({
    selector: 'cwi-groups-list',
    templateUrl: './groups-list.component.html',
    styleUrls: ['./groups-list.component.scss'],
    animations: [
      trigger('detailExpand', [
        state('collapsed', style({height: '0px', minHeight: '0'})),
        state('expanded', style({height: '*'})),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
    ],
  })
  export class GroupListComponent implements  OnDestroy {

    private subscription = new Subscription();
    columnsToDisplay: string[] = ['group', 'roles', 'actions'];
    roles: string[];
    error: string;
    groups$: Observable<GroupInfo[]>;

    @Output()
    public groupChanged = new EventEmitter<void>();

    constructor(
      @Inject(GROUP_SERVICE)
      private readonly groupService: IGroupService,
      @Inject(ROLE_SERVICE)
      private readonly roleService: IRoleService,
      private modalService: NgbModal
    ) {
      this.groups$ = groupService.groups$;
      const roles$ = this.roleService.roles$;
      this.subscription.add(roles$.subscribe(res => this.roles = res));
    }

    openGroupCreationModal() {
      const modalRef = this.modalService.open(GroupCreationComponent, {backdrop: 'static', keyboard: false});
      const componentInstance: GroupCreationComponent = modalRef.componentInstance;

      this.subscription.add(componentInstance.created.subscribe((result: GroupCreationInfo) => {
        this.groupService.addGroup(result.groupName).subscribe(
          (response) => {
            modalRef.dismiss();
            this.groupChanged.next();
            this.groupService.reload();
          },
          (error: HttpErrorResponse) => {
            if (error.status === 409) {
              componentInstance.error = $localize `Group already exists.`;
            }
          });
      }));
      this.subscription.add(componentInstance.closed.subscribe(() => {
        modalRef.dismiss();
      }));
    }

    async deleteGroup(name: string) {
      const modalRef = this.modalService.open(DeleteConfirmationComponent, {backdrop: 'static', keyboard: false});
      const componentInstance: DeleteConfirmationComponent = modalRef.componentInstance;
      const deleteModalSettings = {
        title: $localize `Confirm delete`,
        text: $localize `Are you sure you want to delete ${name}?`
      };
      componentInstance.deleteModalSettings = deleteModalSettings;

      this.subscription.add(componentInstance.confirmed.subscribe(() => {
        this.groupService.deleteGroup(name).toPromise();
        this.groupChanged.next();
        this.groupService.reload();
        modalRef.dismiss();
        }));

      this.subscription.add(componentInstance.closed.subscribe(() => {
        modalRef.dismiss();
      }));
    }

    roleAddedToGroup(groupInfo: GroupInfo, role: string) {
      this.groupService.addRoleToGroup(groupInfo.groupName, role).subscribe(
        (response) => {
          groupInfo.roles.push(role);
        },
        (error: HttpErrorResponse) => {
          if (error.status === 404) {
            this.error = $localize `Role does not exist.`;
          }
        }
      );
    }

    roleDeletedFromGroup(groupInfo: GroupInfo, role: string) {
      this.groupService.deleteRoleFromGroup(groupInfo.groupName, role).subscribe(
        (response) => {
          const roleIndex = groupInfo.roles.findIndex(item => item === role);
          groupInfo.roles.splice(roleIndex, 1);
        },
        (error: HttpErrorResponse) => {
          // TODO Handle
        }
      );
    }

    closeAlert() {
      this.error = null;
    }

    ngOnDestroy(): void {
      this.subscription.unsubscribe();
    }
}
