import { UrlMatcher, UrlSegment, UrlMatchResult, UrlSegmentGroup, Route } from '@angular/router';

const patternMatch = /(:?)([\w_-]+)(\??)\/?/g;

enum MatchType {
  Verbatim,
  Optional,
  Mandatory
}

interface MatchDefinition {
  type: MatchType;
  value: string;
}

export function optionalMatcher(pattern: string): UrlMatcher {
  const matches: MatchDefinition[] = []; // Gli accendini insomma
  for (let part = patternMatch.exec(pattern); part != null; part = patternMatch.exec(pattern)) {
    if (part[1]) {
      if (part[3]) {
        matches.push({
          type: MatchType.Optional,
          value: part[2]
        });
      } else {
        matches.push({
          type: MatchType.Mandatory,
          value: part[2]
        });
      }
    } else {
      matches.push({
        type: MatchType.Verbatim,
        value: part[2]
      });
    }
  }

  return (segments: UrlSegment[], group: UrlSegmentGroup, route: Route) => {
    const posParams: { [name: string]: UrlSegment; } = {};

    for (let i = 0; i < matches.length; i++) {
      const matchDefinition = matches[i];
      const segment = segments[i];

      switch (matchDefinition.type) {
        case MatchType.Verbatim:
          if (segment?.path !== matchDefinition.value) {
            return null;
          }
          break;
        case MatchType.Mandatory:
          if (segment) {
            posParams[matchDefinition.value] = segment;
          } else {
            return null;
          }
          break;
        case MatchType.Optional:
          if (segment) {
            posParams[matchDefinition.value] = segment;
          } else {
            return {
              consumed: segments,
              posParams
            };
          }
          break;
      }
    }

    if (route.pathMatch === 'full') {
      return {
        consumed: segments.slice(0, matches.length),
        posParams
      };
    } else {
      return {
        consumed: segments,
        posParams
      };
    }
  };
}
