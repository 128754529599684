<div class="wrapper mt-1">
    <i *ngIf="probeStatus.isMaster" class="fas fa-crown text-secondary" title="Master"></i>
    <label class="ml-1" i18n>Wagon</label>
    <label class="ml-1">{{ probeStatus.wagon }}</label>
    <label class="ml-1" i18n>is in</label>
    <label class="ml-1"> {{ probeStatus.status }}</label>
    <i [ngClass]="probeStatus.status" style="text-shadow:2px 2px 4px #585858;" class="fas fa-circle ml-2" [title]="probeStatus.status"></i>
</div>
<div class="wrapper">
</div>
