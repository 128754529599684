import { Directive, TemplateRef, Input } from '@angular/core';
import { ComboChartComponent } from './combo-chart.component';
import { ComboChartSeries } from './combo-chart-model';

export interface ComboChartSeriesDirectiveContext<TTag extends string, T> {
  $implicit: ComboChartSeries<TTag, T>;
  chart: ComboChartComponent;
  mainColor: string;
}

@Directive({
  selector: '[cwiComboChartSeries]'
})
export class ComboChartSeriesDirective<TTag extends string, T> {

  @Input()
  public cwiComboChartSeries: TTag;

  constructor(
    public readonly template: TemplateRef<ComboChartSeriesDirectiveContext<TTag, T>>
  ) { }
}
