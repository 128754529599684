import { EventEmitter, Input } from '@angular/core';
import { Component, Output } from '@angular/core';

@Component({
  selector: 'cwi-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent {

  @Input()
  public message: string;

  @Output()
  public readonly confirmed: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public readonly canceled: EventEmitter<void> = new EventEmitter<void>();

  close() {
    this.canceled.next();
  }

  confirm() {
    this.confirmed.next();
  }
}
