<div class="card mb-1" button-cwi>
    <div class="card-body card-cwi">
        <button class="button-text btn btn-sm btn-outline-primary" (click)="$event.stopPropagation(); trainClick()">
            {{ trainStatus.name }}
        </button>
        <div class="test mt-2">
            <div class="button-icon">
                <button type="button" class="btn btn-sm text-secondary"
                    i18n-title
                    title="Follow train"
                    (click)="followClick()">
                    <span class="fas fa-crosshairs"></span>&nbsp;
                </button>
            </div>
            <div class="button-icon ml-1">
                <a class="btn btn-sm text-secondary"
                    i18n-title
                    title="Diagnostic"
                    [routerLink]="['diagnostic', trainStatus.name]">
                    <span class="fas fa-medkit"></span>
                </a>
            </div>
            <div class="button-icon ml-1">
                <a class="btn btn-sm text-secondary"
                    i18n-title
                    title="Options"
                    [routerLink]="['options', trainStatus.name]">
                    <span class="fas fa-cog"></span>
                </a>
            </div>
        </div>
    </div>
</div>
