<mat-expansion-panel #panel class="arrow" togglePosition="before" [expanded]="expanded">
  <mat-expansion-panel-header class="mb-1" >
    <mat-panel-title>
      <div class="system-name">
        <span>{{ name }}</span>
        <span class="sub" i18n>{{ systems.length }} systems present</span>
      </div>
    </mat-panel-title>
    <mat-panel-description [ngClass]="getMainToggleStatus()">
      <mat-slide-toggle
        [checked]="getMainToggleActive()"
        (click)="toggleMain($event)"
        cwiClickSink>
      </mat-slide-toggle>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="row mb-2" *ngFor="let system of systems; let i = index; trackBy: powerTrackBy">
      <div class="my-auto col">
        <div class="system-name">
          <span>{{ system.name }}</span>
          <span class="sub">{{ getSystemRole(system.isMaster) }}</span>
        </div>
      </div>
      <div class="my-auto col-auto" [ngClass]="getSubToggleStatus(system.powerStatus)">
        <mat-slide-toggle
          [checked]="getSubToggleActive(system.powerStatus)"
          (click)="toggleSub($event, system)"
          cwiClickSink>
        </mat-slide-toggle>
      </div>
      <mat-divider *ngIf="i !== systems.length - 1" class="mt-2 p-1 w-100 mat-divider-margin" [inset]="true"></mat-divider>
    </div>
</mat-expansion-panel>
<mat-divider [inset]="true"></mat-divider>
