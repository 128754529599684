import { Directive, Input, Output, InjectionToken, OnDestroy, Inject,
  KeyValueDiffer, KeyValueDiffers, NgZone, EventEmitter } from '@angular/core';
import { GeometryDefinition, RectangleDefinition, GeometryType, CircleDefinition, PolygonDefinition} from './geometry-definition';
import * as L from 'leaflet';
import { LeafletDirective } from '@asymmetrik/ngx-leaflet';
import { Subscription, Observable } from 'rxjs';
import { startWith, filter, distinctUntilChanged } from 'rxjs/operators';
import { Feature } from 'geojson';
import { buffer } from '@turf/turf';

export const GEOMETRY_DRAWER_FACTORY = new InjectionToken<GeometryDrawerFactory>('GeometryDrawer');

export interface GeometryDrawerFactory<T extends GeometryDefinition = GeometryDefinition> {
  type: T['shape'];
  create(onclick, definition: T): GeometryDrawer<T>;
}

export type DrawerConstructor<TGeometry extends GeometryDefinition> =
  new (onclick: () => void, definition: TGeometry) => GeometryDrawer<TGeometry>;

export function createFactory<T extends GeometryDefinition>(implementation: DrawerConstructor<T>, tag: T['shape']) {
  return class Factory implements GeometryDrawerFactory<T> {
    public readonly type = tag;

    create(onclick: () => void, definition: T) {
      return new implementation(onclick, definition);
    }
  };
}

export interface GeometryDrawer<T extends GeometryDefinition = GeometryDefinition> {
  addTo(leafletMap: L.Map): this;
  update(definition: T);
  remove();
}

export class BufferedDrawer<T extends GeometryDefinition> implements GeometryDrawer<T> {
  public readonly control: L.GeoJSON;

  constructor(
    private readonly onclick: () => void,
    private readonly convert: (definition: T) => Feature,
    definition: T
  ) {
    this.control = L.geoJSON(this.getBuffered(definition)/* , { bubblingMouseEvents: false } */).on('click', () => this.onclick());
    setStyle(this.control, !definition.notValid, true);
  }

  private getBuffered(definition: T): Feature {
    const off = definition['offset'] ?? 1000;
    return buffer(this.convert(definition) as any, off, {units: 'meters'}) as Feature;
  }

  addTo(leafletMap: L.Map) {
    this.control.addTo(leafletMap);
    this.control.bringToBack();
    return this;
  }

  update(definition: T) {
    this.control.clearLayers();
    this.control.addData(this.getBuffered(definition));
    setStyle(this.control, !definition.notValid, true);
    this.control.bringToBack();
  }

  remove() {
    this.control.remove();
  }
}

export class BufferedCircleDrawer implements GeometryDrawer<CircleDefinition> {
  public readonly control: L.Circle;

  constructor(
    private readonly onclick: () => void,
    definition: CircleDefinition
  ) {
    this.control = L.circle(definition.center, definition.ray + (definition['offset'] ?? 1000)/* , { bubblingMouseEvents: false } */)
      .on('click', () => this.onclick());
    setStyle(this.control, !definition.notValid, true);
  }

  addTo(leafletMap: L.Map) {
    this.control.addTo(leafletMap);
    this.control.bringToBack();
    return this;
  }

  update(definition: CircleDefinition) {
    this.control.setLatLng(definition.center);
    this.control.setRadius(definition.ray + (definition['offset'] ?? 1000));
    setStyle(this.control, !definition.notValid, true);
    this.control.bringToBack();
  }

  remove() {
    this.control.remove();
  }
}

export function createBufferedFactory<T extends GeometryDefinition>(convert: (definition: T) => Feature, tag: T['shape']) {
  return class Factory implements GeometryDrawerFactory<T> {
    public readonly type = tag;

    create(onclick: () => void, definition: T) {
      return new BufferedDrawer<T>(onclick, convert, definition);
    }
  };
}

function setStyle(path: L.Path|L.GeoJSON, isValid: boolean, isBuffered: boolean = false) {
  if (isValid) {
    path.setStyle({
      color: isBuffered ? '#00f' : '#0f0',
      fillColor: isBuffered ? '#0000ff88' : '#00ff0088'
    });
  } else {
    path.setStyle({
      color: '#f00',
      fillColor: '#ff000088'
    });
  }
}

export class SimpleRectangleDrawer implements GeometryDrawer<RectangleDefinition> {
  public readonly control: L.Rectangle;

  constructor(
    private readonly onclick: () => void,
    definition: RectangleDefinition
  ) {
    this.control = L.rectangle(definition.boundaries).on('click', (p: L.LeafletMouseEvent) => this.onclick());
    setStyle(this.control, !definition.notValid);
  }

  addTo(leafletMap: L.Map) {
    this.control.addTo(leafletMap);
    this.control.bringToFront();
    return this;
  }

  update(definition: RectangleDefinition) {
    this.control.setBounds(definition.boundaries);
    setStyle(this.control, !definition.notValid);
    this.control.bringToFront();
  }

  remove() {
    this.control.remove();
  }
}

export class SimpleCircleDrawer implements GeometryDrawer<CircleDefinition> {
  public readonly control: L.Circle;

  constructor(
    private readonly onclick: () => void,
    definition: CircleDefinition
  ) {
    this.control = L.circle(definition.center, definition.ray/* , { bubblingMouseEvents: false } */).on('click', () => this.onclick());
    setStyle(this.control, !definition.notValid);
  }

  addTo(leafletMap: L.Map) {
    this.control.addTo(leafletMap);
    this.control.bringToFront();
    return this;
  }

  update(definition: CircleDefinition) {
    this.control.setLatLng(definition.center);
    this.control.setRadius(definition.ray);
    setStyle(this.control, !definition.notValid);
    this.control.bringToFront();
  }

  remove() {
    this.control.remove();
  }
}

export class SimplePolygonDrawer implements GeometryDrawer<PolygonDefinition> {
  public readonly control: L.Polygon;

  constructor(
    private readonly onclick: () => void,
    definition: PolygonDefinition
  ) {
    this.control = L.polygon(definition.points).on('click', () => this.onclick());
    setStyle(this.control, !definition.notValid);
  }

  addTo(leafletMap: L.Map) {
    this.control.addTo(leafletMap);
    this.control.bringToFront();
    return this;
  }

  update(definition: PolygonDefinition) {
    this.control.setLatLngs(definition.points);
    setStyle(this.control, !definition.notValid);
    this.control.bringToFront();
  }

  remove() {
    this.control.remove();
  }
}


export const RectangleDrawerFactory = createFactory(SimpleRectangleDrawer, 'rectangle');
export const CircleDrawerFactory = createFactory(SimpleCircleDrawer, 'circle');
export const PolygonDrawerFactory = createFactory(SimplePolygonDrawer, 'polygon');
export function getBufferedRectangle(rect: RectangleDefinition) {
  return L.rectangle(rect.boundaries).toGeoJSON();
}
export const BufferedRectangleDrawerFactory = createBufferedFactory<RectangleDefinition>(
  getBufferedRectangle,
  'rectangle'
);
export const BufferedCircleDrawerFactory = createFactory<CircleDefinition>(BufferedCircleDrawer, 'circle');

export class BufferedPolygonDrawerFactory extends createBufferedFactory<PolygonDefinition>(
  poly => poly.points.length > 2 ? L.polygon(poly.points).toGeoJSON() : L.polyline(poly.points).toGeoJSON(),
  'polygon'
) { }

export class DrawingContext {
  private drawers: GeometryDrawer[];

  constructor(
    private readonly leafletMap: L.Map,
    private readonly drawerFactories: Map<string, GeometryDrawerFactory[]>,
    private readonly onclick: (definition: GeometryDefinition) => void,
    private definition: GeometryDefinition
  ) {
    this.reset(definition);
  }

  private removeAll() {
    if (this.drawers) {
      for (const drawer of this.drawers) {
        drawer.remove();
      }
    }
  }

  private reset(value: GeometryDefinition) {
    this.definition = value;
    this.removeAll();
    if (this.leafletMap) {
      if (this.drawerFactories.has(value.shape)) {
        this.drawers = this.drawerFactories.get(value.shape).map(factory =>
          factory.create(() => this.onclick(this.definition), value).addTo(this.leafletMap)
        );
      } else {
        this.drawers = null;
      }
    }
  }

  update(previousValue: GeometryDefinition, currentValue: GeometryDefinition) {
    this.definition = currentValue;
    if (previousValue.shape !== currentValue.shape) {
      this.reset(currentValue);
    } else if (this.drawers) {
      for (const drawer of this.drawers) {
        drawer.update(currentValue);
      }
    }
  }

  destroy() {
    this.removeAll();
  }
}



@Directive({
  selector: '[cwiGeometryViewer]'
})
export class GeometryViewerDirective implements OnDestroy {

  @Input('cwiGeometryViewer')
  public set geometries(value: Observable<GeometryDefinition[]>) {
    this.updatesSubscription.unsubscribe();
    if (value) {
      this.updatesSubscription = this.subscription.add(value.subscribe(update => {
        this.update(update);
      }, () => { }));
    } else {
      this.update([]);
    }
  }

  @Output()
  public readonly geometrySelected = new EventEmitter<GeometryDefinition>();

  private readonly subscription: Subscription;
  private updatesSubscription = new Subscription();
  private readonly differ: KeyValueDiffer<string|number, any>;
  private readonly drawers = new Map<GeometryType, GeometryDrawerFactory[]>();
  private drawingContexts: DrawingContext[] = [];
  private leafletMap: L.Map;

  constructor(
    private readonly zone: NgZone,
    keyvalueDiffers: KeyValueDiffers,
    leaflet: LeafletDirective,
    @Inject(GEOMETRY_DRAWER_FACTORY)
    drawers: GeometryDrawerFactory[]
  ) {
    this.differ = keyvalueDiffers
      .find([])
      .create();

    for (const drawer of drawers) {
      let drawerList: GeometryDrawerFactory[];
      if (this.drawers.has(drawer.type)) {
        drawerList = this.drawers.get(drawer.type);
      } else {
        drawerList = [];
        this.drawers.set(drawer.type, drawerList);
      }
      drawerList.push(drawer);
    }

    this.subscription = leaflet.mapReady.pipe(
      startWith(leaflet.map),
      filter(m => !!m),
      distinctUntilChanged()
    ).subscribe(map => this.onMapReady(map));
  }

  private clearAll() {
    for (const context of this.drawingContexts) {
      context.destroy();
    }
    this.drawingContexts = [];
  }

  private addGeomety(geometry: GeometryDefinition) {
    this.drawingContexts.push(new DrawingContext(
      this.leafletMap,
      this.drawers,
      (definition) => this.geometrySelected.next(definition),
      geometry
    ));
  }

  private onMapReady(leafletMap: L.Map) {
    this.leafletMap = leafletMap;
    this.clearAll();
  }

  update(geometries: GeometryDefinition[]): void {
    this.zone.runOutsideAngular(() => {
      const diff = this.differ.diff(geometries);
      if (diff) {
        diff.forEachAddedItem(record => this.addGeomety(record.currentValue));

        diff.forEachChangedItem(record => {
          if (this.drawingContexts[+record.key]) {
            this.drawingContexts[+record.key].update(
              record.previousValue,
              record.currentValue
            );
          }
        });

        let resize = this.drawingContexts.length;

        diff.forEachRemovedItem(record => {
          if (this.drawingContexts[+record.key].destroy) {
            this.drawingContexts[+record.key].destroy();
            resize = Math.min(resize, +record.key);
          }
        });

        if (resize !== this.drawingContexts.length) {
          this.drawingContexts.splice(resize);
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.updatesSubscription.unsubscribe();
    if (this.drawingContexts) {
      for (const context of this.drawingContexts) {
        context.destroy();
      }
    }
  }
}
