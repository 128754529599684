import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule, MaterialModule } from '@cwi/components';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AcquisitionsComponent } from './acquisitions/acquisitions.component';
import { DownloadChartComponent } from './download-chart/download-chart.component';
import { DownloadComponent } from './download/download.component';
import { ResponderMobileStatusCardComponent } from './responder-mobile-status-card/responder-mobile-status-card.component';
import { ResponderIsdnStatusCardComponent } from './responder-isdn-status-card/responder-isdn-status-card.component';
import { ResponderPsStatusCardComponent } from './responder-ps-status-card/responder-ps-status-card.component';
import { StorageListComponent } from './storage-list/storage-list.component';
import { SubSystemStatusIndicatorComponent } from './subsystem-status-indicator/subsystem-status-indicator.component';
import { SubSystemStatusListComponent } from './subsystem-status-list/subsystem-status-list.component';
import { DownloadTimeComponent } from './download-time/download-time.component';
import { StorageComponent } from './storage/storage.component';
import { ResponderStatusIndicatorComponent } from './responder-status-indicator/responder-status-indicator.component';
import { AcquisitionStatusIndicatorComponent } from './acquisition-status-indicator/acquisition-status-indicator.component';
import { DeviceStatusIndicatorComponent } from './device-status-indicator/device-status-indicator.component';
import { SubSystemStatusComponent } from './subsystem-status/subsystem-status.component';
import { DeviceLinkComponent } from './device-link/device-link.component';
import { MobileStatusComponent } from './mobile-status/mobile-status.component';
import { GeneralDeviceStatusComponent } from './general-device-status/general-device-status.component';
import { SystemTabsComponent } from './system-tabs/system-tabs.component';
import { SystemTabContentComponent } from './system-tab-content/system-tab-content.component';
import { CarouselComponent } from './carousel/carousel.component';
import { RouterModule } from '@angular/router';
import { TitleComponent } from './title/title.component';
import { PowerListComponent } from './power-list/power-list.component';
import { PowerComponent } from './power/power.component';
import { PowerRestartModelComponent } from './power-restart-modal/power-restart-modal.component';
import { RefreshErrorAlertComponent } from './refresh-error-alert/refresh-error-alert.component';

@NgModule({
  declarations: [
    AcquisitionsComponent,
    AcquisitionStatusIndicatorComponent,
    CarouselComponent,
    DeviceLinkComponent,
    DeviceStatusIndicatorComponent,
    DownloadComponent,
    DownloadChartComponent,
    DownloadTimeComponent,
    GeneralDeviceStatusComponent,
    MobileStatusComponent,
    PowerComponent,
    PowerListComponent,
    StorageComponent,
    StorageListComponent,
    SubSystemStatusComponent,
    SubSystemStatusIndicatorComponent,
    SubSystemStatusListComponent,
    SystemTabsComponent,
    SystemTabContentComponent,
    RefreshErrorAlertComponent,
    ResponderStatusIndicatorComponent,
    ResponderMobileStatusCardComponent,
    ResponderIsdnStatusCardComponent,
    ResponderPsStatusCardComponent,
    TitleComponent,
    PowerRestartModelComponent
  ],
  imports: [ 
    NgApexchartsModule,
    CommonModule,
    ComponentsModule,
    MaterialModule,
  ],
  exports: [
    AcquisitionsComponent,
    AcquisitionStatusIndicatorComponent,
    CarouselComponent,
    DeviceLinkComponent,
    DeviceStatusIndicatorComponent,
    DownloadComponent,
    DownloadChartComponent,
    DownloadTimeComponent,
    GeneralDeviceStatusComponent,
    MobileStatusComponent,
    PowerComponent,
    PowerListComponent,
    StorageComponent,
    StorageListComponent,
    SubSystemStatusComponent,
    SubSystemStatusIndicatorComponent,
    SubSystemStatusListComponent,
    SystemTabsComponent,
    SystemTabContentComponent,
    RefreshErrorAlertComponent,
    ResponderStatusIndicatorComponent,
    ResponderMobileStatusCardComponent,
    ResponderIsdnStatusCardComponent,
    ResponderPsStatusCardComponent,
    TitleComponent,
    PowerRestartModelComponent
  ],
  providers: [],
  entryComponents: [
    PowerRestartModelComponent
  ]
})
export class DiagnosticModule { }
