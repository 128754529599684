import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { SubSystemFullStatus } from "../subsystem-status/subsystem-status.component";

@Component({
  selector: 'cwi-remote-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent {

  currentPage: number = 0;
  totalPages: number;
  arrayInternal: SubSystemFullStatus[] = [];

  @Input()
  public capacity: number = 2;

  @Input()
  public set array(value: SubSystemFullStatus[]) {
    this.arrayInternal = value;
    if (value) {
      this.totalPages = Math.round(value.length / this.capacity);
      this.outputArray.next([...value.slice(this.startIndex, this.endIndex)]);
    }
  }

  public get array() {
    return this.arrayInternal;
  }

  @Output()
  public readonly outputArray = new EventEmitter<SubSystemFullStatus[]>();

  get nextDisabled() {
    if (this.totalPages === 1) {
      return true;
    } else {
      return this.currentPage > 0
      ? (this.array.length / (this.currentPage * this.capacity)) < this.capacity
      : false;
    }
  }

  get previousDisabled() {
    return this.currentPage === 0;
  }

  get startIndex() {
    return this.currentPage * this.capacity;
  }

  get endIndex() {
    return this.currentPage === 0
      ? this.capacity
      : this.startIndex + this.capacity
  }

  previousPage() {
    this.currentPage--;
    this.outputArray.next([...this.array.slice(this.startIndex, this.endIndex)]);
  }

  nextPage() {
    this.currentPage++;
    this.outputArray.next([...this.array.slice(this.startIndex, this.endIndex)]);
  }
}
