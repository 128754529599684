import { ChangeDetectionStrategy, Component, Input, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Duration, Moment } from "moment";
import { AcquisitionDiagnostic } from "../diagnostic";

function compare(a: number | Moment, b: number | Moment, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

function compareString(a: string, b: string, isAsc: boolean) {
  return a.localeCompare(b, undefined, { numeric: true} ) * (isAsc ? 1 : -1);
}

export interface AcquisitionInfo extends AcquisitionDiagnostic {
  isMaster: boolean;
}

@Component({
  selector: 'cwi-remote-acquisitions',
  templateUrl: './acquisitions.component.html',
  styleUrls: ['./acquisitions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcquisitionsComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['system', 'name', 'date', 'status'];  
  data: AcquisitionInfo[];
  dataSource: MatTableDataSource<AcquisitionInfo> = new MatTableDataSource();
  _acquisitions: AcquisitionInfo[];
  lastSort: Sort;

  @Input()
  public uploadTime: Duration;
  
  @Input()
  public set acquisitions(value: AcquisitionInfo[]) {
    this._acquisitions = value;
    if (value) {
      this.dataSource.data = [...value];
      this.dataSource.paginator = this.paginator;
      if (this.lastSort) {
        this.sortData(this.lastSort);
      }
    }
  };

  public get acquisitions() {
    return this._acquisitions;
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  sortData(sort: Sort) {
    this.lastSort = sort;
    const data = [...this.acquisitions];
    if (!sort.active || sort.direction === '') {
      this.data = data;
      return;
    }

    const sorted = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'system':
          return compareString(a.system, b.system, isAsc);
        case 'name':
          return compareString(a.name, b.name, isAsc);
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'status':
          return compareString(a.status.toString(), b.status.toString(), isAsc);
        default:
          return 0;
      }
    });

    this.dataSource.data = sorted;
  }

  getSystemRole(isMaster: boolean) {
    return isMaster ? $localize`Main` : $localize`Mirror`
  }

  trackAcquisition(index: number, item: AcquisitionInfo): string {
    return `${item.system}/${item.name}`;
  }
}
