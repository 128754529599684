import { Observable, Subscription } from 'rxjs';

export function append<T>(project: (value: T) => Observable<T>) {
  return (source: Observable<T>): Observable<T> => {
    return new Observable(subscriber => {
      let lastValue;

      const subscription = new Subscription();
      subscription.add(source.subscribe({
        next(value) { lastValue = value; subscriber.next(value); },
        error(error) { subscriber.error(error); },
        complete() {
          subscription.add(project(lastValue).subscribe(subscriber));
        }
      }));

      return subscription;
    });
  };
}
