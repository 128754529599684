<ng-container *ngIf="languages.length > 1">
<button mat-button [matMenuTriggerFor]="language">
    <span class="flag-icon mr-1" [ngClass]="activeLanguage.icon"></span>
    <span class="flag-text"> {{ activeLanguage.name }}</span>
</button>
<mat-menu #language="matMenu">
    <ng-container *ngTemplateOutlet="languageSelectorTemplate || defaultLanguageSelectorTemplate"></ng-container>
</mat-menu>


<ng-template #defaultLanguageSelectorTemplate>
    <ng-container *ngFor="let language of languages">
        <a *ngIf="language.locale !== locale" [href]="'/' + language.locale + '/'" mat-menu-item >
            <span class="flag-icon mr-1" [ngClass]="language.icon"></span>
            {{ language.name }}
        </a>
    </ng-container>
</ng-template>
</ng-container>
