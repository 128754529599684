<div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="error">
  {{ error }}
  <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeAlert()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-toolbar>
  <span>Clients list</span>
  <span class="toolbar-spacer"></span>
  <button mat-mini-fab color="primary" aria-label="Refresh" (click)="refreshClients()"><mat-icon>refresh</mat-icon></button>
</mat-toolbar>
<div class="main-container mt-1">
  <div class="mat-elevation-z1 table-container">
      <table mat-table *ngIf="clients$ | async;" [dataSource]="clients$">
        <ng-container matColumnDef="trainSystemWagon">
          <th mat-header-cell *matHeaderCellDef i18n>Location</th>
          <td mat-cell *matCellDef="let element">{{ element.trainName }} {{ element.systemName }} {{ element.wagonName }}</td>
        </ng-container>
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef i18n>Id</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef i18n>Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
        <ng-container matColumnDef="tag">
          <th mat-header-cell *matHeaderCellDef i18n>Tag</th>
          <td mat-cell *matCellDef="let element">{{ element.tag }}</td>
        </ng-container>
        <ng-container matColumnDef="installedVersion">
          <th mat-header-cell *matHeaderCellDef i18n><span class="fas fa-cube"></span> Installed</th>
          <td mat-cell *matCellDef="let element">{{ element.installedPackageVersion }}</td>
        </ng-container>
        <ng-container matColumnDef="assignedVersion">
          <th mat-header-cell *matHeaderCellDef i18n><span class="fas fa-cube"></span> Assigned</th>
          <td mat-cell *matCellDef="let element">{{ element.assignedPackageVersion }}</td>
        </ng-container>
        <ng-container matColumnDef="apps">
          <th mat-header-cell *matHeaderCellDef i18n>Applications</th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list [disabled]="disabled">
              <mat-chip *ngFor="let app of element.apps">{{ app.name }}/{{ app.runningVersion }}</mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef i18n>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button mat-raised-button (click)="openAssignPackageModal(element)" i18n>
              Set <span class="fas fa-cube"></span>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="clientColumnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: clientColumnsToDisplay;">
        </tr>
    </table>
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>
<mat-toolbar>
  <span>Update packages list</span>
  <span class="toolbar-spacer"></span>
  <button mat-raised-button (click)="openUploadPackageModal()">Upload packages</button>
  <button mat-mini-fab color="primary" aria-label="Refresh" (click)="refreshPackages()"><mat-icon>refresh</mat-icon></button>
</mat-toolbar>
<div class="main-container mt-1">
  <div class="mat-elevation-z1 table-container">
      <table mat-table *ngIf="packages$ | async;" [dataSource]="packages$">
        <ng-container matColumnDef="tagVersion">
          <th mat-header-cell *matHeaderCellDef i18n>Package</th>
          <td mat-cell *matCellDef="let element">{{ element.tag }}/{{ element.version }}</td>
        </ng-container>
        <ng-container matColumnDef="notes">
          <th mat-header-cell *matHeaderCellDef i18n>Notes</th>
          <td mat-cell *matCellDef="let element">{{ element.notes }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef i18n>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button mat-raised-button (click)="deletePackage(element)" i18n>
              <span class="fas fa-times"></span> Delete
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="packageColumnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: packageColumnsToDisplay;">
        </tr>
    </table>
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>
