<div class="tab-content p-4 mt-3">
    <div class="row title-row">
        <div class="col">
            <div class="title-container">
                <span class="main-title">{{ getSystemRole() }}</span>
                <span class="sub-title" i18n>Total installed systems: {{ subSystems.length }}</span>
            </div>        
        </div>
        <div class="col my-auto">
            <cwi-remote-carousel class="carousel" [array]="subSystems" (outputArray)="getMainSubSystems($event)"></cwi-remote-carousel>
        </div>
    </div>
    <div class="row p-4">
        <ng-container *ngFor="let subSystem of shownSubSystems; trackBy: subSystemsTrackBy">
            <div class="col-6">
                <cwi-remote-subsystem-status [subSystem]="subSystem"></cwi-remote-subsystem-status>
            </div>
        </ng-container>
    </div>
    <ng-container *ngFor="let responderMobile of respondersMobile; trackBy: responderMobileTrackBy">
        <div class="row mt-4">
          <div class="col d-flex">
            <cwi-remote-responder-mobile-status-card
              [title]="responderMobile.name"
              [status]="responderMobile.status"
              [version]="responderMobile.softwareVersion"
              [channels]="responderMobile.mobileChannels"
            ></cwi-remote-responder-mobile-status-card>
          </div>
        </div>
    </ng-container>
    <ng-container *ngFor="let responderIsdn of respondersIsdn; trackBy: responderIsdnTrackBy">
        <div class="row mt-4">
          <div class="col d-flex">
            <cwi-remote-responder-isdn-status-card
              [title]="responderIsdn.name"
              [status]="responderIsdn.status"
              [version]="responderIsdn.softwareVersion"
              [channels]="responderIsdn.isdnChannels"
            ></cwi-remote-responder-isdn-status-card>
          </div>
        </div>
    </ng-container>
    <ng-container *ngFor="let responderPs of respondersPs; trackBy: responderPsTrackBy">
        <div class="row mt-4">
          <div class="col d-flex">
            <cwi-remote-responder-ps-status-card
              [title]="responderPs.name"
              [status]="responderPs.status"
              [version]="responderPs.softwareVersion"
              [channels]="responderPs.psChannels"
            ></cwi-remote-responder-ps-status-card>
          </div>
        </div>
    </ng-container>
</div>