import { Component, Input } from '@angular/core';
import { SystemDefinition } from '../system-definition';
import { ProbeDefinition } from '../probe-definition';

@Component({
  selector: 'cwi-remote-system-map-marker',
  templateUrl: './system-map-marker.component.html',
  styleUrls: ['./system-map-marker.component.scss']
})
export class SystemMapMarkerComponent {

  private systemInternal: SystemDefinition;

  @Input()
  set systemStatus(value: SystemDefinition) {
    this.systemInternal = value;
  }

  get systemStatus() {
    return this.systemInternal;
  }

  probeTrackBy(index: number, probe: ProbeDefinition) {
    return probe.id;
  }

  returnTitle(): string {
    switch (this.systemStatus.status) {
      case 'Working': {
        return $localize`System ${this.systemStatus.name} is working correctly.`;
      }
      case 'Warning': {
        return $localize`System ${this.systemStatus.name} is in warning state. Check diagnostic page for more info.`;
      }
      case 'Error': {
        return $localize`System ${this.systemStatus.name} is in error state. Check diagnostic page for more info.`;
      }
    }
  }
}
