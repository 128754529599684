import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'cwi-assign-package-modal-component',
  templateUrl: './assign-package-modal.component.html',
  styleUrls: [],
  providers: [],
})
export class AssignPackageModalComponent {
  public assignedPackageVersion: string

  constructor(public activeModal: NgbActiveModal) {}
}
