<ng-container *cwiAsync="trains$; loading spinner; error retry; let trains;">
	<cwi-remote-custom-breadcrumbs></cwi-remote-custom-breadcrumbs>
	<div class="main-container">
		<cwi-remote-trains-list 
			[trains]="trains"
			[isVisible]="!selectedTrain"
			(trainSelected)=setTrainSelected($event)
			(followClicked)=handleFollow($event)>
		</cwi-remote-trains-list>
		<cwi-remote-systems-list *ngIf="systems$ | async; let systems;"
			[systems]="systems"
			[isVisible]="selectedTrain && !selectedSystem"
			(systemSelected)="setSystemSelected($event)">
		</cwi-remote-systems-list>
		<cwi-remote-plans-list
		    [selectedPlanName]="selectedPlan?.name"
			[selectedTrainName]="selectedTrain?.name"
			[selectedSystem]="selectedSystem"
			[activatedPlan]="selectedSystem?.activatedPlan"
			(planSelected)="setPlanSelected($event)"
			(planDeleted)="planDeleted()">
		</cwi-remote-plans-list>
		<div class="map-container">
			<cwi-remote-planmode-title *ngIf="selectedPlan" [planModeTitle]="selectedPlan?.workingMode"></cwi-remote-planmode-title>
			<div class="map"
					leaflet
					(cwiSizeChanged)="resizeMap()"
					[leafletOptions]="leafletOptions"
					[leafletLayersControl]="layersControl"
					(leafletMapReady)="onMapReady($event)"
					[cwiGeometryViewer]="geometryViewer"
					(geometrySelected)="geometrySelect($event)">
				<ng-container *ngFor="let train of trains; trackBy: trainTrackBy">
					<cwi-remote-train-map-marker
						[trainStatus]="train"
						[followTrain]="checkIfFollowed(train)"
						[isClickDisabled]="inDrawingMode"
						(markerClicked)="setTrainSelected(train)">
					</cwi-remote-train-map-marker>
				</ng-container>
				<ng-container *ngIf="selectedTrain$ | async">
					<cwi-remote-train-control-buttons
						[isFollowVisible]="selectedTrain ? true : false"
						(follow)="follow();">
					</cwi-remote-train-control-buttons>
					<ng-container *ngIf="selectedSystem && !selectedSystem.activatedPlan">
						<cwi-remote-manual-commands *cwiMapControl="'topright'"
						[immediateStartRecording]="immediateStartRecording"
						[selectedSetup]="selectedSystem.setup"
						[lastCommand]="selectedSystem.lastCommand"
						[setups]="selectedSystem.setups"
						[probes]="selectedSystem.probes"
						></cwi-remote-manual-commands>
					</ng-container>
					<ng-container *ngIf="selectedSystem">
						<cwi-remote-probes-info *cwiMapControl="'bottomleft'"
						[systemStatus]="selectedSystem">
						</cwi-remote-probes-info>
					</ng-container>
				</ng-container>
				<cwi-geometry-drawer *ngIf="geometryDrawerVisible()"
					(draw)=geometryDrawn($event) (inDrawingMode)="setDrawingMode($event)">
				</cwi-geometry-drawer>
			</div>
			<cwi-remote-standard-mode-panel class="standardModePanel" *ngIf="selectedPlan?.workingMode === 'standard'"
				[selectedSystem]="selectedSystem"
				[selectedPlan]="selectedPlan"
				[activatedPlan]="selectedSystem?.activatedPlan">
			</cwi-remote-standard-mode-panel>
			<cwi-remote-speed-mode-panel class="speedModePanel" *ngIf="selectedPlan?.workingMode === 'speed'"
				[selectedSystem]="selectedSystem"
				[selectedPlan]="selectedPlan"
				[activatedPlan]="selectedSystem?.activatedPlan">
			</cwi-remote-speed-mode-panel>
			<cwi-remote-time-schedulations class="timeSchedulationPanel" *ngIf="selectedPlan?.workingMode === 'schedulation'"
				[selectedSystem]="selectedSystem"
				[selectedPlan]="selectedPlan">
			</cwi-remote-time-schedulations>
		</div>
	</div>
</ng-container>

<ng-template #retry let-reload=reload>
	<div style="position: relative; top: 40%;" class="text-center" i18n>
	  <span class="fas fa-unlink mr-2"></span>Something went wrong <button class="btn btn-link" (click)="reload()">Retry.</button>
	</div>
</ng-template>
  

<ng-template #spinner>
	<div style="position: relative; top: 40%;" class="text-center">
		<cwi-spinner></cwi-spinner>
	</div>
</ng-template>