import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { IRemoteControllerConfiguration, REMOTECONTROLLER_CONFIGURATION } from "@cwi/remote-controller";
import { Observable } from "rxjs";
import { GeneralDiagnostic } from "./diagnostic";

export const DIAGNOSTIC_SERVICE = new InjectionToken<IDiagnosticService>('IDiagnosticService');

export interface IDiagnosticService
{
    getTrain(trainName: string, seconds: number): Observable<GeneralDiagnostic>; 
}

@Injectable()
export class HttpDiagnosticService implements IDiagnosticService
{
    constructor(
        private readonly $http: HttpClient,
        @Inject(REMOTECONTROLLER_CONFIGURATION)
        private readonly configuration: IRemoteControllerConfiguration,
    ) { }

    getTrain(trainName: string, seconds: number): Observable<GeneralDiagnostic> {
        return this.$http.get<GeneralDiagnostic>(`${this.configuration.baseUrl}/diagnostic/${encodeURI(trainName)}/seconds/${seconds}`);
    }
}
