import { SETTINGS_CONFIGURATION, ISettingsConfiguration, ITrackDatabaseService } from './service-configuration';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { reload } from '@cwi/rx';
import { Line } from '../models/line-definition';

@Injectable()
export class TrackDabataseService implements ITrackDatabaseService {

  private readonly baseUrl: string;
  private reloadSubject: Subject<any> = new Subject<any>();
  public lines$: Observable<Line[]>;

  constructor(
    private readonly $http: HttpClient,
    @Inject(SETTINGS_CONFIGURATION)
    configuration: ISettingsConfiguration
  ) {
    this.baseUrl = configuration.baseUrl;
    this.lines$ = this.$http.get<Line[]>(`${this.baseUrl}/lines`).pipe(
      reload(this.reloadSubject),
      shareReplay(1)
    );
    
  }
  
  reload() {
    this.reloadSubject.next();
  }
  
  getUserTracks(userName: string): Observable<{ [line: string]: string[] }> {
    return this.$http.get<{ [line: string]: string[] }>(`${this.baseUrl}/user/${userName}/tracks`);
  }

  getGroupTracks(groupName: string): Observable<{ [line: string]: string[] }> {
    return this.$http.get<{ [line: string]: string[] }>(`${this.baseUrl}/group/${groupName}/tracks`);
  }

  addTrackToUser(userName: string, lineCode: string, trackCode: string): Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/user/${encodeURI(userName)}/tracks`, { lineCode, trackCode });
  }

  removeTrackFromUser(userName: string, lineCode: string, trackCode: string): Observable<void> {
    return this.$http.delete<any>(`${this.baseUrl}/user/${encodeURI(userName)}/line/${encodeURI(lineCode)}/track/${encodeURI(trackCode)}`);
  }

  addTrackToGroup(groupName: string, lineCode: string, trackCode: string): Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/group/${encodeURI(groupName)}/tracks`, { lineCode, trackCode });
  }

  removeTrackFromGroup(groupName: string, lineCode: string, trackCode: string): Observable<void> {
    return this.$http.delete<any>(`${this.baseUrl}/group/${encodeURI(groupName)}/line/${encodeURI(lineCode)}/track/${encodeURI(trackCode)}`);
  }

}
