<div class="card manual-commands-container">
  <div class="card-body m-2 p-2 d-block">
    <h6 class="font-weight-bold" i18n> Manual Commands</h6>
    <label>
      <span i18n>Select a setup:</span>
      <select class="form-control" [(ngModel)]="selectedSetup" [disabled]="!enableSetups()"> 
        <option hidden value="" disabled selected i18n>Select a setup </option>
        <option *ngFor="let set of setups" [value]="set"> {{ set }}</option>
      </select>
    </label>
    <div class="row-container">
      <span *ngIf="error != null" style="margin:0 auto;" 
        class="fas ml-2 fa-exclamation-triangle text-danger"
        title="{{ error }}">
      </span>
    </div>
    <div *ngIf="!spinnerInvisible()" class="spinner-container">
      <mat-spinner style="margin:0 auto" 
        class="mb-1 mat-spinner-color"
        [diameter]=25>
      </mat-spinner>
      <label i18n>Waiting for status change...</label>
    </div>
    <div class="buttons-container" [ngClass]="{ 'disabled': !selectedSetup && awaitingCommand }" >
      <button *ngIf="!immediateStartRecording" class="btn btn-sm btn-outline-primary mb-1"
        [disabled]="!startMeasuringEnabled()"
        (click)="startMeasuring()">
        Start Measuring
      </button>
      <button *ngIf="!immediateStartRecording" class="btn btn-sm btn-outline-primary mb-1"
        [disabled]="!stopMeasuringEnabled()"
        (click)="stopMeasuring()">
        Stop Measuring
      </button>
      <button class="btn btn-sm btn-outline-primary mb-1"
        [disabled]="!startRecordingEnabled()"
        (click)="startRecording()">
        Start Recording
      </button>
      <button class="btn btn-sm btn-outline-primary"
        [disabled]="!stopRecordingEnabled()"
        (click)="stopRecording()">
        Stop Recording
      </button>
    </div>
  </div>
</div>