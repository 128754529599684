<div class="modal-header">
  <h4 class="modal-title" i18n>Application token</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h5 class="font-weight-bold" i18n>Current token</h5>
  <p class="border text-light bg-dark p-2 font-monospace text-break">{{ token }}</p>
  <p>If you choose to generate a new token, all applications using the current one will need to be updated.</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="onRefresh()">Generate a new token</button>
</div>
