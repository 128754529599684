import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemoteControllerRoutingModule } from './remote-controller-routing.module';
import { RemoteControllerTrainsViewComponent } from './remote-controller-trainsview/remote-controller-trainsview.component';
import { RemoteControllerTrainOptionsComponent } from './remote-controller-trainoptionsview/remote-controller-trainoptionsview.component';
import { RemoteControllerComponent } from './remote-controller.component';
import { ComponentsModule } from '@cwi/components';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { GeneralSettingsServiceFactory, RemoteControllerModule } from '@cwi/remote-controller';
import { RemoteControllerDiagnosticViewComponent } from './remote-controller-diagnosticview/remote-controller-diagnosticview.component';
import { PAGES_SECTION } from '../home/configuration';
import { DEFAULT_RC_SECTION_CONFIGURATION, REMOTECONTROLLER_SECTION_CONFIGURATION, buildRcSectionConfiguration } from './section.configuration';
import { RemoteControllerRoleGuard } from './remote-controller.role-guard';

@NgModule({
  declarations: [
    RemoteControllerTrainsViewComponent,
    RemoteControllerTrainOptionsComponent,
    RemoteControllerDiagnosticViewComponent,
    RemoteControllerComponent
  ],
  imports: [
    ComponentsModule,
    RemoteControllerModule,
    RemoteControllerRoutingModule,
    LeafletModule,
    CommonModule
  ],
  providers: [
    RemoteControllerRoleGuard,
    GeneralSettingsServiceFactory,
    {
      provide: REMOTECONTROLLER_SECTION_CONFIGURATION,
      useValue: DEFAULT_RC_SECTION_CONFIGURATION
    },
    {
      provide: PAGES_SECTION,
      useFactory: buildRcSectionConfiguration,
      multi: true,
      deps: [ REMOTECONTROLLER_SECTION_CONFIGURATION ]
    }
  ]
})
export class RemoteControllerPagesModule { }
