import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule, DEFAULT_MAP_OPTIONS_FACTORY, MAP_OPTIONS, TrackDrawerFactory, TRACK_DRAWER_FACTORY } from '@cwi/components';
import { SettingsComponent } from './settings.component';
import { PAGES_SECTION } from '../home/configuration';
import { SECTION_CONFIGURATION } from './section.configuration';
import { UserManagementUsersViewComponent } from './user-management-usersview/user-management-usersview.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { DefaultUserCreationConfiguration, DEFAULT_USER_CREATION_DISPLAYNAME_VALIDATORS, DEFAULT_USER_CREATION_EMAIL_VALIDATORS,
  DEFAULT_USER_CREATION_PASSWORD_VALIDATORS, DEFAULT_USER_CREATION_USERNAME_VALIDATORS,
  USER_CREATION_CONFIGURATION, USER_CREATION_DISPLAYNAME_VALIDATORS, USER_CREATION_EMAIL_VALIDATORS, USER_CREATION_PASSWORD_VALIDATORS,
  USER_CREATION_USERNAME_VALIDATORS } from '../user-creation.configuration';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GROUP_SERVICE, ROLE_SERVICE, SETTINGS_CONFIGURATION, SETTINGS_CONFIGURATION_DEFAULT, TRACKDATABASE_SERVICE, USER_SERVICE } from './services/service-configuration';
import { UserService } from './services/user-service';
import { UserCreationComponent } from './components/user-creation/user-creation.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RolesChipsComponent } from './components/roles-chips/roles-chips.component';
import { SettingsRoleGuard } from './settings.role-guard';
import { SETTINGS_SIDEBAR_SECTION, TRACK_SECTION, USER_MANAGEMENT_SECTION } from './sidebar-sections/sidebar-section.configuration';
import { GroupCreationComponent } from './components/group-creation/group-creation.component';
import { GroupListComponent } from './components/groups-list/groups-list.component';
import { RoleService } from './services/role-service';
import { GroupService } from './services/group-service';
import { DefaultGroupCreationConfiguration, DEFAULT_GROUPCREATION_NAME_VALIDATORS,
  GROUPCREATION_CONFIGURATION, GROUPCREATION_NAME_VALIDATORS } from './components/group-creation/group-creation.configuration';
import { UserUpdateComponent } from './components/user-update/user-update.component';
import { TrackDabataseViewComponent } from './trackdatabase-view/trackdatabase-view.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { TrackDabataseService } from './services/trackdatabase-service';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { SOFTWAREUPDATES_CONFIGURATION, SOFTWAREUPDATES_DEFAULT, SOFTWAREUPDATES_SERVICE } from './services/software-updates';
import { SoftwareUpdatesService } from './services/software-updates-service';
import { SoftwareUpdatesViewComponent } from './software-updates-view/software-updates-view.component';
import { AssignPackageModalComponent } from './components/assign-package-modal/assign-package-modal.component';
import { UploadPackageModalComponent } from './components/upload-package-modal/upload-package-modal.component';
import { DeletePackageModalComponent } from './components/delete-package-modal/delete-package-modal.component';

@NgModule({
  declarations: [
    SettingsComponent,
    UserManagementUsersViewComponent,
    UserCreationComponent,
    UserUpdateComponent,
    UsersListComponent,
    RolesChipsComponent,
    GroupCreationComponent,
    GroupListComponent,
    TrackDabataseViewComponent,
    SoftwareUpdatesViewComponent,
    UploadPackageModalComponent,
    DeletePackageModalComponent,
    AssignPackageModalComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    SettingsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    LeafletModule,
    NgbPopoverModule
  ],
  exports: [
    UserCreationComponent,
    UserUpdateComponent,
    UsersListComponent,
    RolesChipsComponent,
    GroupCreationComponent,
    GroupListComponent,
  ],
  providers: [
    SettingsRoleGuard,
    {
      provide: PAGES_SECTION,
      useValue: SECTION_CONFIGURATION,
      multi: true
    },
    {
      provide: SETTINGS_SIDEBAR_SECTION,
      useValue: USER_MANAGEMENT_SECTION,
      multi: true
    },
    {
      provide: SETTINGS_SIDEBAR_SECTION,
      useValue: TRACK_SECTION,
      multi: true
    },
    {
      provide: USER_CREATION_CONFIGURATION,
      useClass: DefaultUserCreationConfiguration
    },
    {
      provide: USER_CREATION_PASSWORD_VALIDATORS,
      useValue: DEFAULT_USER_CREATION_PASSWORD_VALIDATORS
    },
    {
      provide: USER_CREATION_USERNAME_VALIDATORS,
      useValue: DEFAULT_USER_CREATION_USERNAME_VALIDATORS
    },
    {
      provide: USER_CREATION_DISPLAYNAME_VALIDATORS,
      useValue: DEFAULT_USER_CREATION_DISPLAYNAME_VALIDATORS
    },
    {
      provide: USER_CREATION_EMAIL_VALIDATORS,
      useValue: DEFAULT_USER_CREATION_EMAIL_VALIDATORS
    },
    {
      provide: SETTINGS_CONFIGURATION,
      useValue: SETTINGS_CONFIGURATION_DEFAULT
    },
    {
      provide: SOFTWAREUPDATES_CONFIGURATION,
      useValue: SOFTWAREUPDATES_DEFAULT
    },
    {
      provide: GROUPCREATION_CONFIGURATION,
      useClass: DefaultGroupCreationConfiguration
    },
    {
      provide: GROUPCREATION_NAME_VALIDATORS,
      useValue: DEFAULT_GROUPCREATION_NAME_VALIDATORS
    },
    {
      provide: ROLE_SERVICE,
      useClass: RoleService
    },
    {
      provide: USER_SERVICE,
      useClass: UserService
    },
    {
      provide: GROUP_SERVICE,
      useClass: GroupService
    },
    {
      provide: TRACKDATABASE_SERVICE,
      useClass: TrackDabataseService
    },
    {
      provide: SOFTWAREUPDATES_SERVICE,
      useClass: SoftwareUpdatesService
    },
    {
      provide: MAP_OPTIONS,
      useFactory: DEFAULT_MAP_OPTIONS_FACTORY
    },

    {
      provide: TRACK_DRAWER_FACTORY,
      useClass: TrackDrawerFactory,
      multi: true
    },
  ],
  entryComponents: [
    UserCreationComponent,
    UserUpdateComponent
  ]
})
export class SettingsModule { }
