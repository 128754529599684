
export const Ki = 1024;
export const Mi = Ki * Ki;
export const Gi = Ki * Mi;
export const Ti = Ki * Gi;
export const Pi = Ki * Ti;

export const K = 1000;
export const M = K * K;
export const G = K * M;
export const T = K * G;
export const P = K * T;
