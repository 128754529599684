<div class="modal-header">
  <h4 class="modal-title" i18n>Upload packages</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <input #fileUpload hidden="true" type="file" onclick="this.file=null" (change)="onChange($event)" accept=".zip"/>
  <p>
    <button mat-raised-button (click)="fileUpload.click()">Choose Package File (.zip)</button>
  </p>
  <p>
    <label>Notes:</label>
    <input type="text" [(ngModel)]="notes" />
  </p>
  <div *ngIf="file">
    <section class="file-info">
      File details:
      <ul>
        <li>Name: {{file.name}}</li>
        <li>Type: {{file.type}}</li>
        <li>Size: {{file.size}} bytes</li>
      </ul>
    </section>
  
    <button mat-raised-button color="primary"
      (click)="onUpload()" [disabled]="status === 'uploading'">Upload package</button>
  
    <section [ngSwitch]="status">
      <p *ngSwitchCase="'uploading'">Uploading...</p>
      <p *ngSwitchCase="'success'">Upload completed</p>
      <p *ngSwitchCase="'fail'">Error uploading</p>
      <p *ngSwitchDefault>Waiting to upload</p>
    </section>
    <div *ngIf="error"><p></p>{{error}}</div>
  </div>
</div>
<div class="modal-footer">
</div>
