<div class="modal-header">
  <h4 class="modal-title" i18n>Assign package to client</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h5 class="font-weight-bold" i18n>Package version:</h5>
  <input [(ngModel)]="assignedPackageVersion">
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(true)">Assign</button>
</div>
