import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';

export function getPopoverResult<T>(popoverRef: NgbPopover): Promise<T> {
  return new Promise<T>(close => {
    popoverRef.hidden.pipe(take(1)).subscribe(() => close(undefined));
    popoverRef.open({
      close: (value?: T) => {
        close(value);
        popoverRef.close(); },
      dismiss: () => popoverRef.close()
    });
  });
}

