import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService, UserClaims } from '@cwi/components';
import { MainComponent } from '../main.component';
import { HomeConfiguration, HOME_CONFIGURATION, PAGES_SECTION, Section, Version } from './configuration';

@Component({
  selector: 'cwi-pg-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent {
  userInfo: UserClaims;
  appVer: string;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly sanitizer: DomSanitizer,
    @Inject(HOME_CONFIGURATION)
    readonly configuration: HomeConfiguration,
    @Inject(PAGES_SECTION)
    readonly sections: Section[]
  ) {
      this.userInfo = authenticationService.userInfo;
      this.appVer = Version;
  }

  getSectionDescription(index: number) {
    return this.sanitizer.bypassSecurityTrustHtml(this.sections[index].description);
  }

  get greetings() {
    return this.sanitizer.bypassSecurityTrustHtml(this.configuration.greetings);
  }

  isInRole = (section: Section) => {
    for (const role of this.userInfo.roles) {
      if (section.roles) {
        if (section.roles.has(role)) {
          return true;
        }
      }
    }
    return false;
  }
}
