
<div class="alert alert-dismissible fade show" role="alert" *ngIf="message"
    [ngClass]="{'alert-danger': isError, 'alert-success': !isError }">
    {{ message }}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeAlert()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="updateDisplayNameForm" (ngSubmit)="onSubmitDisplayName()">
    <h2 class="header" i18n>Change Display Name</h2>
    <div class="card-body body">
        <div class="input-container">
          <div class="form-group-inline">
            <label i18n>Display name:
                <input type="text" class="form-control input-text ml-3"
                    i18n-placeholder placeholder="Display name"
                    formControlName="displayName"/>
            </label>
          </div>
          <button type="submit" [disabled]="!updateDisplayNameForm.valid"
            class="btn btn-primary btn-sm"
            style="margin-left: auto;" i18n>
            <span class="fas fa-save"></span> Submit
          </button>
        </div>
    </div>
</form>
<form [formGroup]="updateEmailForm" class="mt-3" (ngSubmit)="onSubmitEmail()">
    <h2 class="header" i18n>Change Email</h2>
    <div class="card-body body">
        <div class="input-container">
          <div class="form-group-inline">
            <label i18n>Email:
                <input type="text" class="form-control input-text ml-3"
                    i18n-placeholder placeholder="Email"
                    formControlName="email"/>
            </label>
          </div>
          <button type="submit" [disabled]="!updateEmailForm.valid"
            class="btn btn-primary btn-sm"
            style="margin-left: auto;" i18n>
            <span class="fas fa-save"></span> Submit
          </button>
        </div>
    </div>
</form>
<cwi-pg-change-password class="mt-3"
    [updatePasswordCompleted]="passwordUpdated"
    (passwordUpdated)="onPasswordUpdated($event)">
</cwi-pg-change-password>
<h2 class="header" i18n>Application Token</h2>
<div class="card-body body">
  <button type="button" class="btn btn-primary" i18n (click)="showToken()">Show token</button>
</div>
