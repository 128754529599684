import { OptionsFileCfgDefinition } from '../options-fileCfg-definition';
import { Router } from '@angular/router';
import { debounceTime, startWith, distinctUntilChanged, map } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { Component, Inject, Input } from '@angular/core';
import { IOptionsFileCfgService, IRemoteControllerConfiguration, OPTIONS_FILECFG_SERVICE, REMOTECONTROLLER_CONFIGURATION } from '@cwi/remote-controller';
import { combineLatest, Observable, Subject } from 'rxjs';

@Component({
  selector: 'cwi-remote-manage-configurations',
  templateUrl: './manage-configurations.component.html',
  styleUrls: ['./manage-configurations.component.scss']
})
export class ManageConfigurationsComponent {

  private readonly searchSubject = new Subject<string>();

  @Input()
  train: string;

  baseUrl: string;
  router: Router;
  supportedFileTypes = ['.cfg'];  // acceptable file types
  progressInfos = [];
  alertClosed: boolean;
  infoVisible: boolean;
  filter: string;

  fileCfgs$: Observable<OptionsFileCfgDefinition[]>;
  selectedFile: File;
  messages: string[] = [];

  constructor(
    @Inject(REMOTECONTROLLER_CONFIGURATION)
    configuration: IRemoteControllerConfiguration,
    @Inject(OPTIONS_FILECFG_SERVICE)
    private optionsFileCfgService: IOptionsFileCfgService
  ) {
    this.baseUrl = configuration.baseUrl;

    const fileCfgs = optionsFileCfgService.optionsFileCfg$;

    const filter$ = this.searchSubject.pipe(
      debounceTime(300),
      startWith(''),
      distinctUntilChanged(),
      map(term => new RegExp(`${term.toLowerCase()}`, 'i'))
      );

    this.fileCfgs$ = combineLatest([fileCfgs, filter$]).pipe(
        map(([definitions, term]) =>  definitions.filter(
          definition => {
            return term.test(definition.system)
            || term.test(definition.name);
          }
        ))
      );
  }

  search(term: string) {
    this.filter = term;
    this.searchSubject.next(term);
  }

  selectFile(event: any, row: OptionsFileCfgDefinition) {
    this.progressInfos = [];
    const file = event.target.files[0];
    if (!this.validateFile(file.name)) {
      this.messages.push($localize `Could not upload the file: ` + file.name);
    } else {
      this.upload(file, row);
    }
    this.infoVisible = true;
  }

  validateFile(name: string) {
    const ext = name.substring(name.lastIndexOf('.'));
    return this.supportedFileTypes.indexOf(ext) > -1;
  }

  upload(file: File, row: OptionsFileCfgDefinition) {
    const element = { value: 0, systemName: row.system, fileName: file.name };
    this.progressInfos.push(element);

    this.optionsFileCfgService.upload(row.system, file).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          if (element) {
            element.value = Math.round(100 * event.loaded / event.total);
            if (element.value === 100) {
              setTimeout(() => {
                window.location.reload();
              }, 6000)
            }
          }
        }
      },
      err => {
        element.value = 0;
        this.messages.push($localize `Could not upload the file: ` + file.name);
      });
  }

  resetInfoProperties() {
    this.infoVisible = false;
    this.alertClosed = false;
  }
}
