<cwi-groups-list (groupChanged)="refreshUsers()"></cwi-groups-list>
<div class="title-text mat-title" i18n>Configured User List</div>
<div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="error">
  {{ error }}
  <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeAlert()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<button class="btn btn-primary btn-sm add-new" type="button" (click)="openUserCreationModal()" i18n>Add User</button>
<div class="main-container mt-1">
  <div class="mat-elevation-z1 table-container">
      <table mat-table *ngIf="users$ | async;" [dataSource]="users$">
        <ng-container matColumnDef="userName">
          <th mat-header-cell *matHeaderCellDef i18n> Username </th>
          <td mat-cell *matCellDef="let element"> {{ element.userName }} </td>
        </ng-container>
        <ng-container matColumnDef="displayName">
          <th mat-header-cell *matHeaderCellDef i18n> Display name </th>
          <td mat-cell *matCellDef="let element"> {{ element.displayName }} </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef i18n> Email </th>
          <td mat-cell *matCellDef="let element"> {{ element.email }} </td>
        </ng-container>
        <ng-container matColumnDef="roles">
            <th mat-header-cell *matHeaderCellDef i18n> Roles </th>
            <td mat-cell *matCellDef="let element">
              <cwi-roles-chips *ngIf="roles"
                [selectedValues]="element.roles"
                [allValues]="roles"
                (added)="roleAdded(element, $event)"
                (deleted)="roleDeleted(element, $event)">
              </cwi-roles-chips>
          </td>
        </ng-container>
        <ng-container matColumnDef="groups">
          <th mat-header-cell *matHeaderCellDef i18n> Groups </th>
          <td mat-cell *matCellDef="let element">
            <cwi-roles-chips *ngIf="groups"
              [selectedValues]="element.groups"
              [allValues]="groups"
              (added)="groupAdded(element, $event)"
              (deleted)="groupDeleted(element, $event)">
            </cwi-roles-chips>
        </td>
      </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef i18n> Actions </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="actionsEnabled(element)">
              <button class="btn btn-sm text-secondary btn-link custom-button"
                (click)="openUserEditModal(element)" i18n>Edit</button>
              <button class="btn btn-sm text-danger btn-link custom-button"
                (click)="deleteUser(element.userName)" i18n>Delete</button>
            </div>
          </td>
        </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        class="example-element-row">
      </tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>