import { ValidatorFn, Validators } from '@angular/forms';
import { InjectionToken, Inject, Injectable } from '@angular/core';

export interface IGroupCreationConfiguration {
  nameValidators: ValidatorFn|ValidatorFn[];
}

export const GROUPCREATION_NAME_VALIDATORS = new InjectionToken<ValidatorFn|ValidatorFn[]>('GROUPCREATION_NAME_VALIDATORS');

export const GROUPCREATION_CONFIGURATION = new InjectionToken<IGroupCreationConfiguration>('GROUPCREATION_CONFIGURATION');

export const DEFAULT_GROUPCREATION_NAME_VALIDATORS: ValidatorFn[] = [
  Validators.required,
];

@Injectable()
export class DefaultGroupCreationConfiguration implements IGroupCreationConfiguration {
  constructor(
    @Inject(GROUPCREATION_NAME_VALIDATORS)
    public readonly nameValidators: ValidatorFn|ValidatorFn[]
  ) { }
}
