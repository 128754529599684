import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SystemDefinition } from '../system-definition';

@Component({
  selector: 'cwi-remote-system-status',
  templateUrl: './system-status.component.html',
  styleUrls: ['./system-status.component.scss']
})
export class SystemStatusComponent {

  constructor() { }

  @Input()
  public systemStatus: SystemDefinition;

  @Output()
  public readonly systemSelected = new EventEmitter<void>();

  systemClick() {
    this.systemSelected.next();
  }

  returnTitle(): string {
    switch (this.systemStatus.status) {
      case 'Working': {
        return $localize`System ${this.systemStatus.name} is working correctly.`;
      }
      case 'Warning': {
        return $localize`System ${this.systemStatus.name} is in warning state. Check diagnostic page for more info.`;
      }
      case 'Error': {
        return $localize`System ${this.systemStatus.name} is in error state. Check diagnostic page for more info.`;
      }
    }
  }
}
