import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: 'cwi-remote-device-link',
  templateUrl: './device-link.component.html',
  styleUrls: ['./device-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceLinkComponent {
}
