import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { ComponentsModule } from '@cwi/components';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [MainComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    ComponentsModule,
  ],
  exports: [MainComponent]
})
export class PagesModule { }
