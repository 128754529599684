import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClickSinkDirective } from './click-sink.directive';
import { FuzzFilterPipe } from './fuzz-filter.pipe';
import { DatePipe } from './date.pipe';
import { DurationPipe } from './duration.pipe';
import { GetPipe } from './get.pipe';
import { AsyncDirective } from './async.directive';
import { FilterPipe } from './filter.pipe';
import { SecondsToHHmmPipe } from './sec-to-hhmm.pipe';
import { FileSizePipe } from './file-size.pipe';
import { OrderByPipe } from './order-by.directive';


@NgModule({
  declarations: [
    ClickSinkDirective,
    FuzzFilterPipe,
    DatePipe,
    DurationPipe,
    GetPipe,
    AsyncDirective,
    FilterPipe,
    SecondsToHHmmPipe,
    FileSizePipe,
    OrderByPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ClickSinkDirective,
    FuzzFilterPipe,
    DatePipe,
    DurationPipe,
    GetPipe,
    AsyncDirective,
    FilterPipe,
    SecondsToHHmmPipe,
    FileSizePipe,
    OrderByPipe

  ],
})
export class CwiCommonModule { }
