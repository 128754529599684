import { Component, Input, Inject, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { PlanDefinition } from '../plan-definition';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemDefinition } from '../system-definition';
import { STANDARD_MODE_SERVICE, IStandardModeService } from '../service-configuration';
import { StandardModeDefinition } from '../standard-mode-definition';
import { StandardModeComponent } from '../standard-mode-creator/standard-mode-creator.component';

@Component({
  selector: 'cwi-remote-standard-mode-panel',
  templateUrl: './standard-mode-panel.component.html',
  styleUrls: ['./standard-mode-panel.component.scss']
})
export class StandardModePanelComponent implements OnDestroy {

  @Input()
  selectedSystem: SystemDefinition;

  @Input()
  selectedPlan: PlanDefinition;

  @Input()
  public activatedPlan: string;

  private subscription = new Subscription();
  standardModes$: Observable<StandardModeDefinition[]>;
  standardMode: StandardModeDefinition;

  constructor(
    @Inject(STANDARD_MODE_SERVICE)
    private readonly standardModeService: IStandardModeService,
    private readonly modalService: NgbModal
  ) {
    this.standardModes$ = standardModeService.standardModes$;
    this.subscription.add(this.standardModes$.subscribe((standardModes: StandardModeDefinition[]) => {
      if (standardModes) {
        this.standardMode = standardModes[0];
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openAddStandardMode() {
    const modalRef = this.modalService.open(StandardModeComponent, {backdrop: 'static', keyboard: false});
    const componentInstance: StandardModeComponent = modalRef.componentInstance;
    componentInstance.selectedPlan = this.selectedPlan;
    componentInstance.setups = this.selectedSystem.setups;

    this.subscription.add(componentInstance.result.subscribe((result: StandardModeDefinition) => {
      this.addStandardMode(result);
      modalRef.dismiss();
    }));
    this.subscription.add(componentInstance.canceled.subscribe(() => {
      modalRef.dismiss();
    }));
  }

  editStandardMode() {
    const modalRef = this.modalService.open(StandardModeComponent, {backdrop: 'static', keyboard: false});
    const componentInstance: StandardModeComponent = modalRef.componentInstance;
    componentInstance.selectedPlan = this.selectedPlan;
    componentInstance.setups = this.selectedSystem.setups;
    componentInstance.standardModeInfo = this.standardMode;
    componentInstance.isActive = this.selectedPlan.name === this.activatedPlan;

    this.subscription.add(componentInstance.result.subscribe((result: StandardModeDefinition) => {
      this.updateStandardMode(result);
      modalRef.dismiss();
    }));
    this.subscription.add(componentInstance.canceled.subscribe(() => {
      modalRef.dismiss();
    }));
  }

  async updateStandardMode(standardMode: StandardModeDefinition) {
    await this.standardModeService.updateStandardMode(standardMode).toPromise();
    this.standardModeService.reload();
  }

  async addStandardMode(standardMode: StandardModeDefinition) {
    await this.standardModeService.addStandardMode(standardMode).toPromise();
    this.standardModeService.reload();
  }

  async deleteStandardMode(standardMode: StandardModeDefinition) {
    await this.standardModeService.deleteStandardMode(standardMode.id).toPromise();
    this.standardModeService.reload();
  }

}
