<div class="container">
  <div class="icons-container">
    <cwi-language-selector></cwi-language-selector>
    <cwi-user-profile></cwi-user-profile>
  </div>
  <div class="jumbotron">
    <p class="border-bottom" [innerHtml]="greetings"></p>
    <div class="card-deck">
      <div class="card" *ngFor="let section of sections | cwiFilter: isInRole; let i=index">
        <div class="card-body">
          <h5 class="card-title">{{section.title}}</h5>
          <p class="card-text" [innerHtml]="getSectionDescription(i)"></p>
        </div>
        <div class="card-footer">
          <a [routerLink]="section.link" class="btn btn-primary">{{section.linkCaption}}</a>
        </div>
      </div>
    </div>
  
    <hr class="my-4" />
  
    <div class="lead">
      <img *ngFor="let logo of configuration.logos"
        [attr.width]=logo.width
        [attr.height]=logo.height
        [attr.alt]=logo.alt
        [src]=logo.path />
    </div>
    <div class="appVersion">
      v.{{appVer}}
    </div>
  </div>  
</div>
