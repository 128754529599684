import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpeedModeDefinition } from '../speed-mode-definition';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { getPopoverResult } from '@cwi/components';

@Component({
  selector: 'cwi-remote-speed-mode-info',
  templateUrl: './speed-mode-info.component.html',
  styleUrls: ['./speed-mode-info.component.scss']
})
export class SpeedModeInfoComponent implements OnDestroy {

  @Input()
  speedModeInfo: SpeedModeDefinition;

  @Input()
  editDisabled: boolean;

  @Output()
  public readonly editClicked = new EventEmitter<void>();

  @Output()
  public readonly deleteClicked = new EventEmitter<void>();

  private subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  editClick() {
    this.editClicked.next();
  }

  async deleteClick(popoverRef: NgbPopover) {
    try {
      if (await getPopoverResult<boolean>(popoverRef)) {
        this.deleteClicked.next();
      }
    } catch (e) {
      console.log(e);
    }
  }
}
