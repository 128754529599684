import { Component, Input } from '@angular/core';
import { SystemDefinition } from '../system-definition';

@Component({
  selector: 'cwi-remote-probes-info',
  templateUrl: './probes-info.component.html',
  styleUrls: ['./probes-info.component.scss']
})
export class ProbesInfoComponent {

  constructor() { }

  @Input()
  public systemStatus: SystemDefinition;

  returnTitle(): string {
    switch (this.systemStatus.status) {
      case 'Working': {
        return $localize `System ${this.systemStatus.name} is working correctly.`;
      }
      case 'Warning': {
        return $localize `System ${this.systemStatus.name} is in warning state. Check diagnostic page for more info.`;
      }
      case 'Error': {
        return $localize `System ${this.systemStatus.name} is in error state. Check diagnostic page for more info.`;
      }
    }
  }
}
