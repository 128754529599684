<cwi-remote-device-link></cwi-remote-device-link>
<div class="mobiles-container">
    <mat-card *ngFor="let mobile of mobiles" class="mb-3">
        <mat-card-content>
            <div class="row">
                <div class="col-2 my-auto">
                    <div class="image">
                        <img *ngIf="mobile.type === 'Visual Scripting'" class="my-auto" src="assets/icons/diagnostic/visualscripting.svg">
                        <img *ngIf="mobile.type !== 'Visual Scripting'" class="my-auto" src="assets/icons/diagnostic/mobile.svg">
                    </div>
                </div>
                <div class="col-6 my-auto">
                    <div class="name-container">
                        <span class="name">{{ mobile.name }}</span>
                        <span class="subname">{{ getMobileType(mobile) }}</span>
                    </div>
                </div>
                <div class="col-4 my-auto">
                    <cwi-remote-device-status-indicator [status]="mobile.status"></cwi-remote-device-status-indicator>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>