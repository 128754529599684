import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { AcquisitionStatus } from "../diagnostic";

@Component({
  selector: 'cwi-remote-acquisition-status-indicator',
  templateUrl: './acquisition-status-indicator.component.html',
  styleUrls: ['./acquisition-status-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcquisitionStatusIndicatorComponent {
  
  @Input()
  public status: AcquisitionStatus;

  enumValue = AcquisitionStatus;

  get statusValue(): string {
    switch (this.status)
    {
      case AcquisitionStatus.Completed:
        return $localize`Completed`;
      case AcquisitionStatus.Processing:
        return $localize`Processing`;
      default:
        return $localize`Processing`;
    }
  }
}
