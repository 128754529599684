import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DeviceStatus } from "../diagnostic";

@Component({
  selector: 'cwi-remote-device-status-indicator',
  templateUrl: './device-status-indicator.component.html',
  styleUrls: ['./device-status-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceStatusIndicatorComponent {
  
  @Input()
  public status: DeviceStatus;

  enumValue = DeviceStatus;

  get statusValue(): string {
    switch (this.status)
    {
      case DeviceStatus.Connected:
        return $localize`Connected`;
      case DeviceStatus.Disconnected:
        return $localize`Disconnected`;
      case DeviceStatus.Running:
        return $localize`Running`;
      case DeviceStatus.Disabled:
        return $localize`Disabled`;
      default:
        return $localize`Disconnected`;
    }
  }
}
