<div class="row">
  <div class="col title" i18n>
    Data Acquisitions
  </div>
  <div class="col-auto">
    <div class="time-container d-flex">
      <div class="icon text-center my-auto">
        <mat-icon class="my-auto">alarm</mat-icon>
      </div>
      <cwi-remote-download-time [time]="uploadTime"></cwi-remote-download-time>
    </div>
  </div>
</div>
<!-- TODO Handle filter
<div class="row mt-3">
  <div class="col d-flex inline-flex filter-title">
    <mat-icon class="mr-2">tune</mat-icon>
    <span>Filter by</span>
  </div>
</div> -->
<div class="row mt-3">
  <div class="col">
    <mat-card>
        <table matSort mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" [trackBy]="trackAcquisition"
          class="mat-elevation-z8">
            <ng-container matColumnDef="system">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="system" class="header" i18n>System</th>
              <td mat-cell *matCellDef="let element">
                <div class="system-name">
                  <span>{{ element.system }}</span>
                  <span class="sub">{{ getSystemRole(element.isMaster) }}</span>
                </div>
              </td>
            </ng-container>
            
            <ng-container matColumnDef="name">
              <th mat-header-cell mat-sort-header="name" *matHeaderCellDef class="header" i18n>Name</th>
              <td mat-cell *matCellDef="let element">
                <span>{{ element.name }}</span>
              </td>
            </ng-container>
        
            <ng-container matColumnDef="date">
                <th mat-header-cell mat-sort-header="date" *matHeaderCellDef class="header" i18n>Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.date | cwiDate: true: 'DD-MM-YYYY' }}
                </td>
              </ng-container>
          
            <ng-container matColumnDef="status">
                <th mat-header-cell mat-sort-header="status" *matHeaderCellDef class="header" i18n>Status</th>
                <td mat-cell *matCellDef="let element" style="width: 15%;">
                  <cwi-remote-acquisition-status-indicator [status]="element.status"></cwi-remote-acquisition-status-indicator>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator hidePageSize [pageSize]="10" showFirstLastButtons >
        </mat-paginator>
    </mat-card>
  </div>
</div>
