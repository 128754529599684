<mat-card>
  <mat-card-title>
    <div class="row">
      <div class="col" i18n>System statuses</div>
      <div class="col-auto my-auto small">
        <a *ngIf="tag" [href]="anchor" class="small" i18n>Go<span class="fas fa-arrow-down ml-1"></span></a>
      </div>
    </div>
  </mat-card-title>
  <mat-card-content class="w-100">
    <mat-divider></mat-divider>
    <table mat-table [dataSource]="subSystems" class="mat-elevation-z0">
      <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="my-auto header" i18n>System name</th>
          <td mat-cell *matCellDef="let element">
            <div class="system-name">
              <span>{{ element.name }}</span>
              <span class="sub">{{ getSystemRole(element.isMaster )}}</span>
            </div>
          </td>
        </ng-container>
      
        <!-- GPS Column -->
        <ng-container matColumnDef="fixGps">
          <th mat-header-cell *matHeaderCellDef class="my-auto header">Fix GPS </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'disabled': getIsSubSystemUnreachable(element)}">
            <ng-container *ngTemplateOutlet="gpsTemplate; context: { $implicit: element.fixGps }"></ng-container>
          </td>
        </ng-container>
      
        <!-- Warning Column -->
        <ng-container matColumnDef="warning">
          <th mat-header-cell *matHeaderCellDef class="my-auto header" i18n>System warning</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'disabled': getIsSubSystemUnreachable(element)}">
            <ng-container *ngTemplateOutlet="warningTemplate; context: { $implicit: element.warning }"></ng-container>
          </td>
        </ng-container>
      
        <!-- Devices Column -->
        <ng-container matColumnDef="devices">
          <th mat-header-cell *matHeaderCellDef class="my-auto header">Devices </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'disabled': getIsSubSystemUnreachable(element)}">
            <ng-container *ngTemplateOutlet="devicesTemplate; context: { $implicit: element.devicesDisconnected }"></ng-container>
          </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="my-auto header text-center" i18n>System status</th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <cwi-remote-subsystem-status-indicator [status]="element.status"></cwi-remote-subsystem-status-indicator>
          </td>
        </ng-container>

        <!-- Track db Column -->
        <ng-container matColumnDef="tdbUpdate">
          <th mat-header-cell *matHeaderCellDef class="my-auto header text-center" i18n>Track db update</th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <div class="system-name">
              <span class="sub" *ngIf="checkValidityDate(element.trackdbUpdateTime)">{{ element.trackdbUpdateTime | cwiDate: true: 'DD-MM-YYYY HH:mm:ss'}}</span>
            </div>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
</mat-card>


<!-- Gps template -->
<ng-template #gpsTemplate let-fixed>
  <div class="gps">
    <div style="width: 10px; height: 10px; border-radius: 5px;"
      [style.background]="fixed ? '#5FDCB3' : '#DA5050'"></div>
    <label class="ml-2"> {{ fixed ? 'On' : 'Off' }}</label>
  </div>
</ng-template>

<!-- Devices Template-->
<ng-template #devicesTemplate let-number>
  <div *ngIf="number > 0" class="devices">
    <div style="width: 10px; height: 10px; border-radius: 5px; background: var(--cwi-error);"></div>
    <label class="ml-2" style="color: var(--cwi-error);">{{ number }}</label>
    <label class="ml-2 text-small"> Disconnected </label>
  </div>
</ng-template>

<!-- Warning Template-->
<ng-template #warningTemplate let-warning>
  <ng-container *ngIf="warning">
    <span class="warning text-center p-3">
      <img class="mr-1" src="assets/icons/diagnostic/alert.svg">
      1 warning
    </span>
  </ng-container>
</ng-template>
