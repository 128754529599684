<form [formGroup]="speedModeForm" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h5 class="modal-title">{{ getTitle() }}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div>
        <label>
          <span i18n>Setup list</span>
          <select class="form-control mt-1" formControlName="setup"
          [class.is-valid]="speedModeForm.controls.setup.valid && speedModeForm.controls.setup.touched"
          [class.is-invalid]="speedModeForm.controls.setup.invalid && speedModeForm.controls.setup.touched">
            <option class="dropdown-item" disabled selected value i18n>Select an Option</option>
            <option class="dropdown-item" *ngFor="let set of setups" [value]="set"> {{ set }}</option>
          </select>
        </label>
      </div>
      <div>
        <label>
          <span i18n>Start recording speed (>) [{{sectionConfiguration.unitOfMeasurement}}]</span>
          <input class="form-control mt-1" formControlName="recordingSpeed" type="number" name="recordingSpeed" min="0" step="1" max="600" pattern="^[0-9]+([0-9]{0,0})?$"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          [class.is-valid]="speedModeForm.controls.recordingSpeed.valid && speedModeForm.controls.recordingSpeed.touched"
          [class.is-invalid]="speedModeForm.controls.recordingSpeed.invalid && speedModeForm.controls.recordingSpeed.touched">
        </label>
      </div>
      <div>
        <label>
          <span i18n>Start recording debounce time [s]</span>
          <input class="form-control mt-1" formControlName="recordingDebounce" type="number" name="recordingDebounce" min="1" step="1" max="86400" pattern="^[0-9]+([0-9]{0,0})?$"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          [class.is-valid]="speedModeForm.controls.recordingDebounce.valid && speedModeForm.controls.recordingDebounce.touched"
          [class.is-invalid]="speedModeForm.controls.recordingDebounce.invalid && speedModeForm.controls.recordingDebounce.touched">
        </label>
      </div>
      <div>
        <label>
          <span i18n>Stop recording speed (&leq;) [{{sectionConfiguration.unitOfMeasurement}}]</span>
          <input class="form-control mt-1" formControlName="stopSpeed" type="number" name="stopSpeed" min="0" step="1" max="600" pattern="^[0-9]+([0-9]{0,0})?$"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          [class.is-valid]="speedModeForm.controls.stopSpeed.valid && speedModeForm.controls.stopSpeed.touched"
          [class.is-invalid]="speedModeForm.controls.stopSpeed.invalid && speedModeForm.controls.stopSpeed.touched">
        </label>
      </div>
      <div>
        <label>
          <span i18n>Stop recording debounce time [s]</span>
          <input class="form-control mt-1" formControlName="stopDebounce" type="number" name="stopDebounce" min="1" step="1" max="86400" pattern="^[0-9]+([0-9]{0,0})?$"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          [class.is-valid]="speedModeForm.controls.stopDebounce.valid && speedModeForm.controls.stopDebounce.touched"
          [class.is-invalid]="speedModeForm.controls.stopDebounce.invalid && speedModeForm.controls.stopDebounce.touched">
        </label>
      </div>
      <div>
        <label>
          <span i18n>Speed source</span>
          <select class="form-control mt-1" formControlName="speedSource"
          [class.is-valid]="speedModeForm.controls.speedSource.valid && speedModeForm.controls.speedSource.touched"
          [class.is-invalid]="speedModeForm.controls.speedSource.invalid && speedModeForm.controls.speedSource.touched">
            <option class="dropdown-item" disabled selected value i18n>Select an Option</option>
            <option class="dropdown-item" value = "pos" i18n>Train Positioning System only</option>
            <option class="dropdown-item" value = "pos+sat" i18n>Train Positioning System or satellite</option>
          </select>
        </label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" [disabled]="disableSubmit()" class="btn btn-primary submit" i18n><span class="fas fa-save"></span> Save</button>
    <button type="button" class="btn btn-danger mr-2 cancel" (click)="cancel();" ngbAutofocus i18n>Cancel</button>
  </div>
</form>
