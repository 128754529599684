import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { GroupInfo } from '../models/group-definition';
import { Line } from '../models/line-definition';
import { UserCreationInfo, UserFullInfo } from '../models/user-definition';

export const USER_SERVICE = new InjectionToken<IUserService>('IUserService');
export const ROLE_SERVICE = new InjectionToken<IRoleService>('IRoleService');
export const GROUP_SERVICE = new InjectionToken<IGroupService>('IGroupService');
export const TRACKDATABASE_SERVICE = new InjectionToken<IGroupService>('ITrackDatabase');
export const SETTINGS_CONFIGURATION = new InjectionToken<ISettingsConfiguration>('ISettingsConfiguration');
export const SETTINGS_CONFIGURATION_DEFAULT: ISettingsConfiguration = {
  baseUrl: '/api',
};

export interface ISettingsConfiguration {
  baseUrl: string;
}

export interface IUserService {
  readonly users$: Observable<UserFullInfo[]>;

  reload(): void;
  addUser(body: UserCreationInfo): Observable<void>;
  updatePassword(currentPassword: string, newPassword: string): Observable<void>;
  updateDisplayName(displayName: string): Observable<void>;
  updateEmail(email: string): Observable<void>;
  resetPassword(userName: string, password: string): Observable<void>;
  resetEmail(userName: string, email: string): Observable<void>;
  resetDisplayName(userName: string, displayName: string): Observable<void>;
  deleteUser(userName: string): Observable<void>;
  getApplicationToken(): Observable<string>;
  refreshToken(): Observable<void>;
}

export interface IRoleService {
  readonly roles$: Observable<string[]>;

  addRole(userName: string, role: string): Observable<void>;
  deleteRole(userName: string, role: string): Observable<void>;
}

export interface IGroupService {
  readonly groups$: Observable<GroupInfo[]>;

  reload(): void;
  addGroup(name: string): Observable<void>;
  addRoleToGroup(groupName: string, role: string): Observable<void>;
  addUserToGroup(userName: string, groupName: string): Observable<void>;
  deleteGroup(name: string): Observable<void>;
  deleteRoleFromGroup(groupName: string, role: string): Observable<void>;
  removeGroupFromUser(userName: string, groupName: string): Observable<void>;
}

export interface ITrackDatabaseService {
  readonly lines$: Observable<Line[]>;

  reload(): void;
  getUserTracks(userName: string): Observable<{ [line: string]: string[] }>;
  getGroupTracks(groupName: string): Observable<{ [line: string]: string[] }>;
  addTrackToUser(userName: string, lineCode: string, trackCode: string): Observable<void>;
  removeTrackFromUser(userName: string, lineCode: string, trackCode: string ): Observable<void>;
  addTrackToGroup(groupName: string, lineCode: string, trackCode: string): Observable<void>;
  removeTrackFromGroup(groupName: string, lineCode: string, trackCode: string ): Observable<void>;
}
