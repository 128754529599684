import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { duration, Duration } from "moment";

@Component({
  selector: 'cwi-remote-download-time',
  templateUrl: './download-time.component.html',
  styleUrls: ['./download-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadTimeComponent {

  @Input()
  public time: Duration = duration();

  get days() {
    return this.time.days();
  }

  get hours() {
    return this.time.hours();
  }

  get minutes() {
    return this.time.minutes();
  }
}
