import { AfterViewInit, ChangeDetectorRef, Component, Inject, Injector } from '@angular/core';
import { MAP_OPTIONS, NORTH_EAST, SOUTH_WEST } from '@cwi/components';
import { Line, TrackInfo } from '../models/line-definition';
import L from 'leaflet';
import { MapOptions } from 'leaflet';
import { GROUP_SERVICE, IGroupService, ITrackDatabaseService, IUserService, TRACKDATABASE_SERVICE, USER_SERVICE } from '../services/service-configuration';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { UserFullInfo } from '../models/user-definition';
import { GroupInfo } from '../models/group-definition';
import { startWith } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TrackDatabaseUploadComponent } from 'shared/cwi/remote-controller/lib/trackdatabase-upload/trackdatabase-upload.component';
import { IUploadTrackDatabaseService, UPLOAD_TRACKDATABASE_SERVICE } from 'shared/cwi/remote-controller/lib/service-configuration';
import { UploadtTrackDatabaseService as UploadTrackDatabaseService } from 'shared/cwi/remote-controller/lib/upload-service';
@Component({
    selector: 'cwi-pg-trackdatabase-view',
    templateUrl: './trackdatabase-view.component.html',
    styleUrls: ['./trackdatabase-view.component.scss'],
    providers: [
      {
        provide: UPLOAD_TRACKDATABASE_SERVICE,
        useClass: UploadTrackDatabaseService
      },
    ]
  })
  export class TrackDabataseViewComponent implements AfterViewInit {

    private leafletMap: L.Map;
    
    leafletOptions = {
      center: this.mapOptions.center,
      minZoom: this.mapOptions.minZoom,
      maxZoom: this.mapOptions.maxZoom,
      zoom: this.mapOptions.zoom,
      layers: [ this.mapOptions.layers[0] ],
      maxBoundsViscosity: 1,
      maxBounds: L.latLngBounds(SOUTH_WEST, NORTH_EAST)
    };
    
    loading: boolean = true;
    isError: boolean;
    alertMessage: string;

    trackViewer = new ReplaySubject<TrackInfo[]>(1);
    trackByUserOrGroup$ = new Subject<any>();
    users$: Observable<UserFullInfo[]>;
    groups$: Observable<GroupInfo[]>;
    
    selectedUser: UserFullInfo;
    selectedGroup: GroupInfo;
    selectedTracks: TrackInfo[] = [];
    associatedTracks: TrackInfo[] = [];
    lines: Line[];
    tracksByUser: { [line: string]: string[]; };
    confirmable: boolean;

    constructor(
      @Inject(MAP_OPTIONS)
      private readonly mapOptions: MapOptions,
      @Inject(TRACKDATABASE_SERVICE)
      private readonly trackService: ITrackDatabaseService,
      @Inject(USER_SERVICE)
      private readonly userService: IUserService,
      @Inject(GROUP_SERVICE)
      private readonly groupService: IGroupService,
      @Inject(UPLOAD_TRACKDATABASE_SERVICE)
      private readonly uploadService: IUploadTrackDatabaseService,
      private ref: ChangeDetectorRef,
      private readonly modalService: NgbModal,
      private readonly injector: Injector) {

        combineLatest([trackService.lines$, this.trackByUserOrGroup$.pipe(startWith([]))])
          .subscribe(
          ([lines, tracksByUser]) => {
            this.lines = lines;
            this.tracksByUser = tracksByUser;
            this.loading = false;
            this.trackViewer.next(this.mapTracks(this.lines, this.tracksByUser));
          },
          (err) => {
            this.loading = false;
          }
        );
        this.users$ = this.userService.users$;
        this.groups$ = this.groupService.groups$;

        this.uploadService.getConfirmable().subscribe(
          res => {
            this.confirmable = res;
          }
        );
    }
  
    ngAfterViewInit(): void {
      if (this.lines) {
        this.trackViewer.next(this.mapTracks(this.lines, this.tracksByUser));
      }
    }

    mapTracks(lines: Line[], userTracks: { [line: string]: string[] }) {
      const tracks: TrackInfo[] = [];
      for (const line of lines) {
        const utTracks = userTracks[line.code];
        let mapTrack: TrackInfo;
        
        for (const track of line.tracks) {
          if (utTracks && utTracks.length > 0) {
            const hasUser = utTracks.some(tr => tr === track.code);
            mapTrack = {
              ...track,
              lineCode: line.code,
              hasUser: hasUser
            };
            if (hasUser) {
              this.associatedTracks.push(mapTrack);
            }
          } else {
            mapTrack = {
              ...track,
              lineCode: line.code,
              hasUser: false
            };
          }
          tracks.push(mapTrack);
        }
      }
      return tracks;
    }

    onMapReady(leafletMap: L.Map) {
      this.leafletMap = leafletMap;
      if (leafletMap) {
        this.leafletMap.invalidateSize();
        this.resizeMap();
      }
    }

    drawLines(lines: Line[]) {
      for (const line of lines) {
        for (const track of line.tracks) {
          const polyline = L.polyline(track.points, { smoothFactor: 1 })
          this.leafletMap.addLayer(polyline);
        }
      }
    }

    resizeMap() {
      if (this.leafletMap) {
        setTimeout(() => {
          this.leafletMap.invalidateSize();
        }, 100);
      }
    }

    trackClicked(track: TrackInfo) {
      this.selectedTracks.push(track);
      this.ref.detectChanges();
    }

    onUserChange(user: UserFullInfo) {
      this.selectedGroup = null;
      this.selectedUser = user;
      this.loading = true;
      this.trackService.getUserTracks(user.userName)
        .subscribe(res => {
          this.associatedTracks = [];
          this.selectedTracks = [];
          this.trackByUserOrGroup$.next(res);
        }
      );
    }

    onGroupChange(group: GroupInfo) {
      this.selectedUser = null;
      this.selectedGroup = group;
      this.loading = true;
      this.trackService.getUserTracks(group.groupName)
        .subscribe(res => {
          this.associatedTracks = [];
          this.selectedTracks = [];
          this.trackByUserOrGroup$.next(res);
        }
      );
    }

    addTrackToUser() {
      this.isError = false;
      for (const track of this.selectedTracks) {
        if (!this.associatedTracks.some(t => t.code === track.code)) {
          this.trackService.addTrackToUser(this.selectedUser.userName, track.lineCode, track.code).subscribe(
            res => {
              this.associatedTracks.push(track);
            },
            error => {
              this.isError = true;
              this.alertMessage = $localize `Could not add track ${track.name} to user ${this.selectedUser.displayName}.`;
            }
          )
        }
      }
      if (!this.isError) {
        this.alertMessage = $localize `All tracks have been added correctly to user ${this.selectedUser.displayName}.`;
        this.selectedTracks = [];
      }
    }

    removeTrackFromUser() {
      this.isError = false;
      for (const track of this.selectedTracks) {
        if (this.associatedTracks.some(t => t.code === track.code)) {
          this.trackService.removeTrackFromUser(this.selectedUser.userName, track.lineCode, track.code).subscribe(
            res => {},
            error => {
              this.isError = true;
              this.alertMessage = $localize `Could not remove track ${track.name} from user ${this.selectedUser.displayName}.`;
            }
          )
        }
      }
      if (!this.isError) {
        this.alertMessage = $localize `All tracks have been removed correctly from user ${this.selectedUser.displayName}.`;
        this.selectedTracks = [];
      }
    }

    addTrackToGroup() {
      this.isError = false;
      for (const track of this.selectedTracks) {
        if (!this.associatedTracks.some(t => t.code === track.code)) {
          this.trackService.addTrackToGroup(this.selectedGroup.groupName, track.lineCode, track.code).subscribe(
            res => {
              this.associatedTracks.push(track);
            },
            error => {
              this.isError = true;
              this.alertMessage = $localize `Could not add track ${track.name} to group ${this.selectedGroup.groupName}.`;
            }
          )
        }
      }
      
      if (!this.isError) {
        this.alertMessage = $localize `All tracks have been added correctly to group ${this.selectedGroup.groupName}.`;
        this.selectedTracks = [];
      }
    }

    removeTrackFromGroup() {
      this.isError = false;
      for (const track of this.selectedTracks) {
        if (this.associatedTracks.some(t => t.code === track.code)) {
          this.trackService.removeTrackFromGroup(this.selectedGroup.groupName, track.lineCode, track.code).subscribe(
            res => {},
            error => {
              this.isError = true;
              this.alertMessage = $localize `Could not remove track ${track.name} from group ${this.selectedGroup.groupName}.`;
            }
          )
        }
      }
      if (!this.isError) {
        this.alertMessage = $localize `All tracks have been removed correctly from group ${this.selectedGroup.groupName}.`;
        this.selectedTracks = [];
      }
    }

    openTrackDatabase() {
      const modalRef = this.modalService.open(TrackDatabaseUploadComponent, { size: 'm', backdrop: 'static', keyboard: false, injector: this.injector });
      const componentInstance: TrackDatabaseUploadComponent = modalRef.componentInstance;
      componentInstance.closed.subscribe(() => {
        modalRef.close();
      });
      componentInstance.completed.subscribe(() => {
        this.confirmable = true;
        setTimeout(() => {
          modalRef.close();
        }, 1500);
      });
    }

    confirm(event: any) {
      event.target.disabled = true;
      
      this.alertMessage = null;
      this.isError = false;
      try {
        this.uploadService.confirm().subscribe(
          res => {
            this.alertMessage = $localize `Last uploaded track database has been processed correctly.`;
            this.trackService.reload();
            this.confirmable = false;
          },
          error => {
            this.isError = true;
            if (error.status === 500) {
              this.alertMessage = $localize `There is no available track database to be used.`;
            }
          })
          event.target.disabled = false;
        } finally {
          /* nothing to do */
      }
    }

    closeAlert() {
      this.alertMessage = null;
    }
}

