<div class="alert alert-dismissible fade show text-center" role="alert" *ngIf="alertMessage"
    [ngClass]="{'alert-danger': isError, 'alert-success': !isError }">
    {{ alertMessage }}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeAlert()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="map-container" [ngClass]="{ 'loading': loading }" >
	<div class="track"
		leaflet
		(cwiSizeChanged)="resizeMap()"
		[leafletOptions]="leafletOptions"
		(leafletMapReady)="onMapReady($event)"
		[cwiTrackDatabaseViewer]="trackViewer"
		(trackSelected)="trackClicked($event)">
	</div>
	<!-- RIMOSSA PER COLLAUDO -->
	<!-- <div class="selection-container">
		<ng-container *ngIf="users$ | async; let users">
			<div class="user-container">
				<label>
					<span i18n style="font-weight:bold">Select a user:</span>
					<select class="form-control" [ngModel]="selectedUser" (ngModelChange)="onUserChange($event)"> 
					  <option hidden value="" disabled selected i18n>Select a user </option>
					  <option *ngFor="let user of users" [ngValue]="user">{{ user.displayName }}</option>
					</select>
				</label>
				<button type="button"
					class="btn btn-primary"
					(click)="addTrackToUser()">Add
				</button>
				<button type="button"
					class="btn btn-danger mt-2"
					(click)="removeTrackFromUser()">Delete
				</button>
			</div>
		</ng-container>
		<ng-container *ngIf="groups$ | async; let groups">
			<div class="user-container">
				<label>
					<span i18n style="font-weight:bold">Select a group:</span>
					<select class="form-control" [ngModel]="selectedGroup" (ngModelChange)="onGroupChange($event)"> 
					  <option hidden value="" disabled selected i18n>Select a group </option>
					  <option *ngFor="let group of groups" [ngValue]="group"> {{ group.groupName }}</option>
					</select>
				</label>
				<button type="button"
					class="btn btn-primary"
					(click)="addTrackToGroup()">Add
				</button>
				<button type="button"
					class="btn btn-danger mt-2"
					(click)="removeTrackFromGroup()">Delete
				</button>
			</div>
		</ng-container>
	</div> -->
</div>
<div style="display: flex; align-self: flex-end;">
	<button class="nav-link btn btn-link" (click)="openTrackDatabase()" i18n><span class="fas fa-route"></span> Add new Track Database</button>
	<button *ngIf="confirmable" class="nav-link btn btn-link" i18n
		triggers=manual
		container=body 
		autoClose=outside
		title="Delete"
		popoverTitle="Are you sure you want to upload the last uploaded track database?" 
		[ngbPopover]="confirmTrack" 
		#confirmPopover="ngbPopover"
		ngbAutofocus
		(click)="confirm($event)">
		<span class="fas fa-server"></span> Confirm
	</button>
</div>
<ng-container *ngTemplateOutlet="spinner"></ng-container>

<ng-template #retry let-reload=reload>
	<div style="position: relative; top: 40%;" class="text-center" i18n>
	  <span class="fas fa-unlink mr-2"></span>Something went wrong <button class="btn btn-link" (click)="reload()">Retry.</button>
	</div>
</ng-template>
  

<ng-template #spinner>
	<div *ngIf="loading" class="spinner">
        <label i18n>Loading track database. Please wait...</label>
		<cwi-spinner></cwi-spinner>
	</div>
</ng-template>

<ng-template let-close=close #confirmTrack>
    <button class="btn btn-sm btn-outline-danger"
      (click)="close(true)" i18n>Confirm
    </button>
</ng-template>