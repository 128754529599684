<nav aria-label="breadcrumb">
  <ol class="breadcrumb" *ngIf="train && !system && !plan">
    <li class="breadcrumb-item"><a [routerLink]="['/remote-controller']">Home</a></li>
    <li class="breadcrumb-item active">{{ train }}</li>
  </ol>
  <ol class="breadcrumb" *ngIf="train && system && !plan">
    <li class="breadcrumb-item"><a [routerLink]="['/remote-controller/']">Home</a></li>
    <li class="breadcrumb-item"><a [routerLink]="['/remote-controller/', train]">{{ train }}</a></li>
    <li class="breadcrumb-item active">{{ system }}</li>
  </ol>
  <ol class="breadcrumb" *ngIf="train && system && plan">
    <li class="breadcrumb-item"><a [routerLink]="['/remote-controller/']">Home</a></li>
    <li class="breadcrumb-item"><a [routerLink]="['/remote-controller/', train]">{{ train }}</a></li>
    <li class="breadcrumb-item"><a [routerLink]="['/remote-controller/', train, system]">{{ system }}</a></li>
    <li class="breadcrumb-item active">{{ plan }}</li>
  </ol>
  </nav>