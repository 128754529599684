import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { OrderedMap } from "immutable";
import { DeviceDiagnostic, ResponderMobileDiagnostic, ResponderIsdnDiagnostic, ResponderPsDiagnostic, SubSystemDiagnostic, SystemDiagnostic } from "../diagnostic";
import { SubSystemFullStatus } from "../subsystem-status/subsystem-status.component";

function mapSubSystems(subSystems: SubSystemDiagnostic[]): SubSystemFullStatus[] {
  const subSystemsInfo: SubSystemFullStatus[] = [];
  for (const subSystem of subSystems) {
    subSystemsInfo.push({
      ...subSystem,
      devices: mapDevices(subSystem.devices)
    })
  }
  return subSystemsInfo;
}

function mapDevices(devices: DeviceDiagnostic[]): OrderedMap<string, DeviceDiagnostic[]> {
  return OrderedMap<string, DeviceDiagnostic[]>().withMutations(element => {
    for (const device of devices) {
      switch (device.type) {
        case "R&D TSMx Scanner":
        case "Track Chaser EE":
          element.set(device.name, [device]);
          break;
        case "Visual Scripting":
          {
            const name = device.name.slice(3, device.name.length); // Rimuovo VS_ dal nome;
            if (element.has(name)) {
              element.update(name, arr => [...arr, device]);
            } else {
              element.set(name, [device]);
            }
          }
          break;
        default:
          {
            if (element.has(device.name)) {
              element.update(device.name, arr => [device, ...arr]);
            } else {
              element.set(device.name, [device]);
            }
          }
          break
      }
    }
  })
}

export interface SystemTabInfo {
  subSystems: SubSystemFullStatus[],
  respondersMobile: ResponderMobileDiagnostic[],
  respondersIsdn: ResponderIsdnDiagnostic[],
  respondersPs: ResponderPsDiagnostic[]
}

@Component({
  selector: 'cwi-remote-system-tabs',
  templateUrl: './system-tabs.component.html',
  styleUrls: ['./system-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemTabsComponent {

  mainSystemContent: SystemTabInfo;
  mirrorSystemContent: SystemTabInfo;

  @Input()
  public set systems(value: SystemDiagnostic[]){
    if (value) {
      for (const system of value) {
        const map = {
          respondersMobile: system.respondersMobile,
          respondersIsdn: system.respondersIsdn,
          respondersPs: system.respondersPs,
          subSystems: mapSubSystems(system.subSystems)
        };
        if (system.isMaster) {
          this.mainSystemContent = map;
        } else {
          this.mirrorSystemContent = map;
        }
      }
    }
  }
}
