import { Component, Inject, TemplateRef, Input} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LOGIN_CONFIGURATION, ILoginConfiguration } from './login.configuration';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { LOGIN_PAGE_CONFIGURATION, LoginPageConfiguration } from '@cwi/pages';


@Component({
  selector: 'cwi-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  @Input()
  public domainTemplate: TemplateRef<HTMLElement>;

  @Input()
  public passwordTemplate: TemplateRef<HTMLElement>;

  @Input()
  public usernameTemplate: TemplateRef<HTMLElement>;

  loading = false;
  showPassword = false;
  loginForm: FormGroup;
  loginError: boolean;
  errorMessage: string;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    @Inject(LOGIN_CONFIGURATION)
    configuration: ILoginConfiguration,
    @Inject(LOGIN_PAGE_CONFIGURATION)
    readonly pageConfiguration: LoginPageConfiguration,
  ) {
    this.loginForm = new FormGroup({
      username: new FormControl(null, configuration.usernameValidators),
      password: new FormControl(null, configuration.passwordValidators)
    });
  }

  togglePassword(): void {
    this.showPassword = !this.showPassword;
  }

  async login() {
    if (!this.loading) {
      this.loginError = null;
      this.loading = true;
      try {

        await this.authService.login(
          this.loginForm.value.username,
          this.loginForm.value.password
        );
        this.router.navigateByUrl('/');
      } catch (error) {
        this.loginError = true;
        if (error.status === 400) {
          this.errorMessage = $localize `Could not login. Invalid user.`;
        } else if (error.status === 403) {
          this.errorMessage = $localize `Could not login. Invalid password.`;
        } else {
          this.errorMessage = error.statusText;
        }
      } finally {
        this.loading = false;
      }
    }
  }
}
