import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { BinaryViewComponent } from './binary-view/binary-view.component';
import { ToggleComponent } from './toggle/toggle.component';
import { SvgFixedSizeVirtualScrollDirective } from './svg-fixed-size-virtual-scroll.directive';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { StatusbarComponent } from './statusbar/statusbar.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ArrowDirective } from './arrow.directive';
import { LoginComponent } from './login/login.component';
import {
  LOGIN_PASSWORD_VALIDATORS, DEFAULT_LOGIN_PASSWORD_VALIDATORS, LOGIN_USERNAME_VALIDATORS,
  DEFAULT_LOGIN_USERNAME_VALIDATORS, LOGIN_URL, DEFAULT_LOGIN_ACTION, LOGIN_CONFIGURATION,
  DefaultLoginConfiguration,
  DEFAULT_USER_INFO,
  USER_INFO_URL,
  DEFAULT_LOGOUT_ACTION,
  LOGOUT_URL,
  DEFAULT_LOGIN_PAGE_CONFIGURATION} from './login/login.configuration';
import { BigFixedSizeVirtualScrollDirective } from './big-fixed-size-virtual-scroll.directive';
import { SizeChangedDirective } from './size-changed.directive';
import { SearchTreeComponent } from './search-tree/search-tree.component';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ComboChartsModule } from './combo-chart/combo-chart.module';
import { CwiCommonModule } from './common/common.module';
import { DatePickerComponent } from './date-picker/date-picker.component';

import { NgbDatepickerModule, NgbTimepickerModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

import { ControlGroupComponent } from './control-group/control-group.component';
import { MapControlDirective } from './map-control.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GeometryDrawerComponent } from './geometry-drawer/geometry-drawer.component';
import { GeometryViewerDirective } from './geometry-viewer.directive';
import { UploadDownloadBarComponent } from './upload-download-bar/upload-download-bar.component';
import { StickyDirective } from './sticky.directive';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { AuthenticationGuard } from './authentication.guard';
import { AuthenticationService } from './authentication.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingComponent } from './loading/loading.component';
import { InputNumberDirective, MinMaxInputNumberDirective } from './input-number.directive';
import { MaterialModule } from './material-module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { TrackViewerDirective } from './track-viewer.directive';
import { GaugeComponent } from '@cwi/components';
import { LOGIN_PAGE_CONFIGURATION } from '@cwi/pages';
import { DEFAULT_NAVBAR_CONFIGURATION, NAVBAR_CONFIGURATION } from './navbar/navbar.configuration';

@NgModule({
  declarations: [
    BinaryViewComponent,
    ToggleComponent,
    SvgFixedSizeVirtualScrollDirective,
    SidebarComponent,
    NavbarComponent,
    StatusbarComponent,
    SpinnerComponent,
    ArrowDirective,
    LoginComponent,
    BigFixedSizeVirtualScrollDirective,
    SizeChangedDirective,
    SearchTreeComponent,
    DatePickerComponent,
    ControlGroupComponent,
    MapControlDirective,
    GeometryDrawerComponent,
    GeometryViewerDirective,
    TrackViewerDirective,
    UploadDownloadBarComponent,
    StickyDirective,
    MultiselectComponent,
    LoadingComponent,
    InputNumberDirective,
    MinMaxInputNumberDirective,
    UserProfileComponent,
    DeleteConfirmationComponent,
    LanguageSelectorComponent,
    GaugeComponent,
  ],
  imports: [
    CommonModule,
    CwiCommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    NgxChartsModule,
    ComboChartsModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbTypeaheadModule,
    DragDropModule,
    MaterialModule
  ],
  exports: [
    BinaryViewComponent,
    ToggleComponent,
    SvgFixedSizeVirtualScrollDirective,
    SidebarComponent,
    NavbarComponent,
    StatusbarComponent,
    SpinnerComponent,
    ArrowDirective,
    LoginComponent,
    BigFixedSizeVirtualScrollDirective,
    SizeChangedDirective,
    SearchTreeComponent,
    ComboChartsModule,
    CwiCommonModule,
    DatePickerComponent,
    ControlGroupComponent,
    MapControlDirective,
    GeometryDrawerComponent,
    TrackViewerDirective,
    UploadDownloadBarComponent,
    StickyDirective,
    MultiselectComponent,
    GeometryViewerDirective,
    LoadingComponent,
    InputNumberDirective,
    MinMaxInputNumberDirective,
    UserProfileComponent,
    MaterialModule,
    DeleteConfirmationComponent,
    LanguageSelectorComponent,
    GaugeComponent,
  ],
  providers: [
    AuthenticationGuard,
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: AuthenticationService,
      multi: true
    },
    {
      provide: LOGIN_PASSWORD_VALIDATORS,
      useValue: DEFAULT_LOGIN_PASSWORD_VALIDATORS
    },
    {
      provide: LOGIN_USERNAME_VALIDATORS,
      useValue: DEFAULT_LOGIN_USERNAME_VALIDATORS
    },
    {
      provide: LOGIN_URL,
      useValue: DEFAULT_LOGIN_ACTION
    },
    {
      provide: LOGOUT_URL,
      useValue: DEFAULT_LOGOUT_ACTION
    },
    {
      provide: LOGIN_CONFIGURATION,
      useClass: DefaultLoginConfiguration
    },
    {
      provide: LOGIN_PAGE_CONFIGURATION,
      useValue: DEFAULT_LOGIN_PAGE_CONFIGURATION
    },
    {
      provide: NAVBAR_CONFIGURATION,
      useValue: DEFAULT_NAVBAR_CONFIGURATION
    },
    {
      provide: USER_INFO_URL,
      useValue: DEFAULT_USER_INFO
    }
  ]
})
export class ComponentsModule { }
