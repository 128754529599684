import { Component, EventEmitter, Inject, Input, Output, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IUserCreationConfiguration, USER_CREATION_CONFIGURATION } from '../../../user-creation.configuration';

export interface PasswordChangeRequest {
  currentPassword: string;
  newPassword: string;
}

@Component({
    selector: 'cwi-pg-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    providers: [],
  })
  export class ChangePasswordComponent {

    @Input()
    public passwordTemplate: TemplateRef<HTMLElement>;

    @Input()
    public set updatePasswordCompleted(value: boolean) {
      if (value) {
        this.changePasswordForm.reset();
      }
    }

    @Output()
    public passwordUpdated = new EventEmitter<PasswordChangeRequest>();

    changePasswordForm: FormGroup;

    constructor(
      @Inject(USER_CREATION_CONFIGURATION)
      configuration: IUserCreationConfiguration
    ) {
      this.changePasswordForm = new FormGroup({
        currentPassword: new FormControl(null, Validators.required),
        newPassword: new FormControl(null, configuration.passwordValidators),
        confirmPassword: new FormControl(null, configuration.passwordValidators),
      }, { validators: [ this.checkPasswords ] });
    }

    checkPasswords(formGroup: FormGroup) {
      const newPassword = formGroup.get('newPassword').value;
      const confirmPassword = formGroup.get('confirmPassword').value;
      return newPassword === confirmPassword ? null : { notSamePassword: true };
    }

    onSubmit() {
      if (this.changePasswordForm.valid) {
        const currentPassword = this.changePasswordForm.get('currentPassword').value;
        const newPassword = this.changePasswordForm.get('newPassword').value;

        this.passwordUpdated.next({
          currentPassword,
          newPassword
        });
      }
    }
}

