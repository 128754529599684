import { Component, Inject } from '@angular/core';
import { AcquisitionInfo, SubSystemBaseInfo} from '@cwi/remote-controller';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, interval, of } from 'rxjs';
import { catchError, concatMap, map, scan, switchMap, timeout } from 'rxjs/operators';
import { DeviceStatus, GeneralDiagnostic, ResponderIsdnDiagnostic, ResponderMobileDiagnostic, ResponderPsDiagnostic, SystemDiagnostic } from 'shared/cwi/remote-controller/lib/diagnostic-module/diagnostic';
import { DIAGNOSTIC_SERVICE, HttpDiagnosticService, IDiagnosticService } from 'shared/cwi/remote-controller/lib/diagnostic-module/diagnostic.service';
import { duration, Duration } from 'moment';
import { HttpPowerService, POWER_SERVICE } from 'shared/cwi/remote-controller/lib/diagnostic-module/power.service';

const UPLOAD_INFO_INTERVAL_SECONDS = 60;

function mapAcquisitions(value: SystemDiagnostic[]): AcquisitionInfo[] {
  const acquisitionsInfo: AcquisitionInfo[] = [];
  for (const system of value) {
    for (const acquisition of system.acquisitions) {
      acquisitionsInfo.push({
        ...acquisition,
        isMaster: system.isMaster,
      })
    } 
  }
  return acquisitionsInfo;
}

function mapMobileResponders(value: SystemDiagnostic[]): ResponderMobileDiagnostic[] {
  const respondersInfo: ResponderMobileDiagnostic[] = [];
  for (const system of value) {
    for (const responderMobile of system.respondersMobile) {
      respondersInfo.push({
        ...responderMobile,
      })
    }
  }
  return respondersInfo;
}

function mapIsdnResponders(value: SystemDiagnostic[]): ResponderIsdnDiagnostic[] {
  const respondersInfo: ResponderIsdnDiagnostic[] = [];
  for (const system of value) {
    for (const responderIsdn of system.respondersIsdn) {
      respondersInfo.push({
        ...responderIsdn,
      })
    }
  }
  return respondersInfo;
}

function mapPsResponders(value: SystemDiagnostic[]): ResponderPsDiagnostic[] {
  const respondersInfo: ResponderPsDiagnostic[] = [];
  for (const system of value) {
    for (const responderPs of system.respondersPs) {
      respondersInfo.push({
        ...responderPs,
      })
    }
  }
  return respondersInfo;
}

function mapSubSystems(systems: SystemDiagnostic[]): SubSystemBaseInfo[] {
  const subSystemsInfo: SubSystemBaseInfo[] = [];
  for (const system of systems) {
    for (const subSystem of system.subSystems) {
      let devicesDisconnected = 0;
      for (const device of subSystem.devices) {
        if (device.type !== 'Marker' && device.status === DeviceStatus.Disconnected) {
          devicesDisconnected++;
        }
      }
      subSystemsInfo.push({
        ...subSystem,
        devicesDisconnected,
        isMaster: system.isMaster
      })
    } 
  }
  return subSystemsInfo;
}

function mapElectricalSystems(systems: SystemDiagnostic[]) {
}

type MappedModel = {
  trainName?: string;
  uploadSpeeds?: number[];
  uploadSpeed?: number;
  uploadDuration?: Duration;
  acquisitions?: AcquisitionInfo[];
  respondersMobile?: ResponderMobileDiagnostic[];
  responderIsdn?: ResponderIsdnDiagnostic[];
  respondersPs?: ResponderPsDiagnostic[];
  subSystems?: SubSystemBaseInfo[];
  error?: true;
}

type Model = Partial<GeneralDiagnostic> & MappedModel;

@Component({
  selector: 'cwi-pg-diagnosticview',
  templateUrl: './remote-controller-diagnosticview.component.html',
  styleUrls: ['./remote-controller-diagnosticview.component.scss'],
  providers: [
    {
      provide: DIAGNOSTIC_SERVICE,
      useClass: HttpDiagnosticService
    },
    {
      provide: POWER_SERVICE,
      useClass: HttpPowerService
    }
  ]
})
export class RemoteControllerDiagnosticViewComponent {

  diagnostic$: Observable<Model>;
  
  constructor(
    @Inject(DIAGNOSTIC_SERVICE)
    private readonly diagnosticService: IDiagnosticService,
    activatedRoute: ActivatedRoute,
    private router: Router
    ) {
      this.diagnostic$ = activatedRoute.params.pipe(
        switchMap(params => interval(1000).pipe(
          concatMap(() => {
            return this.diagnosticService.getTrain(params.trainName, UPLOAD_INFO_INTERVAL_SECONDS)
              .pipe(
                timeout(1000),
                map(m => ({...m, trainName: params.trainName})),
                catchError(error => of({ error }))
              );
          }))
        ),
        scan<GeneralDiagnostic | { error: any; }, Model>((prev, diagnostic) => { 
          if ('error' in diagnostic)
          {
            return {...prev, error: true };
          }
          else
          {
            return {
              ...diagnostic,
              uploadSpeeds: [...prev.uploadSpeeds, this.getUploadSpeed(diagnostic.uploadedSize)].slice(-100),
              uploadSpeed: this.getUploadSpeed(diagnostic.uploadedSize),
              uploadDuration: this.getUploadTime(diagnostic.uploadedSize, diagnostic.leftSize),
              acquisitions: mapAcquisitions(diagnostic.systems),
              respondersMobile: mapMobileResponders(diagnostic.systems),
              respondersIsdn: mapIsdnResponders(diagnostic.systems),
              respondersPs: mapPsResponders(diagnostic.systems),
              subSystems: mapSubSystems(diagnostic.systems),
              electricalSystems: mapElectricalSystems(diagnostic.systems)
            };  
          }
        }, { uploadSpeeds: new Array(100).fill(0) })
      );
  }


  getUploadSpeed(uploadedSize: number): number {
    return uploadedSize / UPLOAD_INFO_INTERVAL_SECONDS; 
  }

  getUploadTime(uploadedSize: number, leftSize: number): Duration {
    const uploadSpeed = this.getUploadSpeed(uploadedSize);
    if (uploadSpeed != 0) {
      return duration(Math.trunc((leftSize / uploadSpeed + 59) / 60), 'minutes');
    }
  }

  goBack() {
    this.router.navigate(['/remote-controller']);
  }
}

