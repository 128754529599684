<div class="systems-container" *ngIf="isVisible">
  <div class=list-panel>
    <h6 class="font-weight-bold mt-2 text-muted text-center title" i18n>Systems</h6>
    <div class=list>
      <cwi-remote-system-status *ngFor="let system of systems; trackBy: systemTrackBy;"
        [systemStatus]=system
        (systemSelected)="systemClick(system)">
      </cwi-remote-system-status>
    </div>
  </div>
</div>
