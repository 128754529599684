import { SETTINGS_CONFIGURATION, IUserService, ISettingsConfiguration } from './service-configuration';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserCreationInfo, UserFullInfo } from '../models/user-definition';
import { shareReplay } from 'rxjs/operators';
import { reload } from '@cwi/rx';

const jsonContentOptions = { headers: new HttpHeaders({'Content-Type': 'application/json' }) };

@Injectable()
export class UserService implements IUserService {

  private readonly baseUrl: string;
  private reloadSubject: Subject<any> = new Subject<any>();
  public users$: Observable<UserFullInfo[]>;

  constructor(
    private readonly $http: HttpClient,
    @Inject(SETTINGS_CONFIGURATION)
    configuration: ISettingsConfiguration
  ) {
    this.baseUrl = configuration.baseUrl;
    this.users$ = this.$http.get<UserFullInfo[]>(`${this.baseUrl}/users`).pipe(
      reload(this.reloadSubject),
      shareReplay(1)
    );
  }

  reload() {
    this.reloadSubject.next();
  }

  addUser(body: UserCreationInfo): Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/users`, body);
  }

  resetDisplayName(userName: string, displayName: string): Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/user/${encodeURI(userName)}/displayName`,
      encodeURI(displayName), jsonContentOptions);
  }

  resetEmail(userName: string, email: string): Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/user/${encodeURI(userName)}/email`, encodeURI(email), jsonContentOptions);
  }

  resetPassword(userName: string, password: string): Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/user/${encodeURI(userName)}/newPassword`, encodeURI(password), jsonContentOptions);
  }

  updatePassword(currentPassword: string, newPassword: string): Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/password`, { currentPassword, newPassword });
  }

  updateDisplayName(displayName: string): Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/displayName`, JSON.stringify(displayName), jsonContentOptions);
  }

  updateEmail(email: string): Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/email`, JSON.stringify(email), jsonContentOptions);
  }

  deleteUser(userName: string): Observable<void> {
    return this.$http.delete<any>(`${this.baseUrl}/user/${encodeURI(userName)}`);
  }

  getApplicationToken(): Observable<string> {
    return this.$http.get<string>(`${this.baseUrl}/application-token`);
  }

  refreshToken(): Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/application-token`, {});
  }
}
