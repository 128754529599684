import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'cwi-delete-package-modal-component',
  templateUrl: './delete-package-modal.component.html',
  styleUrls: [],
  providers: [],
})
export class DeletePackageModalComponent {
  constructor(public activeModal: NgbActiveModal) {}
}
