<div class="upload-download-container">
    <i class="far fa-file-alt"
        [class.complete]="progress===100"
        [class.canceled]="canceled"
    ></i>
    <div class="ml-3" style="width: 100%;">
        <p [class.complete]="progress===100" [class.canceled]="canceled">
            <span> {{isDownload ? 'Download' : 'Upload'}}:</span>
            {{fileName}} <span class="upload-file-weight">{{fileSize | cwiFileSize}}</span>
        </p>
        <div *ngIf="(progress > 0 && progress !== 100) && !canceled" class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                aria-valuemin="0" aria-valuemax="100"
                [style.width.%]="progress"
                [attr.aria-valuenow]="progress">
            </div>
        </div>
    </div>
    <button *ngIf="(progress > 0 && progress !== 100) && !canceled"
        class="ml-3 btn bg-transparent fas fa-times-circle buttonInteraction"
        (click)="cancelClick();">
    </button>
</div>
