import { Component, OnDestroy, InjectionToken, Inject, Output, EventEmitter, Injectable, Optional, Host, Self } from '@angular/core';
import { LeafletDirective } from '@asymmetrik/ngx-leaflet';
import { Subscription, Observable, fromEvent} from 'rxjs';
import { map, take, share, switchMap, finalize, startWith, filter, distinctUntilChanged } from 'rxjs/operators';
import * as L from 'leaflet';
import { GeometryDefinition, RectangleDefinition, CircleDefinition, PolygonDefinition } from '../geometry-definition';
import { booleanDisjoint, lineString, distance } from '@turf/turf';


export interface GeometryValidatorFunctor {
  validate(geometry: GeometryDefinition): boolean;
}

export interface GeometryFactory {
  buttonClasses: string;
  title: string;
  createGeometry(leafletMap: L.Map): Observable<GeometryDefinition>;
}

export const GEOMETRY_FACTORY = new InjectionToken<GeometryFactory[]>('GEOMETRY_FACTORY');
export const GEOMETRY_VALIDATOR_FUNCTOR = new InjectionToken<GeometryValidatorFunctor[]>('GEOMETRY_VALIDATOR_FUNCTOR');
export const GEOMETRY_DRAWING_STEP = new InjectionToken<GeometryDrawingStep[]>('GEOMETRY_DRAWING_STEP');

export class PolygonValidator implements GeometryValidatorFunctor {
  // Funzione di validazione per auto intersezione
  validate(geometry: GeometryDefinition): boolean {
    // TODO FIXME Nel caso di edit geometrie
    const geo = geometry as any;
    if (geo.id && geo.id !== 0) {
      return true;
    } else {
      if (geometry.shape === 'polygon') {
        // Prendiamo l'ultimo segmento del poligono e vediamo se interseca con uno dei precedenti
        if (geometry.points.length > 3) {
          const newline = lineString([
            [ geometry.points[geometry.points.length - 2].lng, geometry.points[geometry.points.length - 2].lat ],
            [ geometry.points[geometry.points.length - 1].lng, geometry.points[geometry.points.length - 1].lat ]
          ]);

          for (let i = 0; i < geometry.points.length - 3; i++) {
            const line = lineString([
              [ geometry.points[i].lng, geometry.points[i].lat ],
              [ geometry.points[i + 1].lng, geometry.points[i + 1].lat ]
            ]);

            if (!booleanDisjoint(newline, line)) {
              return false;
            }
          }
        }
      }
      return true;
    }
  }
}

@Injectable()
export class RectangleFactory implements GeometryFactory {

  public readonly buttonClasses = 'far fa-square';

  public readonly title = 'Draw a rectangle';

  private static createBounds(a: L.LatLng, b: L.LatLng): L.LatLngBoundsLiteral {
    return [
      [ Math.min(a.lat, b.lat), Math.min(a.lng, b.lng) ],
      [ Math.max(a.lat, b.lat), Math.max(a.lng, b.lng) ]
    ];
  }

  private static drawRectangle(
    leafletMap: L.Map,
    validator: GeometryValidatorFunctor,
    firstPosition: L.LatLng,
    commitEvent: Observable<any>
  ) {
    const mousemove = fromEvent<L.LeafletMouseEvent>(leafletMap, 'mousemove').pipe(
      map(mouseEvent => {
        return mouseEvent.latlng;
      })
    );

    return new Observable<GeometryDefinition>(observer => {
      let valid = false;
      const unsubscribe = mousemove.subscribe(secondPosition => {
        const bounds = RectangleFactory.createBounds(firstPosition, secondPosition);
        const geometry: RectangleDefinition = {
          shape: 'rectangle',
          boundaries: bounds
        };

        valid = validator.validate(geometry);
        geometry.notValid = !valid;
        observer.next(geometry);
      });

      unsubscribe.add(
        commitEvent.subscribe(() => {
          if (valid) {
            observer.complete();
          }
        })
      );

      unsubscribe.add(fromEvent<L.LeafletKeyboardEvent>(leafletMap, 'keydown').subscribe(keydown => {
        if (keydown.originalEvent.key === 'Escape') {
          observer.error('Esc pressed');
        }
      }));

      return unsubscribe;
    });
  }

  constructor(
    @Self()
    private readonly validator: GeometryValidator
  ) {}

  createGeometry(leafletMap: L.Map): Observable<GeometryDefinition> {
    const click = fromEvent<MouseEvent>(leafletMap.getContainer(), 'click').pipe(
      map(clickEvent => leafletMap.mouseEventToLatLng(clickEvent)),
      share()
    );

    return click.pipe(
      take(1),
      switchMap(firstPosition => RectangleFactory.drawRectangle(leafletMap, this.validator, firstPosition, click)),
    );
  }
}

@Injectable()
export class CircleFactory implements GeometryFactory {

  public readonly buttonClasses = 'far fa-circle';

  public readonly title = 'Draw a circle';

  private static calculateRadius(a: L.LatLng, b: L.LatLng): number {
    return distance([ a.lng, a.lat ], [b.lng, b.lat], {units: 'meters'});
  }

  private static drawCircle(leafletMap: L.Map, validator: GeometryValidatorFunctor, firstPosition: L.LatLng, commitEvent: Observable<any>) {
    const mousemove = fromEvent<L.LeafletMouseEvent>(leafletMap, 'mousemove').pipe(
      map(mouseEvent => {
        return mouseEvent.latlng;
      })
    );

    return new Observable<GeometryDefinition>(observer => {
      let valid = false;
      const unsubscribe = mousemove.subscribe(secondPosition => {
        const radius = CircleFactory.calculateRadius(firstPosition, secondPosition);
        const geometry: CircleDefinition = {
          shape: 'circle',
          center: firstPosition,
          ray: radius
        };

        valid = validator.validate(geometry);
        geometry.notValid = !valid;
        observer.next(geometry);
      });

      unsubscribe.add(
        commitEvent.subscribe(() => {
          if (valid) {
            observer.complete();
          }
        })
      );

      unsubscribe.add(fromEvent<L.LeafletKeyboardEvent>(leafletMap, 'keydown').subscribe(keydown => {
        if (keydown.originalEvent.key === 'Escape') {
          observer.error('Esc pressed');
        }
      }));

      return unsubscribe;
    });
  }

  constructor(
    @Self()
    private readonly validator: GeometryValidator
  ) {}

  createGeometry(leafletMap: L.Map): Observable<GeometryDefinition> {
    const click = fromEvent<MouseEvent>(leafletMap.getContainer(), 'click').pipe(
      map(clickEvent => leafletMap.mouseEventToLatLng(clickEvent)),
      share()
    );

    return click.pipe(
      take(1),
      switchMap(firstPosition => CircleFactory.drawCircle(leafletMap, this.validator, firstPosition, click)),
    );
  }
}

@Injectable()
export class PolygonFactory implements GeometryFactory {

  public readonly buttonClasses = 'fas fa-draw-polygon';
  public readonly title = 'Draw a polygon';

  private static drawPoly(
    leafletMap: L.Map, validator: GeometryValidatorFunctor,
    firstPosition: L.LatLng, commitEvent: Observable<L.LatLng>) {

    let valid = false;
    const points = [ firstPosition ];
    const mousemove = fromEvent<L.LeafletMouseEvent>(leafletMap, 'mousemove').pipe(
      map(mouseEvent => mouseEvent.latlng)
    );

    return new Observable<GeometryDefinition>(observer => {
      const unsubscribe = commitEvent.subscribe(newPosition => {
        if (valid) {
          if (points.length > 2) {
            const start = leafletMap.latLngToLayerPoint(firstPosition);
            const end = leafletMap.latLngToLayerPoint(newPosition);
            const width = start.x - end.x;
            const height = start.y - end.y;

            if (Math.sqrt(width * width + height * height) < 10) {
              observer.next({
                shape: 'polygon',
                points
              });

              observer.complete();
            } else {
              points.push(newPosition);
            }
          } else {
            points.push(newPosition);
          }
        }
      });

      const firstPointMarker: L.CircleMarker = L.circleMarker(firstPosition,
        { color: 'black', fillColor: 'white', fillOpacity: 1, radius: 5, weight: 1 });
      firstPointMarker.addTo(leafletMap);
      unsubscribe.add(() => firstPointMarker.remove());

      unsubscribe.add(mousemove.subscribe(newPoint => {
        const geometry: PolygonDefinition = {
          shape: 'polygon',
          points: [ ...points, newPoint ]
        };

        valid = validator.validate(geometry);
        geometry.notValid = !valid;
        observer.next(geometry);
      }));

      unsubscribe.add(fromEvent<L.LeafletKeyboardEvent>(leafletMap, 'keydown').subscribe(keydown => {
        if (keydown.originalEvent.key === 'Escape') {
          observer.error('Esc pressed');
        }
      }));

      unsubscribe.add(() => {
        // marker.remove();
      });

      return unsubscribe;
    });
  }

  constructor(
    private readonly validator: GeometryValidator
  ) { }

  createGeometry(leafletMap: L.Map): Observable<GeometryDefinition> {
    // Ancora da gestire la sottoscrizione
    const click = fromEvent<MouseEvent>(leafletMap.getContainer(), 'click').pipe(
      map(clickEvent => leafletMap.mouseEventToLatLng(clickEvent)),
      share()
    );

    return click.pipe(
      take(1),
      switchMap(firstPosition => PolygonFactory.drawPoly(leafletMap, this.validator, firstPosition, click))
    );
  }
}

export interface GeometryDrawingStep {
  apply(base: Observable<GeometryDefinition>): Observable<GeometryDefinition>;
}

// FIXME: da mettere in un file (insieme alla definizione di GeometryValidatorFunction & GEOMETRY_VALIDATOR_FUNCTOR)
@Injectable()
export class GeometryValidator implements GeometryValidatorFunctor {
  constructor(
    @Optional()
    @Inject(GEOMETRY_VALIDATOR_FUNCTOR)
    private readonly validators: GeometryValidatorFunctor[] = []
  ) { }

  validate(geometry: GeometryDefinition): boolean {
    for (const validator of this.validators) {
      if (!validator.validate(geometry)) {
        return false;
      }
    }
    return true;
  }
}

@Component({
  selector: 'cwi-geometry-drawer',
  templateUrl: './geometry-drawer.component.html',
  styleUrls: ['./geometry-drawer.component.scss']
})
export class GeometryDrawerComponent implements OnDestroy {

  private readonly subscription: Subscription;
  private readonly drawLayer = L.layerGroup();
  private leafletMap: L.Map;

  disabled = false;

  currentDrawer: Observable<GeometryDefinition[]>;

  @Output()
  public draw = new EventEmitter<Observable<GeometryDefinition>>();

  @Output()
  public inDrawingMode = new EventEmitter<boolean>();

  constructor(
    @Optional()
    @Inject(GEOMETRY_DRAWING_STEP)
    private readonly drawingSteps: GeometryDrawingStep[],
    @Optional()
    @Inject(GEOMETRY_FACTORY)
    readonly drawerFactories: GeometryFactory[],
    leaflet: LeafletDirective,
  ) {
    if (!drawingSteps) {
      this.drawingSteps = [];
    }
    if (!drawerFactories) {
      this.drawerFactories = [];
    }
    this.subscription = leaflet.mapReady.pipe(
      startWith(leaflet.map),
      filter(m => !!m),
      distinctUntilChanged()
    ).subscribe(leafletMap => this.onMapReady(leafletMap));
  }

  addGeometry(drawerFactory: GeometryFactory) {
    // Il check sul disabled è aggiunto per
    // evitare che su click multipli del bottone aggiungesse N first points.
    if (!this.disabled) {
      this.inDrawingMode.next(true);
      const oldCursor = this.leafletMap.getContainer().style.cursor;
      this.disabled = true;
      this.leafletMap.getContainer().style.cursor = 'pointer';
      let geometry = drawerFactory.createGeometry(this.leafletMap);

      for (const step of this.drawingSteps) {
        geometry = step.apply(geometry);
      }

      geometry = geometry.pipe(share());

      this.draw.next(geometry);

      this.currentDrawer = geometry.pipe(
        map(item => [ item ]),
        finalize(() => {
          // FIXME
          this.leafletMap.getContainer().style.cursor = oldCursor;
          this.disabled = false;
          this.currentDrawer = null;
          this.inDrawingMode.next(false);
        })
      );
    }
  }

  onMapReady(leafletMap: L.Map) {
    this.leafletMap = leafletMap;
    this.drawLayer.addTo(leafletMap);
  }

  ngOnDestroy(): void {
    if (this.leafletMap) {
      this.drawLayer.remove();
    }
    this.subscription.unsubscribe();
  }
}

