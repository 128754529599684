import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { OrderedMap } from "immutable";
import { DeviceDiagnostic, SubSystemStatus } from "../diagnostic";

export interface SubSystemFullStatus {
  name: string;
  fixGps: boolean;
  warning?: string;
  status: SubSystemStatus;
  devices: OrderedMap<string, DeviceDiagnostic[]>;
}

@Component({
  selector: 'cwi-remote-subsystem-status',
  templateUrl: './subsystem-status.component.html',
  styleUrls: ['./subsystem-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubSystemStatusComponent {

  @Input()
  public subSystem: SubSystemFullStatus;

  status = SubSystemStatus;
}
