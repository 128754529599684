import { Component, Input, EventEmitter, Output } from '@angular/core';
import { PlanDefinition } from '../plan-definition';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { getPopoverResult } from '@cwi/components';

export interface PlanItemError {
  planName: string;
  error: string;
}

@Component({
  selector: 'cwi-remote-plan-item',
  templateUrl: './plan-item.component.html',
  styleUrls: ['./plan-item.component.scss']
})

export class PlanItemComponent {

  @Input()
  public planItem: PlanDefinition;

  @Input()
  public isClickable: boolean;

  @Input()
  public isActive: boolean;

  @Input()
  public toggleDisabled: boolean;

  @Input()
  public planItemError: PlanItemError;

  @Output()
  public editPlan = new EventEmitter<void>();

  @Output()
  public deletePlan = new EventEmitter<void>();

  @Output()
  public activate = new EventEmitter<void>();

  @Output()
  public deactivate = new EventEmitter<void>();

  @Output()
  public planSelected = new EventEmitter<void>();

  async deleteSchedulation(popoverRef: NgbPopover) {
    try {
      if (await getPopoverResult<boolean>(popoverRef)) {
        this.deletePlan.next();
      }
    } catch (e) {
      console.log(e);
    }
  }

  editPlanModal(event: MouseEvent) {
    this.editPlan.next();
  }

  togglePlan(event) {
    event.preventDefault();
    if (!this.toggleDisabled) {
      if (this.isActive) {
        this.deactivate.next();
      } else {
        this.activate.next();
      }
    }
  }

  planClick() {
    this.planSelected.next();
  }
}
