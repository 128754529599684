import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

export interface ConfirmationSettings {
  title: string;
  text: string;
}

export const CHARS_NUMB = 4;

@Component({
  selector: 'cwi-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss'],
})
export class DeleteConfirmationComponent {

  deleteConfirmationForm: FormGroup;

  @Input()
  deleteModalSettings: ConfirmationSettings;

  @Output()
  public readonly confirmed = new EventEmitter<void>();

  @Output()
  public readonly closed = new EventEmitter<void>();
  confirmationString: string;

  constructor() {
    this.confirmationString = this.randomString();
    this.deleteConfirmationForm = new FormGroup({
      confirmText: new FormControl(null, [Validators.required, this.matchStringInserted.bind(this) ]),
    });
  }

  matchStringInserted = (control: AbstractControl) => {
    const insertedString = control.value;
    return insertedString === this.confirmationString ? null : { charsDontMatch: true };
  }

  randomString() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < CHARS_NUMB; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  close() {
    this.closed.next();
  }

  onSubmit() {
    this.confirmed.next();
  }

}
