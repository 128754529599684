import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';
import { SidebarSection } from './sidebar-configuration';

@Component({
  selector: 'cwi-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @Input()
  isExpanded: boolean;

  @Input()
  title: string;

  @Input()
  sidebarSections: SidebarSection[];

  selectedRoute: string;

  @Input()
  startingSection: SidebarSection;

  autosize: boolean;
  loading: Observable<boolean>;

  constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService
    ) {
      this.loading = router.events.pipe(
        filter(event => event instanceof NavigationStart || event instanceof NavigationEnd),
        map(event => event instanceof NavigationStart),
        debounceTime(100),
        startWith(false),
        distinctUntilChanged()
      );
  }

  ngAfterViewInit(): void {
    const urlMatch = this.sidebarSections.some(
      section => section.link === this.router.url);
    if (!urlMatch && this.startingSection) {
      this.router.navigateByUrl(this.startingSection.link);
    }
  }

  ngOnInit(): void {
    this.resize();
  }

  resize() {
    this.autosize = true;
    setTimeout(() => this.autosize = false, 1000);
  }

  async logout() {
    await this.authenticationService.logout();
    this.router.navigateByUrl('/');
  }

  routeIsActive(routePath: string) {
    return this.router.url === routePath;
}
}
