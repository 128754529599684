<div class="system mx-auto mv-auto">
    <div class="status">
        <h6 class="text-bold mt-2">{{systemStatus.name}}</h6>
        <!-- <span class="fas ml-2" [ngClass]="systemStatus.status"
            [class.fa-check-circle]="systemStatus.status==='Working'"
            [class.fa-exclamation]="systemStatus.status==='Warning'"
            [class.fa-exclamation-triangle]="systemStatus.status==='Error'"
            title="{{returnTitle()}}">
        </span> -->
    </div>
    <ng-container *ngFor="let probe of systemStatus.probes; trackBy: probeTrackBy">
        <cwi-remote-probe-map-marker [probeStatus]="probe"></cwi-remote-probe-map-marker>
    </ng-container>
    <!-- <h6 class="text-secondary text-center">{{ systemStatus.setup }} - {{ systemStatus.mode }} </h6> -->
</div>
