<div class="card">
    <div class="card-body card-body-cwi">
        <div class="system">
            <div class="systemName">
                <h6 class="text-center text-primary font-weight-bold mt-2 mr-2"> {{ systemStatus.name }} </h6>
                <!-- FIXME [class.fa-exclamation-triangle]="systemStatus.status==='Danger'" -->
                <!-- <span class="fas" [ngClass]="systemStatus.status"
                    [class.fa-check-circle]="systemStatus.status==='Working'"
                    [class.fa-exclamation]="systemStatus.status==='Warning'"
                    [class.fa-exclamation-triangle]="systemStatus.status==='Error'"
                    title="{{returnTitle()}}">
                </span> -->
            </div>
            
            <h6 class="text-secondary">{{ systemStatus.setup }} - {{ systemStatus.mode }} </h6>
        </div>
        <cwi-remote-probe-status *ngFor="let probe of systemStatus.probes"
            [probeStatus]=probe>
        </cwi-remote-probe-status>
    </div>   
</div>