import { Component, Input } from '@angular/core';

@Component({
  selector: 'cwi-remote-planmode-title',
  templateUrl: './planmode-title.component.html',
  styleUrls: ['./planmode-title.component.scss']
})
export class PlanModeTitleComponent {

  @Input()
  planModeTitle: string;
}
