import { ChangeDetectionStrategy, Component, HostListener, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'cwi-remote-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitleComponent {

  @HostListener('click', ['$event'])
  titleClick(event: MouseEvent) {
    this.router.navigateByUrl('/remote-controller')
  }
  
  @Input()
  public train: string;


  constructor(
    private readonly router: Router) {}
  }
