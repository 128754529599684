import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cwi-upload-download-bar',
  templateUrl: './upload-download-bar.component.html',
  styleUrls: ['./upload-download-bar.component.scss']
})
export class UploadDownloadBarComponent {

  @Input()
  progress: number;

  /**
   * if true --> download, else --> upload
   */
  @Input()
  isDownload: boolean;

  @Input()
  fileName: number;

  @Input()
  fileSize: number;

  @Output()
  cancelClicked = new EventEmitter<void>();

  canceled: boolean;

  constructor() { }

  cancelClick() {
    this.canceled = true;
    this.cancelClicked.next();
  }

}
