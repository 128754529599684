import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TrainDefinition } from '../train-definition';

@Component({
  selector: 'cwi-remote-train-status',
  templateUrl: './train-status.component.html',
  styleUrls: ['./train-status.component.scss']
})
export class TrainStatusComponent {

  constructor() { }

  @Input()
  public trainStatus: TrainDefinition;

  @Output()
  public readonly trainSelected = new EventEmitter<void>();

  @Output()
  public readonly followClicked = new EventEmitter<void>();

  clicked = false;

  trainClick() {
    this.trainSelected.next();
  }

  followClick() {
    this.followClicked.next();
  }

}
