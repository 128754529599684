import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DeviceDiagnostic, DeviceType } from "../diagnostic";

@Component({
  selector: 'cwi-remote-general-device-status',
  templateUrl: './general-device-status.component.html',
  styleUrls: ['./general-device-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralDeviceStatusComponent {

  @Input()
  public device: DeviceDiagnostic;

  getDeviceType(mobile: DeviceDiagnostic): string {
    switch (mobile.type) {
      case 'R&D TSMx Scanner':
        return $localize`Scanner`;
      case 'Track Chaser EE':
        return $localize`Track Chaser`;
      case "Visual Scripting":
        return $localize`Visual Scripting`;
    }
  }
}
