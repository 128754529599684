import { ChangeDetectionStrategy, Component, Injector, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PowerSupplyStatus } from "../diagnostic";
import { PowerRestartModelComponent } from "../power-restart-modal/power-restart-modal.component";

export interface PowerSystem {
  isMaster: boolean;
  name: string;
  wagonName: string;
  powerStatus: PowerSupplyStatus;
}

@Component({
  selector: 'cwi-remote-power',
  templateUrl: './power.component.html',
  styleUrls: ['./power.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PowerComponent {

  expanded: boolean = true;

  @Input()
  public name: string;

  @Input()
  public systems: PowerSystem[];

  constructor(
    private readonly modalService: NgbModal,
    private readonly injector: Injector) {}

  powerTrackBy(index: number) {
    return index;
  }

  getSubToggleActive(powerStatus: PowerSupplyStatus) {
    switch (powerStatus) {
      case PowerSupplyStatus.Started:
      case PowerSupplyStatus.AtLeastOneDown:
      case PowerSupplyStatus.Restarting:
      case PowerSupplyStatus.StartingGroup:
      case PowerSupplyStatus.ShuttingDown:
        return true;
      case PowerSupplyStatus.NotStarted:
      default:
        return false;
    }
  }

  getSubToggleStatus(powerStatus: PowerSupplyStatus) {
    switch(powerStatus)
    {
      case PowerSupplyStatus.Started:
        return 'started';
      default: return 'waiting';
    }
  }

  getMainToggleStatus() {
    let result = 'started';
    this.expanded = false;
    for (const { powerStatus: status } of this.systems)
    {
      switch (status)
      {
        case PowerSupplyStatus.NotStarted:
          this.expanded = true;
          break;
        case PowerSupplyStatus.Started:
          break;
        default:
          if (result)
          {
            this.expanded = true;
            result = 'waiting';
          }
          break;
      }
    }
    return result;
  }

  getMainToggleActive() {
    for (const { powerStatus: status } of this.systems) {
      if (status != PowerSupplyStatus.NotStarted)
      {
        return true;
      }
    }
    return false;
  }

  toggleMain(event) {
    event.preventDefault();
    this.openModal(this.systems);
  }

  
  toggleSub(event, system: PowerSystem) {
    event.preventDefault();
    this.openModal([ system ])
  }

  openModal(systems: PowerSystem[]) {
    const modalRef = this.modalService.open(PowerRestartModelComponent, { centered: true, keyboard: false, injector: this.injector });
    const componentInstance: PowerRestartModelComponent = modalRef.componentInstance;

    componentInstance.name = this.name;
    componentInstance.powerSystems = systems;

    componentInstance.closed.subscribe(() => {
      modalRef.close();
    });
    componentInstance.completed.subscribe(() => {
      setTimeout(() => {
        modalRef.close();
      }, 3000);
    });
  }

  getSystemRole(isMaster: boolean) {
    return isMaster ? $localize`Main` : $localize`Mirror`
  }
}
