import { SETTINGS_CONFIGURATION, ISettingsConfiguration, IRoleService } from './service-configuration';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { shareReplay } from 'rxjs/operators';

const jsonContentOptions = { headers: new HttpHeaders({'Content-Type': 'application/json' }) };

@Injectable()
export class RoleService implements IRoleService {

  private readonly baseUrl: string;
  public roles$: Observable<string[]>;

  constructor(
    private readonly $http: HttpClient,
    @Inject(SETTINGS_CONFIGURATION)
    configuration: ISettingsConfiguration
  ) {
    this.baseUrl = configuration.baseUrl;
    this.roles$ = this.$http.get<string[]>(`${this.baseUrl}/roles`).pipe(
      shareReplay(1)
    );
  }

  addRole(userName: string, role: string): Observable<void>  {
    return this.$http.post<void>(`${this.baseUrl}/user/${encodeURI(userName)}/roles`, JSON.stringify(role), jsonContentOptions);
  }

  deleteRole(userName: string, role: string): Observable<void> {
    return this.$http.delete<any>(`${this.baseUrl}/user/${encodeURI(userName)}/role/${role}`);
  }
}
