import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MobileChannelInfo, ResponderStatus, ResponderCsTestType } from "../diagnostic";

@Component({
  selector: 'cwi-remote-responder-mobile-status-card',
  templateUrl: './responder-mobile-status-card.component.html',
  styleUrls: ['./responder-mobile-status-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponderMobileStatusCardComponent {
  channelVisible: boolean;
  displayedColumns: string[] = ['test', 'callingNumber', 'calledNumber', 'name', 'vendor', 'model'];
  
  @Input()
  public title: string;
  @Input()
  public status: ResponderStatus;
  @Input()
  public version: string;
  @Input()
  public channels: MobileChannelInfo[];

  isChannelListEmpty() {
    if(this.channels.length) {
      return false;
    }
    else {
      return true;
    }
  }

  getResponderCsTestType(testType) {
    switch (testType) {
      case ResponderCsTestType.S93TDB:
        return $localize`Subset 093 TDB`;
      case ResponderCsTestType.S93TIRBoth:
        return $localize`Subset 093 TIR Both`;
      case ResponderCsTestType.S93TIRUp:
        return $localize`Subset 093 TIR Upload`;
      case ResponderCsTestType.S93TIRDown:
        return $localize`Subset 093 TIR Download`;
      case ResponderCsTestType.VoiceMOC:
        return $localize`Voice MOC`;
      case ResponderCsTestType.VoiceQuality:
        return $localize`Voice Quality`;
      case ResponderCsTestType.DataMOC:
        return $localize`Data MOC`;
      case ResponderCsTestType.DataMTC:
        return $localize`Data MTC`;
      case ResponderCsTestType.VoiceMTC:
        return $localize`Voice MTC`;
      case ResponderCsTestType.Waiting:
        return $localize`Waiting`;
      case ResponderCsTestType.Unattended:
        return $localize`Unattended`;
      case ResponderCsTestType.DataMOC:
        return $localize`Data MOC`;
      case ResponderCsTestType.HOBreakTime:
        return $localize`HO Break Time`;
      case ResponderCsTestType.S93TDBV4:
        return $localize`Subset 093 TDB V4`;
      case ResponderCsTestType.S93VIRBoth:
        return $localize`Subset 093 VIR Both`;
      case ResponderCsTestType.S93VIRUp:
        return $localize`Subset 093 VIR Up`;
      case ResponderCsTestType.S93VIRDown:
        return $localize`Subset 093 VIR Down`;
      case ResponderCsTestType.TestBER:
        return $localize`Test BER`;
      case ResponderCsTestType.Undefined:
      default:
        return '-';
    }
  }
}
