import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'cwi-remote-train-control-buttons',
  templateUrl: './train-control-buttons.component.html',
  styleUrls: ['./train-control-buttons.component.scss']
})
export class TrainControlButtonsComponent implements OnInit {

  @Input()
  isFollowVisible: boolean;

  @Output()
  follow = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  followClick() {
    this.follow.next();
  }

}
