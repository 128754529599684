<form [formGroup]="planCreatorForm" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h5 class="modal-title">{{ getTitle() }}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <div class="form-group">
        <label i18n>Name:
          <input type="text" class="form-control"
            i18n-placeholder
            placeholder="Name" formControlName="name"
            [attr.disabled]="edit"
            [class.is-valid]="planCreatorForm.controls.name.valid && planCreatorForm.controls.name.touched"
            [class.is-invalid]="planCreatorForm.controls.name.invalid && planCreatorForm.controls.name.touched"/>
          <div class="invalid-feedback">
            <ng-container *ngTemplateOutlet="nameTemplate || defaultNameTemplate; context: { $implicit: planCreatorForm.controls.name.errors }"></ng-container>
          </div>
        </label>
      </div>
      <div class="form-group">
        <label i18n>Description:
          <input type="text" class="form-control"
            i18n-placeholder
            placeholder="Description" formControlName="description"
            [class.is-valid]="planCreatorForm.controls.description.valid && planCreatorForm.controls.description.touched"
            [class.is-invalid]="planCreatorForm.controls.description.invalid && planCreatorForm.controls.description.touched"/>
          <div class="invalid-feedback">
            <ng-container *ngTemplateOutlet="descriptionTemplate || defaultDescriptionTemplate; context: { $implicit: planCreatorForm.controls.description.errors }"></ng-container>
          </div>
        </label>
      </div>
      <div class="form-group form-inline">
        <div class="row">
          <div class="col">
            <span i18n>Validity Start Date:
              <div class="input-group">
                  <input class="form-control" placeholder="dd/mm/yyyy"
                    i18n-placeholder
                    formControlName="validityStartDate" 
                    ngbDatepicker #validityST="ngbDatepicker"
                    [class.is-valid]="planCreatorForm.controls.validityStartDate.valid && planCreatorForm.controls.validityStartDate.touched"
                    [class.is-invalid]="planCreatorForm.controls.validityStartDate.invalid && planCreatorForm.controls.validityStartDate.touched" >
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary fas fa-calendar-alt" (click)="validityST.toggle()" type="button"></button>
                  </div>
              </div>
            </span>
          </div>
          <div class="col">
            <span class="validity-indication" i18n>Validity End Date: (included)
              <div class="input-group">
                  <input class="form-control" placeholder="dd/mm/yyyy"
                    i18n-placeholder
                    formControlName="validityEndDate" 
                    ngbDatepicker #validityET="ngbDatepicker"
                    [class.is-valid]="planCreatorForm.controls.validityEndDate.valid && planCreatorForm.controls.validityEndDate.touched"
                    [class.is-invalid]="planCreatorForm.controls.validityEndDate.invalid && planCreatorForm.controls.validityEndDate.touched" >
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary fas fa-calendar-alt" (click)="validityET.toggle()" type="button"></button>
                  </div>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>
          <span i18n>Working Mode:</span>
          <select class="form-control"
            formControlName="workingMode"          
            [class.is-valid]="planCreatorForm.controls.workingMode.valid && planCreatorForm.controls.workingMode.touched"
            [class.is-invalid]="planCreatorForm.controls.workingMode.invalid && planCreatorForm.controls.workingMode.touched" >
            <option class="dropdown-item" disabled selected value="">Select an Option</option>
            <option class="dropdown-item" *ngFor="let mod of modes" [value]="mod"> {{ mod }}</option>
          </select>
          
        </label>
      </div>      
      <div class="invalid-feedback d-block form-inline" 
        *ngIf="planCreatorForm.controls.validityStartDate.touched &&
            planCreatorForm.controls.validityStartDate.valid && 
            planCreatorForm.controls.validityEndDate.touched &&
            planCreatorForm.controls.validityEndDate.valid &&
            planCreatorForm.errors">
        <ng-container *ngTemplateOutlet="defaultTemplate; context: { $implicit: planCreatorForm.errors }"></ng-container>
      </div>
  </div>
  <div class="modal-footer">
    <button type="submit" [disabled]="disableSubmit()" class="btn btn-primary submit" i18n>
      <span class="fas fa-save"></span> Save
    </button>
    <button type="button" class="btn btn-danger mr-2 cancel" (click)="cancel();" ngbAutofocus i18n>Cancel
    </button>
  </div>
</form>

<ng-template #defaultNameTemplate let-errors>
  <ul *ngIf=errors>
    <li *ngIf="errors.required" i18n>This field is required</li>
    <li *ngIf="errors.minlength" i18n>Must be at least of {{errors.minlength.requiredLength}} characters </li>
    <li *ngIf="errors.maxlength" i18n>Must be less than {{errors.maxlength.requiredLength}} characters </li>
    <li *ngIf="errors.pattern" i18n>Cannot contain only blank spaces </li>
  </ul>
</ng-template>
<ng-template #defaultDescriptionTemplate let-errors>
  <ul *ngIf=errors>
    <li *ngIf="errors.required" i18n>This field is required</li>
    <li *ngIf="errors.minlength" i18n>Must be at least of {{errors.minlength.requiredLength}} characters </li>
    <li *ngIf="errors.maxlength" i18n>Must be less than {{errors.maxlength.requiredLength}} characters </li>
  </ul>
</ng-template>
<ng-template #defaultDelayTemplate let-errors>
  <ul *ngIf=errors>
    <li *ngIf="errors.required" i18n>This field is required</li>
    <li *ngIf="errors.max" i18n>Max value is {{ maxValue }} min </li>
    <li *ngIf="errors.min" i18n>Min value is {{ minValue }} min</li>
  </ul>
</ng-template>
<ng-template #defaultTemplate let-errors>
  <ul *ngIf=errors>
    <li *ngIf="errors.dateError" i18n>Validity End Date cannot be less or equal than Validity Start Date</li>
  </ul>
</ng-template>
