import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cwiSecondsToHHmm'
})
export class SecondsToHHmmPipe implements PipeTransform {

  transform(value: number) {
    if (value > 0) {
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value % 3600) / 60);
        return `${this.padTime(hours)}:${this.padTime(minutes)}`;
    } else {
        return '00:00';
    }
  }

  padTime(t: number) {
    return t < 10 ? '0' + t : t;
  }
}


