import { ValidatorFn, Validators } from '@angular/forms';
import { InjectionToken, Inject, Injectable, Optional } from '@angular/core';

export interface ITimeSchedulationCreatorConfiguration {
  nameValidators: ValidatorFn[];
  scheduleValidators: ValidatorFn[];
}

export const TIMESCHEDULATIONCREATOR_NAME_VALIDATORS = new InjectionToken<ValidatorFn[]>(
  'TIMESCHEDULATIONCREATOR_NAME_VALIDATORS'
);
export const TIMESCHEDULATIONCREATOR_SCHEDULE_VALIDATORS = new InjectionToken<ValidatorFn[]>(
  'TIMESCHEDULATIONCREATOR_SCHEDULE_VALIDATORS'
);
export const TIMESCHEDULATIONCREATOR_CONFIGURATION = new InjectionToken<ITimeSchedulationCreatorConfiguration>(
  'TIMESCHEDULATIONCREATOR_CONFIGURATION'
);

export const DEFAULT_TIMESCHEDULATIONCREATOR_NAME_VALIDATORS: ValidatorFn[] = [
  Validators.required,
  Validators.minLength(8),
  Validators.maxLength(16)
];

@Injectable()
export class DefaultTimeSchedulationCreatorConfiguration implements ITimeSchedulationCreatorConfiguration {
  constructor(
    @Inject(TIMESCHEDULATIONCREATOR_NAME_VALIDATORS)
    public readonly nameValidators: ValidatorFn[],
    @Optional()
    @Inject(TIMESCHEDULATIONCREATOR_SCHEDULE_VALIDATORS)
    public readonly scheduleValidators: ValidatorFn[],
  ) { }
}
