import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IUserService, USER_SERVICE } from '../../settings/services/service-configuration';
import { PasswordChangeRequest } from '../components/change-password/change-password.component';
import { IUserCreationConfiguration, USER_CREATION_CONFIGURATION } from '../../user-creation.configuration';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenModalComponent } from '../components/change-password/token-modal.component';

@Component({
    selector: 'cwi-pg-user-settings-view',
    templateUrl: './user-settings-view.component.html',
    styleUrls: ['./user-settings-view.component.scss'],
    providers: [],
  })
  export class UserSettingsViewComponent {

    private subscription = new Subscription();
    updateDisplayNameForm: FormGroup;
    updateEmailForm: FormGroup;
    isError: boolean;
    message: string;
    passwordUpdated: boolean;

    constructor(
      @Inject(USER_SERVICE)
      private readonly userService: IUserService,
      @Inject(USER_CREATION_CONFIGURATION)
      configuration: IUserCreationConfiguration,
      private readonly modalService: NgbModal) {
      this.updateEmailForm = new FormGroup({
        email: new FormControl(null, configuration.emailValidators),
      });

      this.updateDisplayNameForm = new FormGroup({
        displayName: new FormControl(null, configuration.displaynameValidators),
      });
    }

    onPasswordUpdated(passwReq: PasswordChangeRequest) {
      this.passwordUpdated = false;
      const request = this.userService.updatePassword(passwReq.currentPassword, passwReq.newPassword);
      this.subscription.add(request.subscribe(
        (response) => {
          this.isError = false;
          this.passwordUpdated = true;
          this.message = $localize`Password changed correctly.`;
          this.userService.reload();
        },
        (error: HttpErrorResponse) => {
          this.isError = true;
          this.passwordUpdated = false;
          if (error.status === 400) {
            this.message = $localize`The new password cannot be the same as the current one.`;
          } else if (error.status === 409) {
            this.message = $localize`The current password is incorrect.`;
          }
        }));
    }

    onSubmitDisplayName() {
      const displayName = this.updateDisplayNameForm.get('displayName').value;
      const request = this.userService.updateDisplayName(displayName);
      this.subscription.add(request.subscribe(
        (response) => {
          this.isError = false;
          this.message = $localize`Display name changed correctly.`;
          this.updateDisplayNameForm.reset();
          this.userService.reload();
        },
        (error: HttpErrorResponse) => {
          this.isError = true;
          // TODO Handle after backend
          this.message = $localize`Error in setting display name.`;
        }));
    }

    onSubmitEmail() {
      const email = this.updateEmailForm.get('email').value;
      const request = this.userService.updateEmail(email);
      this.subscription.add(request.subscribe(
        (response) => {
          this.isError = false;
          this.message = $localize`Email changed correctly.`;
          this.updateEmailForm.reset();
          this.userService.reload();
        },
        (error: HttpErrorResponse) => {
          this.isError = true;
          // TODO Handle after backend
          this.message = $localize`Error in setting email.`;
        }));
    }

    closeAlert() {
      this.message = null;
    }

    async showToken() {
      this.userService.getApplicationToken()
        .subscribe((token: string) => {
          const modalRef = this.modalService.open(TokenModalComponent, {
            centered: true
          });
          const componentInstance = modalRef.componentInstance;
          componentInstance.token = token;

          componentInstance.refresh.subscribe(() => {
            this.userService.refreshToken()
              .subscribe(
                (response) => {
                  this.userService.getApplicationToken()
                  .subscribe((token: string) => {
                    componentInstance.token = token;
                  },
                  (error: HttpErrorResponse) => {
                    componentInstance.error = $localize `Error in fetching the new application token.`;
                  })
                },
                (error: HttpErrorResponse) => {
                  componentInstance.error = $localize `Error in re-creating the application token.`;
                });
              });
        });
    }
}
