<div class="card main-container">
    <span><label class="font-weight-bold" i18n>Manage file configurations</label>
    <a class="nav-link" style="width: max-content;"
        href="/setupManager/installer"
        download="setupManagerInstaller.exe"
        title="Windows download">
        Download Software
        <span class="ml-1 fab fa-windows"></span>
    </a></span>
    <div class="test-container">
        <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="infoVisible && !alertClosed" i18n>
            The configuration file will be applied when the system goes to idle.
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="resetInfoProperties()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="table-container mt-2">
            <div style="width: 40%;">
                <input class="input-field" type="text" class="form-control" placeholder="Search..." 
                [ngModel]=filter [ngModelOptions]="{standalone: true}" (ngModelChange)=search($event) /> 
            </div>
            <table class="table table-hover table-sm table-style text-center mt-1">
                <thead>
                    <tr>
                        <th scope="col" i18n>System</th>
                        <th scope="col" i18n>Name</th>
                        <th scope="col" i18n>Download</th>
                        <th scope="col" i18n>Upload</th>
                        <th scope="col" i18n>Synced</th>
                        <th scope="col" i18n>Last Update</th>
                    </tr>
                </thead>
                <tbody *ngIf="fileCfgs$ | async; let fileCfgs">
                    <tr *ngFor="let file of fileCfgs">
                        <td scope="col-3">
                            {{ file.system }}
                        </td>
                        <td scope="col-3">
                            {{ file.name }}
                        </td>
                        <td scope="col-2">
                            <a class="btn text-primary"
                                href="{{baseUrl}}/train/{{train}}/system/{{file.system}}/file_config/{{file.name}}"
                                download="{{file.name}}.cfg"
                                title="Download">
                                <span class="fas fa-download text-primary"></span>&nbsp;
                            </a>
                        </td>
                        <td scope="col-2">
                            <div class="upload-column">
                                <label class="hidden" title="Upload existing"><span class="fas fa-upload text-primary"></span>
                                    <input style="display: none;" type="file" [accept]="supportedFileTypes" (change)="selectFile($event, file)" > 
                                </label>
                            </div>                                    
                        </td>
                        <td scope="col-2">
                            <div *ngIf="file.synced; else elseBlock"><span class="fas fa-check text-primary"></span></div>
                            <ng-template #elseBlock><span class="fas fa-hourglass text-primary"></span></ng-template>
                        </td>
                        <td scope="col-2">
                            {{ file.lastUpdate }}
                        </td>
                    </tr>
                </tbody>
            </table>           
            <div *ngIf="messages.length >= 1" class="alert alert-danger mt-3" role="alert">
                <ng-container *ngTemplateOutlet="messageTemplates; context: { $implicit: messages }"></ng-container>
            </div>
            <div *ngFor="let progressInfo of progressInfos">
                <span>{{ progressInfo.system }} -> {{ progressInfo.fileName }}</span>
                <div class="progress">
                    <div
                    class="progress-bar progress-bar-info progress-bar-striped active"
                    role="progressbar"
                    attr.aria-valuenow="{{ progressInfo.value }}"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    [ngStyle]="{ width: progressInfo.value + '%' }"
                    >
                    {{ progressInfo.value }}%
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #messageTemplates let-messages>
    <ul> 
        <li *ngFor="let mess of messages">
            {{ mess }}
        </li>
    </ul>
</ng-template>