import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@cwi/components';
import { SettingsComponent } from './settings.component';
import { SettingsRoleGuard } from './settings.role-guard';
import { TrackDabataseViewComponent } from './trackdatabase-view/trackdatabase-view.component';
import { UserManagementUsersViewComponent } from './user-management-usersview/user-management-usersview.component';
import { SoftwareUpdatesViewComponent } from './software-updates-view/software-updates-view.component';

const routes: Routes = [
  {
    path: 'settings', component: SettingsComponent, canActivate: [ AuthenticationGuard, SettingsRoleGuard ], children:
    [
      { path: 'user-management', pathMatch: 'full', component: UserManagementUsersViewComponent },
      { path: 'track-database', pathMatch: 'full', component: TrackDabataseViewComponent },
      { path: 'software-updates', pathMatch: 'full', component: SoftwareUpdatesViewComponent },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class SettingsRoutingModule { }
