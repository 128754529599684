import { Injectable, Inject } from '@angular/core';
import { Observable, Subject, of, throwError } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { IGeneralSettingsService, REMOTECONTROLLER_CONFIGURATION, IRemoteControllerConfiguration } from './service-configuration';
import { GeneralSettingsDefinition } from './general-settings-definition';
import { HttpClient } from '@angular/common/http';
import { reload } from '@cwi/rx';

export class GeneralSettingsService implements IGeneralSettingsService {
  public readonly settings$: Observable<GeneralSettingsDefinition>;
  baseUrl: string;

  constructor(
    private readonly $http: HttpClient,
    private readonly reloadSubject: Subject<any>,
    configuration: IRemoteControllerConfiguration,
    trainName: string
  ) {
    this.baseUrl = `${configuration.baseUrl}/train/${trainName}`;
    this.settings$ = this.$http.get<GeneralSettingsDefinition>(`${this.baseUrl}/settings`).pipe(
      reload(reloadSubject),
      shareReplay(1)
    );
  }

  getSettings(): Observable<GeneralSettingsDefinition> {
    return this.$http.get<GeneralSettingsDefinition>(`${this.baseUrl}/settings`);
  }

  updateOptions(body: GeneralSettingsDefinition) {
    return this.$http.post<void>(`${this.baseUrl}/setting`, body);
  }

  reload() {
    this.reloadSubject.next();
  }
}

@Injectable()
export class GeneralSettingsServiceFactory {
  constructor(
    private readonly $http: HttpClient,
    @Inject(REMOTECONTROLLER_CONFIGURATION)
    private readonly configuration: IRemoteControllerConfiguration
  ) { }

  create(reloadSubject: Subject<any> = new Subject<any>(), trainName: string): IGeneralSettingsService {
    return new GeneralSettingsService(this.$http, reloadSubject, this.configuration, trainName);
  }
}

@Injectable()
export class GeneralSettingsServiceWrapper implements IGeneralSettingsService {
  private readonly generalSettingsService$: Observable<IGeneralSettingsService>;
  private readonly reloadSubject = new Subject<any>();

  public readonly settings$: Observable<GeneralSettingsDefinition>;

  constructor(
    generalSettingsFactory: GeneralSettingsServiceFactory,
    activatedRoute: ActivatedRoute
  ) {
    this.generalSettingsService$ = activatedRoute.params.pipe(
      map(params => 'trainName' in params
        ? generalSettingsFactory.create(this.reloadSubject, params.trainName)
        : null
      ),
      shareReplay(1)
    );

    this.settings$ = this.generalSettingsService$.pipe(
      switchMap(generalSettingsService => generalSettingsService
        ? generalSettingsService.settings$
        : of(null)
      ),
      shareReplay(1)
    );
  }

  getSettings(): Observable<GeneralSettingsDefinition> {
    return this.generalSettingsService$.pipe(
      take(1),
      switchMap(generalSettingsService => generalSettingsService
        ? generalSettingsService.getSettings()
        : throwError(new Error($localize`No settings selected.`)))
    );
  }

  updateOptions(body: GeneralSettingsDefinition) {
    return this.generalSettingsService$.pipe(
      take(1),
      switchMap(generalSettingsService => generalSettingsService
        ? generalSettingsService.updateOptions(body)
        : throwError(new Error($localize`No settings selected.`)))
    );
  }

  reload() {
    this.reloadSubject.next();
  }
}
