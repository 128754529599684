<div class="modal-header">
  <div class="header">
    <h5 class="modal-title">{{ getTitle() }}</h5>
    <button type="button" *ngIf="edit" class="btn text-danger buttons ml-2"       
        triggers=manual
        container=body 
        autoClose=outside
        title="Delete"
        popoverTitle="Are you sure?" 
        [ngbPopover]="confirmDelete" 
        #confirmPopover="ngbPopover"
      (click)="delete(confirmPopover)" ngbAutofocus>
      <span class="fas fa-trash"></span>&nbsp;
    </button>
  </div>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel();">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div>
      <span>
          <label i18n>Offset:
              <input cwiInputOnlyNumber
                cwiMinMaxInputNumber
                [maxValue]="5000"
                [minValue]="1000"
                [disabled]="edit"
                type="number"
                class="form-control inputStyle"
                [(ngModel)]=geometryInfo.offset
                (ngModelChange)="updateOffset($event)"
                [class.is-valid]="!geometryInfo.notValid"
                [class.is-invalid]="geometryInfo.notValid"
                i18n-placeholder
                placeholder="Offset"/>
          </label> m
      </span>
  </div>
  <div>
      <label>
        <span i18n>Setup list</span>
        <select class="form-control"
          [disabled]="edit"
          [class.is-valid]="geometryInfo.setup"
          [ngModel]=geometryInfo.setup
          (ngModelChange)="updateSetup($event)">
          <option class="dropdown-item" disabled selected value i18n>Select an Option</option>
          <option *ngFor="let set of setups" [value]="set"> {{ set }}</option>
        </select>
      </label>
  </div>
</div>
<div class="modal-footer">
    <button *ngIf="!edit" type="button" [disabled]="checkSaveDisabled()" class="btn btn-primary submit" (click)="save()" i18n>
      <span class="fas fa-save"></span> Save
    </button>
</div>

<ng-template let-close=close #confirmDelete>
    <button class="btn btn-sm btn-outline-danger"
      (click)="close(true)" i18n>Confirm
    </button>
</ng-template>