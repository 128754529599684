<mat-card>
  <mat-card-title>
    <div class="row">
      <div class="col-10">
        <span i18n>Electrical Status</span>
      </div>
    </div>
  </mat-card-title>
  <mat-divider [inset]="true"></mat-divider>
  <mat-card-content>
    <ng-container *ngFor="let system of powerSystems; trackBy: powerTrackBy">
      <cwi-remote-power [name]=system[0] [systems]="system[1]"></cwi-remote-power>
    </ng-container>
  </mat-card-content>
</mat-card>
