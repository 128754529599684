<ngx-charts-chart
  [view]="[width, height]"
  [showLegend]="legend"
  [legendOptions]="legendOptions"
  [activeEntries]="activeEntries"
  [animations]="animations"
  (legendLabelClick)="onClick($event)"
  (legendLabelActivate)="onActivate($event)"
  (legendLabelDeactivate)="onDeactivate($event)"
>
<svg:defs>
    <svg:clipPath [attr.id]="clipPathId">
      <svg:rect
        [attr.width]="dims.width + 10"
        [attr.height]="dims.height + 10"
        [attr.transform]="'translate(-5, -5)'"
      />
    </svg:clipPath>
  </svg:defs>
  <svg:g [attr.transform]="transform" class="line-chart chart">
    <svg:g
      ngx-charts-x-axis
      *ngIf="xAxis"
      [xScale]="xScale"
      [dims]="dims"
      [showGridLines]="showGridLines"
      [showLabel]="showXAxisLabel"
      [labelText]="xAxisLabel"
      [trimTicks]="trimXAxisTicks"
      [rotateTicks]="rotateXAxisTicks"
      [maxTickLength]="maxXAxisTickLength"
      [tickFormatting]="xAxisTickFormatting"
      [ticks]="xAxisTicks"
      (dimensionsChanged)="updateXAxisHeight($event)"
    ></svg:g>
    <svg:g
      ngx-charts-y-axis
      *ngIf="yAxis"
      [yScale]="yScale"
      [dims]="dims"
      [showGridLines]="showGridLines"
      [showLabel]="showYAxisLabel"
      [labelText]="yAxisLabel"
      [trimTicks]="trimYAxisTicks"
      [maxTickLength]="maxYAxisTickLength"
      [tickFormatting]="yAxisTickFormatting"
      [ticks]="yAxisTicks"
      [referenceLines]="referenceLines"
      [showRefLines]="showRefLines"
      [showRefLabels]="showRefLabels"
      (dimensionsChanged)="updateYAxisWidth($event)"
    ></svg:g>
    <svg:g [attr.clip-path]="clipPath">
      <svg:g *ngFor="let series of results" [@animationState]="'active'">
        <ng-container *ngTemplateOutlet="getSeriesTemplate(series) || fallback; context getSeriesContext(series)"></ng-container>
      </svg:g>
      <svg:g *ngIf="!tooltipDisabled" (mouseleave)="hideCircles()">
        <svg:g
          ngx-charts-tooltip-area
          [dims]="dims"
          [xSet]="xSet"
          [xScale]="xScale"
          [yScale]="yScale"
          [results]="results"
          [colors]="colors"
          [tooltipDisabled]="tooltipDisabled"
          [tooltipTemplate]="seriesTooltipTemplate"
          (hover)="updateHoveredVertical($event)"
        />
        <ng-container *ngFor="let series of results">
          <svg:g ngx-charts-circle-series
            *ngIf="series.circles"
            [xScale]="xScale"
            [yScale]="yScale"
            [colors]="colors"
            [data]="series"
            [scaleType]="scaleType"
            [visibleValue]="hoveredVertical"
            [activeEntries]="activeEntries"
            [tooltipDisabled]="tooltipDisabled"
            [tooltipTemplate]="tooltipTemplate"
            (select)="onClick($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
          />
        </ng-container>
      </svg:g>
    </svg:g>
  </svg:g>
  <svg:g
    ngx-charts-timeline
    *ngIf="timeline && scaleType != 'ordinal'"
    [attr.transform]="timelineTransform"
    [results]="results"
    [view]="[timelineWidth, height]"
    [height]="timelineHeight"
    [scheme]="scheme"
    [customColors]="customColors"
    [scaleType]="scaleType"
    [legend]="legend"
    (onDomainChange)="updateDomain($event)"
  >
    <svg:g *ngFor="let series of results">
      <svg:g
        ngx-charts-line-series
        [xScale]="timelineXScale"
        [yScale]="timelineYScale"
        [colors]="colors"
        [data]="series"
        [scaleType]="scaleType"
        [curve]="curve"
        [hasRange]="hasRange"
        [animations]="animations"
      />
    </svg:g>
  </svg:g>
</ngx-charts-chart>

<!-- Default series templates -->
<ng-template [cwiComboChartSeries]="'string'" 
              let-series 
              let-chart=chart 
              let-color=mainColor>
  <svg:g>
    <svg:text
      *ngFor="let point of series.series"
      dominant-baseline="middle"
      [attr.fill]="color"
      [attr.y]="-chart.xScale(point.name)"
      style="transform: rotateZ(90deg)"
    >{{ point.value }}</svg:text>
  </svg:g>
</ng-template>


<ng-template [cwiComboChartSeries]="'icon'" 
              let-series 
              let-color=mainColor
              let-chart=chart>
  <svg:g *ngFor="let point of series.series">
    <svg:line 
      [attr.x1]="chart.xScale(point.name)"
      [attr.y1]="20"
      [attr.x2]="chart.xScale(point.name)"
      [attr.y2]="999"
      stroke="grey">
    </svg:line>

    <svg:text
      style="font-size: 2em;"
      [attr.fill]="color"
      [attr.x]="chart.xScale(point.name) - 13.5"
      [innerHTML]="series.iconUnicode"
      y="20">
    </svg:text>

  </svg:g>
</ng-template>

<ng-template #fallback let-series let-chart=chart>
  <svg:g
    ngx-charts-line-series
    [xScale]="chart.xScale"
    [yScale]="chart.yScale"
    [colors]="chart.colors"
    [data]="series"
    [activeEntries]="chart.activeEntries"
    [scaleType]="chart.scaleType"
    [curve]="chart.curve"
    [rangeFillOpacity]="chart.rangeFillOpacity"
    [hasRange]="chart.hasRange"
    [animations]="chart.animations"
  />
</ng-template>
