import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { RemoteControllerComponent } from './remote-controller.component';
import { optionalMatcher, AuthenticationGuard } from '@cwi/components';
import { Route } from '@angular/compiler/src/core';
import { RemoteControllerTrainsViewComponent } from './remote-controller-trainsview/remote-controller-trainsview.component';
import { RemoteControllerDiagnosticViewComponent } from './remote-controller-diagnosticview/remote-controller-diagnosticview.component';
import { RemoteControllerTrainOptionsComponent } from './remote-controller-trainoptionsview/remote-controller-trainoptionsview.component';
import { RemoteControllerRoleGuard } from './remote-controller.role-guard';

// Questo doppio giro serve ad evitare inlining da parte dell'AOT di angular
// dato che porterebbe ad un errore dato che non si possono chiamare funzioni
// durante la definizione di un decorator...
const remoteControllerPathLogic = optionalMatcher(':trainName?/:systemName?/:planName?');
export function remoteControllerPath(segments: UrlSegment[], group: UrlSegmentGroup, route: Route) {
  return remoteControllerPathLogic(segments, group, route);
}

const routes: Routes = [
  {
    path: 'remote-controller', component: RemoteControllerComponent, canActivate: [ AuthenticationGuard, RemoteControllerRoleGuard ],
    children: [
      { path: 'diagnostic/:trainName', pathMatch: 'full', component: RemoteControllerDiagnosticViewComponent },
      { path: 'options/:trainName', pathMatch: 'full', component: RemoteControllerTrainOptionsComponent },
      { matcher: remoteControllerPath, pathMatch: 'full', component: RemoteControllerTrainsViewComponent },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class RemoteControllerRoutingModule { }
