<ng-container *cwiAsync="diagnostic$; loading spinner; let diagnostic;">
  <cwi-remote-refresh-error-alert *ngIf="diagnostic.error"></cwi-remote-refresh-error-alert>
  <div class="background">
    <div class="container">
      <div class="row">
        <div class="col my-auto d-flex">
          <cwi-remote-title [train]="diagnostic.trainName"></cwi-remote-title>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-4 d-flex">
          <cwi-remote-power-list [systems]="diagnostic.systems" class="w-100"></cwi-remote-power-list>
        </div>
        <div class="col-4 d-flex">
          <cwi-remote-download
            class="w-100"
            [tag]="'acquisitions'"
            [uploadSpeed]="diagnostic.uploadSpeed"
            [uploadSpeeds]="diagnostic.uploadSpeeds"
            [uploadTime]="diagnostic.uploadDuration">
          </cwi-remote-download>
        </div>
        <div class="col-4 d-flex">
          <cwi-remote-storage-list [storages]="diagnostic.systems" class="w-100"></cwi-remote-storage-list>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col d-flex">
          <cwi-remote-subsystem-status-list
            [tag]="'systems'"
            [subSystems]="diagnostic.subSystems">
          </cwi-remote-subsystem-status-list>
        </div>
      </div>
      <div id="systems" class="row tabs">
        <div class="col">
          <cwi-remote-system-tabs [systems]="diagnostic.systems"></cwi-remote-system-tabs>
        </div>
      </div>
  
      <div id="acquisitions" class="row acquisitions">
        <div class="col-12 d-flex">
          <cwi-remote-acquisitions
            [acquisitions]="diagnostic.acquisitions"
            [uploadTime]="diagnostic.uploadDuration">
          </cwi-remote-acquisitions>
        </div>
      </div>
    </div>
  </div>

</ng-container>


<ng-template #spinner>
	<div style="position: relative; top: 50%;" class="text-center">
		<cwi-spinner></cwi-spinner>
	</div>
</ng-template>