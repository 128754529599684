import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ResponderStatus } from "../diagnostic";

@Component({
  selector: 'cwi-remote-responder-status-indicator',
  templateUrl: './responder-status-indicator.component.html',
  styleUrls: ['./responder-status-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponderStatusIndicatorComponent {
  
  @Input()
  public status: ResponderStatus;

  enumValue = ResponderStatus;

  get statusValue(): string {
    switch (this.status)
    {
      case ResponderStatus.Connected:
        return $localize`Connected`;
      case ResponderStatus.Disconnected:
        return $localize`Disconnected`;
      case ResponderStatus.Running:
        return $localize`Running`;
      default:
        return $localize`Disconnected`;
    }
  }

}
