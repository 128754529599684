<mat-card>
  <mat-card-title>
    <div class="row">
      <div class="col" i18n>Acquisitions Info</div>
      <div class="col-auto my-auto small">
        <a *ngIf="anchor" [href]="anchor" class="small" i18n>Go<span class="fas fa-arrow-down ml-1"></span></a>
      </div>
    </div>
  </mat-card-title>
  <mat-divider [inset]="true"></mat-divider>
  <mat-card-content class="p-3">
    <div class="row my-auto">
      <div class="col-6 p-2">
        <cwi-gauge [speed]="uploadSpeed"></cwi-gauge>
      </div>
      <div class="col-6 p-2 text-center">
        <div class="row">
          <div class="col">
            <cwi-remote-download-chart
              class="h-50 w-100"
              [data]="uploadSpeeds"> 
            </cwi-remote-download-chart>  
          </div>
        </div>
        <div class="row mt-3">
          <cwi-remote-download-time [time]="uploadTime"></cwi-remote-download-time>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>