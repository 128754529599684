import { Component, Input, Inject, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { PlanDefinition } from '../plan-definition';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemDefinition } from '../system-definition';
import { SPEED_MODE_SERVICE, ISpeedModeService } from '../service-configuration';
import { SpeedModeDefinition } from '../speed-mode-definition';
import { SpeedModeComponent } from '../speed-mode-creator/speed-mode-creator.component';

@Component({
  selector: 'cwi-remote-speed-mode-panel',
  templateUrl: './speed-mode-panel.component.html',
  styleUrls: ['./speed-mode-panel.component.scss']
})
export class SpeedModePanelComponent implements OnDestroy {

  @Input()
  selectedSystem: SystemDefinition;

  @Input()
  selectedPlan: PlanDefinition;

  @Input()
  public activatedPlan: string;

  private subscription = new Subscription();
  speedModes$: Observable<SpeedModeDefinition[]>;
  speedMode: SpeedModeDefinition;

  constructor(
    @Inject(SPEED_MODE_SERVICE)
    private readonly speedModeService: ISpeedModeService,
    private readonly modalService: NgbModal
  ) {
    this.speedModes$ = speedModeService.speedModes$;
    this.subscription.add(this.speedModes$.subscribe((speedModes: SpeedModeDefinition[]) => {
      if (speedModes) {
        this.speedMode = speedModes[0];
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openAddSpeedMode() {
    const modalRef = this.modalService.open(SpeedModeComponent, {backdrop: 'static', keyboard: false});
    const componentInstance: SpeedModeComponent = modalRef.componentInstance;
    componentInstance.selectedPlan = this.selectedPlan;
    componentInstance.setups = this.selectedSystem.setups;

    this.subscription.add(componentInstance.result.subscribe((result: SpeedModeDefinition) => {
      this.addSpeedMode(result);
      modalRef.dismiss();
    }));
    this.subscription.add(componentInstance.canceled.subscribe(() => {
      modalRef.dismiss();
    }));
  }

  editSpeedMode() {
    const modalRef = this.modalService.open(SpeedModeComponent, {backdrop: 'static', keyboard: false});
    const componentInstance: SpeedModeComponent = modalRef.componentInstance;
    componentInstance.selectedPlan = this.selectedPlan;
    componentInstance.setups = this.selectedSystem.setups;
    componentInstance.speedModeInfo = this.speedMode;
    componentInstance.isActive = this.selectedPlan.name === this.activatedPlan;

    this.subscription.add(componentInstance.result.subscribe((result: SpeedModeDefinition) => {
      this.updateSpeedMode(result);
      modalRef.dismiss();
    }));
    this.subscription.add(componentInstance.canceled.subscribe(() => {
      modalRef.dismiss();
    }));
  }

  async updateSpeedMode(standardMode: SpeedModeDefinition) {
    await this.speedModeService.updateSpeedMode(standardMode).toPromise();
    this.speedModeService.reload();
  }

  async addSpeedMode(speedMode: SpeedModeDefinition) {
    await this.speedModeService.addSpeedMode(speedMode).toPromise();
    this.speedModeService.reload();
  }

  async deleteSpeedMode(speedMode: SpeedModeDefinition) {
    await this.speedModeService.deleteSpeedMode(speedMode.id).toPromise();
    this.speedModeService.reload();
  }

}
