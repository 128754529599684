import { animate, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IPowerService, POWER_SERVICE } from "../power.service";
import { PowerSystem } from "../power/power.component";

const SHUTDOWN_COMMAND_DELAY_MS = 60000;

@Component({
  selector: 'cwi-remote-power-restart-modal',
  templateUrl: './power-restart-modal.component.html',
  styleUrls: ['./power-restart-modal.component.scss'],
  animations: [
    trigger('fade', [      
      transition('void => *', [
        style({opacity: 0}),
        animate(1000, style({opacity: 1}))
      ])
    ])
  ]
})
export class PowerRestartModelComponent {

  restarting = false;
  error: string;

  @Input()
  public name: string;

  @Input()
  public powerSystems: PowerSystem[];

  @Output()
  public closed = new EventEmitter();

  @Output()
  public completed = new EventEmitter();

  public train$: Observable<string>;

  constructor(
    @Inject(POWER_SERVICE)
    private readonly powerService: IPowerService,
    readonly activatedRoute: ActivatedRoute) {
      this.train$ = activatedRoute.params.pipe(
        map(params => params.trainName)
      )
    }

  close() {
    this.closed.next()
  }

  restart(trainName: string) {
    let promises: Promise<void>[] = [];
    for (const powerSystem of this.powerSystems) {
      promises.push(
        this.powerService.restart(trainName, powerSystem.wagonName, powerSystem.name).toPromise()
      );
    }
    Promise.all(promises).then(() => {
      this.restarting = true;
      setTimeout(() => {
        this.restarting = false;
        this.completed.next();
      }, SHUTDOWN_COMMAND_DELAY_MS)
    })
    .catch((err) => {
      this.error = $localize `Error in restarting system`;
    })
  }

  getSystemRole(isMaster: boolean) {
    return isMaster ? $localize`Main` : $localize`Mirror`
  }
}
