import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable()
export class NgbDateMomentAdapter extends NgbDateAdapter<moment.Moment> {
  utc = false;

  fromModel(date: moment.Moment): NgbDateStruct {
    if (date && moment.isMoment(date)) {
      if (this.utc) {
        if (date.isLocal()) {
          date = date.utc();
        }
      } else {
        if (date.isUTC()) {
          date = date.local();
        }
      }

      return {
        year: date.year(),
        month: date.month() + 1,
        day: date.date(),
      };
    } else {
      return null;
    }
  }

  toModel(date: NgbDateStruct): moment.Moment {
    if (!date || isNaN(date.day) || isNaN(date.month) || isNaN(date.day)) {
      return null;
    } else {
      const def = {
        year: date.year,
        month: date.month - 1,
        day: date.day
      };

      return this.utc ? moment.utc(def) : moment(def);
    }
  }
}

// TODO: da aggiungere (se serve) un NgbTimeAdapter...
