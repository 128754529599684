<nav class="navbar navbar-expand-lg">
  <a class="navbar-brand" [routerLink]="['/']">
    <img [src]=configuration.logo.path [alt]=configuration.logo.alt class="logo-img"/>
    <ng-content select="[brand]"></ng-content>
  </a>
  <div class="navbar-collapse">
    <div class="mr-auto">
      <ng-content select="[center]"></ng-content>
    </div>
    <div>
      <ng-content select="[right]"></ng-content>
    </div>
  </div>
  <cwi-language-selector></cwi-language-selector>
  <cwi-user-profile></cwi-user-profile>
</nav>