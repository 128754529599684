import { Observable, Subject, of, throwError } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { REMOTECONTROLLER_CONFIGURATION, IRemoteControllerConfiguration, ISpeedModeService } from './service-configuration';
import { reload } from '@cwi/rx';
import { map, shareReplay, switchMap, take, startWith } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { SpeedModeDefinition } from './speed-mode-definition';

class SpeedModeService implements ISpeedModeService {

  private readonly baseUrl: string;
  public readonly speedModes$: Observable<SpeedModeDefinition[]>;

  constructor(
    private readonly $http: HttpClient,
    private readonly reloadSubject: Subject<any>,
    @Inject(REMOTECONTROLLER_CONFIGURATION)
    configuration: IRemoteControllerConfiguration,
    trainName: string,
    systemName: string,
    planName: string
  ) {
    this.baseUrl = `${configuration.baseUrl}/train/${trainName}/system/${systemName}/plan/${planName}`;
    this.speedModes$ = $http.get<SpeedModeDefinition[]>(`${this.baseUrl}/speeds`).pipe(
      reload(reloadSubject),
      shareReplay(1)
    );
  }

  getSpeedMode(id: number): Observable<SpeedModeDefinition> {
    return this.$http.get<SpeedModeDefinition>(`${this.baseUrl}/speed/${id}`);
  }

  addSpeedMode(body: SpeedModeDefinition): Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/speeds`, body);
  }

  updateSpeedMode(body: SpeedModeDefinition) {
    return this.$http.post<void>(`${this.baseUrl}/speed/${body.id}`, body);
  }

  deleteSpeedMode(id: number): Observable<void> {
    return this.$http.delete<any>(`${this.baseUrl}/speed/${id}`);
  }

  reload() {
    this.reloadSubject.next();
  }

}

@Injectable()
export class SpeedModeServiceFactory {
  constructor(
    private readonly $http: HttpClient,
    @Inject(REMOTECONTROLLER_CONFIGURATION)
    private readonly configuration: IRemoteControllerConfiguration
  ) { }

  create(trainName: string, systemName: string, planName: string, reloadSubject: Subject<any> = new Subject<any>()): ISpeedModeService {
    return new SpeedModeService(this.$http, reloadSubject, this.configuration, trainName, systemName, planName);
  }
}

@Injectable()
export class SpeedModeServiceWrapper implements ISpeedModeService {
  private readonly speedModeService$: Observable<ISpeedModeService>;
  private readonly reloadSubject = new Subject<any>();

  public readonly speedModes$: Observable<SpeedModeDefinition[]>;

  constructor(
    speedModeServiceFactory: SpeedModeServiceFactory,
    activatedRoute: ActivatedRoute
  ) {
    this.speedModeService$ = activatedRoute.params.pipe(
      map(params => 'trainName' in params && 'systemName' in params && 'planName' in params
        ? speedModeServiceFactory.create(params.trainName, params.systemName, params.planName, this.reloadSubject)
        : null
      ),
      shareReplay(1)
    );

    this.speedModes$ = this.speedModeService$.pipe(
      switchMap(service => service
        ? service.speedModes$
        : of(null)),
      shareReplay(1)
    );
  }

  getSpeedMode(id: number): Observable<SpeedModeDefinition> {
    return this.speedModeService$.pipe(
      take(1),
      switchMap(service => service
        ? service.getSpeedMode(id)
        : throwError(new Error($localize`No automode selected.`))
      )
    );
  }

  addSpeedMode(body: SpeedModeDefinition): Observable<void> {
    return this.speedModeService$.pipe(
      take(1),
      switchMap(service => service
        ? service.addSpeedMode(body)
        : throwError(new Error($localize`No automode selected.`))
      )
    );
  }

  deleteSpeedMode(id: number): Observable<void> {
    return this.speedModeService$.pipe(
      take(1),
      switchMap(service => service
        ? service.deleteSpeedMode(id)
        : throwError(new Error($localize`No automode selected.`))
      )
    );
  }

  updateSpeedMode(body: SpeedModeDefinition): Observable<void> {
    return this.speedModeService$.pipe(
      take(1),
      switchMap(service => service
        ? service.updateSpeedMode(body)
        : throwError(new Error($localize`No automode selected.`)))
    );
  }

  reload() {
    this.reloadSubject.next();
  }
}


