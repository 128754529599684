<form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
    <h2 class="header" i18n>Change Password</h2>
    <div class="card-body body">
        <div class="form-group">
            <label i18n>Current password:
                <input type="password" class="form-control input-text ml-3"
                    i18n-placeholder placeholder="Current password"
                    formControlName="currentPassword"/>
            </label>
        </div>
        <div class="form-group">
            <label i18n>New password:
                <input type="password" class="form-control input-text ml-3"
                    i18n-placeholder placeholder="New password"
                    formControlName="newPassword" />
            </label>
        </div>
        <div class="form-group">
            <label i18n>Confirm password:
                <input type="password" class="form-control input-text ml-3"
                    i18n-placeholder placeholder="Confirm password"
                    formControlName="confirmPassword"/>
            </label>
        </div>
        <div class="text-danger">
            <ng-container *ngTemplateOutlet="defaultConfirmPasswordTemplate; context: { $implicit: changePasswordForm.errors }"></ng-container>
        </div>
        <button type="submit" class="btn btn-primary" [disabled]="!changePasswordForm.valid" i18n>Submit</button>
    </div>
</form>

<ng-template #defaultConfirmPasswordTemplate let-errors>
    <ul *ngIf="errors && changePasswordForm.controls.newPassword.touched && changePasswordForm.controls.confirmPassword.touched">
      <li *ngIf="errors.notSamePassword" i18n><small>The passwords do not match</small></li>
    </ul>
</ng-template>
