import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { GeometryTrigger } from './geometry-trigger';
import { TimeSchedulationDefinition } from './time-schedulation-definition';
import { PlanDefinition } from './plan-definition';
import { SystemDefinition } from './system-definition';
import { TrainDefinition } from './train-definition';
import { StandardModeDefinition } from './standard-mode-definition';
import { SpeedModeDefinition } from './speed-mode-definition';
import { ProbeCommandsDefinition } from './probe-commands-definition';
import { SRCUCommandsDefinition } from './srcu-commands-definition';
import { HttpEvent } from '@angular/common/http';
import { OptionsFileCfgDefinition } from './options-fileCfg-definition';
import { GeneralSettingsDefinition } from './general-settings-definition';


export const TRAIN_SERVICE = new InjectionToken<ITrainService>('ITrainService');
export const SYSTEM_SERVICE = new InjectionToken<ISystemService>('ISystemService');
export const OPTIONS_FILECFG_SERVICE = new InjectionToken<IOptionsFileCfgService>('IOptionsFileCfgService');
export const PLAN_SERVICE = new InjectionToken<IPlanService>('IPlanService');
export const TIME_SCHEDULATION_SERVICE = new InjectionToken<ITimeSchedulationService>('ITimeSchedulationService');
export const GEOMETRY_TRIGGER_SERVICE = new InjectionToken<IGeometryTriggerService>('IGeometryTriggerService');
export const STANDARD_MODE_SERVICE = new InjectionToken<IStandardModeService>('IStandardModeService');
export const SPEED_MODE_SERVICE = new InjectionToken<ISpeedModeService>('ISpeedModeService');
export const MANUAL_COMMANDS_SERVICE = new InjectionToken<IManualCommandsService>('IManualCommandsService');
export const UPLOAD_TRACKDATABASE_SERVICE = new InjectionToken<IUploadTrackDatabaseService>('IUploadTrackDatabaseService');
export const GENERAL_SETTINGS_SERVICE = new InjectionToken<IGeneralSettingsService>('IGeneralSettingsService');
export const REMOTECONTROLLER_CONFIGURATION = new InjectionToken<IRemoteControllerConfiguration>('IRemoteControllerConfiguration');
export const TOPOLOGY_CONFIGURATION = new InjectionToken<ITopologyConfiguration>('ITopologyConfiguration');
export const REMOTECONTROLLER_DEFAULT: IRemoteControllerConfiguration = {
  baseUrl: '/api/remote-controller',
  websocket: `${location.origin.replace(/^http/, 'ws')}/api/remote-controller`
};
export const TOPOLOGY_CONFIGURATION_DEFAULT: ITopologyConfiguration = {
  baseUrl: '/api'
};
export const SRCU_COMMANDS_SERVICE = new InjectionToken<ISRCUCommandsService>('ISRCUCommandsService');

export interface IRemoteControllerConfiguration {
  baseUrl: string;
  websocket: string;
}

export interface ITopologyConfiguration {
  baseUrl: string;
}

export interface ITrainService {
  readonly trains$: Observable<Iterable<TrainDefinition>>;
  readonly trainDefinitions$: Observable<TrainDefinition[]>;

  reload();
  getTrain(trainName: string): Observable<TrainDefinition>;
  getSystemService(trainName: string): ISystemService;
  getCommandsService(trainName: string, systemName: string): IManualCommandsService;
}

export interface ISystemService {
  readonly systems$: Observable<Iterable<SystemDefinition>>;

  getSystem(systemName: string): Observable<SystemDefinition>;
}

export interface IOptionsFileCfgService {
  readonly optionsFileCfg$: Observable<OptionsFileCfgDefinition[]>;

  reload();
  upload(systemName: string, file: File): Observable<HttpEvent<any>>;
}

export interface IPlanService {
  readonly plans$: Observable<PlanDefinition[]>;

  reload();
  getPlan(planName: string): Observable<PlanDefinition>;
  addPlan(body: PlanDefinition): Observable<void>;
  deletePlan(planName: string): Observable<void>;
  updatePlan(planName: string, body: PlanDefinition): Observable<void>;
  activatePlan(planName: string): Observable<void>;
  deactivatePlan(planName: string): Observable<void>;
}

export interface IStandardModeService {
  readonly standardModes$: Observable<StandardModeDefinition[]>;

  reload();
  getStandardMode(id: number): Observable<StandardModeDefinition>;
  addStandardMode(body: StandardModeDefinition): Observable<void>;
  deleteStandardMode(id: number): Observable<void>;
  updateStandardMode(body: StandardModeDefinition): Observable<void>;
}

export interface ITimeSchedulationService {
  readonly timeSchedulations$: Observable<TimeSchedulationDefinition[]>;

  reload();
  getTimeSchedulation(id: number): Observable<TimeSchedulationDefinition>;
  addTimeSchedulation(body: TimeSchedulationDefinition): Observable<void>;
  updateTimeSchedulation(body: TimeSchedulationDefinition): Observable<void>;
  deleteTimeSchedulation(id: number): Observable<void>;
}

export interface IGeometryTriggerService {
  readonly geometryTriggers$: Observable<GeometryTrigger[]>;

  reload();
  getGeometryTrigger(id: number): Observable<GeometryTrigger>;
  addGeometryTrigger(body: GeometryTrigger): Observable<void>;
  updateGeometryTrigger(id: number, body: GeometryTrigger): Observable<void>;
  deleteGeometryTrigger(id: number): Observable<void>;
}

export interface ISpeedModeService {
  readonly speedModes$: Observable<SpeedModeDefinition[]>;

  reload();
  getSpeedMode(id: number): Observable<SpeedModeDefinition>;
  addSpeedMode(body: SpeedModeDefinition): Observable<void>;
  deleteSpeedMode(id: number): Observable<void>;
  updateSpeedMode(body: SpeedModeDefinition): Observable<void>;
}

export interface IManualCommandsService {
  pushCommand(body: ProbeCommandsDefinition): Observable<void>;
}

export interface ISRCUCommandsService {

  reload();
  pushCommand(trainName: string, systemName: string, probeId: number, body: SRCUCommandsDefinition): Observable<void>;
}

export interface IUploadTrackDatabaseService {
  upload(file: FormData): Observable<HttpEvent<any>>;
  confirm(): Observable<void>;
  getConfirmable(): Observable<boolean>;
}

export interface IGeneralSettingsService {
  readonly settings$: Observable<GeneralSettingsDefinition>;
  updateOptions(body: GeneralSettingsDefinition): Observable<void>;
  getSettings(): Observable<GeneralSettingsDefinition>;
  
  reload();
}
