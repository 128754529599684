import { Injectable, Inject } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { SystemDefinition } from './system-definition';
import * as immutable from 'immutable';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ISystemService, TRAIN_SERVICE, ITrainService } from './service-configuration';

export class SystemService implements ISystemService {
  public readonly systems$: Observable<Iterable<SystemDefinition>>;
  constructor(
    private readonly systemsMap$: Observable<immutable.Map<string, SystemDefinition>>
  ) {
    this.systems$ = systemsMap$.pipe(map(systems => systems.valueSeq()));
  }

  getSystem(systemName: string) {
    return this.systemsMap$.pipe(
      map(systems => systems.get(systemName)),
      distinctUntilChanged()
    );
  }

  reload() {
    // this.trainService.reload();
  }
}

@Injectable()
export class SystemServiceFactory {
  constructor(
    @Inject(TRAIN_SERVICE)
    private readonly trainService: ITrainService
  ) { }

  create(trainName: string, reloadSubject: Subject<any> = new Subject<any>()): ISystemService {
    return this.trainService.getSystemService(trainName);
  }
}

@Injectable()
export class SystemServiceWrapper implements ISystemService {
  private readonly systemService$: Observable<ISystemService>;
  private readonly reloadSubject = new Subject<any>();

  public readonly systems$: Observable<Iterable<SystemDefinition>>;

  constructor(
    systemServiceFactory: SystemServiceFactory,
    activatedRoute: ActivatedRoute
  ) {
    this.systemService$ = activatedRoute.params.pipe(
      map(params => 'trainName' in params
        ? systemServiceFactory.create(params.trainName, this.reloadSubject)
        : null
      ),
      shareReplay(1)
    );

    this.systems$ = this.systemService$.pipe(
      switchMap(systemService => systemService
        ? systemService.systems$
        : of(null)
      ),
      shareReplay(1)
    );
  }

  getSystem(systemName: string) {
    return this.systemService$.pipe(
      switchMap(systemService => systemService
        ? systemService.getSystem(systemName)
        : of(null)
      ),
      shareReplay(1)
    );
  }

  reload() {
    this.reloadSubject.next();
  }
}
