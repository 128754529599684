import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DeviceDiagnostic, DeviceType } from "../diagnostic";

@Component({
  selector: 'cwi-remote-mobile-status',
  templateUrl: './mobile-status.component.html',
  styleUrls: ['./mobile-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileStatusComponent {

  @Input()
  public mobiles: DeviceDiagnostic[];

  getMobileType(mobile: DeviceDiagnostic): string {
    switch (mobile.type) {
        case "Visual Scripting":
          return $localize`Visual Scripting`;
        default:
        return $localize`Mobile`;
    }
  }
}
