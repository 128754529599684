import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, SidebarSection } from '@cwi/components';
import { DEFAULT_SECTION, SETTINGS_SIDEBAR_SECTION } from './sidebar-sections/sidebar-section.configuration';

@Component({
  selector: 'cwi-pg-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: []
})
export class SettingsComponent {

  sidebarSections: SidebarSection[];
  sidebarExpanded = true;
  defaultSection = DEFAULT_SECTION;

  constructor(
    @Inject(SETTINGS_SIDEBAR_SECTION)
    private readonly sections,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
  ) {
    this.sidebarSections = sections;
  }

  async logout() {
    await this.authenticationService.logout();
    this.router.navigateByUrl('/');
  }

  toggleSidebar() {
    this.sidebarExpanded = !this.sidebarExpanded;
  }
}
