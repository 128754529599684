import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { IRemoteControllerConfiguration, ISRCUCommandsService, REMOTECONTROLLER_CONFIGURATION } from './service-configuration';
import { SRCUCommandsDefinition as SRCUCommandDefinition} from './srcu-commands-definition';
import { shareReplay, map, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

class SRCUCommandsService implements ISRCUCommandsService {

  private readonly baseUrl: string;

  constructor(private readonly $http: HttpClient,
    private readonly reloadSubject: Subject<any>,
    @Inject(REMOTECONTROLLER_CONFIGURATION)
    configuration: IRemoteControllerConfiguration) {
      this.baseUrl = `${configuration.baseUrl}/train`;
    }

  reload() {
    this.reloadSubject.next();
  }

  pushCommand(trainName: string, systemName: string, probeId: number, body: SRCUCommandDefinition): Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/${trainName}/system/${systemName}/probe/${probeId}/commands`, body);
  }
}

@Injectable()
export class SRCUCommandsServiceFactory {
  constructor(
    private readonly $http: HttpClient,
    @Inject(REMOTECONTROLLER_CONFIGURATION)
    private readonly configuration: IRemoteControllerConfiguration
  ) { }

  create(
    reloadSubject: Subject<any> = new Subject<any>()): ISRCUCommandsService {
    return new SRCUCommandsService(this.$http, reloadSubject, this.configuration);
  }
}

@Injectable()
export class SRCUCommandsServiceWrapper implements ISRCUCommandsService {

  private readonly reloadSubject = new Subject<any>();
  private readonly srcuCommandsService$: Observable<ISRCUCommandsService>;

  constructor(
    srcuCommandsFactory: SRCUCommandsServiceFactory,
    activatedRoute: ActivatedRoute) {

      this.srcuCommandsService$ = activatedRoute.params.pipe(
        map(params => params
          ? srcuCommandsFactory.create(this.reloadSubject)
          : null
        ),
        shareReplay(1)
      );

    }

  reload() {
    this.reloadSubject.next();
  }

  pushCommand(trainName: string, systemName: string, probeId: number, body: SRCUCommandDefinition): Observable<void> {
    return this.srcuCommandsService$.pipe(
      take(1),
      switchMap(service => service
        ? service.pushCommand(trainName, systemName, probeId, body)
        : throwError(new Error($localize`No SRCU command selected`))
      )
    );
  }

}
