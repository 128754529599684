import { Observable, Subscription } from 'rxjs';

export const cacheUntilSubscription = () => <T>(source$: Observable<T>) => {
  const items = [];
  let error;
  let completed = false;
  const subscription = source$.subscribe({
    next: item => items.push(item),
    error: e => error = { e },
    complete: () => completed = true
  });

  return new Observable<T>(observer => {
    for (const item of items) {
      observer.next(item);
    }

    let teardown = new Subscription();

    if (error) {
      observer.error(error.e);
    } else if (completed) {
      observer.complete();
    } else {
      teardown = source$.subscribe(
        value => observer.next(value),
        err => observer.error(err),
        () => observer.complete()
      );
    }

    subscription.unsubscribe();

    return teardown;
  });
};
