<form [formGroup]="scheduleForm" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <div class="header">
      <h5 class="modal-title" i18n>{{ edit ? 'Edit' : 'Add' }} schedulation</h5>
      <button [disabled]="isActive" type="button" *ngIf="edit" class="btn text-danger buttons ml-2"
            triggers=manual
            container=body
            autoClose=outside
            i18n-title title="Delete"
            popoverTitle="Are you sure?"
            [ngbPopover]="confirmDelete"
            #confirmPopover="ngbPopover"
            (click)="delete(confirmPopover)">
            <span class="fas fa-trash"></span>&nbsp;
      </button>
    </div>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>
        <span i18n>Setup list</span>
        <select class="form-control" formControlName="setup">
          <option hidden value="" disabled selected i18n>Select one setup </option>
          <option *ngFor="let set of setups" [value]="set"> {{ set }}</option>
        </select>
      </label>
    </div>
    <div class="form-group">
      <div class="custom-control custom-checkbox">
        <label>
          <input type="checkbox" class="custom-control-input" formControlName="alwaysActive" />
          <span class="custom-control-label" i18n>Always active</span>
        </label>
      </div>
    </div>
    <fieldset *ngIf="!scheduleForm.controls.alwaysActive.value" formGroupName="timeGroup">
      <div class="form-group">
        <label>
          <span i18n>Days of week</span>
          <select class="form-control" formControlName="day">
            <option hidden value="" disabled selected i18n>Select one day </option>
            <option *ngFor="let d of days" [value]="formatDayName(d)"> {{ d }}</option>
          </select>
        </label>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col">
            <span i18n>Start time
              <ngb-timepicker [formControl]="timeGroup.controls.startTime" [spinners]="false"></ngb-timepicker>
            </span>
          </div>
          <div class="col">
            <span i18n>End time
              <ngb-timepicker [formControl]="timeGroup.controls.endTime" [spinners]="false"></ngb-timepicker>
            </span>
          </div>
        </div>
      </div>
    </fieldset>
    <div class="invalid-feedback d-block" *ngIf="scheduleForm.controls.timeGroup.errors">
      <ng-container *ngTemplateOutlet="defaultTemplate; context: { $implicit: scheduleForm.controls.timeGroup.errors }"></ng-container>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" [disabled]="disableSubmit()" class="btn btn-primary submit" i18n><span class="fas fa-save"></span> Save</button>
    <button type="button" class="btn btn-danger mr-2 cancel" (click)="cancel();" ngbAutofocus i18n>Cancel</button>
  </div>
</form>

<ng-template let-close=close #confirmDelete>
  <button class="btn btn-sm btn-outline-danger" (click)="close(true)" i18n>Confirm</button>
</ng-template>
<ng-template #defaultNameTemplate let-errors>
  <ul *ngIf=errors>
    <li *ngIf="errors.required" i18n>This field is required</li>
    <li *ngIf="errors.minlength" i18n>Must be at least of {{errors.minlength.requiredLength}} characters </li>
    <li *ngIf="errors.maxlength" i18n>Must be less than {{errors.maxlength.requiredLength}} characters </li>
  </ul>
</ng-template>
<ng-template #defaultSelectTemplate let-errors>
  <ul *ngIf=errors>
    <li *ngIf="errors.required" i18n>Select at least one item</li>
  </ul>
</ng-template>
<ng-template #defaultTemplate let-errors>
  <ul *ngIf="errors && (timeGroup.controls.startTime.touched || timeGroup.controls.endTime.touched)">
    <li *ngIf="errors.timeError" i18n>End time cannot be less or equal than Start time</li>
    <li *ngIf="errors.schedNameExists" i18n>A schedulation with the same name already exists</li>
  </ul>
</ng-template>

