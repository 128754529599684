import { Component, Inject } from '@angular/core';
import { AuthenticationService } from '@cwi/components';
import { Router } from '@angular/router';
import { IRemoteControllerSectionConfiguration, REMOTECONTROLLER_SECTION_CONFIGURATION } from './section.configuration';

@Component({
  selector: 'cwi-pg-remote-controller',
  templateUrl: './remote-controller.component.html',
  styleUrls: ['./remote-controller.component.scss']
})
export class RemoteControllerComponent {

  constructor(
    @Inject(REMOTECONTROLLER_SECTION_CONFIGURATION)
    readonly sectionConfiguration: IRemoteControllerSectionConfiguration,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router
  ) { }

  async logout() {
    await this.authenticationService.logout();
    this.router.navigateByUrl('/');
  }
}
