import { Component, EventEmitter, Inject, Input, Output, TemplateRef } from '@angular/core';
import { PlanDefinition } from '../plan-definition';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  IPlanCreatorConfiguration, PLANCREATOR_CONFIGURATION,
  PLANCREATOR_NAME_VALIDATORS, DEFAULT_PLANCREATOR_NAME_VALIDATORS,
  PLANCREATOR_DESCRIPTION_VALIDATORS, DEFAULT_PLANCREATOR_DESCRIPTION_VALIDATORS,
  DefaultPlanCreatorConfiguration } from './plan-creator.configuration';
import moment from 'moment';
import { SecondsTimeAdapter } from '../remote-controller-time-utility';
import { NgbTimeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateMomentAdapter } from '@cwi/components';

@Component({
  selector: 'cwi-remote-plan-creator',
  templateUrl: './plan-creator.component.html',
  styleUrls: ['./plan-creator.component.scss'],
  providers: [
    {
      provide: PLANCREATOR_NAME_VALIDATORS,
      useValue: DEFAULT_PLANCREATOR_NAME_VALIDATORS
    },
    {
      provide: PLANCREATOR_DESCRIPTION_VALIDATORS,
      useValue: DEFAULT_PLANCREATOR_DESCRIPTION_VALIDATORS
    },
    {
      provide: PLANCREATOR_CONFIGURATION,
      useClass: DefaultPlanCreatorConfiguration
    },
    {
      provide: NgbTimeAdapter,
      useClass: SecondsTimeAdapter
    },
    {
      provide: NgbDateAdapter,
      useClass: NgbDateMomentAdapter
    }
  ]
})
export class PlanCreatorComponent {

  public modes: string[] = ['standard', 'geometry', 'speed'];

  @Input()
  isActive: boolean;

  @Input()
  nameTemplate: TemplateRef<{ $implicit: any }>;

  @Input()
  descriptionTemplate: TemplateRef<{ $implicit: any }>;

  @Input()
  public set planInfo(value: PlanDefinition) {
    this.planInfoInternal = value;
    if (value) {
      this.setPreviousValues();
      this.edit = true;
    } else {
      this.edit = false;
    }
  }

  @Output()
  public readonly result = new EventEmitter<PlanDefinition>();

  @Output()
  public readonly canceled = new EventEmitter<void>();

  edit: boolean;
  planCreatorForm: FormGroup;
  planInfoInternal: PlanDefinition;
  maxValue = 60;
  minValue = 1;

  constructor(
    @Inject(PLANCREATOR_CONFIGURATION)
    configuration: IPlanCreatorConfiguration
  ) {
    this.planCreatorForm = new FormGroup({
      name: new FormControl(null, configuration.nameValidators),
      description: new FormControl(null, configuration.descriptionValidators),
      validityStartDate: new FormControl(Validators.required),
      validityEndDate: new FormControl(Validators.required),
      workingMode: new FormControl(null, Validators.required),
    }, [this.dateValidation]);

  }

  dateValidation(form: FormGroup) {
    const validityStartDate = form.get('validityStartDate').value;
    const validityEndDate = form.get('validityEndDate').value;
    if (validityStartDate && validityEndDate) {
      return validityStartDate < validityEndDate ? null : { dateError: true };
    } else {
      return null;
    }
  }

  cancel() {
    this.planCreatorForm.reset();
    this.setPreviousValues();
  }

  close() {
    this.canceled.next();
  }

  setPreviousValues() {
    this.planCreatorForm.get('name').setValue(this.planInfoInternal.name);
    this.planCreatorForm.get('description').setValue(this.planInfoInternal.description);
    this.planCreatorForm.get('validityStartDate').setValue(this.planInfoInternal.validityStartDate);
    this.planCreatorForm.get('validityEndDate').setValue(this.planInfoInternal.validityEndDate);
    this.planCreatorForm.get('workingMode').setValue(this.planInfoInternal.workingMode);
  }

  onSubmit() {
    this.planCreatorForm.value.name = this.planCreatorForm.value.name.replace(/ {2,}/g, ' ').trim();
    this.planCreatorForm.value.validityStartDate = this.planCreatorForm.value.validityStartDate.toISOString(true);
    this.planCreatorForm.value.validityEndDate = this.planCreatorForm.value.validityEndDate.set({hour:23,minute:59,second:59}).toISOString(true);

    if (this.planCreatorForm.valid) {
      this.result.emit({
        ...this.planCreatorForm.value,
        activation: false,
        creation: moment.utc()
      });
    }
  }

  disableSubmit() {
    if (this.planInfoInternal) {
      if (this.isActive) {
        return true;
      } else {
        return !this.planCreatorForm.valid;
      }
    } else {
      return !this.planCreatorForm.valid;
    }
  }

  getTitle() {
    return this.edit ? $localize`Edit plan` : $localize`Add plan`
  }
}
