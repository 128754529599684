import { Component, Input, Inject, OnDestroy } from '@angular/core';
import { TimeSchedulationDefinition, WeekDay } from '../time-schedulation-definition';
import { Observable, Subject, combineLatest, Subscription } from 'rxjs';
import { startWith, map, scan } from 'rxjs/operators';
import { PlanDefinition } from '../plan-definition';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrioritySchedulationComponent } from '../priority-schedulation/priority-schedulation.component';
import { TimeSchedulationCreatorComponent } from '../time-schedulation-creator/time-schedulation-creator.component';
import { SystemDefinition } from '../system-definition';
import { TIME_SCHEDULATION_SERVICE, ITimeSchedulationService } from '../service-configuration';

@Component({
  selector: 'cwi-remote-time-schedulations',
  templateUrl: './time-schedulations.component.html',
  styleUrls: ['./time-schedulations.component.scss']
})
export class TimeSchedulationsComponent implements OnDestroy {

  @Input()
  selectedSystem: SystemDefinition;

  @Input()
  set selectedPlan(value: PlanDefinition) {
    if (value) {
      this.planInternal = value;
      this.dayChange$.next(this.formatDayName(this.days[0]));
    }
  }
  get selectedPlan() {
    return this.planInternal;
  }

  @Input()
  public activatedPlan: string;

  readonly dayChange$ = new Subject<string>();
  readonly timeSchedulations$: Observable<TimeSchedulationDefinition[]>;
  readonly filteredTimeSchedulations$: Observable<TimeSchedulationDefinition[]>;
  readonly selectedDay$: Observable<string>;

  private subscription = new Subscription();
  private planInternal: PlanDefinition;
  public days: string[] = [
    $localize`Monday`,
    $localize`Tuesday`,
    $localize`Wednesday`,
    $localize`Thursday`,
    $localize`Friday`,
    $localize`Saturday`,
    $localize`Sunday`
  ];
  public selectedDayIndex = 0;
  public filteredSchedulationNumber: number;
  public timeSchedulations: TimeSchedulationDefinition[];

  constructor(
    @Inject(TIME_SCHEDULATION_SERVICE)
    private readonly timeSchedulationService: ITimeSchedulationService,
    private readonly modalService: NgbModal
  ) {
    this.timeSchedulations$ = timeSchedulationService.timeSchedulations$;
    this.selectedDay$ = this.dayChange$.pipe(startWith('Monday'));

    this.filteredTimeSchedulations$ = combineLatest([this.selectedDay$, this.timeSchedulations$]).
      pipe(
      map(([selectedDay, timeSchedulations]) => selectedDay && timeSchedulations ?
        timeSchedulations.filter(sched => sched.day === selectedDay || sched.day === 'ALL')
          .sort((sched1, sched2) => sched1.priority - sched2.priority) :
        null
      )
    );

    this.subscription.add(this.filteredTimeSchedulations$.subscribe((timeSchedulations: TimeSchedulationDefinition[]) => {
      this.timeSchedulations = timeSchedulations;
      this.filteredSchedulationNumber = timeSchedulations ? timeSchedulations.length : 0;
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  formatDayName(day: string): WeekDay {
    return day.substr(0, 3).toUpperCase() as WeekDay;
  }

  updateDay() {
    this.dayChange$.next(this.formatDayName(this.days[this.selectedDayIndex]));
  }

  moveLeft() {
    this.selectedDayIndex -= 1;
    this.updateDay();
  }

  moveRight() {
    this.selectedDayIndex += 1;
    this.updateDay();
  }

  openSort(schedulations: TimeSchedulationDefinition[]) {
    const modalRef = this.modalService.open(PrioritySchedulationComponent, {backdrop: 'static', keyboard: false, });
    const componentInstance: PrioritySchedulationComponent = modalRef.componentInstance;
    componentInstance.schedulations = schedulations;

    this.subscription.add(componentInstance.update.subscribe((result: TimeSchedulationDefinition[]) => {
      this.updatePriorities(result);
      modalRef.dismiss();
    }));
    this.subscription.add(componentInstance.canceled.subscribe(() => {
      modalRef.dismiss();
    }));
  }

  openAddTimeScheduleModal() {
    const modalRef = this.modalService.open(TimeSchedulationCreatorComponent, {backdrop: 'static', keyboard: false});
    const componentInstance: TimeSchedulationCreatorComponent = modalRef.componentInstance;
    componentInstance.totalSchedulationsNumber = this.filteredSchedulationNumber;
    componentInstance.setups = this.selectedSystem.setups;
    const day = componentInstance.scheduleForm.get('timeGroup.day');
    day.setValue(this.formatDayName(this.days[this.selectedDayIndex]));

    this.subscription.add(componentInstance.result.subscribe((result: TimeSchedulationDefinition) => {
      this.addTimeSchedulation(result);
      modalRef.dismiss();
    }));
    this.subscription.add(componentInstance.canceled.subscribe(() => {
      modalRef.dismiss();
    }));
  }

  openEditTimeScheduleModal(schedulation: TimeSchedulationDefinition) {
    const modalRef = this.modalService.open(TimeSchedulationCreatorComponent, {backdrop: 'static', keyboard: false});
    const componentInstance: TimeSchedulationCreatorComponent = modalRef.componentInstance;
    componentInstance.timeSchedulationInfo = schedulation;
    componentInstance.setups = this.selectedSystem.setups;
    componentInstance.planInfo = this.planInternal;
    componentInstance.isActive = this.activatedPlan === this.planInternal.name;

    this.subscription.add(componentInstance.result.subscribe((result: TimeSchedulationDefinition) => {
      this.updateSchedulation(result);
      modalRef.dismiss();
    }));
    this.subscription.add(componentInstance.deleteTimeSchedulation.subscribe((result: TimeSchedulationDefinition) => {
      this.deleteSchedulation(result);
      modalRef.dismiss();
    }));
    this.subscription.add(componentInstance.canceled.subscribe(() => {
      modalRef.dismiss();
    }));
  }

  async updatePriorities(timeSchedulations: TimeSchedulationDefinition[]) {
    for (const sched of timeSchedulations) {
      const newPriority = timeSchedulations.findIndex(k => k.id === sched.id) + 1;
      if (sched.priority !== newPriority) {
        sched.priority = newPriority;
        await this.timeSchedulationService.updateTimeSchedulation(sched).toPromise();
      }
    }
    this.timeSchedulationService.reload();
  }

  async updateSchedulation(timeSchedulation: TimeSchedulationDefinition) {
    await this.timeSchedulationService.updateTimeSchedulation(timeSchedulation).toPromise();
    this.timeSchedulationService.reload();
  }

  async deleteSchedulation(timeSchedulation: TimeSchedulationDefinition) {
    await this.timeSchedulationService.deleteTimeSchedulation(timeSchedulation.id).toPromise();
    this.timeSchedulationService.reload();
  }

  async addTimeSchedulation(timeSchedulation: TimeSchedulationDefinition) {
    await this.timeSchedulationService.addTimeSchedulation(timeSchedulation).toPromise();
    this.timeSchedulationService.reload();
  }

}
