import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { TimeSchedulationDefinition, WeekDay } from '../time-schedulation-definition';
import {
  TIMESCHEDULATIONCREATOR_CONFIGURATION,
  TIMESCHEDULATIONCREATOR_NAME_VALIDATORS,
  DEFAULT_TIMESCHEDULATIONCREATOR_NAME_VALIDATORS,
  DefaultTimeSchedulationCreatorConfiguration,
} from './time-schedulation-creator.configuration';
import { NgbActiveModal, NgbTimeAdapter, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { SecondsTimeAdapter } from '../remote-controller-time-utility';
import { getPopoverResult } from '@cwi/components';
import { PlanDefinition } from '../plan-definition';

@Component({
  selector: 'cwi-remote-time-schedulation-creator',
  templateUrl: './time-schedulation-creator.component.html',
  styleUrls: ['./time-schedulation-creator.component.scss'],
  providers: [
    {
      provide: TIMESCHEDULATIONCREATOR_NAME_VALIDATORS,
      useValue: DEFAULT_TIMESCHEDULATIONCREATOR_NAME_VALIDATORS
    },
    {
      provide: TIMESCHEDULATIONCREATOR_CONFIGURATION,
      useClass: DefaultTimeSchedulationCreatorConfiguration
    },
    {
      provide: NgbTimeAdapter,
      useClass: SecondsTimeAdapter
    }
  ]
})
export class TimeSchedulationCreatorComponent {

  public days: string[] = [
    $localize`Monday`,
    $localize`Tuesday`,
    $localize`Wednesday`,
    $localize`Thursday`,
    $localize`Friday`,
    $localize`Saturday`,
    $localize`Sunday`
  ];

  @Input()
  public isActive: boolean;

  @Input()
  public setups: string[];

  @Input()
  public set timeSchedulationInfo(value: TimeSchedulationDefinition) {
    this.timeSchedulationInternal = value;
    if (value) {
      if (value.day === 'ALL') {
        this.scheduleForm.get('alwaysActive').setValue(true);
      } else {
        this.scheduleForm.get('timeGroup.day').setValue(value.day);
        this.scheduleForm.get('timeGroup.startTime').setValue(value.startTime);
        this.scheduleForm.get('timeGroup.endTime').setValue(value.endTime);
      }
      this.scheduleForm.get('setup').setValue(value.setupName);
      this.edit = true;
    } else {
      this.edit = false;
    }
  }

  public get timeSchedulationInfo() {
    return this.timeSchedulationInternal;
  }

  @Input()
  public set planInfo(value: PlanDefinition) {
    this.planInfoInternal = value;
  }

  public get planInfo() {
    return this.planInfoInternal;
  }

  @Input()
  public totalSchedulationsNumber: number;

  @Output()
  public readonly result = new EventEmitter<TimeSchedulationDefinition>();

  @Output()
  public readonly canceled = new EventEmitter<void>();

  @Output()
  public deleteTimeSchedulation = new EventEmitter<TimeSchedulationDefinition>();

  public scheduleForm: FormGroup;
  public timeGroup: FormGroup;

  public selectedDay: string;
  public selectedSetup: string;
  public timeSchedulationInternal: TimeSchedulationDefinition;
  public planInfoInternal: PlanDefinition;
  edit: boolean;

  constructor(
    @Inject(TIMESCHEDULATIONCREATOR_CONFIGURATION)
    public activeModal: NgbActiveModal
    ) {
    const time = {hour: 0, minute: 0};
    const emptyGroup = new FormControl();
    const timeGroup = new FormGroup({
      day: new FormControl( '' , Validators.required),
      startTime: new FormControl( time , Validators.required),
      endTime: new FormControl( time, Validators.required)
    }, [this.timeValidation]);

    this.timeGroup = timeGroup;

    this.scheduleForm = new FormGroup({
      setup: new FormControl( '', Validators.required ),
      alwaysActive: new FormControl(''),
      get timeGroup() {
        return this.alwaysActive.value ? emptyGroup : timeGroup;
      }
    });
  }

  timeSchedulationRequired = (control: AbstractControl) => {
    if (this.scheduleForm && this.scheduleForm.get('alwaysActive').value) {
      return null;
    } else {
      return Validators.required(control);
    }
  }

  timeValidation(form: FormGroup) {
    const startTimeSeconds = form.get('startTime').value;
    const endTimeSeconds = form.get('endTime').value;
    if (startTimeSeconds && endTimeSeconds) {
      return startTimeSeconds < endTimeSeconds ? null : { timeError: true };
    } else {
      return null;
    }
  }

  formatDayName(day: string): WeekDay {
    return day.substr(0, 3).toUpperCase() as WeekDay;
  }

  disableSubmit() {
    if (this.planInfoInternal) {
      if (this.isActive) {
        return true;
      } else {
        return !this.scheduleForm.valid;
      }
    } else {
      return !this.scheduleForm.valid;
    }
  }

  onSubmit() {
    if (this.scheduleForm.valid) {
      const alwaysActive = this.scheduleForm.get('alwaysActive').value;
      const priority = this.timeSchedulationInternal ? this.timeSchedulationInternal.priority : this.totalSchedulationsNumber + 1;
      const id = this.timeSchedulationInternal ? this.timeSchedulationInternal.id : null;
      if (alwaysActive) {
        this.result.emit({
          id,
          setupName: this.scheduleForm.get('setup').value,
          day: 'ALL',
          priority
        });
      } else {
        this.result.emit({
          id,
          setupName: this.scheduleForm.get('setup').value,
          ...this.timeGroup.value,
          priority
        });
      }
    }
  }

  async delete(popoverRef: NgbPopover) {
    try {
      if (await getPopoverResult<boolean>(popoverRef)) {
        this.deleteTimeSchedulation.next(this.timeSchedulationInternal);
      }
    } catch (e) {
      console.log(e);
    }
  }

  cancel() {
    this.canceled.next();
  }
}
