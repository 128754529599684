import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { SettingsModule, HomePagesModule, MainComponent,
  REMOTECONTROLLER_SECTION_CONFIGURATION,
  PagesModule, HOME_CONFIGURATION, RemoteControllerPagesModule, UserSettingsModule, LOGIN_PAGE_CONFIGURATION, } from '@cwi/pages';
import { RouterModule } from '@angular/router';
import { EvoiaDtHomeConfiguration, EvoiaDtLoginPageConfiguration, EvoiaDtRemoteControllerSectionConfiguration } from './home.configuration';
import { mapOptionsFactoryGenerator } from './map.configuration';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAP_OPTIONS, NAVBAR_CONFIGURATION } from '@cwi/components';
import { AppConfigService } from './services/app-config.service';
import { LANGUAGE_SECTION } from '@cwi/components';
import { ENGLISH } from './languages.configuration';
import { EvoiaDtNavbarConfiguration } from './navbar.configuration';

export function initConfig(appConfig: AppConfigService) {
  return () => appConfig.loadConfig();
}

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    LeafletModule,
    RemoteControllerPagesModule,
    SettingsModule,
    UserSettingsModule,
    HomePagesModule,
    PagesModule,
    BrowserAnimationsModule
  ],
  bootstrap: [MainComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfigService],
      multi: true,
    },
    {
      provide: HOME_CONFIGURATION,
      useValue: EvoiaDtHomeConfiguration
    },
    {
      provide: NAVBAR_CONFIGURATION,
      useValue: EvoiaDtNavbarConfiguration
    },
    {
      provide: LOGIN_PAGE_CONFIGURATION,
      useValue: EvoiaDtLoginPageConfiguration
    },
    {
      provide: REMOTECONTROLLER_SECTION_CONFIGURATION,
      useValue: EvoiaDtRemoteControllerSectionConfiguration
    },
    {
      provide: LANGUAGE_SECTION,
      useValue: ENGLISH,
      multi: true
    },
    {
      provide: MAP_OPTIONS,
      useFactory: mapOptionsFactoryGenerator,
      deps: [AppConfigService]
    }
  ]
})
export class AppModule { }
