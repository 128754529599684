import { Component, Input, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, UserClaims } from '../authentication.service';

@Component({
  selector: 'cwi-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent {

  @Input()
  public userProfileTemplate: TemplateRef<HTMLElement>;

  userInfo: UserClaims;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private router: Router) {
    this.userInfo = authenticationService.userInfo;
  }

  async logout() {
    await this.authenticationService.logout();
    this.router.navigateByUrl('/');
  }

  GoToSettings() {
    this.router.navigate([ `/user-settings/${this.userInfo.userName}` ]);
  }
}
