import { Observable, Subject, of, throwError } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { PlanDefinition } from './plan-definition';
import { HttpClient } from '@angular/common/http';
import { REMOTECONTROLLER_CONFIGURATION, IRemoteControllerConfiguration, IPlanService } from './service-configuration';
import { reload } from '@cwi/rx';
import { map, shareReplay, switchMap, take, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';

function parsePlan(plan: PlanDefinition): PlanDefinition {
  return {
    ...plan,
    creation: moment(plan.creation),
    validityStartDate: moment(plan.validityStartDate),
    validityEndDate: moment(plan.validityEndDate)
  };
}

class PlanService implements IPlanService {

  private readonly baseUrl: string;
  public readonly plans$: Observable<PlanDefinition[]>;

  constructor(
    private readonly $http: HttpClient,
    private readonly reloadSubject: Subject<any>,
    @Inject(REMOTECONTROLLER_CONFIGURATION)
    configuration: IRemoteControllerConfiguration,
    trainName: string,
    systemName: string
  ) {
    this.baseUrl = `${configuration.baseUrl}/train/${trainName}/system/${systemName}`;
    this.plans$ = this.$http.get<PlanDefinition[]>(`${this.baseUrl}/plans`).pipe(
      reload(reloadSubject),
      map(plans => plans.map(parsePlan)),
      shareReplay(1)
    );
  }

  reload() {
    this.reloadSubject.next();
  }

  getPlan(planName: string): Observable<PlanDefinition> {
    return this.plans$.pipe(
      map(plans => parsePlan(plans.find(plan => plan.name === planName))),
      distinctUntilChanged()
    );
  }

  addPlan(body: PlanDefinition): Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/plans`, body);
  }

  deletePlan(planName: string): Observable<void> {
    return this.$http.delete<any>(`${this.baseUrl}/plan/${planName}`);
  }

  updatePlan(planName: string, body: PlanDefinition): Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/plan/${planName}`, body);
  }

  activatePlan(planName: string): Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/plan_activation/${planName}`, {});
  }
  deactivatePlan(planName: string): Observable<void> {
    return this.$http.delete<any>(`${this.baseUrl}/plan_deactivation/${planName}`);
  }
}

@Injectable()
export class PlanServiceFactory {
  constructor(
    private readonly $http: HttpClient,
    @Inject(REMOTECONTROLLER_CONFIGURATION)
    private readonly configuration: IRemoteControllerConfiguration
  ) { }

  create(trainName: string, systemName: string, reloadSubject: Subject<any> = new Subject<any>()): IPlanService {
    return new PlanService(this.$http, reloadSubject, this.configuration, trainName, systemName);
  }
}

@Injectable()
export class PlanServiceWrapper implements IPlanService {
  private readonly planService$: Observable<IPlanService>;
  private readonly reloadSubject = new Subject<any>();

  public readonly plans$: Observable<PlanDefinition[]>;

  constructor(
    planServiceFactory: PlanServiceFactory,
    activatedRoute: ActivatedRoute
  ) {
    this.planService$ = activatedRoute.params.pipe(
      map(params => 'trainName' in params && 'systemName' in params
        ? planServiceFactory.create(params.trainName, params.systemName, this.reloadSubject)
        : null
      ),
      shareReplay(1)
    );

    this.plans$ = this.planService$.pipe(
      switchMap(planService => planService
        ? planService.plans$
        : of(null)
      ),
      shareReplay(1)
    );
  }

  reload() {
    this.reloadSubject.next();
  }

  getPlan(planName: string): Observable<PlanDefinition> {
    return this.planService$.pipe(
      take(1),
      switchMap(planService => planService
        ? planService.getPlan(planName)
        : throwError(new Error($localize`No plan selected.`)))
    );
  }

  addPlan(body: PlanDefinition): Observable<void> {
    return this.planService$.pipe(
      take(1),
      switchMap(planService => planService
        ? planService.addPlan(body)
        : throwError(new Error($localize`No plan selected.`)))
    );
  }

  deletePlan(planName: string): Observable<void> {
    return this.planService$.pipe(
      take(1),
      switchMap(planService => planService
        ? planService.deletePlan(planName)
        : throwError(new Error($localize`No plan selected.`)))
    );
  }

  updatePlan(planName: string, body: PlanDefinition): Observable<void> {
    return this.planService$.pipe(
      take(1),
      switchMap(planService => planService
        ? planService.updatePlan(planName, body)
        : throwError(new Error($localize`No plan selected.`)))
    );
  }

  activatePlan(planName: string): Observable<void> {
    return this.planService$.pipe(
      take(1),
      switchMap(planService => planService
        ? planService.activatePlan(planName)
        : throwError(new Error($localize`No plan selected.`)))
    );
  }

  deactivatePlan(planName: string): Observable<void> {
    return this.planService$.pipe(
      take(1),
      switchMap(planService => planService
        ? planService.deactivatePlan(planName)
        : throwError(new Error($localize`No plan selected.`)))
    );
  }

}
