import { Directive, OnDestroy, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { LeafletDirective } from '@asymmetrik/ngx-leaflet';
import { Subscription } from 'rxjs';
import { startWith, filter } from 'rxjs/operators';
import { Control, ControlPosition, DomEvent } from 'leaflet';

@Directive({
  selector: '[cwiMapControl]'
})
export class MapControlDirective implements OnDestroy {
  private readonly subscription: Subscription;

  private readonly control = new Control({ position: 'topright', });

  @Input('cwiMapControl')
  set position(value: ControlPosition) {
    value = value ?? 'topright';
    this.control.setPosition(value);
  }

  constructor(
    viewContainer: ViewContainerRef,
    templateRef: TemplateRef<any>,
    leaflet: LeafletDirective
  ) {
    const view = viewContainer.createEmbeddedView(templateRef);
    DomEvent.disableClickPropagation(view.rootNodes[0]);
    this.control.onAdd = () => view.rootNodes[0];

    this.subscription = leaflet.mapReady.pipe(
        startWith(leaflet.map),
        filter(map => !!map)
      )
      .subscribe(map => {
        this.control.addTo(map);
      })
      .add(() => view.destroy());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
