<div class="modal-header">
  <h2 i18n>Edit user: {{ userInfo.userName }}</h2>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close();">
    <span aria-hidden="false">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="alert alert-dismissible fade show" role="alert" *ngIf="message"
            [ngClass]="{'alert-danger': isError, 'alert-success': !isError }">
            {{ message }}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeAlert()">
            <span aria-hidden="true">&times;</span>
            </button>
  </div>
  <form [formGroup]="updateDisplayNameForm" (ngSubmit)="onSubmitDisplayName()">
    <div class="input-container">
      <div class="form-group-inline">
        <label i18n>Display name:
            <input type="text" class="form-control input-text ml-3"
                i18n-placeholder
                placeholder="Display name"
                formControlName="displayName"/>
        </label>
      </div>
      <button type="submit" [disabled]="!updateDisplayNameForm.valid"
        class="btn btn-primary btn-sm"
        style="margin-left: auto;" i18n>
        <span class="fas fa-save"></span> Submit
      </button>
    </div>
  </form>
  <form [formGroup]="updateEmailForm" class="mt-3" (ngSubmit)="onSubmitEmail()">
    <div class="input-container">
      <div class="form-group-inline">
        <label i18n>Email:
            <input type="text" class="form-control input-text ml-3" 
                i18n-placeholder
                placeholder="Email"
                formControlName="email"/>
        </label>
      </div>
      <button type="submit" [disabled]="!updateEmailForm.valid"
        class="btn btn-primary btn-sm"
        style="margin-left: auto;" i18n>
        <span class="fas fa-save"></span> Submit
      </button>
    </div>
  </form>
  <form [formGroup]="updatePasswordForm" class="mt-3" (ngSubmit)="onSubmitPassword()">
    <div class="input-container">
      <div>
        <div class="form-group-inline">
          <label i18n>Reset password:
            <input [type]="'password'" class="form-control input-text ml-3"
              i18n-placeholder
              placeholder="Password" 
              formControlName="password"/>
          </label>
        </div>
        <div class="form-group-inline">
          <label i18n>Confirm password:
            <input [type]="'password'" class="form-control input-text ml-3"
              i18n-placeholder
              placeholder="Confirm password" 
              formControlName="confirmPassword" />
          </label>
        </div>
      </div>
      <button type="submit" [disabled]="!updatePasswordForm.valid"
        class="btn btn-primary btn-sm"
        style="margin-left: auto;" i18n>
        <span class="fas fa-save"></span> Submit
      </button>
    </div>
    <div class="text-danger">
      <ng-container *ngTemplateOutlet="defaultConfirmPasswordTemplate; context: { $implicit: updatePasswordForm.errors }"></ng-container>
    </div>
    <div class="invalid-feedback">
      <ng-container *ngTemplateOutlet="defaultPasswordTemplate; context: { $implicit: updatePasswordForm.controls.confirmPassword.errors }"></ng-container>
    </div>
    <div class="invalid-feedback">
      <ng-container *ngTemplateOutlet="defaultPasswordTemplate; context: { $implicit: updatePasswordForm.controls.confirmPassword.errors }"></ng-container>
    </div>
  </form>
</div>

<!-- Default templates -->
<ng-template #defaultPasswordTemplate let-errors>
  <ul *ngIf=errors>
    <li *ngIf="errors.minlength" i18n>Must be at least of {{errors.minlength.requiredLength}} characters </li>
    <li *ngIf="errors.maxlength" i18n>Must be less than {{errors.maxlength.requiredLength}} characters </li>
    <li *ngIf="errors.uppercase" i18n>Must contain an uppercase letter</li>
    <li *ngIf="errors.lowercase" i18n>Must contain a lowercase letter</li>
    <li *ngIf="errors.number" i18n>Must contain a number</li>
  </ul>
</ng-template>

<ng-template #defaultConfirmPasswordTemplate let-errors>
  <ul *ngIf="errors && updatePasswordForm.controls.password.touched && updatePasswordForm.controls.confirmPassword.touched">
    <li *ngIf="errors.notSamePassword" i18n>The passwords do not match</li>
  </ul>
</ng-template>

