import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'cwi-roles-chips',
  templateUrl: './roles-chips.component.html',
  styleUrls: ['./roles-chips.component.scss']
})
export class RolesChipsComponent {

  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  chipCtrl = new FormControl();
  filteredValues: Observable<string[]>;

  @Input()
  public selectedValues: string[] = [];

  @Input()
  public allValues: string[];

  @Input()
  public disabled: boolean;

  @Output()
  public added = new EventEmitter<string>();

  @Output()
  public deleted = new EventEmitter<string>();

  @ViewChild('newChip') newChip: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor() {
    this.filteredValues = this.chipCtrl.valueChanges.pipe(
        startWith(null as string),
        map((value: string | null) => value ? this.filter(value) : this.allValues.slice()));
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (!this.valueAlreadyPresent(value)
      && (value || '').trim()) {
        this.added.next(value);
      }

    if (input) {
      input.value = '';
    }

    this.chipCtrl.setValue(null);
  }

  remove(value: string): void {
    this.deleted.next(value);
  }

  selected(event: MatAutocompleteSelectedEvent) {
    const value = event.option.viewValue;
    if (!this.valueAlreadyPresent(value)) {
      this.added.next(value);
      this.newChip.nativeElement.value = '';
      this.chipCtrl.setValue(null);
    }
  }

  valueAlreadyPresent(value: string) {
    return this.selectedValues.some(val => val === value);
  }

  filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allValues.filter(chip => chip.toLowerCase().indexOf(filterValue) === 0);
  }
}
