import { Component, Output, EventEmitter, Input, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { GeometryTrigger } from '../geometry-trigger';
import { getPopoverResult, GeometryValidator } from '@cwi/components';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

const MAX_OFFSET = 5000; // meters
const MIN_OFFSET = 1000; // meters

@Component({
  selector: 'cwi-remote-geometry-info',
  templateUrl: './geometry-info.component.html',
  styleUrls: ['./geometry-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeometryInfoComponent implements OnDestroy {

  @Input()
  public setups: string[];

  @Input()
  public geometryInfo: GeometryTrigger;

  @Output()
  public readonly geometry = new EventEmitter<{ update: GeometryTrigger } | { delete: number }>();

  get edit(): boolean { return this.geometryInfo.id > 0; }

  constructor(
    private readonly validator: GeometryValidator,
    private readonly detectorRef: ChangeDetectorRef,
  ) { }

  updateSetup(setup: string) {
    if (setup !== this.geometryInfo.setup) {
      this.geometryInfo = {
        ...this.geometryInfo,
        setup
      };
      this.geometry.next({update: this.geometryInfo});
      this.detectorRef.detectChanges();
    }
  }

  updateOffset(offset: number) {
    this.geometryInfo.offset = offset = this.checkMaxMinValues(offset);
    this.geometryInfo = {
      ...this.geometryInfo,
      offset
    };
    this.geometryInfo.notValid = !this.validator.validate(this.geometryInfo);
    this.geometry.next({ update: this.geometryInfo });
    this.detectorRef.detectChanges();
  }

  checkMaxMinValues(offset: number): number {
    if (offset > MAX_OFFSET) {
      return MAX_OFFSET;
    } else if (offset < MIN_OFFSET) {
      return MIN_OFFSET;
    } else {
      return offset;
    }
  }

  cancel() {
    this.geometry.error($localize `User canceled`);
  }

  save() {
    this.geometry.complete();
  }

  async delete(popoverRef: NgbPopover) {
    try {
      if (await getPopoverResult<boolean>(popoverRef)) {
        this.geometry.next({delete: this.geometryInfo.id});
        this.geometry.complete();
      }
    } catch (e) {
      console.log(e);
    }
  }

  checkSaveDisabled(): boolean {
    if (this.geometryInfo) {
      return !this.geometryInfo.setup || this.geometryInfo.notValid;
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.cancel();
  }

  getTitle() {
    return this.edit ? $localize`Edit geometry info` : $localize`Add geometry info`;
  }
}
