import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cwiFilter'
})
export class FilterPipe<T> implements PipeTransform {
  transform(value: T[], filter: (item: T, ...args: any[]) => boolean, ...args: any[]): T[] {
    return value.filter((item) => filter(item, ...args));
  }
}
