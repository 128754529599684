<span class="title" i18n>My Systems</span>
<mat-tab-group class="tab-container" [animationDuration]="0">
    <mat-tab *ngIf="mainSystemContent" label="Main" i18n-label>
        <cwi-remote-system-tab-content
            [isMaster]="true"
            [subSystems]="mainSystemContent.subSystems"
            [respondersMobile]="mainSystemContent.respondersMobile"
            [respondersIsdn]="mainSystemContent.respondersIsdn"
            [respondersPs]="mainSystemContent.respondersPs">
        </cwi-remote-system-tab-content>
    </mat-tab>
    <mat-tab *ngIf="mirrorSystemContent" label="Mirror" i18n-label>
        <cwi-remote-system-tab-content
            [isMaster]="false"
            [subSystems]="mirrorSystemContent.subSystems"
            [respondersMobile]="mirrorSystemContent.respondersMobile"
            [respondersIsdn]="mirrorSystemContent.respondersIsdn"
            [respondersPs]="mirrorSystemContent.respondersPs">
        </cwi-remote-system-tab-content>
    </mat-tab>
</mat-tab-group>