import { Observable, Subject, of, throwError } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { REMOTECONTROLLER_CONFIGURATION, IRemoteControllerConfiguration, IStandardModeService } from './service-configuration';
import { reload } from '@cwi/rx';
import { map, shareReplay, switchMap, take, startWith } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { StandardModeDefinition } from './standard-mode-definition';

class StandardModeService implements IStandardModeService {

  private readonly baseUrl: string;
  public readonly standardModes$: Observable<StandardModeDefinition[]>;

  constructor(
    private readonly $http: HttpClient,
    private readonly reloadSubject: Subject<any>,
    @Inject(REMOTECONTROLLER_CONFIGURATION)
    configuration: IRemoteControllerConfiguration,
    trainName: string,
    systemName: string,
    planName: string
  ) {
    this.baseUrl = `${configuration.baseUrl}/train/${trainName}/system/${systemName}/plan/${planName}`;
    this.standardModes$ = $http.get<StandardModeDefinition[]>(`${this.baseUrl}/standards`).pipe(
      reload(reloadSubject),
      shareReplay(1)
    );
  }

  getStandardMode(id: number): Observable<StandardModeDefinition> {
    return this.$http.get<StandardModeDefinition>(`${this.baseUrl}/standard/${id}`);
  }

  addStandardMode(body: StandardModeDefinition): Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/standards`, body);
  }

  updateStandardMode(body: StandardModeDefinition) {
    return this.$http.post<void>(`${this.baseUrl}/standard/${body.id}`, body);
  }

  deleteStandardMode(id: number): Observable<void> {
    return this.$http.delete<any>(`${this.baseUrl}/standard/${id}`);
  }

  reload() {
    this.reloadSubject.next();
  }

}

@Injectable()
export class StandardModeServiceFactory {
  constructor(
    private readonly $http: HttpClient,
    @Inject(REMOTECONTROLLER_CONFIGURATION)
    private readonly configuration: IRemoteControllerConfiguration
  ) { }

  create(trainName: string, systemName: string, planName: string, reloadSubject: Subject<any> = new Subject<any>()): IStandardModeService {
    return new StandardModeService(this.$http, reloadSubject, this.configuration, trainName, systemName, planName);
  }
}

@Injectable()
export class StandardModeServiceWrapper implements IStandardModeService {
  private readonly standardModeService$: Observable<IStandardModeService>;
  private readonly reloadSubject = new Subject<any>();

  public readonly standardModes$: Observable<StandardModeDefinition[]>;

  constructor(
    standardModeServiceFactory: StandardModeServiceFactory,
    activatedRoute: ActivatedRoute
  ) {
    this.standardModeService$ = activatedRoute.params.pipe(
      map(params => 'trainName' in params && 'systemName' in params && 'planName' in params
        ? standardModeServiceFactory.create(params.trainName, params.systemName, params.planName, this.reloadSubject)
        : null
      ),
      shareReplay(1)
    );

    this.standardModes$ = this.standardModeService$.pipe(
      switchMap(service => service
        ? service.standardModes$
        : of(null)),
      shareReplay(1)
    );
  }

  getStandardMode(id: number): Observable<StandardModeDefinition> {
    return this.standardModeService$.pipe(
      take(1),
      switchMap(service => service
        ? service.getStandardMode(id)
        : throwError(new Error($localize`No automode selected.`))
      )
    );
  }

  addStandardMode(body: StandardModeDefinition): Observable<void> {
    return this.standardModeService$.pipe(
      take(1),
      switchMap(service => service
        ? service.addStandardMode(body)
        : throwError(new Error($localize`No automode selected.`))
      )
    );
  }

  deleteStandardMode(id: number): Observable<void> {
    return this.standardModeService$.pipe(
      take(1),
      switchMap(service => service
        ? service.deleteStandardMode(id)
        : throwError(new Error($localize`No automode selected.`))
      )
    );
  }

  updateStandardMode(body: StandardModeDefinition): Observable<void> {
    return this.standardModeService$.pipe(
      take(1),
      switchMap(service => service
        ? service.updateStandardMode(body)
        : throwError(new Error($localize`No automode selected.`)))
    );
  }

  reload() {
    this.reloadSubject.next();
  }
}


