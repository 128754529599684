import { FormArray, ValidatorFn, Validators } from '@angular/forms';
import { InjectionToken, Inject, Injectable } from '@angular/core';

export interface IUserCreationConfiguration {
  passwordValidators: ValidatorFn|ValidatorFn[];
  usernameValidators: ValidatorFn|ValidatorFn[];
  displaynameValidators: ValidatorFn|ValidatorFn[];
  emailValidators: ValidatorFn|ValidatorFn[];
}

export const USER_CREATION_PASSWORD_VALIDATORS = new InjectionToken<ValidatorFn|ValidatorFn[]>('USER_CREATION_PASSWORD_VALIDATORS');
export const USER_CREATION_USERNAME_VALIDATORS = new InjectionToken<ValidatorFn|ValidatorFn[]>('USER_CREATION_USERNAME_VALIDATORS');
export const USER_CREATION_DISPLAYNAME_VALIDATORS = new InjectionToken<ValidatorFn|ValidatorFn[]>('USER_CREATION_DISPLAYNAME_VALIDATORS');
export const USER_CREATION_EMAIL_VALIDATORS = new InjectionToken<ValidatorFn|ValidatorFn[]>('USER_CREATION_EMAIL_VALIDATORS');

export const USER_CREATION_CONFIGURATION = new InjectionToken<IUserCreationConfiguration>('USER_CREATION_CONFIGURATION');


export const DEFAULT_USER_CREATION_PASSWORD_VALIDATORS: ValidatorFn[] = [
  Validators.required,
];

export const DEFAULT_USER_CREATION_USERNAME_VALIDATORS: ValidatorFn[] = [
  Validators.required
];

export const DEFAULT_USER_CREATION_DISPLAYNAME_VALIDATORS: ValidatorFn[] = [
  Validators.required
];

export const DEFAULT_USER_CREATION_EMAIL_VALIDATORS: ValidatorFn[] = [
  Validators.required,
  Validators.email
];

@Injectable()
export class DefaultUserCreationConfiguration implements IUserCreationConfiguration {
  constructor(
    @Inject(USER_CREATION_PASSWORD_VALIDATORS)
    public readonly passwordValidators: ValidatorFn|ValidatorFn[],
    @Inject(USER_CREATION_USERNAME_VALIDATORS)
    public readonly usernameValidators: ValidatorFn|ValidatorFn[],
    @Inject(USER_CREATION_DISPLAYNAME_VALIDATORS)
    public readonly displaynameValidators: ValidatorFn|ValidatorFn[],
    @Inject(USER_CREATION_EMAIL_VALIDATORS)
    public readonly emailValidators: ValidatorFn|ValidatorFn[]
  ) { }
}
