<mat-card [ngClass]="{'error': subSystem.warning }">
    <mat-card-title class="p-1">
        <div class="row">
            <div class="col-8 my-auto name-container">
                <span class="name">{{ subSystem.name }}</span>
                <span class="subname">NetProbe - DT</span>
            </div>
            <div class="col my-auto" [ngClass]="{'disabled' : subSystem.status === status.Unreachable }">
                <cwi-remote-subsystem-status-indicator [status]="subSystem.status"></cwi-remote-subsystem-status-indicator>
            </div>
        </div>
        <div class="row mt-1" [ngClass]="{'disabled' : subSystem.status === status.Unreachable }">
            <ng-container *ngTemplateOutlet="warningTemplate; context: { $implicit: subSystem.warning }"></ng-container>
        </div>
    </mat-card-title>
    <mat-divider [inset]="true"></mat-divider>
    <mat-card-content class="p-1" [ngClass]="{'disabled' : subSystem.status === status.Unreachable }">
        <div *ngFor="let device of subSystem.devices" class="mt-2">
            <cwi-remote-mobile-status *ngIf="device[1].length > 1" [mobiles]="device[1]"></cwi-remote-mobile-status>
            <cwi-remote-general-device-status class="ml-1 mb-3" *ngIf="device[1].length === 1" [device]="device[1][0]"></cwi-remote-general-device-status>
        </div>
    </mat-card-content>
</mat-card>

<!-- Warning Template-->
<ng-template #warningTemplate let-warning>
    <ng-container *ngIf="warning">
      <span class="warning w-100 small">
        <img class="ml-2" src="assets/icons/diagnostic/alert.svg">
        <span i18n class="ml-2 font-weight-bold">Error:</span>
        <span class="ml-2">{{ warning }}</span>
      </span>
    </ng-container>
</ng-template>