<div class="buttonish"
  [ngClass]="{'Connected' : status === enumValue.Connected, 'Disconnected': status === enumValue.Disconnected,
      'Running' : status === enumValue.Running, 'Disabled' : status === enumValue.Disabled }">
  <div class="status-container p-1">
    <div class="circle" 
      style="width: 10px; height: 10px; border-radius: 5px;"
      [ngClass]="{'Connected' : status === enumValue.Connected, 'Disconnected': status === enumValue.Disconnected,
      'Running' : status === enumValue.Running, 'Disabled' : status === enumValue.Disabled }"></div>
    <span class="ml-2">{{ statusValue }}</span>
  </div>
</div>