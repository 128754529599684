import { Component, Output, EventEmitter, Inject, OnDestroy, Input, Injector } from '@angular/core';
import { PlanDefinition } from '../plan-definition';
import { Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlanCreatorComponent } from '../plan-creator/plan-creator.component';
import { IPlanService, PLAN_SERVICE } from '../service-configuration';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import { HttpErrorResponse } from '@angular/common/http';
import { PlanItemError } from '../plan-item/plan-item.component';
import { SystemDefinition } from '../system-definition';

@Component({
  selector: 'cwi-remote-plans-list',
  templateUrl: './plans-list.component.html',
  styleUrls: ['./plans-list.component.scss']
})
export class PlansListComponent implements OnDestroy {

  @Input()
  public selectedPlanName: string;

  @Input()
  public selectedTrainName: string;

  @Input()
  public selectedSystem: SystemDefinition;

  @Input()
  public activatedPlan: string;

  @Output()
  public readonly planSelected = new EventEmitter<PlanDefinition>();

  @Output()
  public planDeleted: EventEmitter<void> = new EventEmitter<void>();

  readonly plans$: Observable<PlanDefinition[]>;
  private subscription = new Subscription();
  planAlreadyActive: boolean;
  planError: PlanItemError;
  planActivationDisabled: boolean;

  constructor(
    @Inject(PLAN_SERVICE)
    private readonly planService: IPlanService,
    private readonly injector: Injector,
    private readonly modalService: NgbModal
  ) {
    this.plans$ = planService.plans$;
  }

  planClick(plan: PlanDefinition) {
    this.planSelected.next(plan);
  }

  planTrackBy(index: number, plan: PlanDefinition) {
    return plan.name;
  }

  openAddPlanModal() {
    const modalRef = this.modalService.open(PlanCreatorComponent, {backdrop: 'static'});
    const componentInstance: PlanCreatorComponent = modalRef.componentInstance;

    this.subscription.add(componentInstance.result.subscribe((result: PlanDefinition) => {
      this.addPlan(result);
      modalRef.dismiss();
    }));

    this.subscription.add(componentInstance.canceled.subscribe(() => {
      modalRef.dismiss();
    }));
  }

  openEditPlanModal(plan: PlanDefinition) {
    const modalRef = this.modalService.open(PlanCreatorComponent, { backdrop: 'static' });
    const componentInstance: PlanCreatorComponent = modalRef.componentInstance;
    componentInstance.planInfo = plan;
    componentInstance.isActive = this.activatedPlan === plan.name;

    this.subscription.add(componentInstance.result.subscribe((result: PlanDefinition) => {
      this.updatePlan(result);
      modalRef.dismiss();
    }));

    this.subscription.add(componentInstance.canceled.subscribe(() => {
      modalRef.dismiss();
    }));
  }

  async deletePlan(plan: PlanDefinition) {
    await this.planService.deletePlan(plan.name).toPromise();
    this.planDeleted.emit();
    this.planService.reload();
  }

  async addPlan(planDefinition: PlanDefinition) {
    await this.planService.addPlan(planDefinition).toPromise();
    this.planService.reload();
  }

  async updatePlan(plan: PlanDefinition) {
    await this.planService.updatePlan(plan.name, plan).toPromise();
    this.planService.reload();
  }

  async activate(planName: string) {
    try {
      await this.planService.activatePlan(planName).toPromise();
      this.planError = null;
    } catch (error) {
      if (error.status === 412) {
        this.planError = {
          planName,
          error: $localize `The current plan has no configured setup.`
        };
      }
    }
  }

  async activatePlan(plan: PlanDefinition) {
    this.planActivationDisabled = true;
    if (this.activatedPlan != null) {
      const modalRef = this.modalService.open(DeleteConfirmationComponent, {backdrop: 'static', keyboard: false });
      const componentInstance: DeleteConfirmationComponent = modalRef.componentInstance;
      componentInstance.message = $localize `There is an already active plan, do you want to stop it and activate this one?`;
      componentInstance.confirmed.subscribe(async () => {
          await this.activate(plan.name);
          modalRef.close();
      });
      componentInstance.canceled.subscribe(() =>  modalRef.close());
    } else {
      await this.activate(plan.name);
    }
  }

  async deactivate(planName: string) {
    await this.planService.deactivatePlan(planName).toPromise();
  }

  async deactivatePlan(plan: PlanDefinition) {
    const modalRef = this.modalService.open(DeleteConfirmationComponent, {backdrop: 'static', keyboard: false });
    const componentInstance: DeleteConfirmationComponent = modalRef.componentInstance;
    componentInstance.message = $localize `Do you really want disable the plan?`;
    componentInstance.confirmed.subscribe(async () => {
        await this.deactivate(plan.name);
        modalRef.close();
    });
    componentInstance.canceled.subscribe(() => modalRef.close());
  }

  getToggleDisabled(planName: string) {
    return this.activatedPlan !== planName && ![...this.selectedSystem.probes].some(probe => probe.status === 'Idle');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
