import { DOCUMENT } from '@angular/common';
import { Component, Inject, Injectable, Input } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { map, filter, distinctUntilChanged, startWith, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'cwi-pg-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {

  public readonly loading: Observable<boolean>;
  
  constructor(
    @Inject(DOCUMENT)
    private readonly document: Document,
    router: Router) {

      this.toggleMode(false);
      this.loading = router.events.pipe(
        filter(event => event instanceof NavigationStart || event instanceof NavigationEnd),
        map(event => event instanceof NavigationStart),
        debounceTime(100),
        startWith(false),
        distinctUntilChanged()
      );
  }

  toggleMode(darkMode: boolean) {
    if (darkMode) {
      this.document.body.classList.remove('default-mode');
      this.document.body.classList.add('dark-mode');
    } else {
      this.document.body.classList.remove('dark-mode');
      this.document.body.classList.add('default-mode');
    }
  }
}
