import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GENERAL_SETTINGS_SERVICE, IGeneralSettingsService } from '../service-configuration';
import { Subscription } from 'rxjs';
import { GeneralSettingsDefinition } from '../general-settings-definition';

const MAX_VALUE = 60;
const MIN_VALUE = 5;

@Component({
  selector: 'cwi-remote-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnDestroy {

  private subscription = new Subscription();
  maxValue = MAX_VALUE;
  minValue = MIN_VALUE;
  generalOptionsForm: FormGroup;
  settings: GeneralSettingsDefinition;

  constructor(
    @Inject(GENERAL_SETTINGS_SERVICE)
    private readonly generalSettingsService: IGeneralSettingsService,
  ) {
    this.generalOptionsForm = new FormGroup({
      delayStartOnStableSupply: new FormControl(null, [Validators.required, Validators.max(this.maxValue), Validators.min(this.minValue)]),
      delayShutdownOnUnstableSupply: new FormControl(null, [Validators.required, Validators.max(this.maxValue), Validators.min(this.minValue)])
    });

    this.subscription.add(generalSettingsService.settings$.subscribe(settings => {
      if (settings) {
        this.settings = settings;
        this.setPreviousValues();
      }
    }));
  }

  setPreviousValues() {
    this.generalOptionsForm.get('delayStartOnStableSupply').setValue(this.settings.delayStartOnStableSupply / 60);
    this.generalOptionsForm.get('delayShutdownOnUnstableSupply').setValue(this.settings.delayShutdownOnUnstableSupply / 60);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  cancel() {
    this.generalOptionsForm.reset();
    this.setPreviousValues();
  }

  async onSubmitGeneralOptions() {
    if (this.generalOptionsForm.valid) {
      const result: GeneralSettingsDefinition = {
        delayStartOnStableSupply: this.generalOptionsForm.get('delayStartOnStableSupply').value * 60,
        delayShutdownOnUnstableSupply: this.generalOptionsForm.get('delayShutdownOnUnstableSupply').value * 60
      };
      await this.generalSettingsService.updateOptions(result).toPromise();
      await this.generalSettingsService.reload();
      this.generalOptionsForm.reset();
    }
  }
}
