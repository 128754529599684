import { InjectionToken } from '@angular/core';
import { SidebarSection } from '@cwi/components';

export const SETTINGS_SIDEBAR_SECTION = new InjectionToken<SidebarSection>('SETTINGS_SIDEBAR_SECTION');

export const USER_MANAGEMENT_SECTION: SidebarSection = {
    title: $localize`User Management`,
    description: $localize`Configure and display users`,
    link: '/settings/user-management',
    linkCaption: $localize`Go to user management`,
    icon: 'fas fa-users',
};

export const TRACK_SECTION: SidebarSection = {
    title: $localize`Track database`,
    description: $localize`Configure track associated to users and groups`,
    link: '/settings/track-database',
    linkCaption: $localize`Go to track management`,
    icon: 'fas fa-road',
}

export const SOFTWAREUPDATES_SECTION: SidebarSection = {
    title: $localize`Software Updates`,
    description: $localize`Update packages delivery`,
    link: '/settings/software-updates',
    linkCaption: $localize`Go to software updates`,
    icon: 'fas fa-cube',
}

export const DEFAULT_SECTION = USER_MANAGEMENT_SECTION;

