<mat-chip-list #chipList [disabled]="disabled">
  <mat-chip
    *ngFor="let val of selectedValues"
    [removable]="removable"
    (removed)="remove(val)">
    {{ val }}
    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
  </mat-chip>
  <input
    placeholder="New..."
    #newChip
    [formControl]="chipCtrl"
    [matAutocomplete]="auto"
    [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    (matChipInputTokenEnd)="add($event)">
</mat-chip-list>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
  <mat-option *ngFor="let val of filteredValues | async" [value]="val">
    {{ val }}
  </mat-option>
</mat-autocomplete>