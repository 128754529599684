import { Inject, Injectable } from '@angular/core';
import { Observable, Subject, of, throwError } from 'rxjs';
import { GeometryTrigger } from './geometry-trigger';
import { HttpClient } from '@angular/common/http';
import { REMOTECONTROLLER_CONFIGURATION, IRemoteControllerConfiguration, IGeometryTriggerService } from './service-configuration';
import { reload } from '@cwi/rx';
import { shareReplay, map, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

class GeometryTriggerService implements IGeometryTriggerService {
  private readonly baseUrl: string;

  public readonly geometryTriggers$: Observable<GeometryTrigger[]>;

  constructor(
    private readonly $http: HttpClient,
    private readonly reloadSubject: Subject<any>,
    @Inject(REMOTECONTROLLER_CONFIGURATION)
    configuration: IRemoteControllerConfiguration,
    trainName: string,
    systemName: string,
    planName: string
  ) {
    this.baseUrl = `${configuration.baseUrl}/train/${trainName}/system/${systemName}/plan/${planName}`;
    this.geometryTriggers$ = $http.get<GeometryTrigger[]>(`${this.baseUrl}/geometries`).pipe(
      reload(reloadSubject),
      shareReplay(1)
    );
  }

  reload() {
    this.reloadSubject.next();
  }

  getGeometryTrigger(id: number): Observable<GeometryTrigger> {
    return this.$http.get<GeometryTrigger>(`${this.baseUrl}/geometry/${id}`);
  }

  addGeometryTrigger(body: GeometryTrigger): Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/geometries`, body);
  }

  updateGeometryTrigger(id: number, body: GeometryTrigger) {
    return this.$http.post<void>(`${this.baseUrl}/geometry/${id}`, body);
  }

  deleteGeometryTrigger(id: number): Observable<void> {
    return this.$http.delete<any>(`${this.baseUrl}/geometry/${id}`);
  }
}

@Injectable()
export class GeometryTriggerServiceFactory {
  constructor(
    private readonly $http: HttpClient,
    @Inject(REMOTECONTROLLER_CONFIGURATION)
    private readonly configuration: IRemoteControllerConfiguration
  ) { }

  create(
    trainName: string, systemName: string, planName: string,
    reloadSubject: Subject<any> = new Subject<any>()): IGeometryTriggerService {
    return new GeometryTriggerService(this.$http, reloadSubject, this.configuration, trainName, systemName, planName);
  }
}

@Injectable()
export class GeometryTriggerServiceWrapper implements IGeometryTriggerService {
  private readonly geometryTriggerService$: Observable<IGeometryTriggerService>;
  private readonly reloadSubject = new Subject<any>();

  geometryTriggers$: Observable<GeometryTrigger[]>;

  constructor(
    geometryTriggerService: GeometryTriggerServiceFactory,
    activatedRoute: ActivatedRoute
  ) {
    this.geometryTriggerService$ = activatedRoute.params.pipe(
      map(params => 'trainName' in params && 'systemName' in params && 'planName' in params
        ? geometryTriggerService.create(params.trainName, params.systemName, params.planName, this.reloadSubject)
        : null
      ),
      shareReplay(1)
    );

    this.geometryTriggers$ = this.geometryTriggerService$.pipe(
      switchMap(service => service
        ? service.geometryTriggers$
        : of([])),
      shareReplay(1)
    );
  }

  reload() {
    this.reloadSubject.next();
  }

  getGeometryTrigger(id: number): Observable<GeometryTrigger> {
    return this.geometryTriggerService$.pipe(
      take(1),
      switchMap(service => service
        ? service.getGeometryTrigger(id)
        : throwError(new Error($localize`No geometry selected`))
      )
    );
  }

  addGeometryTrigger(body: GeometryTrigger): Observable<void> {
    return this.geometryTriggerService$.pipe(
      take(1),
      switchMap(service => service
        ? service.addGeometryTrigger(body)
        : throwError(new Error($localize`No geometry selected`))
      )
    );
  }

  updateGeometryTrigger(id: number, body: GeometryTrigger): Observable<void> {
    return this.geometryTriggerService$.pipe(
      take(1),
      switchMap(geometryTriggerService => geometryTriggerService
        ? geometryTriggerService.updateGeometryTrigger(id, body)
        : throwError(new Error($localize`No geometry selected`)))
    );
  }

  deleteGeometryTrigger(id: number): Observable<void> {
    return this.geometryTriggerService$.pipe(
      take(1),
      switchMap(service => service
        ? service.deleteGeometryTrigger(id)
        : throwError(new Error($localize`No geometry selected`))
      )
    );
  }
}

