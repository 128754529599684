<form [formGroup]="generalOptionsForm"
    class="card"
    (ngSubmit)="onSubmitGeneralOptions()">
    <div class="general-options">
        <label class="font-weight-bold" i18n>General options</label>
        <div class="options-container">
            <div class="form-group">
                <label i18n>Start after stable supply (min)
                  <input 
                    [formControl]="generalOptionsForm.controls.delayStartOnStableSupply"
                    cwiInputOnlyNumber
                    type="number" min="minValue" max="maxValue" class="form-control form-control-sm inputStyle"
                    [class.is-valid]="generalOptionsForm.controls.delayStartOnStableSupply.valid && generalOptionsForm.controls.delayStartOnStableSupply.touched"
                    [class.is-invalid]="generalOptionsForm.controls.delayStartOnStableSupply.invalid && generalOptionsForm.controls.delayStartOnStableSupply.touched"
                    style="width: 10rem;">
                  <div class="invalid-feedback">
                    <ng-container *ngTemplateOutlet="defaultDelayTemplate; context: { $implicit: generalOptionsForm.controls.delayStartOnStableSupply.errors }"></ng-container>
                  </div>
                </label>
              </div>
              <div class="form-group">
                <label i18n>Stop after unstable supply (min)
                  <input cwiInputOnlyNumber
                    cwiMinMaxInputNumber
                    type="number" min="minValue" max="maxValue" class="form-control form-control-sm inputStyle" [formControl]="generalOptionsForm.controls.delayShutdownOnUnstableSupply"
                    [class.is-valid]="generalOptionsForm.controls.delayShutdownOnUnstableSupply.valid && generalOptionsForm.controls.delayShutdownOnUnstableSupply.touched"
                    [class.is-invalid]="generalOptionsForm.controls.delayShutdownOnUnstableSupply.invalid && generalOptionsForm.controls.delayShutdownOnUnstableSupply.touched"
                    style="width: 10rem;">
                  <div class="invalid-feedback">
                    <ng-container *ngTemplateOutlet="defaultDelayTemplate; context: { $implicit: generalOptionsForm.controls.delayShutdownOnUnstableSupply.errors }"></ng-container>
                  </div>
                </label>
              </div>
              
              <div class="apply ml-auto">
                  <button type="button" class="btn btn-danger mr-2 cancel" (click)="cancel();" ngbAutofocus i18n>Cancel</button>
                  <button type="submit" [disabled]="!generalOptionsForm.valid" class="btn btn-primary" i18n><span class="fas fa-save"></span> Apply</button>
              </div>
        </div>
    </div>
</form>


<ng-template #defaultDelayTemplate let-errors>
  <ul *ngIf=errors>
    <li *ngIf="errors.required" i18n>This field is required</li>
    <li *ngIf="errors.max" i18n>Max value is {{ maxValue }} min </li>
    <li *ngIf="errors.min" i18n>Min value is {{ minValue }} min</li>
  </ul>
</ng-template>