import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'cwi-token-modal-component',
    templateUrl: './token-modal.component.html',
    styleUrls: [],
    providers: [],
  })
  export class TokenModalComponent {
    @Input()
    public token: string;

    @Output()
    public readonly refresh = new EventEmitter();

    constructor(public activeModal: NgbActiveModal) {}

    onRefresh() {
        this.refresh.next();
    }
  }
