import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import {  SystemDiagnostic } from "../diagnostic";

@Component({
  selector: 'cwi-remote-storage-list',
  templateUrl: './storage-list.component.html',
  styleUrls: ['./storage-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StorageListComponent {

  @Input()
  public storages: SystemDiagnostic[];

  
  storagesTrackBy(index: number) {
    return index; 
  }
}
