import { Observable, Subject, throwError, of } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { IRemoteControllerConfiguration, REMOTECONTROLLER_CONFIGURATION,
  IOptionsFileCfgService } from './service-configuration';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { OptionsFileCfgDefinition } from './options-fileCfg-definition';

class OptionsFileCfgService implements IOptionsFileCfgService {
  private readonly baseUrl: string;
  public readonly optionsFileCfg$: Observable<OptionsFileCfgDefinition[]>;

  constructor(
    private readonly $http: HttpClient,
    private readonly reloadSubject: Subject<any>,
    configuration: IRemoteControllerConfiguration,
    trainName: string
  ) {
     this.baseUrl = `${configuration.baseUrl}/train/${trainName}`;
     this.optionsFileCfg$ = $http.get<OptionsFileCfgDefinition[]>(`${this.baseUrl}/file_configs`);
  }

  reload() {
    this.reloadSubject.next();
  }

  upload(systemName: string, file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', `${this.baseUrl}/system/${systemName}/file_configs`, formData, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.$http.request(req);
  }
}

@Injectable()
export class OptionsFileCfgServiceFactory {
  constructor(
    private readonly $http: HttpClient,
    @Inject(REMOTECONTROLLER_CONFIGURATION)
    private readonly configuration: IRemoteControllerConfiguration
  ) { }

  create(
    trainName: string, reloadSubject: Subject<any> = new Subject<any>()): IOptionsFileCfgService {
    return new OptionsFileCfgService(this.$http, reloadSubject, this.configuration, trainName);
  }
}

@Injectable()
export class OptionsFileCfgServiceWrapper implements IOptionsFileCfgService {
  private readonly optionsFileCfgService$: Observable<IOptionsFileCfgService>;
  private readonly reloadSubject = new Subject<any>();

  public readonly optionsFileCfg$: Observable<OptionsFileCfgDefinition[]>;

  constructor(
    optionsFileCfgServiceFactory: OptionsFileCfgServiceFactory,
    activatedRoute: ActivatedRoute
  ) {
    this.optionsFileCfgService$ = activatedRoute.params.pipe(
      map(params => 'trainName' in params
        ? optionsFileCfgServiceFactory.create(params.trainName)
        : null
      ),
      shareReplay(1)
    );
    this.optionsFileCfg$ = this.optionsFileCfgService$.pipe(
      switchMap(optionFileCfgService => optionFileCfgService
        ? optionFileCfgService.optionsFileCfg$
        : of(null)
      ),
      shareReplay(1)
    );
  }

  reload() {
    this.reloadSubject.next();
  }

  upload(systemName: string, file: File): Observable<HttpEvent<any>> {
    return this.optionsFileCfgService$.pipe(
      take(1),
      switchMap(optionFileCfgService => optionFileCfgService
        ? optionFileCfgService.upload(systemName, file)
        : throwError(new Error($localize`No file selected`)))
    );
  }
}
