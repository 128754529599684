import * as immutable from 'immutable';
import { TrainDefinition } from './train-definition';
import { SystemDefinition } from './system-definition';
import { ProbeDefinition } from './probe-definition';
import { isNumber, isString, mapIterable } from '@cwi/lib';

export interface InternalTrainDefinition extends TrainDefinition {
  systemsMap: immutable.Map<string, immutable.RecordOf<InternalSystemDefinition>>;
}

export interface InternalSystemDefinition extends SystemDefinition {
  probesMap: immutable.Map<number, immutable.RecordOf<InternalProbeDefinition>>;
}

export interface InternalProbeDefinition extends ProbeDefinition {
}

export const InternalTrainRecord = immutable.Record<InternalTrainDefinition>({
  name: undefined,
  systemsMap: immutable.Map<any, any>(),
  systems: immutable.List<any>()
});

const InternalSystemRecord = immutable.Record<InternalSystemDefinition>({
  name: undefined,
  probesMap: immutable.Map<any, any>(),
  probes: immutable.List(),
  setups: [],
  mode: undefined,
  setup: undefined,
  status: undefined,
  activatedPlan: undefined,
  lastCommand: undefined
});

const InternalProbeRecord = immutable.Record<InternalProbeDefinition>({
  id: undefined,
  wagon: undefined,
  isMaster: undefined,
  lastUpdate: undefined,
  status: undefined,
  position: undefined,
  mode: undefined,
  setup: undefined,
  powerOn: undefined,
  upTime: undefined,
  setups: undefined,
  powerStatus: undefined,
  errorCode: undefined,
  errorDescription: undefined
});

export function mapTrain(definition: TrainDefinition) {
  const systemsMap = mapSystems(definition.systems);
  return new InternalTrainRecord({
    name: definition.name,
    systemsMap,
    systems: systemsMap.valueSeq()
  });
}

function updateSystemTopic(oldTrain: immutable.RecordOf<InternalTrainDefinition>, update: any[]) {
  if (oldTrain) {
    const newTrain = oldTrain.withMutations(mutator => {
      if (isString(update[0])) {
        mutator.update('systemsMap', oldSystems => updateSystems(oldSystems, update as [string, ...any[]]));
        mutator.set('systems', mutator.systemsMap.valueSeq());
      } else {
        for (const [key, value] of Object.entries(update[0])) {
          if (mutator.has(key)) {
            mutator.set(key, value as any);
          }
        }
      }
    });
    return newTrain;
  } else {
    return InternalTrainRecord(update[0]);
  }
}

export function updateSystemsTopic(
  trains: immutable.OrderedMap<string, immutable.RecordOf<InternalTrainDefinition>>,
  [ trainName, ...updates ]: [ string, ...any[] ]) {
  return trains.update(trainName, oldTrain => updateSystemTopic(oldTrain, updates));
}

export function mapSystems(definitions: Iterable<SystemDefinition>): immutable.Map<string, immutable.RecordOf<InternalSystemDefinition>> {
  return immutable.OrderedMap(mapIterable(
    definitions,
    ({ name, probes, ...rest }) => {
      const probesMap = mapProbes(probes);
      return [ name, InternalSystemRecord({
        name,
        probes: probesMap.valueSeq(),
        probesMap,
        ...rest
      })];
    }
  ));
}

function updateSystem(oldSystem: immutable.RecordOf<InternalSystemDefinition>, update: any[]) {
  if (oldSystem) {
    return oldSystem.withMutations(mutator => {
      if (isNumber(update[0])) {
        mutator.update('probesMap', oldProbes => updateProbes(oldProbes, update as [number, ...any[]]));
        mutator.set('probes', mutator.probesMap.valueSeq());
      } else {
        for (const [key, value] of oldSystem) {
          // Taccone per evitare di cancellare anche i campi per cui non ho una notifica
          if (update[0][key]
            || key === 'activatedPlan'
            || key === 'lastCommand') {
            mutator.set(key, update[0][key]);
          }
        }
      }
    });
  } else {
    return InternalSystemRecord(update[0]);
  }
}

export function updateSystems(
  oldSystems: immutable.Map<string, immutable.RecordOf<InternalSystemDefinition>>,
  [systemName, ...updates]: [ string, ...any[] ]) {
    return oldSystems.update(systemName, oldSystem => updateSystem(oldSystem, updates));
}

export function mapProbes(probes: Iterable<ProbeDefinition>): immutable.Map<number, immutable.RecordOf<InternalProbeDefinition>> {
  return immutable.Map(mapIterable(
    probes,
    ({ id, ...rest}) => {
      return [id, InternalProbeRecord({
        id,
        ...rest
      })];
    }
  ));
}
function updateProbe(oldProbe: immutable.RecordOf<InternalProbeDefinition>, update: any[]) {
  if (oldProbe) {
    return oldProbe.withMutations(mutator => {
      for (const [key, value] of Object.entries(update[0])) {
        if (mutator.has(key)) {
          mutator.set(key, value as any);
        }
      }
    });
  } else {
    return InternalProbeRecord(update[0]);
  }
}

export function updateProbes(
  oldProbes: immutable.Map<number, immutable.RecordOf<InternalProbeDefinition>>,
  [probeName, ...updates]: [ number, ...any[]]) {
  return oldProbes.update(probeName, oldProbe => updateProbe(oldProbe, updates));
}

