import { Inject, Injectable } from '@angular/core';
import { Observable, Subject, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { REMOTECONTROLLER_CONFIGURATION, IRemoteControllerConfiguration, IManualCommandsService, TRAIN_SERVICE, ITrainService } from './service-configuration';
import { shareReplay, map, switchMap, take } from 'rxjs/operators';
import { ProbeCommandsDefinition as ProbeCommandDefinition } from './probe-commands-definition';
import { ActivatedRoute } from '@angular/router';

export class ManualCommandsService implements IManualCommandsService {

  private readonly baseUrl: string;
  lastCommand$: Observable<ProbeCommandDefinition>;

  constructor(
    private readonly $http: HttpClient,
    @Inject(REMOTECONTROLLER_CONFIGURATION)
    configuration: IRemoteControllerConfiguration,
    trainName: string,
    systemName: string
  ) {
    this.baseUrl = `${configuration.baseUrl}/train/${trainName}/system/${systemName}`;
  }

  pushCommand(body: ProbeCommandDefinition): Observable<void> {
    return this.$http.post<void>(`${this.baseUrl}/probe_commands`, body);
  }
}

@Injectable()
export class ManualCommandsServiceFactory {
  constructor(
    @Inject(TRAIN_SERVICE)
    private readonly trainService: ITrainService
  ) { }

  create(trainName: string, systemName: string): IManualCommandsService {
    return this.trainService.getCommandsService(trainName, systemName);
  }
}

@Injectable()
export class ManualCommandsServiceWrapper implements IManualCommandsService {
  private readonly commandService$: Observable<IManualCommandsService>;

  constructor(
    commandServiceFactory: ManualCommandsServiceFactory,
    activatedRoute: ActivatedRoute
  ) {
    this.commandService$ = activatedRoute.params.pipe(
      map(params => 'trainName' in params && 'systemName' in params
        ? commandServiceFactory.create(params.trainName, params.systemName)
        : null
      ),
      shareReplay(1)
    );
  }

  pushCommand(body: ProbeCommandDefinition): Observable<void> {
    return this.commandService$.pipe(
      take(1),
      switchMap(service => service
        ? service.pushCommand(body)
        : throwError(new Error($localize`No manual command selected`))
      )
    );
  }
}
