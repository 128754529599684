import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Input, OnDestroy } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { DeleteConfirmationComponent, UserClaims } from '@cwi/components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserCreationInfo, UserFullInfo } from '../../models/user-definition';
import { GROUP_SERVICE, IGroupService, IRoleService, IUserService, ROLE_SERVICE, USER_SERVICE } from '../../services/service-configuration';
import { UserCreationComponent } from '../user-creation/user-creation.component';
import { UserUpdateComponent } from '../user-update/user-update.component';

@Component({
    selector: 'cwi-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss'],
    animations: [
      trigger('detailExpand', [
        state('collapsed', style({height: '0px', minHeight: '0'})),
        state('expanded', style({height: '*'})),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
    ],
  })
  export class UsersListComponent implements OnDestroy {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    private subscription = new Subscription();

    error: string;
    columnsToDisplay: string[] = ['userName', 'displayName', 'email', 'roles', 'groups', 'actions'];
    users$: Observable<UserFullInfo[]>;
    roles: string[];
    groups: string[];

    @Input()
    userInfo: UserClaims;

    constructor(
      @Inject(USER_SERVICE)
      private readonly userService: IUserService,
      @Inject(ROLE_SERVICE)
      private readonly roleService: IRoleService,
      @Inject(GROUP_SERVICE)
      private readonly groupService: IGroupService,
      private readonly modalService: NgbModal
    ) {
      this.users$ = userService.users$;
      this.subscription.add(this.roleService.roles$.subscribe(res => this.roles = res));
      const groupNames$ = this.groupService.groups$.pipe(
        map(groups => groups.map(group => group.groupName))
      );
      this.subscription.add(groupNames$.subscribe(res => this.groups = res));
    }

    ngOnDestroy(): void {
      this.subscription.unsubscribe();
    }

    openUserCreationModal() {
      const modalRef = this.modalService.open(UserCreationComponent, {backdrop: 'static', keyboard: false});
      const componentInstance: UserCreationComponent = modalRef.componentInstance;

      this.subscription.add(componentInstance.created.subscribe((result: UserCreationInfo) => {
        this.userService.addUser(result).subscribe(
          (response) => {
            modalRef.dismiss();
            this.userService.reload();
          },
          (error: HttpErrorResponse) => {
            if (error.status === 409) {
              componentInstance.error = $localize `User already exists.`;
            }
          });
      }));
      this.subscription.add(componentInstance.closed.subscribe(() => {
        modalRef.dismiss();
      }));
    }

    openUserEditModal(user: UserFullInfo) {
      const modalRef = this.modalService.open(UserUpdateComponent, {backdrop: 'static', keyboard: false});
      const componentInstance: UserUpdateComponent = modalRef.componentInstance;
      componentInstance.userInfo = user;

      this.subscription.add(componentInstance.passwordUpdated.subscribe((password: string) => {
        // TODO Hanfle errors if needed
        this.userService.resetPassword(user.userName, password).subscribe(
          (response) => {
            componentInstance.message = $localize `Password changed correctly.`;
          }
        );
      }));
      this.subscription.add(componentInstance.emailUpdated.subscribe((email: string) => {
        // TODO Hanfle errors if needed
        this.userService.resetEmail(user.userName, email).subscribe(
          (response) => {
            componentInstance.message = $localize `Email changed correctly.`;
          }
        );
      }));
      this.subscription.add(componentInstance.displayNameUpdated.subscribe((displayName: string) => {
        // TODO Hanfle errors if needed
        this.userService.resetDisplayName(user.userName, displayName).subscribe(
          (response) => {
            componentInstance.message = $localize `Display name changed correctly.`;
          }
        );
      }));

      this.subscription.add(componentInstance.closed.subscribe(() => {
        this.userService.reload();
        modalRef.dismiss();
      }));
    }

    deleteUser(userName: string) {
      const modalRef = this.modalService.open(DeleteConfirmationComponent, {backdrop: 'static', keyboard: false});
      const componentInstance: DeleteConfirmationComponent = modalRef.componentInstance;
      const deleteModalSettings = {
        title: $localize `Confirm delete`,
        text: $localize `Are you sure you want to delete ${userName}?`
      }
      componentInstance.deleteModalSettings = deleteModalSettings;
      this.subscription.add(componentInstance.confirmed.subscribe(async () => {
          this.userService.deleteUser(userName).subscribe(
            (response) => {
              modalRef.dismiss();
              this.userService.reload();
            },
          );
        }));

      this.subscription.add(componentInstance.closed.subscribe(() => {
        modalRef.dismiss();
      }));
    }

    roleAdded(userInfo: UserFullInfo, role: string) {
      this.roleService.addRole(userInfo.userName, role).subscribe(
        (response) => {
          userInfo.roles.push(role);
        },
        (error: HttpErrorResponse) => {
          if (error.status === 404) {
            this.error = $localize `Role does not exist.`;
          }
        }
      );
    }

    roleDeleted(userInfo: UserFullInfo, role: string) {
      this.roleService.deleteRole(userInfo.userName, role).subscribe(
        (response) => {
          const roleIndex = userInfo.roles.findIndex(item => item === role);
          userInfo.roles.splice(roleIndex, 1);
        },
        (error: HttpErrorResponse) => {
          // TODO Handle
        }
      );
    }

    groupAdded(userInfo: UserFullInfo, groupName: string) {
      this.groupService.addUserToGroup(userInfo.userName, groupName).subscribe(
        (response) => {
          userInfo.groups.push(groupName);
        },
        (error: HttpErrorResponse) => {
          if (error.status === 40) {
            this.error = $localize `Group does not exist.`;
          }
        }
      );
    }

    groupDeleted(userInfo: UserFullInfo, groupName: string) {
      this.groupService.removeGroupFromUser(userInfo.userName, groupName).subscribe(
        (response) => {
          const roleIndex = userInfo.groups.findIndex(item => item === groupName);
          userInfo.groups.splice(roleIndex, 1);
        },
        (error: HttpErrorResponse) => {
          // TODO Handle
        }
      );
    }

    actionsEnabled(user: UserFullInfo) {
      return user.userName !== this.userInfo.userName;
    }

    refreshUsers() {
      this.userService.reload();
    }

    closeAlert() {
      this.error = null;
    }
}
