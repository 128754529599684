import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TimeSchedulationDefinition } from '../time-schedulation-definition';
import { Observable, timer, Subscription } from 'rxjs';
import moment, { Moment } from 'moment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cwi-remote-schedulation-timeline',
  templateUrl: './schedulation-timeline.component.html',
  styleUrls: ['./schedulation-timeline.component.scss']
})
export class SchedulationTimelineComponent implements OnDestroy {

  @Input()
  public timeSchedulations: TimeSchedulationDefinition[];

  @Output()
  public schedulationClicked = new EventEmitter<TimeSchedulationDefinition>();

  get filteredSchedulations(): TimeSchedulationDefinition[] {
    return (this.timeSchedulations || []);
  }
  private timerRefresh = 60; // secondi
  private subscription = new Subscription();

  public dateTimeNowSeconds: number;
  public time$: Observable<Moment>;

  constructor() {
    this.time$ = timer(0, this.timerRefresh * 1000).pipe(
      map(() => moment.utc())
    );

    this.subscription.add(this.time$.subscribe((date: Moment) => {
      const hours = date.local().hours() * 3600;
      const minutes = date.local().minutes() * 60;
      const seconds = date.local().seconds();
      this.dateTimeNowSeconds = hours + minutes + seconds;
    }));
  }

  public hours: string[] = [
    '', '01', '02', '03', '04', '05', '06', '07', '08',
    '09', '10', '11', '12', '13', '14', '15', '16', '17',
    '18', '19', '20', '21', '22', '23', ''
  ];

  schedulationClick(schedulation: TimeSchedulationDefinition) {
    this.schedulationClicked.next(schedulation);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
