import { isNullOrUndefined } from '@cwi/lib';
import { NgbTimeStruct, NgbTimeAdapter, NgbDateStruct, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

export function numberToTimeStruct(value: number): NgbTimeStruct {
    if (isNullOrUndefined(value)) {
        return null;
    } else {
        const hour = Math.floor(value / 3600);
        const minute = Math.floor((value / 60) % 60);
        const second = Math.floor(value % 60);

        return {
        hour,
        minute,
        second
        };
    }
}

export function timeStructToNumber(time: NgbTimeStruct): number {
    if (time) {
        return time.hour * 3600 + time.minute * 60 + time.second;
    } else {
        return null;
    }
}

@Injectable()
export class SecondsTimeAdapter extends NgbTimeAdapter<number> {

  fromModel(value: number): NgbTimeStruct {
    return numberToTimeStruct(value);
  }

  toModel(time: NgbTimeStruct): number {
    return timeStructToNumber(time);
  }
}

