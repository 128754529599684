<mat-sidenav-container class="sidenav-container" autosize="true">
    <mat-sidenav mode="side" opened>
        <mat-nav-list>
            <mat-list-item *ngFor="let section of sidebarSections" class="mat-list-item-container" [class.active]="routeIsActive(section.link)" [routerLink]="section.link" [title]="section.title" >
                <div class="mat-item-container">
                    <span [class]="section.icon" class="icon"></span>
                    <p *ngIf="isExpanded" class="mt-1"> {{ section.title }}</p>
                </div>
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>



