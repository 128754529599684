import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[cwiClickSink]'
})
export class ClickSinkDirective {
  @HostListener('click', ['$event'])
  clickSink(event: MouseEvent) {
    event.stopPropagation();
  }
}
