import { Component, Input } from '@angular/core';
import { ProbeDefinition } from '../probe-definition';

@Component({
  selector: 'cwi-remote-probe-status',
  templateUrl: './probe-status.component.html',
  styleUrls: ['./probe-status.component.scss']
})
export class ProbeStatusComponent {

  constructor() { }

  @Input()
  public probeStatus: ProbeDefinition;

}
