  <cwi-navbar>
    <span brand>Settings</span>
    <ul class="navbar-nav" *ngIf="sidebarSections.length > 0" center>
      <button mat-icon-button (click)="toggleSidebar()"><mat-icon ckass="material-icons color_gray">menu</mat-icon></button>
    </ul>
  </cwi-navbar>
  <cwi-sidebar
    [title]="'CWI - Settings'"
    [sidebarSections]="sidebarSections"
    [isExpanded]="sidebarExpanded"
    [startingSection]="defaultSection">
  </cwi-sidebar>