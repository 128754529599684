import { Component, OnDestroy, Inject, Injector, forwardRef, NgZone } from '@angular/core';
import { CircleFactory, GEOMETRY_DRAWER_FACTORY, RectangleDrawerFactory,
  BufferedCircleDrawerFactory, CircleDrawerFactory, PolygonDrawerFactory,
  GEOMETRY_VALIDATOR_FUNCTOR,	GEOMETRY_FACTORY,	PolygonValidator,	RectangleFactory,	PolygonFactory, BufferedPolygonDrawerFactory,
  GeometryDefinition,
  GeometryValidatorFunctor,
  distance,
  BufferedRectangleDrawerFactory,
  GeometryValidator,
  GeometryDrawingStep,
  GEOMETRY_DRAWING_STEP, geometryContainsAnother, MAP_OPTIONS
  } from '@cwi/components';
import { Observable, Subscription, ReplaySubject, of, Subject, combineLatest, interval, merge, empty } from 'rxjs';
import {
  TrainDefinition,
  PlanDefinition,
  GeometryTrigger,
  GeometryInfoComponent,
  SystemDefinition,
  SystemServiceWrapper,
  PlanServiceWrapper,
  TimeSchedulationServiceWrapper,
  GeometryTriggerServiceWrapper,
  SYSTEM_SERVICE,
  PLAN_SERVICE,
  TIME_SCHEDULATION_SERVICE,
  GEOMETRY_TRIGGER_SERVICE,
  TRAIN_SERVICE,
  ITrainService,
  ISystemService,
  IGeometryTriggerService,
  IPlanService,
  STANDARD_MODE_SERVICE,
  StandardModeServiceWrapper,
  SPEED_MODE_SERVICE,
  SpeedModeServiceWrapper,
  MANUAL_COMMANDS_SERVICE,
  ManualCommandsServiceWrapper,
  GENERAL_SETTINGS_SERVICE,
  GeneralSettingsServiceWrapper,
  IGeneralSettingsService,
} from '@cwi/remote-controller';
import { Router, ActivatedRoute } from '@angular/router';
import * as L from 'leaflet';
import { catchError, concatMap, startWith, switchMap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { append } from '@cwi/rx';
import { MapOptions } from 'leaflet';
import { ITrackDatabaseService, TRACKDATABASE_SERVICE } from '../../settings/services/service-configuration';
import { Line } from '../../settings/models/line-definition';
import { GeneralSettingsDefinition } from 'shared/cwi/remote-controller/lib/general-settings-definition';

const SOUTH_WEST = L.latLng(-90, -180);
const NORTH_EAST = L.latLng(90, 180);
const POLLING_MS = 300000

@Component({
  selector: 'cwi-pg-remote-controller-trainsview',
  templateUrl: './remote-controller-trainsview.component.html',
  styleUrls: ['./remote-controller-trainsview.component.scss'],
  providers: [
    {
      provide: SYSTEM_SERVICE,
      useClass: SystemServiceWrapper
    },
    {
      provide: PLAN_SERVICE,
      useClass: PlanServiceWrapper
    },
    {
      provide: TIME_SCHEDULATION_SERVICE,
      useClass: TimeSchedulationServiceWrapper
    },
    {
      provide: GEOMETRY_TRIGGER_SERVICE,
      useClass: GeometryTriggerServiceWrapper
    },
    {
      provide: STANDARD_MODE_SERVICE,
      useClass: StandardModeServiceWrapper
    },
    {
      provide: SPEED_MODE_SERVICE,
      useClass: SpeedModeServiceWrapper
    },
    {
      provide: MANUAL_COMMANDS_SERVICE,
      useClass: ManualCommandsServiceWrapper
    },
    {
      provide: GENERAL_SETTINGS_SERVICE,
      useClass: GeneralSettingsServiceWrapper
    },
    {
      provide: GEOMETRY_VALIDATOR_FUNCTOR,
      useClass: PolygonValidator,
      multi: true
    },
    {
      provide: GEOMETRY_FACTORY,
      useClass: RectangleFactory,
      multi: true
    },
    {
      provide: GEOMETRY_FACTORY,
      useClass: CircleFactory,
      multi: true
    },
    {
      provide: GEOMETRY_FACTORY,
      useClass: PolygonFactory,
      multi: true
    },
    {
      provide: GEOMETRY_DRAWER_FACTORY,
      useClass: BufferedCircleDrawerFactory,
      multi: true
    },
    {
      provide: GEOMETRY_DRAWER_FACTORY,
      useClass: BufferedRectangleDrawerFactory,
      multi: true
    },
    {
      provide: GEOMETRY_DRAWER_FACTORY,
      useClass: BufferedPolygonDrawerFactory,
      multi: true
    },
    {
      provide: GEOMETRY_DRAWER_FACTORY,
      useClass: PolygonDrawerFactory,
      multi: true
    },
    {
      provide: GEOMETRY_DRAWER_FACTORY,
      useClass: RectangleDrawerFactory,
      multi: true
    },
    {
      provide: GEOMETRY_DRAWER_FACTORY,
      useClass: CircleDrawerFactory,
      multi: true
    },
    {
      provide: GEOMETRY_VALIDATOR_FUNCTOR,
      useExisting: forwardRef(() => RemoteControllerTrainsViewComponent),
      multi: true
    },
    {
      provide: GEOMETRY_DRAWING_STEP,
      useExisting: forwardRef(() => RemoteControllerTrainsViewComponent),
      multi: true
    },
    GeometryValidator
  ]
})
export class RemoteControllerTrainsViewComponent implements OnDestroy, GeometryValidatorFunctor, GeometryDrawingStep {


  private trackLayer: L.LayerGroup = L.layerGroup();
  private leafletMap: L.Map;
  private subscription = new Subscription();

  leafletOptions = {
    center: this.mapOptions.center,
    minZoom: this.mapOptions.minZoom,
    maxZoom: this.mapOptions.maxZoom,
    zoom: this.mapOptions.zoom,
    layers: [ this.mapOptions.layers[0], this.trackLayer],
    maxBoundsViscosity: 1,
    maxBounds: L.latLngBounds(SOUTH_WEST, NORTH_EAST),
  };
  
  layersControl = {
    overlays: {
      'Track database': this.trackLayer
    }
  }

  readonly geometryViewer = new ReplaySubject<GeometryDefinition[]>(1);
  readonly mapReady = new Subject<boolean>();
  readonly trains$: Observable<Iterable<TrainDefinition>>;
  readonly systems$: Observable<Iterable<SystemDefinition>>;
  readonly plans$: Observable<PlanDefinition[]>;

  readonly selectedTrain$: Observable<TrainDefinition>;
  readonly selectedSystem$: Observable<SystemDefinition>;
  readonly selectedPlan$: Observable<PlanDefinition>;

  selectedTrain: TrainDefinition;
  selectedSystem: SystemDefinition;
  selectedPlan: PlanDefinition;
  filteredSchedulationNumber: number;
  geometries: GeometryTrigger[];
  trainPosition: L.LatLngTuple;
  autoModeActive: boolean;
  inDrawingMode: boolean;
  followedTrains: [string, boolean][];
  error: boolean;
  messages: string[] = ['Error whatever'];
  immediateStartRecording: boolean;

  constructor(
    @Inject(TRAIN_SERVICE)
    private readonly trainService: ITrainService,
    @Inject(SYSTEM_SERVICE)
    private readonly systemService: ISystemService,
    @Inject(PLAN_SERVICE)
    private readonly planService: IPlanService,
    @Inject(GEOMETRY_TRIGGER_SERVICE)
    private readonly geometryTriggerService: IGeometryTriggerService,
    @Inject(TRACKDATABASE_SERVICE)
    private readonly trackService: ITrackDatabaseService,
    @Inject(GENERAL_SETTINGS_SERVICE)
    private readonly settingsService: IGeneralSettingsService,
    @Inject(MAP_OPTIONS)
    private readonly mapOptions: MapOptions,
    private readonly router: Router,
    activatedRoute: ActivatedRoute,
    private readonly modalService: NgbModal,
    private readonly injector: Injector,
    private zone: NgZone
  ) {
    this.trains$ = this.trainService.trains$;
    this.systems$ = this.systemService.systems$;

    this.selectedTrain$ = activatedRoute.params.pipe(
      switchMap(params => 'trainName' in params
        ? this.trainService.getTrain(params.trainName)
        : of(null)
      )
    );
    this.selectedSystem$ = activatedRoute.params.pipe(
      switchMap(params => 'systemName' in params
        ? systemService.getSystem(params.systemName)
        : of(null)
      )
    );
    this.selectedPlan$ = activatedRoute.params.pipe(
      switchMap(params => 'planName' in params
        ? this.planService.getPlan(params.planName)
        : of(null)
      )
    );

    this.subscription.add(this.trainService.trainDefinitions$.subscribe((trains: TrainDefinition[]) => {
      if (trains) {
        this.error = false;
        this.followedTrains = trains.map(train => {
          return [train.name, false];
        });
      }
    }));

    this.subscription.add(this.selectedTrain$.subscribe((train: TrainDefinition) => {
      this.selectedTrain = train;
    }));

    this.subscription.add(this.selectedSystem$.subscribe((system: SystemDefinition) => {
      this.selectedSystem = system;
    }));

    this.subscription.add(this.selectedPlan$.subscribe((plan: PlanDefinition) => {
      this.selectedPlan = plan;
    }));


    this.subscription.add(combineLatest([this.geometryTriggerService.geometryTriggers$, this.mapReady.pipe()])
      .subscribe(([triggers, mapReady]) => {
      this.geometries = triggers;
      if (triggers && mapReady) {
        this.geometryViewer.next(triggers.map(trigger => ({ ...trigger, valid: true })));
      }
    }));

    this.subscription.add(combineLatest([this.trackService.lines$, this.mapReady.pipe()])
      .subscribe(([lines, mapReady]) => {
      if (lines && mapReady) {
        this.drawTracks(lines)
      }}
    ));

    this.subscription.add(settingsService.settings$.subscribe(settings => {
      if (settings) {
      }
    }));

    this.subscription.add(activatedRoute.params.pipe(
      switchMap(params => 'trainName' in params ? interval(POLLING_MS).pipe(
        startWith(this.settingsService.settings$),
        concatMap(() => {
          return this.settingsService.getSettings()
            .pipe(
              catchError(error => of({ error }))
            );
        })) : of(undefined)
      ),
    ).subscribe((result: GeneralSettingsDefinition) => {
        if (result) {
          this.immediateStartRecording = result.immediateStartRecording
        }
      }
    ));
  }

  setDrawingMode(result: boolean) {
    this.inDrawingMode = result;
  }

  ngOnDestroy(): void {
        this.subscription.unsubscribe();
  }

  resizeMap() {
    if (this.leafletMap) {
      setTimeout(() => {
        this.leafletMap.invalidateSize();
      }, 1000);
    }
  }

  onMapReady(leafletMap: L.Map) {
    this.leafletMap = leafletMap;
    if (leafletMap) {
      this.resizeMap();
      this.mapReady.next(true);
      this.leafletMap.on('dragstart', () => {
        this.disableFollow();
      });
      this.leafletMap.on('zoomend', () => {
        var zoom = this.leafletMap.getZoom();
        if (zoom > 13) {
          this.trackLayer.eachLayer((layer: L.Polygon) => layer.setStyle({weight: 8, dashArray: '8,10', }))
        }
        else if (zoom <  this.leafletMap.getMinZoom()) {
          this.trackLayer.eachLayer((layer: L.Polygon) => layer.setStyle({weight: 1}))
        }
        else {
          this.trackLayer.eachLayer((layer: L.Polygon) => layer.setStyle({weight: 3, dashArray:'0'}))
        }
      })
    }
  }

  trainTrackBy(index: number, train: TrainDefinition) {
    return train.name;
  }

  // ******* CHANGE ROUTES *********

  setTrainSelected(train: TrainDefinition) {
    this.zone.run(() => {
      this.router.navigate([ `/remote-controller/${train.name}` ]);
    });
  }

  setSystemSelected(system: SystemDefinition) {
    this.router.navigate([ `/remote-controller/${this.selectedTrain.name}/${system.name}` ]);
  }

  setPlanSelected(plan: PlanDefinition) {
    this.router.navigate([ `/remote-controller/${this.selectedTrain.name}/${this.selectedSystem.name}/${plan.name}` ]);
  }

  planDeleted() {
    this.router.navigate([ `/remote-controller/${this.selectedTrain.name}/${this.selectedSystem.name}` ]);
  }

  apply(base: Observable<GeometryDefinition>): Observable<GeometryDefinition> {
    return base.pipe(
      append(geometry => new Observable<GeometryTrigger>(subscriber => {
        const modalRef = this.modalService.open(GeometryInfoComponent, {backdrop: 'static', keyboard: false, injector: this.injector});
        const componentInstance: GeometryInfoComponent = modalRef.componentInstance;
        componentInstance.geometryInfo = {
          id: 0,
          offset: 1000,
          setup: '',
          ...geometry
        };

        componentInstance.setups = this.selectedSystem.setups;
        const subscription = new Subscription(() => modalRef.close());
        subscription.add(componentInstance.geometry.subscribe(subscriber));
        return subscription;
      }))
    );
  }

  // ****** GEOMETRY SCHEDULATION *******
  async geometryDrawn(geometryDrawn: Observable<GeometryDefinition>) {
    try {
      const geometry = await geometryDrawn.toPromise();
      // Show popup
      if (geometry) {
        await this.addGeometryTrigger(geometry as GeometryTrigger);
      }
      // Se ok
      // Upload
      // Reload
    } catch { }
  }

  async geometrySelect(definition: GeometryTrigger) {
    const modalRef = this.modalService.open(GeometryInfoComponent, {backdrop: 'static', keyboard: false, injector: this.injector});
    const componentInstance: GeometryInfoComponent = modalRef.componentInstance;

    componentInstance.geometryInfo = definition;
    componentInstance.setups = this.selectedSystem.setups;

    try {
      const geometry = await componentInstance.geometry.toPromise();
      if (geometry['update']) {
        await this.updateGeometryTrigger(geometry['update']);
      } else {
        await this.deleteGeometryTrigger(geometry['delete']);
      }
    } catch { } finally {
      modalRef.close();
    }
  }

  validate(geometry: GeometryDefinition): boolean {
    const geo = geometry as GeometryTrigger;
    for (const previousGeom of this.geometries) {
      if (geo.id !== previousGeom.id) {
        const geometryInAnother = geometryContainsAnother(geometry, previousGeom);
        if (geometryInAnother) {
          return false;
        }
        const dist = distance(geometry, previousGeom);
        const value = dist - (geometry['offset'] || 1000) - (previousGeom['offset'] || 1000);
        if (value < 0) {
          return false;
        }
      }
    }
    return true;
  }

  async updateGeometryTrigger(result: GeometryTrigger) {
    await this.geometryTriggerService.updateGeometryTrigger(result.id, result).toPromise();
    this.geometryTriggerService.reload();
  }

  async addGeometryTrigger(geometry: GeometryTrigger) {
    await this.geometryTriggerService.addGeometryTrigger(geometry['update']).toPromise();
    this.geometryTriggerService.reload();
  }

  async deleteGeometryTrigger(id: number) {
    await this.geometryTriggerService.deleteGeometryTrigger(id).toPromise();
    this.geometryTriggerService.reload();
  }

  checkIfFollowed(train: TrainDefinition): boolean {
    if (this.followedTrains) {
      const findTrainToFollow = this.followedTrains.find(fTrain => fTrain[0] === train.name);
      return findTrainToFollow ? findTrainToFollow[1] : false;
    }
    return false;
  }

  handleFollow(train: TrainDefinition) {
    for (const ft of this.followedTrains) {
      if (train.name === ft[0]) {
        ft[1] = !ft[1];
      } else {
        ft[1] = false;
      }
    }
  }

  follow() {
    return this.handleFollow(this.selectedTrain);
  }

  disableFollow() {
    for (const ft of this.followedTrains) {
      ft[1] = false;
    }
  }

  geometryDrawerVisible() {
    if (this.selectedSystem && this.selectedPlan)
    {
      return this.selectedSystem.activatedPlan !== this.selectedPlan.name
        && this.selectedPlan.workingMode === 'geometry';
    }
    return false;
  }

  errorLoad() {
    this.error = true;
  }

  drawTracks(lines: Line[]) {
    for (const line of lines) {
      for (const track of line.tracks) {
        const polyline = L.polyline(track.points, { color: '#4ab9c4', opacity: .6});
        polyline.on('mouseover', function(e) {
          var layer = e.target;
          layer.setStyle({
              opacity: 1,
          });
        });
        polyline.on('mouseout', function(e) {
          var layer = e.target;
          layer.setStyle({
              opacity: 0.6,
          });
        });
        polyline.bindTooltip(`<div style='background:white; padding:1px 3px 1px 3px'><div style='display:flex; flex-direction:column'>${line.code} - ${track.code}</div></div>`, 
        {
            direction: 'center',
            permanent: false,
            sticky: true,
            offset: [10, 0],
            opacity: 0.8,
            className: 'leaflet-tooltip-own' 
        });
        polyline.addTo(this.trackLayer);
      }
    }
  }
}
