import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { OrderedMap } from "immutable";
import { SystemDiagnostic } from "../diagnostic";
import { PowerSystem } from "../power/power.component";

function mapPowerSystems(systems: SystemDiagnostic[]): OrderedMap<string, PowerSystem[]> {
  return OrderedMap<string, PowerSystem[]>().withMutations(element => {

    for (const system of systems) {
      for (const subSystem of system.subSystems) {
        const powerStatus = {
          isMaster: system.isMaster,
          name: subSystem.name,
          wagonName: system.wagonName,
          powerStatus: subSystem.powerStatus,
        };
        if (!element.has(subSystem.name)) {
          element.set(subSystem.name, [ powerStatus ])
        } else {
          element.update(subSystem.name, arr => [...arr, powerStatus ]);
        }
      }
    }
  })
}

@Component({
  selector: 'cwi-remote-power-list',
  templateUrl: './power-list.component.html',
  styleUrls: ['./power-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PowerListComponent {

  powerSystems: OrderedMap<string, PowerSystem[]>;

  @Input()
  public set systems(value: SystemDiagnostic[]) {
    this.powerSystems = mapPowerSystems(value);
  }

  powerTrackBy(index: number) {
    return index;
  }

}
