<div class="standardModeInfo">
  <div>
    <div class="cellInfo">
      <i class="fas fa-file-code mr-1" title="Setup"></i>
      <h6 class="font-weight-normal" i18n>Working with setup {{ standardModeInfo.setup }}</h6>
    </div>
  <div>
    <button class="btn btn-sm text-primary ml-3"
      (click)="editClick()"
      [disabled]="editDisabled"
      title="Edit">
      <span class="fas fa-pencil-alt"></span>
    </button>
    <button class="btn btn-sm text-danger"
      [disabled]="editDisabled"
      cwiClickSink
      triggers=manual
      container=body
      autoClose=outside
      title="Delete"
      popoverTitle="Are you sure?"
      [ngbPopover]="confirmDelete"
      #confirmPopover="ngbPopover"
      (click)="deleteClick(confirmPopover)">
      <span class="fas fa-trash"></span>
    </button>
  </div>
</div>

<ng-template let-close=close #confirmDelete>
  <button class="btn btn-sm btn-outline-danger" (click)="close(true)" i18n>Confirm</button>
</ng-template>
