import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

export interface CustomBreadcrumbDefinition {
  label: string;
  route: string;
}

@Component({
  selector: 'cwi-remote-custom-breadcrumbs',
  templateUrl: './custom-breadcrumbs.component.html',
  styleUrls: ['./custom-breadcrumbs.component.scss']
})
export class CustomBreadcrumbsComponent implements OnDestroy{

  private subscription = new Subscription();
  train: string;
  system: string;
  plan: string;

  constructor(activatedRoute: ActivatedRoute) {
    const params$ = activatedRoute.paramMap.pipe(
      map(paramMap => paramMap)
    );

    this.subscription.add(params$.subscribe(res => {
      this.train = this.hasParameter(res, 'trainName');
      this.system = this.hasParameter(res, 'systemName');
      this.plan = this.hasParameter(res, 'planName');
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  hasParameter(paramMap: ParamMap, param: string): string {
    return paramMap.get(param);
  }

}
