<div class="title-text mat-title" i18n>Configured Group List</div>
<div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="error">
  {{ error }}
  <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeAlert()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<button class="btn btn-primary btn-sm add-new" type="button" (click)="openGroupCreationModal()" i18n>Add Group</button>
<div class="main-container mt-1">
  <div class="mat-elevation-z1 table-container">
      <table mat-table *ngIf="groups$ | async;" [dataSource]="groups$">
        <ng-container matColumnDef="group">
          <th mat-header-cell *matHeaderCellDef i18n> Name </th>
          <td mat-cell *matCellDef="let element"> {{ element.groupName }} </td>
        </ng-container>
        <ng-container matColumnDef="roles">
            <th mat-header-cell *matHeaderCellDef i18n> Roles </th>
            <td mat-cell *matCellDef="let element">
              <cwi-roles-chips *ngIf="roles"
                [selectedValues]="element.roles"
                [allValues]="roles"
                (added)="roleAddedToGroup(element, $event)"
                (deleted)="roleDeletedFromGroup(element, $event)">
              </cwi-roles-chips>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef i18n> Actions </th>
          <td mat-cell *matCellDef="let element">
            <button class="btn btn-sm text-danger btn-link custom-button" (click)="deleteGroup(element.groupName)" i18n>Delete</button>
          </td>
        </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        class="example-element-row">
      </tr>
    </table>
  </div>
</div>