<div class="text-center mt-2">
  <button *ngIf="!speedMode" [disabled]="selectedPlan.name === activatedPlan" class="btn btn-sm btn-primary ml-2" (click)="openAddSpeedMode()" i18n-title title="Add new speed mode" i18n>
    <span class="fas fa-hourglass mr-1"></span> Configure
  </button>
  <cwi-remote-speed-mode-info class="speedModeInfo mt-2" *ngIf="speedMode"
    [speedModeInfo]="speedMode"
    [editDisabled]="selectedPlan.name === activatedPlan"
    (editClicked)="editSpeedMode()"
    (deleteClicked)="deleteSpeedMode(speedMode)">
  </cwi-remote-speed-mode-info>
</div>
