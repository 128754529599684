import { Component } from '@angular/core';

@Component({
  selector: 'cwi-pg-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  providers: []
})
export class UserSettingsComponent {
}
