<div class="row my-auto">
  <div class="col">
    <div class="left-side">
      <span class="summary">{{ getStorageRole(storage.isMaster) }}</span>
      <span class="remaining mt-2" i18n>available {{ spaceFormatted }} {{ unit }}</span>
    </div>
  </div>
  <div class="col">
    <div class="right-side">
      <span class="summary">{{ percentageValue }} %</span>
      <span class="remaining mt-2" i18n>available {{ availableKmValue }} Km</span>
    </div>
  </div>
</div>
<div class="row my-auto">
  <div class="progress mt-2 ml-3 mr-3">
    <div class="progress-bar" role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
      attr.aria-valuenow="{{ percentage }}"
      [ngStyle]="{ 'background-color': getbarColor(), 'width': percentage + '%' }">
    </div>
  </div>
</div>