import { ChangeDetectionStrategy, Component, Input, ViewChild } from "@angular/core";
import { ApexOptions } from "apexcharts";
import {
  ChartComponent,
  ApexAxisChartSeries,
} from "ng-apexcharts";

const CHART_OPTIONS: ApexOptions = {
  chart: {
    type: "area",
    toolbar: {
      show: false
    },
    animations: {
      enabled: false
    },
    height: '100%',
    sparkline: { enabled: true }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: "smooth",
    lineCap: "round",
    colors: ['#5FDCB3'],
    width: 2
  },
  xaxis: {
    type: "numeric",
    crosshairs: {
      show: false
    },
    tooltip: {
      enabled: false
    },
    labels: {
      show: false
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    axisTicks: {
      show: false
    },
    labels: {
      show: false,
    },
    min: 0,
  },
  grid: {
    show: false
  },
  fill: {
    colors: ['#5FDCB3'],
    opacity: 1,
    gradient: {
      shade: 'transparent',
      type: "vertical",
      shadeIntensity: 1,
      opacityFrom: 1,
      opacityTo: 1.5,
    }
  },
  tooltip: {
    enabled: false
  }
};

export interface Test {
  value: number;
  seconds: number;
}

@Component({
  selector: 'cwi-remote-download-chart',
  templateUrl: './download-chart.component.html',
  styleUrls: ['./download-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadChartComponent {
  

  @ViewChild("chart") chart: ChartComponent;

  chartOptions: Partial<ApexOptions> = CHART_OPTIONS;
  series: ApexAxisChartSeries = [{
    name: "Speed",
    data: []
  }];

  @Input()
  public set data(value: number[]) {
    this.series = [{
      name: "Speed",
      data: value
    }];
  }
}
