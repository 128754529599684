import { Moment } from "moment";

type bytes = number;
type meters = number;

export interface GeneralDiagnostic
{
    totalMeters: meters;
    uploadedSize: bytes;
    leftSize: bytes;
    systems: SystemDiagnostic[];
}

export interface SystemDiagnostic
{
    isMaster: boolean;
    wagonName: string;
    totalSpace: bytes;
    availableSpace: bytes;
    respondersMobile: ResponderMobileDiagnostic[];
    respondersIsdn: ResponderIsdnDiagnostic[];
    respondersPs: ResponderPsDiagnostic[];
    subSystems: SubSystemDiagnostic[];
    acquisitions: AcquisitionDiagnostic[];
    trackdbUpdateTime: Moment;
}

export enum ResponderStatus
{
    Disconnected,
    Connected,
    Running
}

export enum ResponderCsTestType {
    Undefined = -1,
    S93TDB,
    S93TIRBoth,
    S93TIRUp,
    S93TIRDown,
    VoiceMOC,
    VoiceQuality,
    DataMOC,
    DataMTC,
    VoiceMTC,
    Waiting,
    Unattended,
    HOBreakTime,
    S93TDBV4,
    S93VIRBoth,
    S93VIRUp,
    S93VIRDown,
    TestBER,
}

export enum ResponderPsTestType {
    Undefined = -1,
    None,
    PDPLossRate,
    TransferDelay,
    Saturate,
    checkConnectivity,
    TransactionTransferDelay,
    TransactionTransferDelay2,
}

export interface ResponderMobileDiagnostic
{
    name: string;
    status: ResponderStatus;
    lastUpdate: Date;
    softwareVersion: string;
    mobileChannels: MobileChannelInfo[];
}

export interface MobileChannelInfo
{
    responderName: string;
    comPort: number;
    name: string;
    vendor: string;
    model: string;
    firmwareVersion: string;
    callingNumber: string;
    calledNumber: string;
    testType: ResponderCsTestType
}

export interface ResponderIsdnDiagnostic
{
    name: string;
    status: ResponderStatus;
    lastUpdate: string;
    softwareVersion: string;
    isdnChannels: IsdnChannelInfo[];
}

export interface IsdnChannelInfo
{
    responderName: string;
    line: number;
    channel: number;
    callingNumber: string;
    calledNumber: string;
    testType: ResponderCsTestType
}

export interface ResponderPsDiagnostic
{
    name: string;
    status: ResponderStatus;
    lastUpdate: string;
    softwareVersion: string;
    psChannels: PsChannelInfo[];
}

export interface PsChannelInfo
{
    responderName: string;
    sourceIp: string;
    testType: ResponderPsTestType
    protocol: string;
}

export enum PowerSupplyStatus
{
    NotStarted,
    StartingGroup,
    AtLeastOneDown,
    Started,
    ShuttingDown,
    Restarting
}

export enum SubSystemStatus
{
    Unreachable,
    Idle,
    Measuring,
    Recording,
    Error
}

export interface SubSystemDiagnostic
{
    name: string;
    fixGps: boolean;
    warning?: string;
    status: SubSystemStatus;
    devices: DeviceDiagnostic[];
    powerStatus: PowerSupplyStatus;
    trackdbUpdateTime: Moment;
}

export type DeviceType = "Visual Scripting" | "Track Chaser EE" | "R&D TSMx Scanner" | string;

export enum DeviceStatus {
    Disabled,
    Connected,
    Disconnected,
    Running
}

export interface DeviceDiagnostic
{
    name: string;
    type: DeviceType,
    status: DeviceStatus
    fixGps?: boolean
}

export enum AcquisitionStatus {
    Processing,
    Completed
}

export interface AcquisitionDiagnostic {
    system: string;
    name: string;
    date: Moment;
    status: AcquisitionStatus;
}
  
