import { ValidatorFn, Validators } from '@angular/forms';
import { InjectionToken, Inject, Injectable } from '@angular/core';

export interface IPlanCreatorConfiguration {
    nameValidators: ValidatorFn|ValidatorFn[];
    descriptionValidators: ValidatorFn|ValidatorFn[];
  }

export const PLANCREATOR_NAME_VALIDATORS = new InjectionToken<ValidatorFn|ValidatorFn[]>('NAME_VALIDATORS');
export const PLANCREATOR_DESCRIPTION_VALIDATORS = new InjectionToken<ValidatorFn|ValidatorFn[]>('NAME_VALIDATORS');
export const PLANCREATOR_CONFIGURATION = new InjectionToken<IPlanCreatorConfiguration>('PLANCREATOR_CONFIGURATION');

export const DEFAULT_PLANCREATOR_NAME_VALIDATORS: ValidatorFn[] = [
  Validators.required,
  Validators.minLength(8),
  Validators.maxLength(16),
  Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)
];
export const DEFAULT_PLANCREATOR_DESCRIPTION_VALIDATORS: ValidatorFn[] = [
  Validators.maxLength(32)
];

@Injectable()
export class DefaultPlanCreatorConfiguration implements IPlanCreatorConfiguration {
    constructor(
      @Inject(PLANCREATOR_NAME_VALIDATORS)
      public readonly nameValidators: ValidatorFn|ValidatorFn[],
      @Inject(PLANCREATOR_DESCRIPTION_VALIDATORS)
      public readonly descriptionValidators: ValidatorFn|ValidatorFn[]
    ) { }
  }
