import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { IRemoteControllerConfiguration, REMOTECONTROLLER_CONFIGURATION } from "@cwi/remote-controller";
import { Observable } from "rxjs";

export const POWER_SERVICE = new InjectionToken<IPowerService>('IPowerService');

export interface IPowerService
{
    restart(trainName: string, wagonName: string, systemName: string): Observable<void>;
}

@Injectable()
export class HttpPowerService implements IPowerService
{
    constructor(
        private readonly $http: HttpClient,
        @Inject(REMOTECONTROLLER_CONFIGURATION)
        private readonly configuration: IRemoteControllerConfiguration,
    ) { }

    restart(trainName: string, wagonName: string, systemName: string): Observable<void> {
        return this.$http.post<void>(`${this.configuration.baseUrl}/train/${trainName}/wagon/${wagonName}/system/${systemName}/restart`, {});
    }
}
